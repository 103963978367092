import React, { useCallback, useEffect, useRef, useState } from "react";
import rightArrowIcon from "src/assets/icons/ArrowLeft.svg";
import { Round } from "../types";
import { SimpleBracket } from "./brackets/simpleBracket";
import { DoubleBracket } from "./brackets/doubleBracket";
import { useLocale } from "../../../../../providers/LocaleProvider";
import LeagueBracket from "./brackets/league/leagueBracket";

interface DesktopBracketProps {
    bracket: Round[];
    classes: { [key: string]: string };
    type: 'single' | 'double' | 'league'; 
}

const DesktopBracket: React.FC<DesktopBracketProps> = ({
    bracket,
    classes,
    type,
}) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const mainBracketsRef = useRef<HTMLDivElement | null>(null);
    const mainHeadersRef = useRef<HTMLDivElement | null>(null);

    const SCROLL_THRESHOLD = 1; 

    const {
        lobby: {
            tournaments: {
                ubtitle,
                gfTitle,
                roundTitle
            },
        },
    } = useLocale();

    // Для двойной сетки, подготавливаем основные раунды
    const mainBracket = type === 'double' ? bracket.map((round) => ({
        ...round,
        matches: round.matches.filter((match) => !match.sub),
    })) : bracket;

    // Фильтруем раунды, чтобы отображать только те, которые имеют матчи
    const filteredMainBracket = type === 'double'
        ? mainBracket.filter(round => round.matches.length > 0)
        : mainBracket;

    const updateArrowVisibility = useCallback(() => {
        if (mainBracketsRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = mainBracketsRef.current;
            const maxScroll = scrollWidth - clientWidth;

            setCanScrollLeft(scrollLeft >= SCROLL_THRESHOLD);

            setCanScrollRight(Math.ceil(scrollLeft) < maxScroll - SCROLL_THRESHOLD);
        }
    }, []);

    const handleScroll = useCallback(() => {
        if (mainBracketsRef.current && mainHeadersRef.current) {
            mainHeadersRef.current.scrollLeft = mainBracketsRef.current.scrollLeft;
            updateArrowVisibility();
        }
    }, [updateArrowVisibility]);

    useEffect(() => {
        const bracketElement = mainBracketsRef.current;
        if (bracketElement) {
            const checkInitialScroll = () => {
                const { scrollWidth, clientWidth } = bracketElement;
                setCanScrollRight(scrollWidth > clientWidth + SCROLL_THRESHOLD);
                setCanScrollLeft(false);
            };

            setTimeout(checkInitialScroll, 100);

            bracketElement.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", updateArrowVisibility);

            return () => {
                bracketElement.removeEventListener("scroll", handleScroll);
                window.removeEventListener("resize", updateArrowVisibility);
            };
        }
    }, [updateArrowVisibility, bracket, handleScroll]);

    useEffect(() => {
        setTimeout(updateArrowVisibility, 100);
    }, [bracket, updateArrowVisibility]);

    const scrollLeft = () => {
        if (mainBracketsRef.current) {
            const columnWidth = mainBracketsRef.current.clientWidth / bracket.length;
            mainBracketsRef.current.scrollBy({ left: -columnWidth, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (mainBracketsRef.current) {
            const columnWidth = mainBracketsRef.current.clientWidth / bracket.length;
            mainBracketsRef.current.scrollBy({ left: columnWidth, behavior: "smooth" });
        }
    };

    return (
        <div className={classes.bracketContainer}>
            <div className={classes.mainBracketSection}>
                {canScrollLeft && (
                    <button
                        className={classes.carouselButtonLeft}
                        onClick={scrollLeft}
                        style={{
                            position: 'absolute',
                            zIndex: 100
                        }}
                    >
                        <img src={rightArrowIcon} alt="Scroll Left" />
                    </button>
                )}

                <div className={type === 'single' ? classes.bracketWrapper : classes.bracketWrapperLong} ref={mainBracketsRef}>
                    {/* Заголовки для обоих типов сеток - вынесены в родительский компонент */}
                    {type === 'single' ? (
                        <div ref={mainHeadersRef} className={classes.fixedHeaders}>
                            {bracket.map((round) => (
                                <div key={`header-${round.title}`} className={classes.headerColumn}>
                                    {round.title}
                                </div>
                            ))}
                        </div>
                    ) : (
                        // Заголовки для двойной сетки
                        <div ref={mainHeadersRef} className={classes.fixedHeadersLong}>
                            {filteredMainBracket.map((round, roundIndex) => (
                                !round.isHidden && (
                                    <div
                                        key={roundIndex}
                                        className={classes.headerColumnLong}
                                    >
                                        {roundIndex + 1 === filteredMainBracket.length
                                            ? gfTitle("Grand Final")
                                            : `${roundTitle('Round')} ${roundIndex + 1}`}
                                    </div>
                                )
                            ))}
                        </div>
                    )}

                    {/* Компоненты сеток без заголовков */}
                    {type === 'single' && <SimpleBracket bracket={bracket} classes={classes} />}
                    {type === 'double' && <DoubleBracket bracket={bracket} classes={classes} hideHeaders={true} />}
                    {type === 'league' && <LeagueBracket />} 
                </div>

                {canScrollRight && (
                    <button
                        className={classes.carouselButtonRight}
                        onClick={scrollRight}
                        style={{
                            position: 'absolute',
                            zIndex: 100
                        }}
                    >
                        <img
                            src={rightArrowIcon}
                            style={{ transform: "rotate(180deg)" }}
                            alt="Scroll Right"
                        />
                    </button>
                )}
            </div>
        </div>
    );
};

export default DesktopBracket;