import { makeStyles } from "@mui/styles";

import theme from "src/theme";
// import greenMoveGameGlow from "src/assets/images/greenMoveGameGlow.svg";
// "&::after": {
//   content: '""',
//   display: "block",
//   width: "586px",
//   height: "144px",
//   position: "absolute",
//   zIndex: 999999,
//   top: "0px",
//   right: "0px",
//   backgroundSize: "cover",
//   backgroundImage: `url(${greenMoveGameGlow})`,
// },

import { MAX_L_TABLET, MIN_XL_TABLET } from "src/pages/game-new2/constants";
import { appColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes isWinnerAndHideBank": {
            "0%": {
                filter: "brightness(1)",
            },
            "70%": {
                filter: "brightness(1)",
                visibility: "visible",
            },
            "100%": {
                visibility: "hidden",
                filter: "brightness(0)",
            },
        },
    },
    rightPartNew: {
        position: "relative",
        paddingLeft: 0,
        wordBreak: "break-all",
        width: "100%",
        display: "grid",
        height: "inherit",
        // gridTemplateRows: "90px auto 90px",
        //====
        gridTemplateRows: "13.5% auto 13.5%",
        overflow: "visible",
        padding: "0px 5px",
        [theme.breakpoints.up(1800)]: {
            // gridTemplateRows: "112px auto 112px",
            padding: "15px 0px",
            "&.isMultiTable": {
                height: "100%",
                padding: 0,
                "@media screen and (max-height: 860px)": {
                    gridTemplateRows: "60px 1fr 60px",
                },
            },
        },
        "&.isMultiTable": {
            height: "100%",
            padding: 0,
            "@media screen and (max-height: 860px)": {
                gridTemplateRows: "60px 1fr 60px",
            },
        },
        // "&.isGameOver": {
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "space-between",
        //     alignItems: "center",
        // },
    },

    normalScreenBox: {
        position: "absolute",
        zIndex: 1,
        top: "10px",
        right: "10px",
        borderRadius: "50%",
        width: "35px",
        aspectRatio: "1 / 1",
        background: "rgba(245, 245, 245, 0.1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("xl")]: {
            top: "14px",
            right: "14px",
            width: "40px",
        },
        [theme.breakpoints.up(1800)]: {
            top: "14px",
            right: "14px",
            width: "50px",
        },
    },

    actionAndDiceCenterBox: {
        display: "grid",
        // gridTemplateRows: "35% 1fr 50%",
        position: "relative",
        // overflow: "hidden",
        gridTemplateRows: "35% 20% 45%",
        width: "100%",
        height: "100%",
        // paddingBottom: "10px",
        "&.isSoloGameEnd": {
            gridTemplateRows: "35% auto auto",
            justifyContent: "stretch",
        },
        "&.isMultiTable": {
            paddingBottom: "0px",
            gridTemplateRows: "30% 1fr 50%",
        },
    },

    showPopupContainerNew: {
        position: "relative",
        bottom: 0,
        width: "100%",
        height: "100%",
        padding: "0px",
        display: "flex",
        alignItems: "center",
        overflow: "scroll",
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
            width: "0!important",
        },
    },

    alertInteractiveData: {
        position: "absolute",
        top: "0%",
        zIndex: 311,
        maxWidth: "101%",
        width: "fit-content",
        height: "fit-content",
        // borderRadius: "24px",
        //padding: '16px',
        borderRadius: "calc(0.4vmin + 0.4%)",
        padding: "calc(0.4vmin + 1%)",
        backdropFilter: "blur(10px)",
        background:
            "linear-gradient(180.21deg, rgba(245, 245, 245, 0) 0.18%, rgba(245, 245, 245, 0.5) 98.24%)",
        [theme.breakpoints.up(1800)]: {
            borderRadius: "calc(0.8vmin + 1%)",
            padding: "calc(0.8vmin + 1%)",
        },
        [theme.breakpoints.up(2400)]: {
            borderRadius: "calc(1vmin + 1%)",
            padding: "calc(1vmin + 1%)",
        },
    },

    alertInteractiveParagraph: {
        fontSize: "30px",
        color: "rgba(0, 186, 108, 1)",
        textAlign: "center",
        fontWeight: 700,
        [theme.breakpoints.up(1800)]: {
            fontSize: "32px",
        },
        [theme.breakpoints.up(2400)]: {
            fontSize: "40px",
        },
        [theme.breakpoints.up(3200)]: {
            fontSize: "50px",
        },
    },

    bankWrapperNew: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 0,
        "&.isMultiRoundGame": {},
        [theme.breakpoints.up("lg")]: {
            width: "inherit",
        },
    },

    bankContainer: {
        display: "grid",
        gridTemplateColumns: "0% 33% 0%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
        transition: "grid-template-columns 0.2s ease-in",
        "&.activeInit": {
            transitionDelay: "0.2s",
            gridTemplateColumns: "33% 33% 0%",
            transition: "grid-template-columns 0.3s ease-in",
        },
        "&.isWinnerWithReject": {
            gridTemplateColumns: "33% 33% 33%",
            transition: "grid-template-columns 0.33s ease-in",
            animationName: "isWinnerAndHideBank",
            animationDelay: "2.15s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "550ms",
        },
        "&.acceptedAndFinished": {
            gridTemplateColumns: "33% 33% 33%",
            transition: "grid-template-columns 0.2s ease-in",
        },
        "&.isWinnerAndHideBank": {
            animationName: "isWinnerAndHideBank",
            animationDelay: "2.15s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "550ms",
        },
        "&.solidWinner": {
            animationName: "isWinnerAndHideBank",
            animationDelay: "2.15s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "550ms",
        },
    },

    dicesWrapperNew: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        width: "100%",
        alignItems: "center",
        zIndex: -1,
        "&.isWinner": {
            zIndex: -1,
        },
    },

    viewModeModalsWrapper: {
        width: "inherit",
        height: "13.5%",
        position: "absolute",
        left: 0,
        // transform: "translateY(-50%)",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridRowGap: "10px",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,

        "&.inProgressPreviewFull": {
            "&.top": {
                top: 0,
                width: "calc(100% - 60px)",
            },
            "&.bottom": {
                bottom: 0,
            },
        },
    },

    winOrLoseAndRematchDialogWrapperNew: {
        maxWidth: "100%",
        minWidth: "80%",

        [theme.breakpoints.between(MAX_L_TABLET, "lg")]: {
            transform: "scale(0.9)",
        },
        [theme.breakpoints.up(MIN_XL_TABLET)]: {
            display: "flex",
            alignItems: "flex-end",
        },
        [theme.breakpoints.up(1800)]: {
            // transform: "scale(1.2)",
            maring: 0,
            padding: 0,
            // marginBottom: 40,
        },
        [theme.breakpoints.up(2400)]: {
            // transform: "scale(1.25)",
            margin: 0,
        },
        "&.isMultiTable": {
            [theme.breakpoints.up(1800)]: {
                transform: "scale(1)",
                marginBottom: 0,
                padding: "10px",
                minWidth: "auto",
            },
        },
    },
});
