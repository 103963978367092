import {useEffect, useRef, useState} from "react";
import {Player, Round} from "../types";
import {BracketColumn} from "./bracketColumn";
import rightArrowIcon from 'src/assets/icons/ArrowLeft.svg';
import {observer} from "mobx-react";
import MatchPair from "./matchPair";
import TournamentLines from "./tournamentLines";
import SimpleBracketCarousel from "./brackets/simpleBracketCarousel";
import DoubleBracketCarousel from "./brackets/doubleBracketCarousel";

interface BracketsCarouselProps {
    bracket: Round[];
    classes: any;
    type: 'single' | 'double';
}

const BracketsCarousel: React.FC<BracketsCarouselProps> = ({
    bracket,
    classes,
    type
}) => {
    return (
        <div className={classes.bracketContainer}>
            {type === 'single' ? (
                <SimpleBracketCarousel bracket={bracket} classes={classes} />
            ) : (
                <DoubleBracketCarousel bracket={bracket} classes={classes} />
            )}
        </div>
    );
};

export default observer(BracketsCarousel);
