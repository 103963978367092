import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Box, useMediaQuery, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useTransition, animated } from "react-spring";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import defaultPlaceholder from "src/assets/images/defaultPlaceholder.png";
import { Spinner } from "src/components/spinner";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";
import TournamentTopPlayersIcon from "src/assets/icons/tournaments/TournamentTopPlayersIcon";
import { prizeModalGold } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import refreshCircle from "src/assets/icons/refreshCircle.svg";

import RatingCrown from "../RaitingCrownBox";

import { useStyles } from "./styles";
import TopPlayerItem from "./topPlayerBox";
import { ITopPlayersList } from "./types";
import { RankType } from "../../store/models";

const TopPlayers = ({
    isLobbyPage,
    paginationLimit,
    maxCountOfPaganation,
    topCountTitle,
}: ITopPlayersList) => {
    const { authStore, achievementsStatStore } = useStores();
    const {
        achievements: {
            result: { loadingResults },
            titles: { topPlayerTitle },
        },
    } = useLocale();
    const classes = useStyles();
    const [paginationCounter, setPaginationCounter] =
        useState<number>(paginationLimit);
    const topPlayerBoxSRef = useRef<HTMLDivElement>(null);
    const changeScreenStyles = useMediaQuery(
        `(max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`
    );
    const [refreshHelper, setHelper] = useState<boolean>(false);

    const topPlayersFromStore = achievementsStatStore?.topPlayers;
    const myPosition = achievementsStatStore?.myPlace;
    const me = authStore?.currentUser?._id;
    const myInfo = authStore?.currentUser;

    useEffect(() => {
        if (topPlayersFromStore && topPlayersFromStore.length) {
            setPaginationCounter(
                Math.round(topPlayersFromStore.length / paginationLimit)
            );
        }
    }, [topPlayersFromStore]);

    const handleScroll = (
        paginationLim: number,
        paginationCounter: number,
        maxCountOfPaganation: number
    ) => {
        //Check count of paganation, in this case limit is 4 (20 exist and 80 fetch object = 100 elems in array)
        if (paginationCounter > maxCountOfPaganation) return;
        if (topPlayerBoxSRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                topPlayerBoxSRef.current;
            if (
                //Observ scrool down and avoid multiple fetch. Fetch is posible when prev data will be responsed
                scrollTop + clientHeight + 50 >= scrollHeight &&
                achievementsStatStore.isLoadedRating
            ) {
                achievementsStatStore.getTopPlayers({
                    skip: paginationLim * paginationCounter,
                    limit: paginationLim,
                });
                setPaginationCounter((prev) => prev + 1);
            }
        }
    };

    const transitionsTopPlayers = useTransition(topPlayersFromStore, {
        from: {
            opacity: 0.3,
            transform: "translate3d(300px,0,0) rotate(120deg)",
            "-webkit-transform": "translate3d(300px,0,0) rotate(120deg)",
        },
        enter: {
            opacity: 1,
            transform: "translate3d(0px,0,0) rotate(0deg)",
            "-webkit-transform": `translate3d(0px,0,0) rotate(0deg)`,
        },
        trail: 70,
    });

    useLayoutEffect(() => {
        if (refreshHelper && achievementsStatStore.isLoadedRating) {
            setHelper(false);
        }
    }, [refreshHelper, achievementsStatStore.isLoadedRating]);

    if (isLobbyPage && !achievementsStatStore.isLoadedRating && refreshHelper)
        return <Spinner isAbsolute />;

    const isValidRankType = (rank: any): rank is RankType =>
        Object.values(RankType).includes(rank);

    if (isLobbyPage) {
        return (
            <>
                <Box
                    className={cn(classes.topPlayersGridBox, {
                        isLobbyPage: isLobbyPage,
                    })}
                >
                    <Box
                        className={cn(classes.topPlayerListTitle, {
                            isLobbyPage: isLobbyPage,
                        })}
                    >
                        <RatingCrown type={"isLobbyPage"} />
                        <p className={cn(classes.topTextTitle, {})}>
                            {topPlayerTitle.compile(
                                { count: topCountTitle },
                                "Top-{{count}} Players"
                            )}
                        </p>
                    </Box>
                    <Box
                        className={cn(classes.refreshClickerBox, {
                            isLobbyPage: isLobbyPage,
                        })}
                        onClick={() => {
                            setHelper(true);
                            achievementsStatStore.resetTopPlayers();
                            achievementsStatStore.getTopPlayers({
                                skip: 0,
                                limit: 20,
                            });
                        }}
                    >
                        <img
                            className={cn(classes.refreshImage, {
                                isLobbyPage: isLobbyPage,
                            })}
                            src={refreshCircle}
                            alt="refresh"
                        />
                    </Box>
                    {topPlayersFromStore ? (
                        <div
                            ref={topPlayerBoxSRef}
                            onScroll={() =>
                                handleScroll(
                                    paginationLimit,
                                    paginationCounter,
                                    maxCountOfPaganation
                                )
                            }
                            className={cn(classes.topPlayersBox, {
                                isLobbyPage: isLobbyPage,
                            })}
                        >
                            {}
                            {transitionsTopPlayers(
                                (style, topPlayer, transitionState, index) => (
                                    <animated.div
                                        style={{
                                            ...style,
                                            backfaceVisibility: "hidden",
                                            willChange: "transform",
                                        }}
                                    >
                                        <TopPlayerItem
                                            index={index + 1}
                                            //@ts-ignore
                                            topPlayer={topPlayer}
                                            //@ts-ignore
                                            topPlayerId={topPlayer?.user}
                                            me={me}
                                            key={`topPlayers_${topPlayer?._id}_${index}`}
                                        />
                                    </animated.div>
                                )
                            )}
                            {typeof myPosition === "number" &&
                                myPosition > topPlayersFromStore.length &&
                                myInfo && (
                                    <>
                                        <Box
                                            className={cn(
                                                classes.topPlayersEllipsisBox,
                                                {}
                                            )}
                                        >
                                            <p
                                                className={cn(
                                                    classes.topPlayersEllipsis,
                                                    {}
                                                )}
                                            >
                                                ...
                                            </p>
                                        </Box>
                                        <TopPlayerItem
                                            index={myPosition}
                                            topPlayer={{
                                                id: myInfo._id,
                                                score: 0,
                                                rank: {
                                                    title: myInfo?.rankFull && isValidRankType(myInfo.rankFull.name)
                                                        ? myInfo.rankFull.name
                                                        : RankType.junior1
                                                },
                                                isSystemAvatar:
                                                    myInfo?.isSystemAvatar,
                                                nickname: myInfo?.nickname,
                                                avatar:
                                                    myInfo?.avatar ||
                                                    defaultPlaceholder,
                                                rating: myInfo?.rating?.elo,
                                                user: myInfo._id,
                                                stats: {
                                                    _id: myInfo._id,
                                                    wonGames: myInfo?.stats?.games?.won ? +myInfo.stats.games.won.toString() : 0,
                                                },
                                            }}
                                            topPlayerId={me!}
                                        />
                                    </>
                                )}
                            {!achievementsStatStore.isLoadedRating && (
                                <div
                                    className={cn(
                                        classes.topPlayerSpinnerWrapper
                                    )}
                                >
                                    <Spinner size={30} />
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className={cn(classes.topPlayersTitle, {})}>
                            {loadingResults("Loading Results")}
                        </p>
                    )}
                </Box>
            </>
        );
    }

    return (
        <>
            <Grid
                item
                md={2}
                lg={changeScreenStyles ? 2 : 3}
                xl={3}
                className={cn(classes.topPlayersGridBox, {
                    isTournamentPage: true,
                })}
            >
                <Box className={classes.topPlayerListTitle}>
                    <TournamentTopPlayersIcon
                        fillColor={prizeModalGold}
                        width={24}
                        height={18}
                    />
                    <p>
                        {topPlayerTitle.compile(
                            { count: topCountTitle },
                            "Top-{{count}} Players"
                        )}
                    </p>
                </Box>
                <Box
                    className={cn(classes.refreshClickerBox, {
                        isTournamentPage: true,
                    })}
                    onClick={() => {
                        setHelper(true);
                        achievementsStatStore.resetTopPlayers();
                        achievementsStatStore.getTopPlayers({
                            skip: 0,
                            limit: 20,
                        });
                    }}
                >
                    <img
                        className={cn(classes.refreshImage, {
                            isTournamentPage: true,
                        })}
                        src={refreshCircle}
                        alt="refresh"
                    />
                </Box>
                {topPlayersFromStore ? (
                    <div
                        ref={topPlayerBoxSRef}
                        onScroll={() =>
                            handleScroll(
                                paginationLimit,
                                paginationCounter,
                                maxCountOfPaganation
                            )
                        }
                        className={cn(classes.topPlayersBox, {})}
                    >
                        <>
                            {transitionsTopPlayers(
                                (style, topPlayer, transitionState, index) => (
                                    <animated.div style={style}>
                                        <TopPlayerItem
                                            index={index + 1}
                                            //@ts-ignore
                                            topPlayer={topPlayer}
                                            //@ts-ignore
                                            topPlayerId={topPlayer?.user}
                                            me={me}
                                            key={`topPlayers_${topPlayer?._id}_${index}`}
                                        />
                                    </animated.div>
                                )
                            )}
                            {typeof myPosition === "number" &&
                                myPosition > topPlayersFromStore.length &&
                                myInfo &&
                                me && (
                                    <>
                                        <Box
                                            className={cn(
                                                classes.topPlayersEllipsisBox,
                                                {}
                                            )}
                                        >
                                            <p
                                                className={cn(
                                                    classes.topPlayersEllipsis,
                                                    {}
                                                )}
                                            >
                                                ...
                                            </p>
                                        </Box>
                                        <TopPlayerItem
                                            index={myPosition}
                                            topPlayer={{
                                                id: myInfo._id,
                                                score: 0,
                                                rank: {
                                                    title: myInfo?.rankFull && isValidRankType(myInfo.rankFull.name)
                                                        ? myInfo.rankFull.name
                                                        : RankType.junior1
                                                },
                                                isSystemAvatar:
                                                    myInfo?.isSystemAvatar,
                                                nickname: myInfo?.nickname,
                                                avatar:
                                                    myInfo?.avatar ||
                                                    defaultPlaceholder,
                                                rating: myInfo?.rating?.elo,
                                                user: myInfo._id,
                                                stats: {
                                                    _id: myInfo._id,
                                                    wonGames: myInfo?.stats?.games?.won ? +myInfo.stats.games.won.toString() : 0,

                                                },
                                            }}
                                            topPlayerId={me}
                                        />
                                    </>
                                )}
                            {!achievementsStatStore.isLoadedRating &&
                                !refreshHelper && (
                                    <div
                                        className={cn(
                                            classes.topPlayerSpinnerWrapper
                                        )}
                                    >
                                        <Spinner size={30} />
                                    </div>
                                )}
                        </>
                    </div>
                ) : (
                    <>
                        <div
                            ref={topPlayerBoxSRef}
                            onScroll={() =>
                                handleScroll(
                                    paginationLimit,
                                    paginationCounter,
                                    maxCountOfPaganation
                                )
                            }
                            className={cn(classes.topPlayersBox, {})}
                        >
                            <>
                                {!achievementsStatStore.isLoadedRating &&
                                refreshHelper ? (
                                    <Spinner isAbsolute />
                                ) : (
                                    <p
                                        className={cn(
                                            classes.topPlayersTitle,
                                            {}
                                        )}
                                    >
                                        {loadingResults("Loading Results")}
                                    </p>
                                )}
                            </>
                        </div>
                    </>
                )}
            </Grid>
        </>
    );
};

export default observer(TopPlayers);
