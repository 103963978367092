import { SoundFilesType } from "../store/models";

import newGameOfferSoundPlay from "./mp3-new/proposition.mp3";
import diceSoundPlay from "./mp3/dice.mp3";

// NEW SOUNDS
import bidConfirmSoundPlay from "./converted_wav/bid-confirm.mp3";
import bidRefuseSoundPlay from "./converted_wav/bid-refuse.mp3";
import buttonSoundPlay from "./converted_wav/button.mp3";
import cashboxSoundPlay from "./converted_wav/cashbox.mp3";
import diceConfirmSoundPlay from "./converted_wav/dice-confirm.mp3";
import drawOfferSoundPlay from "./converted_wav/draw.mp3";
import figureChooseSoundPlay from "./converted_wav/figure-choose.mp3";
import figureEatSoundPlay from "./converted_wav/figure-eat.mp3";
//import pieceSoundPlay from "./converted_wav/figure-move.mp3";
import pieceSoundPlay from "./mp3-new/sound_move.mp3";
import forfeitSoundPlay from "./converted_wav/forfeit.mp3";

import gameCreate from "./mp3-new/signalFly.mp3";
import modalSound from "./converted_wav/modal.mp3";

import diceSpinningSoundPlay from "./converted_wav/dice-spinning.mp3";
import {
    default as rematchOfferSoundPlay,
    default as rematchSoundPlay,
} from "./converted_wav/rematch.mp3";
import {
    default as lostSoundPlay,
    default as roundDefeatSoundPlay,
} from "./converted_wav/round-defeat.mp3";
import {
    default as joinSoundPlay,
    default as roundStartSoundPlay,
} from "./converted_wav/round-start.mp3";
import roundTimeDefeatSoundPlay from "./converted_wav/round-timedefeat.mp3";
import wonSoundPlay from "./converted_wav/round-win.mp3";
import settingsSoundPlay from "./converted_wav/settings.mp3";
import timeoutSoundPlay from "./converted_wav/time.mp3";
import raise from "./converted_wav/Raise_1.mp3";
import beatSoundPlay from "./mp3-new/sound_bey.mp3";

export const sounds: SoundFilesType = {
    newGameOfferSoundPlay,

    // CHANGED
    timeoutSoundPlay,
    wonSoundPlay,
    lostSoundPlay,
    joinSoundPlay,
    pieceSoundPlay,
    beatSoundPlay,
    drawOfferSoundPlay,
    rematchOfferSoundPlay,
    diceSoundPlay,

    // NEW SOUNDS
    bidConfirmSoundPlay,
    bidRefuseSoundPlay,
    buttonSoundPlay,
    cashboxSoundPlay,
    diceConfirmSoundPlay,
    figureChooseSoundPlay,
    figureEatSoundPlay,
    forfeitSoundPlay,
    // gameCreateSoundPlay,
    // we will use music in future, now its option is switch off
    // musicChessOSTDemoSoundPlay,
    // diceButtonV2SoundPlay,
    rematchSoundPlay,
    roundDefeatSoundPlay,
    roundStartSoundPlay,
    roundTimeDefeatSoundPlay,
    settingsSoundPlay,
    diceSpinningSoundPlay,
    gameCreate,
    modalSound,
    raise,
};
