import { observer } from "mobx-react";
import React, { ReactNode, useEffect, useState } from "react";

import useStores from "src/hooks/useStores";
import OfferedX2Modal from "./OfferedX2Modal";
import OfferedRematchModal from "./OfferedRematchModal";
import WinModal from "./WinModal";
import X2AcceptedModal from "./X2AcceptedModal";
import { DoublingStage, RematchStage } from "src/store/gameStore/dialogs";
import RejectedRematchModal from "./RejectedRematchModal";
import { GameModeType, GameModeTypeNew } from "src/store/models";

export interface IViewModeModals {
  player?: {
    _id: string;
    agreement: boolean;
    blockReason: string | null;
    email: string;
    avatar: string;
    isAdmin: boolean;
    isBot: boolean;
    isSupport: boolean;
    isSystemAvatar: boolean;
    nickname: string;
    online: boolean;
    password: string;
    referralProgramId: string | null;
    userType: string;
  };
}

const ViewModeModals = ({ player }: IViewModeModals) => {
  const { gameStore, lobbyStore } = useStores();
  const [modals, setModals] = useState<
    { isActive: boolean; component: ReactNode; key: string }[]
  >([]);

  const isRegularGame =
        gameStore.currentGameNew?.type !== GameModeTypeNew.TOURNAMENT;

    const isLeague = gameStore.currentGameNew?.type === GameModeTypeNew.TOURNAMENT_LEAGUE;

  const dialogs = gameStore.dialogs;
  const curMove = gameStore.currentGameNew?.curMove;
  const curChessMove = gameStore.currentGameNew?.curChessMove;
  const isShowWinOrLostDialog = gameStore.isShowWinOrLostDialog;
  const isViewMode = gameStore.isViewMode()

  // const showX2Modal =
  //     dialogs.doublingDialog.isOpen &&
  //     dialogs.doublingDialog.stage === DoublingStage.acceptOrRejectDoubling &&
  //     !dialogs.giveUpDialog.isOpen &&
  //     !dialogs.rematchDialog.isOpen &&
  //     !isShowWinOrLostDialog;

  const showX2AcceptedModal =
    dialogs.doublingDialog.isOpen &&
    dialogs.doublingDialog.stage ===
    DoublingStage.opponentAcceptedDoubling &&
    !dialogs.giveUpDialog.isOpen &&
    !dialogs.rematchDialog.isOpen &&
    !isShowWinOrLostDialog;

  // const showRematchModal =
  //     dialogs.rematchDialog.isOpen &&
  //     dialogs.rematchDialog.stage ===
  //         RematchStage.waitingForOpponentToDecide &&
  //     !isShowWinOrLostDialog;

    const showRematchModal =
        gameStore.isShowWinOrLostDialog &&
        !!dialogs.rematchData.rematchStartedBy &&
        player &&
        player._id !== dialogs.rematchData.rematchStartedBy &&
        !isLeague;

  const winModalPersona =
    gameStore.isShowWinOrLostDialog &&
    !dialogs.rematchData.rematchStartedBy &&
    player &&
    player._id !== gameStore.currentGameNew?.winner;

  const showX2Modal =
    !gameStore.isShowWinOrLostDialog &&
    !showRematchModal &&
    player &&
    player._id === curMove &&
    curMove !== curChessMove;

    const showRejectedRematchModal =
        dialogs.rematchDialog.isOpen &&
        dialogs.rematchDialog.stage === RematchStage.rejected &&
        !isShowWinOrLostDialog &&
        !isLeague;

  const offeredX2Modal = {
    isActive: showX2Modal,
    component: <OfferedX2Modal key="showX2Modal" />,
    key: "showX2Modal",
  };

  const offeredRematchModal = {
    isActive: showRematchModal,
    component: <OfferedRematchModal key="showRematchModal" />,
    key: "showRematchModal",
  };

  const rejectedRematchModal = {
    isActive: showRejectedRematchModal,
    component: <RejectedRematchModal key="showRejectedRematchModal" />,
    key: "showRejectedRematchModal",
  };

  // const winModal = {
  //     isActive: isShowWinOrLostDialog,
  //     component: <WinModal key="showWinOrLostDialog" />,
  //     key: "isShowWinOrLostDialog",
  // };
  const winModal = {
    isActive: winModalPersona && !isViewMode,
    component: <WinModal key="showWinOrLostDialog" />,
    key: "isShowWinOrLostDialog",
  };

  const x2AcceptedModal = {
    isActive: showX2AcceptedModal,
    component: <X2AcceptedModal key="X2AcceptedModal" />,
    key: "X2AcceptedModal",
  };

  const dataForRegular = [
    offeredX2Modal,
    offeredRematchModal,
    rejectedRematchModal,
    winModal,
    x2AcceptedModal,
  ];
  const dataForTournament = [winModal];

  useEffect(() => {
    const activeModal = isRegularGame
      ? dataForRegular.find((el) => el.isActive)
      : dataForTournament.find((el) => el.isActive);
    if (!activeModal) return;
    const alreadyInModals = modals.find((el) => el.key === activeModal.key);
    if (alreadyInModals) return;

    //@ts-ignore
    setModals((prevModals) => [...prevModals, activeModal]);

    const deleteActiveModal = () => {
      // if (isShowWinOrLostDialog) return;
      setModals((prevModals) => [
        ...prevModals.filter((el) => el.key !== activeModal.key),
      ]);
    };
    const timeout = setTimeout(deleteActiveModal, 60000);

    return () => {
      deleteActiveModal();
      clearTimeout(timeout);
    };
  }, [showX2Modal, showRematchModal, isShowWinOrLostDialog]);

  return <>{modals.map((elem) => elem.component).slice(0, 2)}</>;
};

export default observer(ViewModeModals);
