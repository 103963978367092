import React, { useEffect, useState, useLayoutEffect, useRef, useCallback } from "react";
import { Box, useMediaQuery, Theme } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";

import { useStyles } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import coinIcon from "src/assets/icons/coin.svg";
import arrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import arrowRIcon from "src/assets/icons/RA.svg";
import coinStackIcon from "src/assets/icons/Chips.svg";
import { GameModeTypeNew, ITournament, PlayerInfoExistPosition } from "../../../../store/models";
import useStores from "../../../../hooks/useStores";
import { BracketsContainerProps } from "./types";
import generateTournamentBracket from "./generateTournamentBracket";
import BracketCarousel from "./components/bracketCarousel";
import { fileHelper } from "../../../../shared/helpers/FileHelper";
import { useLocale } from "src/providers/LocaleProvider";
import GamePreview from "src/pages/lobby/components/GamePreview";
import freeCoinIcon from "src/assets/icons/bonusCoin.svg";
import DesktopBracket from "./components/desktopBracket";
import FindMeButton from "./components/findMeButton";
import MobileLeaguePage from "./components/brackets/league/mobileLeagueBracket";


const BracketsContainerNew: React.FC<BracketsContainerProps> = observer(() => {
    const classes = useStyles({ matchIndex: 0, marginTop: 0, columnIndex: 0 });
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { tournamentsStore, generalStore, viewHistoryGameStore, gamesStore, authStore } = useStores();
    const [tournament, setTournament] = useState<ITournament | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [foundPosition, setFoundPosition] = useState<{
        columnIndex: number;
        matchIndex: number;
    } | null>(null);

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const bracketsRef = useRef<HTMLDivElement | null>(null);
    const [isOpenPreview, setIsOpenPreview] = useState(true);

    const {
        lobby: {
            tournaments: {
                wins, 
                back, 
                upTitle,
                ratTitle,
                final,  
                semifinal,
                expected,
                bye,
                placeTitle,
                noPrize,
                ubtitle,
                thirdPlace
            },
        },
    } = useLocale();

    const [currentStep, setCurrentStep] = useState(0); // Текущий шаг

    const bracket = generateTournamentBracket(tournament, {
        final: final('Final'),
        semifinal: semifinal('Semi-final'),
        expected: expected('To be decided'),
        bye: bye('Bye'),
        thirdPlace: thirdPlace('3st place match'),
    });

    useEffect(() => {
        const loadTournament = async () => {
            try {
                setIsLoading(true);

                const tournament = tournamentsStore.tournaments.find(t => t._id === id);

                if (tournament) {                   
                    setTournament({
                        ...tournament,
                        pairs: tournament.pairs.map(pair => ({
                            ...pair,
                            firstWins: pair.firstWins ?? 0, 
                            secondWins: pair.secondWins ?? 0, 
                        })),
                    });

                    const maxStep = Math.max(...tournament.pairs.map(pair => pair.step), 0);
                    setCurrentStep(maxStep);
                } else {
                    console.warn("Tournament not found in tournamentsStore. Please check handleTournamentsFromLobby.");
                }
            } catch (error) {
                console.error("Error loading tournament from tournamentsStore:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadTournament();
    }, [id, tournamentsStore.tournaments]);


    useEffect(() => {
        if (tournament?.imageURL) {
            generalStore.setTournamentBackground(fileHelper.getMediaFileURL(tournament.imageURL));
        }

        return () => {
            generalStore.setTournamentBackground(null);
        }
    }, [tournament]);

    const gameStoreById = viewHistoryGameStore.gameId ? 
    //@ts-ignore
    gamesStore.getGameStoreById(viewHistoryGameStore.gameId) : null

        useLayoutEffect(() => {
      if (gameStoreById && gameStoreById?.currentGameNew?.type !== GameModeTypeNew.TOURNAMENT 
      ) {
        // viewHistoryGameStore.reset()
      }
    }, [gameStoreById, tournament])


    const findCurrentUser = useCallback(() => {
        if (!tournament || !authStore.currentUser) return;

        const userPair = tournament.pairs
            .slice()
            .reverse()
            .find(pair =>
                pair.player1?._id === authStore.currentUser?._id ||
                pair.player2?._id === authStore.currentUser?._id
            );

        if (!userPair) {
            console.warn("User not found in tournament pairs.");
            return;
        }

        const isByeRound = !userPair.player2 || userPair.player2._id.startsWith("BYE_");

        const columnIndex = bracket.findIndex(round =>
            round.matches.some(match =>
                (match.player1?._id === userPair.player1?._id &&
                    (isByeRound || match.player2?._id === userPair.player2?._id)) ||
                (match.player1?._id === userPair.player2?._id &&
                    match.player2?._id === userPair.player1?._id)
            )
        );

        const matchIndex = bracket[columnIndex]?.matches.findIndex(match =>
            (match.player1?._id === userPair.player1?._id &&
                (isByeRound || match.player2?._id === userPair.player2?._id)) ||
            (match.player1?._id === userPair.player2?._id &&
                match.player2?._id === userPair.player1?._id)
        );

        if (columnIndex !== -1 && matchIndex !== -1) {
            setFoundPosition({ columnIndex, matchIndex });

            setTimeout(() => {
                const matchElement = document.querySelector(
                    `[data-column="${columnIndex}"][data-match="${matchIndex}"]`
                ) as HTMLElement;

                if (!matchElement) {
                    console.warn("Match element not found in DOM.");
                    return;
                }

                document.querySelectorAll(`.${classes.highlightedPlayerBlock}`).forEach(el => {
                    el.classList.remove(classes.highlightedPlayerBlock);
                });

                const player1Element = matchElement.querySelector(`.${classes.player}:first-child`);
                const player2Element = matchElement.querySelector(`.${classes.player}:last-child`);

                if (userPair.player1?._id === authStore.currentUser?._id && player1Element) {
                    requestAnimationFrame(() => {
                        player1Element.classList.add(classes.highlightedPlayerBlock);
                    });
                } else if (userPair.player2?._id === authStore.currentUser?._id && player2Element) {
                    requestAnimationFrame(() => {
                        player2Element.classList.add(classes.highlightedPlayerBlock);
                    });
                }

                // Скролл к элементу
                if (bracketsRef.current) {
                    const container = bracketsRef.current;
                    const elementRect = matchElement.getBoundingClientRect();
                    const containerRect = container.getBoundingClientRect();

                    const padding = 20;

                    const isFullyVisible =
                        elementRect.left >= (containerRect.left + padding) &&
                        elementRect.right <= (containerRect.right - padding);

                    if (!isFullyVisible) {
                        const scrollTo = container.scrollLeft +
                            (elementRect.left - containerRect.left) -
                            (containerRect.width - elementRect.width) / 2;

                        container.scrollTo({
                            left: Math.max(0, scrollTo),
                            behavior: 'smooth'
                        });
                    }
                }
            }, 0);
        }
    }, [tournament, authStore.currentUser, bracket, classes.highlightedPlayerBlock]);

    if (!tournament) {
        return (
            <div className={classes.errorContainer}>
                
            </div>
        );
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleBackClick = () => {
        history.push('/tournaments');
    };

    const gameSeries = tournament.gameSettings?.matchCount || 2;

    const formatPrize = (prize: number) => {
        const [whole, decimal] = prize.toString().split('.');
        if (!decimal) return whole;
        return `${whole}.${decimal.padEnd(2, '0').slice(0, 2)}`;
    };

    const shouldShowNoPrizes = !tournament.prizes ||
        tournament.prizes.length === 0 ||
        tournament.prizes.every(prize => prize.amount === 0);

    const togglePreview = (status) => {
        setIsOpenPreview(status);
    };

    const isLeague = tournament?.type === 3;

    return (
        <div className={classes.root}>
            <Box className={cn(classes.previewTourBox, {
                active: viewHistoryGameStore.gameId,
                league: tournament?.type === 3, 
            })}>
                <GamePreview
                    isPreviewGame={undefined}
                    isFriends={false}
                    showPreview={() => { }}
                    showFriends={() => { }}
                    togglePreview={togglePreview} 
                    isFullScreen={null}
                    isOpenPreview={isOpenPreview} 
                    componentPlace={PlayerInfoExistPosition.inBracketPreview}
                />
            </Box>

            <div className={classes.backgroundImage}/>
            <div className={classes.gradientOverlay} />

            <div className={classes.content}>
                {isMobile ? (
                    <div className={classes.headerMobile}>
                        <div className={classes.buttonBlockMobile}>
                            <button className={classes.backButtonMobile} onClick={handleBackClick}>
                                <img className={classes.arrowRight} src={arrowRIcon} alt="back" />
                                {back('Back')}
                            </button>
                            {/*<FindMeButton*/}
                            {/*    onClick={findCurrentUser}*/}
                            {/*    isFound={foundPosition !== null}*/}
                            {/*    isDisabled={!authStore.currentUser}*/}
                            {/*/>*/}
                        </div>
                        <div className={classes.titleMobile}>
                            {tournament.title}
                            <div className={classes.prizeInfoMobile}>
                                <img
                                    className={classes.coin}
                                    src={tournament.buyIn === 0 ? freeCoinIcon : coinIcon}
                                    alt="coin"
                                />
                                <span className={classes.gradientSpan}>
                                    {formatPrize(tournament.totalPrize)}
                                </span>

                                {tournament?.type === 3 ? (
                                    <button
                                        className={classes.leagueButton}
                                        onClick={() => console.log("Лига: кнопка нажата")}
                                    >
                                        Forfeit
                                    </button>
                                ) : (
                                    <div className={classes.dropdownWrapper}>
                                        <img
                                            className={cn(classes.arrowDown, {
                                                [classes.arrowDownRotated]: isDropdownOpen,
                                            })}
                                            src={arrowIcon}
                                            alt="arrow"
                                            onClick={toggleDropdown}
                                        />
                                        {isDropdownOpen && (
                                            <div className={classes.dropdownContent}>
                                                <div className={classes.placeWrapper}>
                                                    {shouldShowNoPrizes ? (
                                                        <div className={classes.noPrizesText}>{noPrize('Призы отсутствуют')}</div>
                                                    ) : (
                                                        tournament.prizes
                                                            .slice()
                                                            .sort((prizeA, prizeB) => (prizeA.place || 0) - (prizeB.place || 0))
                                                            .map((prize, index) => (
                                                                <div key={index} className={classes.placeRow}>
                                                                    <span className={classes.placeText}>
                                                                        {prize.place
                                                                            ? `${prize.place} ${placeTitle('Place')}`
                                                                            : `${prize.range?.[0]}-${prize.range?.[1]} ${placeTitle('Place')}`}
                                                                    </span>
                                                                    <div className={classes.rewardText}>
                                                                        <img
                                                                            src={tournament.buyIn === 0 ? freeCoinIcon : coinIcon}
                                                                            alt="Coin"
                                                                            className={classes.coinIcon}
                                                                        />
                                                                        <span className={classes.gradientText}>{prize.amount}</span>
                                                                        <span className={classes.regularText}>
                                                                            + {prize.rating} {ratTitle('RP')}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>                                
                                )}
                            </div>
                        </div>
                        <div className={classes.dateInfoMobile}>
                            {tournament?.type === 3 ? (
                                <>
                                    <div className={classes.leagueBlock}>
                                        <div className={classes.leagueInfo}>
                                            Start balance:
                                            <img
                                                className={classes.coin}
                                                src={coinStackIcon}
                                                alt="coins"
                                            />
                                            <span style={{ fontWeight: 700 }}>100</span>
                                        </div>
                                        {" "} • {" "}
                                        <div className={classes.winsBlock}>
                                            <span style={{ fontWeight: 700 }}>
                                                {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}
                                            </span>
                                        </div>
                                    </div>  
                                </>
                            ) : (
                                <>
                                    <div className={classes.winsBlockMobile}>
                                        {upTitle('Up to')}
                                        <span style={{ fontWeight: 700 }}>
                                            {tournament.gameSettings.matchCount}
                                        </span>
                                        {wins('wins ')} • {" "}
                                        <span style={{ fontWeight: 700 }}>
                                            {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={`${classes.header} ${isLeague ? classes.headerNoBorder : ""}`}>
                       <div className={classes.headerBlock}>
                            <div className={classes.buttonBlock}>
                                <button className={classes.backButtonMobile} onClick={handleBackClick}>
                                    <img className={classes.arrowRight} src={arrowRIcon} alt="back" />
                                    {back('Back')}
                                </button>
                                {/*<FindMeButton*/}
                                {/*    onClick={findCurrentUser}*/}
                                {/*    isFound={foundPosition !== null}*/}
                                {/*    isDisabled={!authStore.currentUser}*/}
                                {/*/>*/}
                            </div>

                            <div className={classes.titleContainer}>
                                <div className={classes.title}>
                                    <div className={classes.titleText}>
                                        {tournament.title}
                                        <img
                                            className={classes.coin}
                                            src={tournament.buyIn === 0 ? freeCoinIcon : coinIcon}
                                            alt="coin"
                                        />
                                        <span className={classes.gradientSpan}>
                                            {formatPrize(tournament.totalPrize)}
                                        </span>

                                            {tournament?.type === 3 ? (
                                                <button
                                                    className={classes.leagueButton}
                                                    onClick={() => console.log("Лига: кнопка нажата")}
                                                >
                                                    Forfeit
                                                </button>
                                            ) : (
                                                <div className={classes.dropdownWrapper}>
                                                    <img
                                                        className={cn(classes.arrowDown, {
                                                            [classes.arrowDownRotated]: isDropdownOpen,
                                                        })}
                                                        src={arrowIcon}
                                                        alt="arrow"
                                                        onClick={toggleDropdown}
                                                    />
                                                    {isDropdownOpen && (
                                                        <div className={classes.dropdownContent}>
                                                            <div className={classes.placeWrapper}>
                                                                {shouldShowNoPrizes ? (
                                                                    <div className={classes.noPrizesText}>{noPrize('Призы отсутствуют')}</div>
                                                                ) : (
                                                                    tournament.prizes
                                                                        .slice()
                                                                        .sort((prizeA, prizeB) => (prizeA.place || 0) - (prizeB.place || 0))
                                                                        .map((prize, index) => (
                                                                            <div key={index} className={classes.placeRow}>
                                                                                <span className={classes.placeText}>
                                                                                    {prize.place
                                                                                        ? `${prize.place} ${placeTitle('Place')}`
                                                                                        : `${prize.range?.[0]}-${prize.range?.[1]} ${placeTitle('Place')}`}
                                                                                </span>
                                                                                <div className={classes.rewardText}>
                                                                                    <img
                                                                                        src={tournament.buyIn === 0 ? freeCoinIcon : coinIcon}
                                                                                        alt="Coin"
                                                                                        className={classes.coinIcon}
                                                                                    />
                                                                                    <span className={classes.gradientText}>{prize.amount}</span>
                                                                                    <span className={classes.regularText}>
                                                                                        + {prize.rating} {ratTitle('RP')}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}                                      
                                    </div>
                                </div>
                                <div className={classes.dateInfo}>
                                    {tournament?.type === 3 ? (
                                        <>
                                            <div className={classes.leagueBlock}>
                                                <div className={classes.leagueInfo}>
                                                    Start balance:
                                                    <img
                                                        className={classes.coin}
                                                        src={coinStackIcon}
                                                        alt="coins"
                                                    />
                                                    <span style={{ fontWeight: 700 }}>100</span>
                                                </div>
                                                    {" "} • {" "}
                                                <div className={classes.winsBlock}>
                                                    <span style={{ fontWeight: 700 }}>
                                                        {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        })}
                                                    </span>
                                                </div>
                                            </div>                                          
                                        </>
                                    ) : (
                                        <>
                                            <div className={classes.winsBlock}>
                                                {upTitle('Up to')}
                                                <span style={{ fontWeight: 700 }}>
                                                    {tournament.gameSettings.matchCount}
                                                </span>
                                                {wins('wins ')} • {" "}
                                                <span style={{ fontWeight: 700 }}>
                                                    {new Date(tournament.startAt).toLocaleDateString("ru-RU", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "numeric",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>                               
                            </div>
                    </div>
                        {tournament?.type === 2 && (
                            <div className={classes.titleBracket}>
                                    <span>{ubtitle('Upper bracket')}</span>
                            </div>
                        )}
                        </div>

                )}
                {isMobile ? (
                    tournament.type === 3 ? (
                        <MobileLeaguePage /> 
                    ) : (
                        <BracketCarousel
                            bracket={bracket}
                            classes={classes}
                            type={tournament.type === 2 ? 'double' : 'single'}
                        />
                    )
                ) : (
                    <DesktopBracket
                        bracket={bracket}
                        classes={classes}
                        type={
                            tournament.type === 3
                                ? 'league'  
                                : tournament.type === 2
                                    ? 'double'  
                                    : 'single'  
                        }
                    />
                )}
            </div>
        </div>
    );
});


export default BracketsContainerNew;