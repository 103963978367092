import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import { Grid, Box, Container } from "@mui/material";
import defaultAvatar from "src/assets/images/defaultPlaceholder.png";

import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import watchingUsers from "src/assets/icons/watching-users.svg";
import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import endpoints from "src/core/endpoints";
import calcNameLength from "src/utils/calcNameLength";
import useChangeTheme from "src/hooks/useChangeTheme";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";
import {
    ChatExistPosition,
    PlayerInfoExistPosition,
    RankType,
} from "src/store/models";

import { useStyles } from "./styles";
import WatchingToggler from "../../../../components/WatchingPlayers/components/WatchingToggler";
import RankItem from "src/components/RankItem/index";
import { UserStarsBorder } from "../../../../components/controls/app-bar/components/animateStar/UserStarsBorder";

interface IDesktopWatchers {
    componentPosition: keyof typeof ChatExistPosition;
}

const DesktopWatchers = ({ componentPosition }: IDesktopWatchers) => {
    const { gameStore, authStore } = useStores();
    const me = authStore.currentUser?._id;
    const [isOpen, setOpen] = useState<boolean | WatchersStatusType>(false);
    const viewers = gameStore.currentGameNew?.viewers || [];
    const p1 = gameStore.currentGameNew?.players[0];
    const p2 = gameStore.currentGameNew?.players[1];
    const isLeague = gameStore.currentGameNew?.tournament?.type === 3;

    const watchers = useMemo(() => {
        const filtred = viewers.filter((watcher) => {
            return watcher._id !== p1?._id && watcher._id !== p2?._id;
        });
        return filtred;
    }, [viewers, gameStore.currentGame?.id]);

    const classes = useStyles();
    const appearanceTheme = useChangeTheme();
    const chessBoardProps = useChessBoardProps();
    const {
        lobby: {
            playerMiniStats: { statsRank, tournamentRp, noSpectators },
        },
        tournaments: {
            titles: {
                prizesTab: { rankPointShort },
            },
        },
    } = useLocale();

    const gameAndPreviewPosition =
        componentPosition === ChatExistPosition.inGame ||
        componentPosition === ChatExistPosition.inProgressPreview ||
        componentPosition === ChatExistPosition.inProgressPreviewFull;

    if (gameAndPreviewPosition) {
        return (
            <Box
                component="div"
                className={cn(
                    classes.watchingMainContainer,
                    appearanceTheme,
                    componentPosition
                )}
            >
                <div
                    className={cn(
                        classes.watchingBox,
                        appearanceTheme,
                        componentPosition,
                        { inLeague: isLeague }
                    )}
                >
                    {watchers &&
                        watchers.map((user, index) => (
                            <Box
                                key={`${user._id}_box`}
                                className={cn(
                                    classes.watchingUserBox,
                                    appearanceTheme,
                                    componentPosition,
                                    { noBorder: watchers.length === 1 } 
                                )}
                            >
                                <div className={classes.avatarContainer}>
                                    <img
                                        src={
                                            !!user.avatar
                                                ? `${endpoints.avatarPath}${user.avatar}`
                                                : `${defaultAvatar}`
                                        }
                                        className={cn(
                                            classes.watchingUserAvatar,
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    />
                                    {/*<UserStarsBorder*/}
                                    {/*    starsCount={user.stars || 0}*/}
                                    {/*    className={classes.starsOverlay} */}
                                    {/*/>*/}
                                </div>
                                <div
                                    className={cn(
                                        classes.watchingUserTextBox,
                                        appearanceTheme,
                                        componentPosition
                                    )}
                                >
                                    <p
                                        className={cn(
                                            classes.watchingUserNick,
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        {user.nickname}
                                    </p>
                                    <span
                                        className={cn(
                                            classes.watchingUserSpanRank,
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        <p
                                            className={cn(
                                                classes.watchingUserRank,
                                                "rank",
                                                appearanceTheme,
                                                componentPosition
                                            )}
                                        >
                                            {rankPointShort("RP")}:{" "}
                                            <strong>{Math.round(user.rating)}</strong>
                                        </p>
                                        <RankItem
                                            rank={
                                                user.rankFull.name as RankType
                                            }
                                            size={25}
                                        />
                                        {/* <img src={watchingUsers} alt="rank" /> */}
                                    </span>
                                    {/* <span
                                        className={cn(
                                            classes.watchingUserSpanRank,
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        <p
                                            className={cn(
                                                classes.watchingUserRank,
                                                "rankPoint",
                                                appearanceTheme,
                                                componentPosition
                                            )}
                                        >
                                            {tournamentRp("Rank Points")}:
                                        </p>
                                        <p
                                            className={cn(
                                                classes.watchingUserRank,
                                                "rankPointNumber",
                                                appearanceTheme,
                                                componentPosition
                                            )}
                                        >
                                            {user.rating}
                                        </p>
                                    </span> */}
                                </div>
                            </Box>
                        ))}
                </div>
            </Box>
        );
    }

    if (componentPosition === ChatExistPosition.inBracketPreview) {
        return (
            <Box
                component="div"
                className={cn(
                    classes.watchingMainContainer,
                    appearanceTheme,
                    componentPosition
                )}
            >
                <div
                    className={cn(
                        classes.watchingBox,
                        appearanceTheme,
                        componentPosition
                    )}
                >
                    {watchers &&
                        watchers.map((user, index) => (
                            <Box
                                key={`${user._id}_box`}
                                className={cn(
                                    classes.watchingUserBox,
                                    appearanceTheme,
                                    componentPosition,
                                    { noBorder: watchers.length === 1 } 
                                )}
                            >
                                <img
                                    src={
                                        !!user.avatar
                                            ? `${endpoints.avatarPath}${user.avatar}`
                                            : `${defaultAvatar}`
                                    }
                                    className={cn(
                                        classes.watchingUserAvatar,
                                        appearanceTheme,
                                        componentPosition
                                    )}
                                />
                                <div
                                    className={cn(
                                        classes.watchingUserTextBox,
                                        appearanceTheme,
                                        componentPosition
                                    )}
                                >
                                    <p
                                        className={cn(
                                            classes.watchingUserNick,
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        {user.nickname}
                                    </p>
                                    <span
                                        className={cn(
                                            classes.watchingUserSpanRank,
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        <p
                                            className={cn(
                                                classes.watchingUserRank,
                                                "rank",
                                                appearanceTheme,
                                                componentPosition
                                            )}
                                        >
                                            {statsRank("Rank")}:
                                        </p>
                                        <RankItem
                                            rank={
                                                user.rankFull.name as RankType
                                            }
                                            size={25}
                                        />
                                    </span>

                                    <span
                                        className={cn(
                                            classes.watchingUserSpanRank,
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        <p
                                            className={cn(
                                                classes.watchingUserRank,
                                                "rank",
                                                appearanceTheme,
                                                componentPosition
                                            )}
                                        >
                                            {rankPointShort("RP")}:{" "}
                                            <strong>{Math.round(user.rating)}</strong>
                                        </p>
                                        {/* <img src={watchingUsers} alt="rank" /> */}
                                    </span>
                                    {/* <span
                                    className={cn(
                                        classes.watchingUserSpanRank,
                                        appearanceTheme,
                                        componentPosition
                                    )}
                                >
                                    <p
                                        className={cn(
                                            classes.watchingUserRank,
                                            "rankPoint",
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        {tournamentRp("Rank Points")}:
                                    </p>
                                    <p
                                        className={cn(
                                            classes.watchingUserRank,
                                            "rankPointNumber",
                                            appearanceTheme,
                                            componentPosition
                                        )}
                                    >
                                        {user.rating}
                                    </p>
                                </span> */}
                                </div>
                            </Box>
                        ))}
                </div>
            </Box>
        );
    }

    return (
        <>
            {!chessBoardProps?.isMultiTable && (
                <WatchingToggler
                    setOpen={(properties) => setOpen(properties)}
                    openStatus={WatchersStatusType.show}
                    isOpen={isOpen}
                    watchingArray={watchers}
                    isMobileGamePage={false}
                    togglerClassName={cn({
                        [classes.watchingToggler]:
                            chessBoardProps?.isMultiTable,
                    })}
                />
            )}
            <OutsideClickHandler
                onOutsideClick={() => {
                    isOpen === WatchersStatusType.show &&
                        setOpen(WatchersStatusType.hide);
                }}
            >
                <Box
                    className={
                        isOpen === WatchersStatusType.show
                            ? cn(classes.watchingListShow, appearanceTheme)
                            : isOpen === WatchersStatusType.hide
                            ? cn(classes.watchingListHide, appearanceTheme)
                            : cn(classes.watchingListReload, appearanceTheme)
                    }
                >
                    <Container
                        className={cn(
                            classes.watchingListContainerDesktop,
                            appearanceTheme
                        )}
                    >
                        <WatchingToggler
                            setOpen={(properties) => setOpen(properties)}
                            openStatus={WatchersStatusType.hide}
                            isOpen={isOpen}
                            watchingArray={watchers}
                            dryStyle={true}
                            togglerClassName={classes.watchingToggler}
                            elementPlace={
                                PlayerInfoExistPosition.inProgressPreview
                            }
                        />
                        <Grid
                            container
                            spacing={1.5}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.watchingMainGrid}
                        >
                            {watchers &&
                                watchers.map((user, index) => (
                                    <Grid
                                        key={user._id}
                                        item
                                        xs={4}
                                        style={{
                                            minWidth: "50px",
                                            maxWidth: "80px",
                                        }}
                                    >
                                        <Box
                                            key={`${user._id}_box`}
                                            className={classes.watchingUserBox}
                                        >
                                            <img
                                                src={
                                                    !!user.avatar
                                                        ? `${endpoints.avatarPath}${user.avatar}`
                                                        : `${defaultAvatar}`
                                                }
                                                className={cn(
                                                    classes.watchingUserAvatar,
                                                    appearanceTheme
                                                )}
                                            />
                                            <p
                                                className={
                                                    classes.watchingUserNick
                                                }
                                            >
                                                {calcNameLength(
                                                    user.nickname,
                                                    450,
                                                    9
                                                )}
                                            </p>
                                        </Box>
                                    </Grid>
                                ))}
                        </Grid>
                    </Container>
                </Box>
            </OutsideClickHandler>
        </>
    );
};

export default observer(DesktopWatchers);
