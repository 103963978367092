import React from "react";
import { Box, Typography } from "@mui/material";
import cn from "classnames";

import { useRanksTranslations } from "src/hooks/useRankData";
import { rankIconsList } from "src/components/Chessmen/SvgIcons";

import gm1Image from "../../assets/images/gm1.png";
import gm2Image from "../../assets/images/gm2.png";
import gm3Image from "../../assets/images/gm3.png";
import elite1Image from "../../assets/images/elite1.png";
import elite2Image from "../../assets/images/elite2.png";
import elite3Image from "../../assets/images/elite3.png";
import pro1Image from "../../assets/images/pro1.png";
import pro2Image from "../../assets/images/pro2.png";
import pro3Image from "../../assets/images/pro3.png";
import { nonChessmenFigures } from "../../constants/ranks";

import { useStyles } from "./styles";
import { getIconByRank } from "../RankSystem";
import { RankType } from "../../../../store/models";

type CurrentRankProps = {
    rank: string;
    label?: string | undefined;
    placePosition?: string;
};

const pawnRanks = ["pawn1", "pawn2", "pawn3"];
const knightRanks = ["knight1", "knight2", "knight3"];
const bishopRanks = ["bishop1", "bishop2", "bishop3"];
const rookRanks = ["castle1", "castle2", "castle3"];
const queenRanks = ["queen1", "queen2", "queen3"];
const kingRanks = ["king1", "king2", "king3"];

const getCommonTranslations = (key: string) => {
    const isPawn = pawnRanks.includes(key) && "pawn";
    const isKnight = knightRanks.includes(key) && "knight";
    const isBishop = bishopRanks.includes(key) && "bishop";
    const isRook = rookRanks.includes(key) && "castle";
    const isQueen = queenRanks.includes(key) && "queen";
    const isKing = kingRanks.includes(key) && "king";

    return isPawn || isKnight || isBishop || isRook || isQueen || isKing;
};

const nonChessmenFigureImages = {
    gm1: gm1Image,
    gm2: gm2Image,
    gm3: gm3Image,
    elit1: elite1Image,
    elit2: elite2Image,
    elit3: elite3Image,
    pro1: pro1Image,
    pro2: pro2Image,
    pro3: pro3Image,
};

const CurrentRank = ({ rank, label, placePosition }: CurrentRankProps) => {
    const styles = useStyles();
    const rankSystemTranslations = useRanksTranslations();
    const rankLabel = rank?.toLowerCase?.().replace(/\s+/g, "").replace(/[^a-z0-9]/g, "") ?? "";
    const rankInfo = rankIconsList[rankLabel];
    const RankIcon = getIconByRank(rankLabel as RankType);
    console.log("RankIcon:", RankIcon);
    console.log("rankLabel:", rankLabel);

    const showNonChessmenFigureRank = nonChessmenFigures.includes(rankLabel);

    const commonTranslationMemo = getCommonTranslations(rankLabel);
    const commonTranslation =
        commonTranslationMemo && rankSystemTranslations[commonTranslationMemo];

    const nonChessmenFigureImage =
        showNonChessmenFigureRank && nonChessmenFigureImages[rankLabel];

    const isComponent = typeof RankIcon === "function";

    if (label !== "profile") {
        return (
            <>
                {showNonChessmenFigureRank ? (
                    <img
                        src={nonChessmenFigureImage as string}
                        className={cn(
                            styles.nonChessmenFigureIcon,
                            label,
                            "nonProfile",
                            placePosition
                        )}
                        alt="Rank Icon"
                    />
                ) : isComponent ? (
                    <RankIcon
                        className={cn(
                            styles.rankIcon,
                            label,
                            "nonProfile",
                            placePosition
                        )}
                        label={label}
                    />
                ) : (
                    <img
                        src={RankIcon as string}
                        className={cn(
                            styles.rankIcon,
                            label,
                            "nonProfile",
                            placePosition
                        )}
                        alt="Rank Icon"
                    />
                )}
            </>
        );
    }


    return (
        < Box
            component="div"
            className={cn(styles.rankBoxWrapper, label, placePosition, {
                showBg: !showNonChessmenFigureRank,
            })}
        >
            {showNonChessmenFigureRank ? (
                <img
                    src={nonChessmenFigureImage as string}
                    className={cn(styles.nonChessmenFigureIcon, label, placePosition)}
                    alt="Rank Icon"
                />
            ) : (
                <Box component="div" className={cn(styles.rankBox, placePosition)}>
                    {isComponent ? (
                        <RankIcon
                            className={cn(styles.rankIcon, placePosition)}
                            label={label}
                        />
                    ) : (
                        <img
                            src={RankIcon as string}
                            className={cn(styles.rankIcon, placePosition)}
                            alt="Rank Icon"
                        />
                    )}
                    <Typography component="p" className={cn(styles.rankTitle, placePosition)}>
                        {commonTranslation}
                    </Typography>
                </Box>
            )}
        </Box>

    );
};

export default CurrentRank;
