import { Grid } from "@mui/material";
import Badge from "@mui/material/Badge";
import cn from "classnames";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Notifications from "src/components/Notifications";
import Avatar from "src/components/UserAvatar";
import UserNameBox from "src/components/UserNameBox";
import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import ArrowIcon from "src/assets/icons/selectIcon.svg";
import SettingsIcon from "src/assets/icons/gear.svg";

import endpoints from "../../../core/endpoints";
import {
    ModalProvider,
    ModalTypes,
} from "../../../pages/landing/utils/context";
import LiveIcon from "src/assets/icons/LIVE.svg"; 

import { useStyles } from "./styles";
import { UserStarsBorder } from "../app-bar/components/animateStar/UserStarsBorder";

type SplashProps = {
    nickname: string;
    avatar?: string;
    isSystemAvatar?: boolean;
    pathCompressed?: string;
};

const SPECIAL_USERS = [
    //"bazarwokzal",
    //"alexandra_chess",
    //"floating_morality",
    //"gucci",
    //"shahmatkanal",
    //"mashates1222test",
    "123test"
];

// Объявляем внешнюю переменную для инициализации
declare global {
    interface Window {
        globalStarsCount?: number;
    }
}

const Splash = observer(({
    nickname,
    avatar,
    isSystemAvatar,
    pathCompressed
}: SplashProps) => {

    const styles = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { authStore, generalStore } = useStores();

    const isHalyk = generalStore.isHalyk;
    const lowerCaseNickname = nickname.toLowerCase();
    const isSpecialUser = SPECIAL_USERS.includes(lowerCaseNickname);

    const userStars = authStore.currentUser?.stars || 0;

    useEffect(() => {
        if (typeof window.globalStarsCount === 'undefined') {
            window.globalStarsCount = Math.min(userStars, 20);

            if (userStars > 0) {
                for (let i = 0; i < window.globalStarsCount; i++) {
                    setTimeout(() => {
                        const event = new CustomEvent('star-added', {
                            detail: { starsCount: i + 1 }
                        });
                        window.dispatchEvent(event);
                    }, i * 50); 
                }
            }
        }
    }, [userStars]);

    const prepareNickname =
        nickname &&
        nickname
            .toString()
            .split("")
            .map((a) => (a === "-" ? "-\u200d" : `${a}`))
            .join("");

    const [modalState, setModalState] = useState({
        notifications: false,
    });

    const userNotifications = authStore.currentUser?.notification?.numberOfUnseenNotifications;
    const newNotifications = userNotifications ? userNotifications : undefined;

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    return (
        <ModalProvider.Provider value={{ openModal, closeModal }}>
            {modalState.notifications && <Notifications />}
            <div className={styles.avatarNicknameWrapper}>
                {!isHalyk && (
                    <div className={styles.avatarWrapper}>
                        <Badge
                            color="error"
                            badgeContent={newNotifications}
                            className={cn(styles.badgeNotifications, {
                                emptyNote:
                                    !newNotifications || newNotifications < 1,
                            })}
                        >
                            <div className={styles.avatarContainer}>
                                <Avatar
                                    avatarUrl={`${endpoints.avatarPath}${authStore.currentUser?.avatar}`} 
                                    className={styles.avatar}
                                    isSystemAvatar={isSystemAvatar}
                                    pathCompressed={pathCompressed}
                                />
                                <UserStarsBorder
                                    starsCount={authStore.currentUser?.stars || 0}
                                    starSize={8}
                                />
                                {isSpecialUser && (
                                    <div className={styles.liveIconWrapper}>
                                        <img src={LiveIcon} alt="LIVE" className={styles.liveIcon} />
                                    </div>
                                )}
                            </div>
                            
                        </Badge>
                    </div>
                )}
                <span className={styles.userNameBox}>
                    {nickname.length > 10 ? (
                        <span className="marquee-content">{prepareNickname}</span>
                    ) : (
                        prepareNickname
                    )}
                </span>
            </div>
        </ModalProvider.Provider>
    );
});

export default (Splash);
