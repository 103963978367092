import React, { useRef } from "react";
import { Round, SubBracketMatch } from "../../types";
import TournamentLinesDouble from "../tournamentLinesDouble";
import BracketColumn from "../bracketColumn";
import MatchPair from "../matchPair";
import { useLocale } from "../../../../../../providers/LocaleProvider";

interface DoubleBracketProps {
    bracket: Round[];
    classes: { [key: string]: string };
    hideHeaders?: boolean; // Новый флаг для скрытия заголовков
}

export const DoubleBracket: React.FC<DoubleBracketProps> = ({
    bracket,
    classes,
    hideHeaders = false // По умолчанию заголовки показываются
}) => {

    const {
        lobby: {
            tournaments: {
                cbtitle,
                ubTitle,
                gfTitle
            },
        },
    } = useLocale();

    const headersRef = useRef<HTMLDivElement>(null);
    const bracketRef = useRef<HTMLDivElement>(null);


    const mainBracket = bracket.map((round) => ({
        ...round,
        matches: round.matches.filter((match) => !match.sub),
    }));

    const subBracketMatches = bracket
        .flatMap((round) =>
            round.matches.filter(
                (match): match is SubBracketMatch =>
                    match.sub === true && "step" in match
            )
        );

    const subBracketByStep = subBracketMatches.reduce<Record<number, SubBracketMatch[]>>((acc, match) => {
        if (!acc[match.step]) {
            acc[match.step] = [];
        }
        acc[match.step].push(match);
        return acc;
    }, {});

    const subBracket = mainBracket
        .slice(1)
        .map((mainRound, index) => {
            const step = index + 2;
            const matches = subBracketByStep[step] || [];
            return {
                title: mainRound.title,
                matches,
                step,
            };
        })
        .filter((round) => round.matches.length > 0);

    const filteredMainBracket = mainBracket.filter(
        round => round.matches.length > 0
    );


    return (
        <div className={classes.doubleBracketWrapper}>
            {/* Фиксированные заголовки */}
            {/*<div className={classes.fixedHeadersLong} ref={headersRef}>*/}
            {/*    {filteredMainBracket.map((round, roundIndex) => (*/}
            {/*        !round.isHidden && (  // Проверяем, скрыта ли колонка*/}
            {/*            <div*/}
            {/*                key={roundIndex}*/}
            {/*                className={classes.headerColumnLong}*/}
            {/*            >*/}
            {/*                {roundIndex + 1 === filteredMainBracket.length*/}
            {/*                    ? gfTitle("Grand Final")*/}
            {/*                    : `Round ${roundIndex + 1}`}*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    ))}*/}
            {/*</div>*/}

            <div className={classes.bracketScrollContainer} >
                <div className={classes.upperBracketSection}>
                    <div className={`${classes.upperBracket} ${classes.bracketContent}`}>
                        <TournamentLinesDouble bracket={mainBracket} />
                        {filteredMainBracket.map((round, roundIndex) => (
                            <BracketColumn
                                key={round.title}
                                title={round.title}
                                columnIndex={roundIndex}
                                totalRounds={filteredMainBracket.length}
                                matchesInRound={round.matches.length}
                                hideHeader={true}
                                isUpperBracket={true}
                                isDoubleBracket={true}
                                previousRoundMatches={roundIndex > 0 ? filteredMainBracket[roundIndex - 1].matches.length : 0}
                                hideColumn={round.isHidden}  // флаг для полного скрытия
                            >

                                {round.matches.map((match, matchIndex) => (
                                    <div key={matchIndex} className={classes.singleMatch} data-column={roundIndex} data-match={matchIndex}>
                                        <MatchPair
                                            {...match}
                                            firstWins={match.firstWins ?? 0}
                                            secondWins={match.secondWins ?? 0}
                                            columnIndex={roundIndex}
                                            matchIndex={matchIndex}
                                            totalColumns={filteredMainBracket.length}
                                            isUpperBracket={true}
                                            isSubBracket={false}
                                        />
                                    </div>
                                ))}
                            </BracketColumn>
                        ))}
                    </div>
                </div>

                <div className={classes.lowerBracketSection}>
                    <div className={classes.subBracketTitle}>{cbtitle('Cellar bracket')}</div>
                    <div className={`${classes.lowerBracket} ${classes.bracketContent}`}>
                        {/* Передаём isSubBracket для нижней сетки */}
                        {/*<TournamentLinesDouble*/}
                        {/*    bracket={subBracket as Round[]}*/}
                        {/*    isSubBracket={true}*/}
                        {/*/>*/}
                        {subBracket.map((round, roundIndex) => (
                            <BracketColumn
                                key={round.title}
                                title={round.title}
                                columnIndex={roundIndex}
                                totalRounds={subBracket.length}
                                matchesInRound={round.matches.length}
                                hideHeader={false}
                                isSubBracket={true}
                                isDoubleBracket={true}
                                previousRoundMatches={roundIndex > 0 ? subBracket[roundIndex - 1].matches.length : 0}
                            >
                                {round.matches.map((match, matchIndex) => (
                                    <div
                                        key={matchIndex}
                                        className={classes.singleMatch}
                                        data-column={roundIndex}
                                        data-match={matchIndex}
                                    >
                                        <MatchPair
                                            {...match}
                                            firstWins={match.firstWins ?? 0}
                                            secondWins={match.secondWins ?? 0}
                                            columnIndex={roundIndex}
                                            matchIndex={matchIndex}
                                            totalColumns={subBracket.length}
                                            isUpperBracket={false}
                                            isSubBracket={true}
                                        />
                                    </div>
                                ))}
                            </BracketColumn>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
};