import { TimePreviewExistPosition } from "src/store/models";

import { RapidIcon } from "./icons/rapid";
import { BulletIcon } from "./icons/bullet";
import { BlitzIcon } from "./icons/blitz";
import { ClassicalIcon } from "./icons/classical";
import { NullIcon } from "./icons/null";
import { UltraBulletIcon } from "./icons/ultraBullet";

type CurrentPosition = keyof typeof TimePreviewExistPosition;
interface Props {
    time: number;
    additionalStyles?: CurrentPosition;
}
const TimeIcon = ({ time, additionalStyles }: Props) => {
    const icons = {
        [TimePreviewExistPosition.lobby]: {
            2: <UltraBulletIcon />,
            3: <BulletIcon />,
            5: <BlitzIcon />,
            10: <RapidIcon />,
            20: <ClassicalIcon />,
        },
        [TimePreviewExistPosition.tournamentModal]: {
            1: <UltraBulletIcon />,
            2: <UltraBulletIcon />,
            3: <BulletIcon />,
            5: <BlitzIcon />,
            10: <RapidIcon />,
            20: <ClassicalIcon />,
        },
    };

    return (
        icons[additionalStyles || TimePreviewExistPosition.lobby][time] || (
            <NullIcon />
        )
    );
};

export default TimeIcon;
