import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    avatarStarsBorder: {
        position: 'absolute',
        pointerEvents: 'none',
        zIndex: 999999,
        top: 0,
        left: 0,
        //// Смещение для маленьких экранов (мобильные устройства)
        //"@media (min-width: 375px) and (max-width: 767px)": {
        //    top: "0",
        //    left: '50%', // Центрируем
        //    transform: 'translateX(-50%)', // Чтобы точка отсчета была в центре
        //},

        //// Смещение для планшетов
        //"@media (min-width: 768px) and (max-width: 1023px)": {
        //    top: "3px",
        //    left: '100px',
        //},

        //// Смещение для ноутбуков
        //"@media (min-width: 1024px) and (max-width: 1279px)": {
        //    top: "2px",
        //    left: '15px',
        //},

        //// Смещение для больших экранов
        //"@media (min-width: 1280px) and (max-width: 1440px)": {
        //    top: "5px",
        //    left: '10px',
        //},

        //// Смещение для 4K мониторов (если нужно)
        //"@media (min-width: 1441px)": {
        //    top: "5px",
        //    left: '5px',
        //},
    }

});
