import { Match, Player, Round } from "./types";

interface LocaleKeys {
    final: string;
    semifinal: string;
    expected: string;
    bye: string;
    thirdPlace: string;
}

interface TournamentPlayer {
    nickname: string;
    _id: string;
    email: string;
    avatar?: string;
    stars?: number;
}

interface TournamentPair {
    step: number;
    gameId: string | null;
    firstWins: number;
    secondWins: number;
    isFinished: boolean;
    sub?: boolean;
    player1: TournamentPlayer;
    player2?: TournamentPlayer;
    isThirdPlace?: boolean;
}

interface Tournament {
    players?: TournamentPlayer[];
    pairs?: TournamentPair[];
    maxPlayers?: number;
    prizes: any[];
    type?: number;
}

// Общие вспомогательные функции
const createTBDPlayer = (index: number, localeKeys: LocaleKeys): Player => ({
    _id: `TBD_${index}`,
    name: localeKeys.expected,
    isExpected: true,
    avatar: undefined
});

const createByePlayer = (index: number, localeKeys: LocaleKeys): Player => ({
    _id: `BYE_${index}`,
    name: localeKeys.bye,
    isBye: true,
    avatar: undefined
});

const createMatch = (
    pair: TournamentPair,
    byeCounter: number,
    localeKeys: LocaleKeys
): Match => ({
    player1: {
        _id: pair.player1._id,
        name: pair.player1.nickname,
        avatar: pair.player1.avatar,
        stars: pair.player1.stars ?? 0,
    },
    player2: pair.player2 ? {
        _id: pair.player2._id,
        name: pair.player2.nickname,
        avatar: pair.player2.avatar,
        stars: pair.player2.stars ?? 0,
    } : createByePlayer(byeCounter, localeKeys),
    gameId: pair.gameId || undefined,
    firstWins: pair.firstWins,
    secondWins: pair.secondWins,
    isFinished: pair.isFinished,
    step: pair.step,
    sub: !!pair.sub,
    isThirdPlace: !!pair.isThirdPlace 
});

// Функция для турниров с одной сеткой
function generateSingleBracket(tournament: Tournament | null, localeKeys: LocaleKeys): Round[] {
    if (!tournament?.players?.length) {
        return [];
    }

    const getRoundTitle = (step: number, totalRounds: number): string => {
        if (step === totalRounds) return localeKeys.final;
        if (step === totalRounds - 1) return localeKeys.semifinal;
        const denominator = Math.pow(2, totalRounds - step);
        return `1/${denominator}`;
    };

    const firstRoundPairs = tournament.pairs?.filter(item => item.step === 1) || [];
    const maxPlayers = firstRoundPairs.length * 2;
    const pairsByStep = new Map<number, Match[]>();

    let tbdCounter = 0;
    let byeCounter = 0;

    // Обработка пар
    tournament.pairs?.forEach(pair => {
        const match = createMatch(pair, byeCounter++, localeKeys);

        // Если это матч за 3-е место, устанавливаем флаг
        if (pair.isThirdPlace) {
            match.isThirdPlace = true;
        }

        const pairs = pairsByStep.get(pair.step) || [];
        pairs.push(match);
        pairsByStep.set(pair.step, pairs);
    });

    const totalRounds = Math.ceil(Math.log2(maxPlayers));
    const rounds: Round[] = [];

    const needsThirdPlaceMatch = tournament.prizes.length > 2;

    for (let step = 1; step <= totalRounds; step++) {
        const isFinalRound = step === totalRounds;
        const currentRoundPairs = pairsByStep.get(step) || [];

        // Стандартное количество пар в раунде
        let expectedPairsInRound = maxPlayers / Math.pow(2, step);

        // Для финального раунда, если нужен матч за 3-е место, ожидаем 2 пары
        if (isFinalRound && needsThirdPlaceMatch) {
            expectedPairsInRound = 2; // Одна для финала, одна для матча за 3-е место
        }

        // Заполняем недостающие пары
        while (currentRoundPairs.length < expectedPairsInRound) {
            const isFutureRound = step > Math.max(...(tournament.pairs?.map(p => p.step) || [0]));
            const isLastPairInFinalRound = isFinalRound && currentRoundPairs.length === 1 && needsThirdPlaceMatch;

            // Если это вторая пара в финальном раунде и нужен матч за 3-е место
            const newMatch = {
                player1: isFutureRound ? createTBDPlayer(tbdCounter++, localeKeys) : createByePlayer(byeCounter++, localeKeys),
                player2: isFutureRound ? createTBDPlayer(tbdCounter++, localeKeys) : createByePlayer(byeCounter++, localeKeys),
                gameId: undefined,
                firstWins: 0,
                secondWins: 0,
                isFinished: false,
                sub: false,
                isThirdPlace: isLastPairInFinalRound
            };

            currentRoundPairs.push(newMatch);


        }

        const existingThirdPlaceMatch = tournament.pairs?.find(pair =>
            pair.step === step && pair.isThirdPlace
        );

        // Если в финальном раунде нужен матч за 3-е место и он уже существует в данных
        if (isFinalRound && needsThirdPlaceMatch && existingThirdPlaceMatch) {
            // Находим индекс второго матча (который должен быть за 3-е место)
            if (currentRoundPairs.length >= 2) {
                // Создаем матч из существующих данных
                const thirdPlaceMatch = createMatch(existingThirdPlaceMatch, byeCounter++, localeKeys);
                thirdPlaceMatch.isThirdPlace = true;

                // Заменяем сгенерированный пустой матч за 3-е место реальным
                currentRoundPairs[1] = thirdPlaceMatch;

            }
        }

        rounds.push({
            title: getRoundTitle(step, totalRounds),
            matches: currentRoundPairs,
            hasThirdPlaceMatch: isFinalRound && needsThirdPlaceMatch
        });
    }

    return rounds;
}


const isByePair = (pair: TournamentPair): boolean => {
    return !pair.player2 || !pair.gameId;
};


// Функция для турниров с двойной сеткой
function getStepTitle(
    step: number,
    totalSteps: number,
    localeKeys: LocaleKeys,
    hasGrandFinal?: boolean
): string {
    if (hasGrandFinal) {
        return localeKeys.final;
    }
    return `Round ${step}`;
}

// Функция для турниров с двойной сеткой
function generateDoubleBracket(tournament: Tournament | null, localeKeys: LocaleKeys): Round[] {
    if (!tournament?.players?.length) {
        return [];
    }

    const firstRoundPairs = tournament.pairs?.filter(item => item.step === 1 && !item.sub) || [];
    const maxPlayers = firstRoundPairs.length * 2;

    // Сортируем пары по сеткам и раундам
    const pairsByStepAndBracket = new Map<string, TournamentPair[]>();

    tournament.pairs?.forEach(pair => {
        const key = `${pair.step}_${pair.sub ? 'lower' : 'main'}`;
        const pairs = pairsByStepAndBracket.get(key) || [];
        pairs.push(pair);
        pairsByStepAndBracket.set(key, pairs);
    });

    // Считаем количество реальных матчей (без bye) в первом раунде
    const realMatchesInFirstRound = firstRoundPairs.filter(p => !isByePair(p)).length;

    // Расчет структуры сетки
    const bracketStructure = new Map<number, { main: number; lower: number }>();
    let mainBracketPlayers = maxPlayers;

    const getNext2 = (len) => {
        let two = 2;
        while (two < len) {
            two <<= 1;
        }

        return two;
    }

    const isLargeTournament = true;
    let totalRounds = 0;
    let lowerBracketExtraRounds = 0;
    let up = tournament.players.length, down = 0, step  = 1;

    let pairsUp = 0, pairsDown = 0;

    while (up || down) {

        const lastUp = up;

        console.log(step, up, down);

        if (up === 1 && down === 1) {
            bracketStructure.set(step, {
                main: 1, // Grand final
                lower: 0
            });

            break;
        }

        if (up === 1 && down > 1) {
            lowerBracketExtraRounds++;
        }

        if (step === 1) {
            const nxt2 = getNext2(up);

            const byes = nxt2 - up;
            down = (up - byes) / 2;

            up = (up - byes) / 2 + byes;
            pairsUp = nxt2 / 2;
        } else {
            up = (up + up%2) / 2;
            pairsUp = lastUp === 1 ? 0 : up;
        }

        if (step === 2) {
            let nxt2 = getNext2(up) * 2;
              // console.log(nxt2);

            if (down === 2) {
                nxt2 /= 2;
            }

            const byes = Math.min(nxt2 - down, down);

            // console.log(down, byes);
            down = (down - byes) / 2 + byes;
            // console.log(down);
            down += lastUp - up;
            // console.log(down);
            pairsDown = nxt2 / 2;
        } else if (step > 2) {
            pairsDown = (down + down % 2) / 2;


            down = (down + down % 2) / 2;
            // console.log(down, lastUp - up);

            down += lastUp - up;
            // console.log(down);
        }


        bracketStructure.set(step, {
            main: pairsUp,
            lower: pairsDown,
        });

        totalRounds++;
        step++;
    }

    // Получаем все пары нижней сетки для первого раунда
    const lowerBracketFirstRound = tournament.pairs?.filter(item => item.step === 2 && item.sub) || [];

    // Рассчитываем необходимое количество пар для первой колонки нижней сетки
    const requiredLowerBracketPairs = Math.ceil(firstRoundPairs.length / 2);


    // Генерация матчей остается без изменений
    let tbdCounter = 0;
    let byeCounter = 0;
    const rounds: Round[] = [];

    Array.from(bracketStructure.entries())
        .sort(([a], [b]) => a - b)
        .forEach(([stepCur, counts]) => {

            const mainKey = `${stepCur}_main`;
            const lowerKey = `${stepCur}_lower`;

            const mainPairs = pairsByStepAndBracket.get(mainKey) || [];
            const lowerPairs = pairsByStepAndBracket.get(lowerKey) || [];

            const matches: Match[] = [];

            // Создаем матчи основной сетки
            for (let i = 0; i < counts.main; i++) {
                const isGrandFinal = stepCur === step - 1; // Последний раунд - это Grand Final

                let match: Match;
                if (i < mainPairs.length) {
                    match = {
                        ...createMatch(mainPairs[i], byeCounter++, localeKeys),
                        isGrandFinal,
                    };
                } else {
                    match = {
                        player1: createTBDPlayer(tbdCounter++, localeKeys),
                        player2: createTBDPlayer(tbdCounter++, localeKeys),
                        gameId: undefined,
                        firstWins: 0,
                        secondWins: 0,
                        isFinished: false,
                        step: stepCur,
                        sub: false,
                        isGrandFinal
                    };
                }

                matches.push(match);
            }

            // Создаем матчи нижней сетки
            if (stepCur === 2) { // Первая колонка нижней сетки
                const expectedLowerMatches = Math.ceil(realMatchesInFirstRound / 2);               

                for (let i = 0; i < requiredLowerBracketPairs; i++) {

                    if (i < lowerBracketFirstRound.length) {
                        // Используем существующие реальные пары
                        matches.push(createMatch(lowerBracketFirstRound[i], byeCounter++, localeKeys));

                    } else if (i < expectedLowerMatches) {
                        // Для ожидаемых будущих пар создаем TBD/TBD
                        matches.push({
                            player1: createTBDPlayer(tbdCounter++, localeKeys),
                            player2: createTBDPlayer(tbdCounter++, localeKeys),
                            gameId: undefined,
                            firstWins: 0,
                            secondWins: 0,
                            isFinished: false,
                            step: stepCur,
                            sub: true
                        });

                    } else {
                        // Только для оставшихся слотов используем bye/bye
                        matches.push({
                            player1: createByePlayer(byeCounter++, localeKeys),
                            player2: createByePlayer(byeCounter++, localeKeys),
                            gameId: undefined,
                            firstWins: 0,
                            secondWins: 0,
                            isFinished: false,
                            step: stepCur,
                            sub: true
                        });

                    }
                }
            } else {                
                // Остальные колонки нижней сетки остаются без изменений
                for (let i = 0; i < counts.lower; i++) {

                    if (i < lowerPairs.length) {

                        matches.push(createMatch(lowerPairs[i], byeCounter++, localeKeys));

                    } else {
                        matches.push({
                            player1: createTBDPlayer(tbdCounter++, localeKeys),
                            player2: createTBDPlayer(tbdCounter++, localeKeys),
                            gameId: undefined,
                            firstWins: 0,
                            secondWins: 0,
                            isFinished: false,
                            step: stepCur,
                            sub: true
                        });

                    }
                }
            }


            rounds.push({
                title: getStepTitle(stepCur, bracketStructure.size, localeKeys),
                matches,
            });
        });


    //// Определяем два последних раунда перед гранд-финалом
    //const secondToLastRound = maxRound - 4;
    //const lastRoundBeforeGrandFinal = maxRound - 3;

    // Скрываем раунды верхней сетки, если в них есть хотя бы один BYE (кроме первого раунда)
    rounds.forEach((round, index) => {
        if (index > 0 && round.matches.some(match => (!match.sub && (match.player1?.isBye || match.player2?.isBye)))) {
            round.isHidden = true; // Помечаем раунд как скрытый
        }
    });

    return rounds;
}
// Основная функция-обертка
function generateTournamentBracket(tournament: Tournament | null, localeKeys: LocaleKeys): Round[] {
    if (!tournament) {
        return [];
    }

    return tournament.type === 2
        ? generateDoubleBracket(tournament, localeKeys)
        : generateSingleBracket(tournament, localeKeys);
}

export default generateTournamentBracket; 