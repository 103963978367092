import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import { React_Brackets_WIDTH_CONDITION } from "src/consts/window-size-params";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";
import theme from "src/theme";

interface IFillContent {
    ratio: any;
    isLeague?: boolean; 
}

export const useStyles = makeStyles({
    "@global": {
        "@keyframes isWinnerAndHideBank": {
            "0%": {
                filter: "brightness(1)",
            },
            "70%": {
                filter: "brightness(1)",
                visibility: "visible",
            },
            "100%": {
                visibility: "hidden",
                filter: "brightness(0)",
            },
        },
    },

    desktopWatchersBoxLeague: {
        position: "absolute",
        zIndex: 10,
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        padding: "16px 0",
        opacity: 0,
        visibility: "hidden",
        background: "rgba(0,0,0,1)", 
        backdropFilter: "blur(10px)", 
        boxShadow: "0 0 15px 5px rgba(0,0,0,0.5)", 
        isolation: "isolate", 
        transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out",
        "&.show": {
            visibility: "visible",
            opacity: 1,
        },
        "&.hide": {
            opacity: 0,
            visibility: "hidden",
            transition: "opacity 0.3s ease-in-out, visibility 0s linear 0.3s",
        }
    },

    closeButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        background: "rgba(245, 245, 245, 0.1)",
        transition: "background 0.2s ease",
        cursor: 'pointer',
        "&:hover": {
            background: "rgba(245, 245, 245, 0.2)",
        },
        "& img": {
            width: 18,
            rotate: '90deg',
        }
    },

    previewGame: ({ isLeague }: IFillContent) => ({
        position: "relative",
        padding: 0,

        overflow: "hidden",

        // border: "1px solid #0B0C12",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        background: "none",
        border: "none",
        margin: "0 auto",
        width: "100%",
        // containerType: "inline-size",
        containerType: "size",
        borderRadius: 0,
        aspectRatio: "9/16",
        [theme.breakpoints.down("lg")]: {
            height: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            gridGap: "1%",
        },
        "@media screen and (orientation: portrait)": {
            maxHeight: "1024px",
        },
        "&:after": {
            content: "''",
            position: "absolute",
            top: 0,
            height: "25%",
            width: "100%",
            zIndex: -1,
            background:
                "radial-gradient(50% 50%, rgba(80, 0, 117, 0.6) 0%, rgba(9, 0, 35, 0.156) 100%)",
        },
        "&.isOpenPreview": {
            display: "none",
        },
        "&.inBracketPreview": {
            // minWidth: "calc((100dvh - 100px) * 9 /16)",
            // minHeight: "calc(100dvh - 100px)",
            width: "calc((100dvh - 100px) * 9 /16)",
            height: "calc(100dvh - 100px)",
            maxWidth: "560px",
            maxHeight: "1000px",
            [theme.breakpoints.up(2160)]: {
                maxHeight: "1600px",
                maxWidth: "900px",
            },
            "&:after": {
                display: "none",
                content: "''",
                position: "absolute",
                top: 0,
                height: "25%",
                width: "100%",
                zIndex: -1,
                background:
                    "radial-gradient(50% 50%, rgba(80, 0, 117, 0.6) 0%, rgba(9, 0, 35, 0.156) 100%)",
            },
            ...(isLeague && {
                width: "calc((120dvh - 20px) * 10 /16)",
                height: "calc(97dvh - 100px)",

            }),
        },
    }),

    desktopWatchersBox: {
        zIndex: 2,
        position: "absolute",
        top: "50px",
        width: "100%",
        height: "calc(100% - 50px)",
        [theme.breakpoints.up(2000)]: {
            top: "65px",
            height: "calc(100% - 65px)",
        },
        transform: "translateX(101%)",
        borderRadius: "2%",
        padding: "24px",
        border: `1px solid ${appColors.bg400}`,
        "&.show": {
            transform: "translateX(0%)",
            transition: "0.4s ease-in",
            backdropFilter: "blur(5px)",
            background: "rgba(0,0,0,0.7)",
            zIndex: 3,
        },
        "&.hide": {
            transform: "translateX(100%)",
            transition: "0.2s ease-out all",
        },
        "&.inBracketPreview": {
            position: "static",
            height: "100%",
            overflow: "hidden",
            borderRadius: "12px",
            top: 0,
            border: `0px solid ${appColors.bg400}`,
            padding: 0,
            width: "0dvw",
            "&.show": {
                border: `1px solid ${appColors.bg400}`,
                padding: "0px",
                top: 0,
                borderRadius: "12px",
                width: "25dvw",
                overflow: "hidden",
                background: "#0000004f",
                transition: "0.4s ease-in",
                maxWidth: "560px",
                // maxWidth: "calc((100dvh - 100px) * 9 / 16)",
                [theme.breakpoints.up(2160)]: {
                    maxWidth: "900px",
                },
                zIndex: 3,
            },
            "&.hide": {
                width: "0dvw",
                overflow: "hidden",
                opacity: 0,
                transition: "0.2s ease-out",
            },
        },
    },

    solidLine: {
        width: "0px",
        height: "100%",
        borderLeft: `0px solid ${appColors.bg400}`,
        display: "none",
        "&.show": {
            transitionDelay: "0.3s",
            display: "block",
            borderLeft: `0.5px solid ${appColors.bg400}`,
            transition: "0.4s ease-in",
            margin: "0px 8px",
            width: "1px",
        },
        "&.hide": {
            width: "0px",
            borderLeft: `0px solid ${appColors.bg400}`,
            transition: "0.2s ease-out",
        },
    },

    iconsWrapperBox: {
        display: "flex",
        width: "fit-content",
        maxWidth: 130,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        gridGap: "8px",
    },

    fullIconScreenBox: {
        borderRadius: "50%",
        padding: "10px",
        aspectRatio: "1 / 1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(245, 245, 245, 0.1)",
    },

    returnPreview: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        aspectRatio: "1 / 1",
        height: "100%",
        borderRadius: "50%",
        background: "rgba(245, 245, 245, 0.1)",
        "&.inBracketPreview": {
            background: "none",
            "&:hover": { cursor: "pointer" },
        },
    },

    bankContainer: {
        display: "grid",
        gridTemplateColumns: "0% 33% 0%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
        transition: "grid-template-columns 0.2s ease-in",
        "&.activeInit": {
            transitionDelay: "0.2s",
            gridTemplateColumns: "33% 33% 0%",
            transition: "grid-template-columns 0.3s ease-in",
        },
        "&.isWinnerWithReject": {
            gridTemplateColumns: "33% 33% 33%",
            transition: "grid-template-columns 0.33s ease-in",
            animationName: "isWinnerAndHideBank",
            animationDelay: "2.1s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "550ms",
        },
        "&.acceptedAndFinished": {
            gridTemplateColumns: "33% 33% 33%",
            transition: "grid-template-columns 0.2s ease-in",
        },
        "&.isWinnerAndHideBank": {
            animationName: "isWinnerAndHideBank",
            animationDelay: "2.15s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "550ms",
        },
        "&.solidWinner": {
            animationName: "isWinnerAndHideBank",
            animationDelay: "2.15s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "550ms",
        },
    },

    pattern: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },

    previewLobbyStyle: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
    },

    watchersAndFullScreenBox: {
        display: "flex",
        gridGap: "5px",
        height: "100%",
        width: "fit-content",
        alignItems: "center",
    },

    timeAndBet: {
        position: "absolute",
        top: 20,
        left: 20,
        right: 20,
    },

    scroll: {
        height: "calc(100vh - 190px)",
        // overflow: "scroll",
    },
    grid: {
        color: "white",
        paddingTop: 7,
    },
    container: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    tableCellTime: {
        color: "white",
        fontSize: 14,
        width: 120,
    },
    tableRow: {
        height: 50,
        cursor: "pointer",
    },
    freeGame: {
        color: "#FFC327",
        fontSize: 13,
        paddingTop: 8,
    },
    typeGame: {
        fontWeight: "bold",
        color: "white",
        textAlign: "left",
        fontSize: 13,
        width: 100,
    },
    tableName: {
        fontSize: 13,
        color: "white",
        paddingLeft: 6,
        paddingTop: 4,
    },
    tableAvatar: {
        width: 24,
        height: 24,
        marginLeft: 8,
        marginRight: 4,
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #3D4969",
    },

    game: {
        fontSize: 12,
        position: "relative",
    },
    timeInfo: {
        paddingLeft: 4,
        paddingTop: 2,
        fontSize: 14,
        color: "rgba(255, 255, 255, 0.7)",
    },
    greenButton: {
        padding: 8,
        paddingTop: 20,
        textAlign: "center",
    },
    gameNotSelect: {
        color: "white",
        left: "50%",
        top: "50%",
        fontSize: 12,
        transform: "translate(-50%,-50%)",
        position: "absolute",
    },
    avatar: {
        width: "32px !important",
        height: "32px !important",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #3D4969",
    },
    awaitPlayer: {
        fontSize: "1.2vw",
        color: "white",
        textAlign: "left",
        paddingTop: 4,
        paddingRight: 10,
    },
    player: {
        fontWeight: 700,
        fontSize: "1.3vw",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        marginLeft: "8px",
        paddingBottom: "2px",
        background:
            "linear-gradient(178.82deg, #A2A2A2 31.6%, #FFFFFF 50.51%, #A9A9A9 76.41%)",
    },

    header: {
        color: "white",
        fontSize: 13,
        fontWeight: "bold",
    },
    icon: {
        width: 18,
        height: 18,
    },
    current: {
        backgroundColor: "#22263D",
    },

    leftAlign: {
        fontWeight: 400,
        textAlign: "left",
        color: "rgba(255, 255, 255, 0.2)",
    },

    rightAlign: {
        textAlign: "right",
        color: "rgba(255, 255, 255, 0.7)",
    },

    deprecatedPlayer: {
        fontSize: 12,
        color: "#EDF4FF",
        fontWeight: "bold",
        paddingLeft: 10,
        paddingTop: 10,
    },

    bet: {
        textAlign: "right",
        fontWeight: 800,
        fontSize: "1.2vw",
        background: "-webkit-linear-gradient(#F8BF44 30%, #A16529 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    },

    verticalLine: {
        width: 1,
        padding: 0,
    },

    pointer: {
        cursor: "pointer",
    },

    previewGameText: {
        fontSize: "14px",
        color: "white",
        paddingLeft: 5,
        paddingRight: 5,
        minHeight: 27,
        alignItems: "center",
        flexBasis: "5%",
    },
    cube: {
        margin: "10px auto",
        maxHeight: 60,
        height: "100%",
    },
    diceWrapper: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "0 auto",
        height: "10%",
        maxHeight: "90px",
        minHeight: "40px",
        [theme.breakpoints.up("xl")]: {
            maxHeight: "100px",
        },
        [theme.breakpoints.up(1600)]: {
            maxHeight: "130px",
        },
        // "@media screen and (min-height: 1050px)": {
        //     padding: "10px 0px 15px 0px",
        // },
        opacity: 1,
        transition: "0.15s opacity ease-in",

        // "&.hide": {
        //     opacity: 0,
        //     transition: "0.3s opacity ease-out",
        // },
        "&.winner": {
            zIndex: -1,
            opacity: 0,
            transition: "0.1s opacity ease-out",
        },
    },

    returnAndWatchersBox: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        minHeight: "30px",
        maxHeight: "50px",
        height: "5%",
        position: "absolute",
        top: "0px",
        zIndex: 999,
    },

    chessboardContainerPreview: ({ ratio }: IFillContent) => ({
        margin: 0,
        padding: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden",
        gridGap: "4px",
        [theme.breakpoints.up("lg")]: {
            gridGap: "1%",
        },
        opacity: 1,
        transition: "0.15s opacity ease-in",
        // "&.hide": {
        //     opacity: 0,
        //     transition: "0.3s opacity ease-out",
        // },
    }),

    topWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        //height: "12.5%",
        minHeight: "60px",
        maxHeight: "100px",
        [theme.breakpoints.up(2000)]: {
            maxHeight: "120px",
        },
    },

    midWrapper: {},

    bottomWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "relative",
        height: "12.5%",
        minHeight: "60px",
        maxHeight: "100px",
        [theme.breakpoints.up(2000)]: {
            maxHeight: "120px",
        },
    },

    togglerWrapper: {
        marginTop: 10,
        display: "flex",
        height: "25px",
        width: "100%",
        margin: "0 auto",
        postion: "absolute",
        top: 65,
        zIndex: 1,
        "@media screen and (max-height: 800px)": {
            marginTop: 5,
        },
        "@media screen and (max-height: 750px)": {
            marginTop: 2,
        },
    },

    viewMobilePotWrapperDesk: {
        display: "flex",
        justifyContent: "center",
        minHeight: "40px",
        zIndex: 1,
        [theme.breakpoints.up("lg")]: {
        //    marginBottom: "1%",
        },
        "@media screen and (max-width: 450px) and (min-height: 850px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 450px) and (min-height: 870px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 450px) and (min-height: 890px)": {
            paddingTop: 20,
        },
        "@media screen and (max-width: 390px) and (min-height: 810px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 390px) and (min-height: 830px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 390px) and (min-height: 850px)": {
            paddingTop: 20,
        },
        // [theme.breakpoints.up(1250)]: {
        //     "&.isTournamentGame": {
        //         visibility: "hidden",
        //     },
        // },
    },

    viewMobilePotWrapperAnimateDesk: {
        display: "flex",
        justifyContent: "center",
        minHeight: "40px",
        "@media screen and (max-width: 450px) and (min-height: 850px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 450px) and (min-height: 870px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 450px) and (min-height: 890px)": {
            paddingTop: 20,
        },
        "@media screen and (max-width: 390px) and (min-height: 810px)": {
            paddingTop: 10,
        },
        "@media screen and (max-width: 390px) and (min-height: 830px)": {
            paddingTop: 15,
        },
        "@media screen and (max-width: 390px) and (min-height: 850px)": {
            paddingTop: 20,
        },
        animationName: "$viewMobilePotWrapperAnimate",
        animationDelay: "3s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "1700ms",
        [theme.breakpoints.down("md")]: {
            "&.isTournamentGame": {
                minHeight: "0px",
                maxHeight: "0px",
            },
        },
    },
    "@keyframes viewMobilePotWrapperAnimate": {
        "0%": {
            minHeight: "40px",
        },
        "100%": {
            minHeight: "0px",
            padiing: 0,
            margin: 0,
        },
    },

    avatarWrapper: {
        display: "flex",
        position: "relative",
        zIndex: 5,
        justifyContent: "center",
        alignItems: "center",
    },

    "@media screen and (max-height: 760px)": {
        cube: {
            display: "none",
        },
        chessboardContainer: {
            marginTop: 20,
        },
    },
    "@media screen and (min-height: 780px)": {
        cube: {
            maxHeight: 80,
        },
        chessboardContainer: {
            marginTop: 40,
        },
    },

    "@media screen and (min-width: 1600px)": {
        chessboardContainer: {},
    },

    playerInfoWrapperView: {
        position: "relative",
        maxWidth: "80%",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,

        "&.topPlayer": {
            //height: "100%",
            //minHeight: "60px",
            // maxHeight: "100px",
        },
        "&.bottomPlayer": {
            height: "100%",
            minHeight: "60px",
            // maxHeight: "100px",
        },
    },

    playerInfo: {
        "& .user-avatar": {
            width: 65,
            height: 65,
            padding: 4,
            "& .nickname-box": {
                fontSize: 16,
            },
        },
        "& .nickname-wrapper": {
            // padding: '2px 35px',
            padding: "2px 0px",
        },
        "& .pieces-group-wrapper-margin": {
            marginRight: 0,
        },
        "& .redesign-clock-text": {
            display: "flex",
            // justifyContent: 'center',
            justifyContent: "flex-start",
            fontSize: 20,
        },
        "& .player-name": {
            fontSize: 16,
        },
        "& .pawn-icon-wrapper": {
            "&.chessmen-margin": {
                marginRight: -13,
            },
            "& svg": {
                width: 22,
                height: 22,
                "@media screen and (max-width: 1400px)": {
                    width: 20,
                    height: 20,
                },
            },
        },
    },
    chessBoardWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        aspectRatio: "1 / 1",
        //Calc this for Chrome. We have 12.5% for top and 12.5% for bottom player box + 1% grid-gap
        // height: "73%",
        // height: "auto",
        maxHeight: "73%",
        "@media screen and (max-height: 700px)": {
            maxHeight: "65%",
        },
        opacity: 1,
        transition: "opacity ease-in 0.3s",
        "&.isWinner": {
            zIndex: 0,
            transition: "opacity ease-in 0.3s",
            opacity: 0.85
        },
    },

    chessBoardInnerWrapper: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        aspectRatio: "1 / 1",
    },
    modal: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridColumnGap: "15px",
        background: "#262C50",
        maxWidth: 300,
        width: "100%",
        padding: "15px",
        borderRadius: 10,
    },
    userAvatarInModal: {
        width: 50,
        height: 50,
        padding: 3,
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    modalContentTitle: {
        color: appColors.white,
        fontSize: 15,
        fontWeight: 500,
    },
    modalContentText: {
        color: "#BBC2DF",
        fontSize: 14,
        fontWeight: 400,
        marginTop: 5,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
    },

    viewModeModalsWrapper: {
        width: "inherit",
        height: "inherit",
        position: "absolute",
        left: 0,
        // transform: "translateY(-50%)",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridRowGap: "10px",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
        "&.inLobbyPreview": {
            "&.top": {
                bottom: 0,
            },
            "&.bottom": {
                top: 0,
            },
            "@media (max-height: 780px) ": {
                height: "auto",
                transform: "scale(0.9)",
            },
        },
        "&.inBracketPreview": {
            "&.top": {
                bottom: 0,
            },
            "&.bottom": {
                top: 0,
            },
            "@media (max-height: 780px) ": {
                height: "auto",
                transform: "scale(0.9)",
            },
        },
    },

    closeButtonBlock: {
        width: "40px",
        flexShrink: 0, 
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    watchersButtonBlock: {
        width: "65px",
        flexShrink: 0, 
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    fullscreenButtonBlock: {
        width: "40px",
        flexShrink: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    fullscreenIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        background: "rgba(245, 245, 245, 0.1)",
        cursor: "pointer",
        transition: "background 0.2s ease",
        "&:hover": {
            background: "rgba(245, 245, 245, 0.2)",
        }
    },

    textContainer: {
        width: "100%",
        overflow: "hidden",
        position: "relative",
        display: 'flex',
        justifyContent: 'center',
    },

    textContent: {
        whiteSpace: "nowrap",
        width: "fit-content",
        position: "relative",
        display: "inline-block",
        transition: "transform 0.3s ease",
    },

    marqueeAnimation: {
        animation: "$marquee 15s linear infinite",
    },

    "@keyframes marquee": {
        "0%": { transform: "translateX(100%)" },
        "100%": { transform: "translateX(-100%)" }
    },


    stageInfoBlock: {
        width: "100%",
        maxWidth: "350px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 5px",
        margin: "0 auto",
    },

    controlsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        background: 'transparent'
    },

    iconClose: {
        width: 15,
    },

});
