import cn from "classnames";
import { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";

import { useHistory } from "react-router-dom";

import useStores from "src/hooks/useStores";
import {
    GameChatType,
    GameChatToggleStatus,
    EGameChatTab,
} from "src/store/models";

import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";

import { LeftLayoutPropsContext } from "../../components/left/leftLayoutContext";

import { useStyles } from "./styles";
import LeftLayout from "src/pages/game-new2/layouts/components/left";
import ChessTableMobile from "src/pages/game-new2/layouts/layoutTypes/mobileTablet/chessTableMobile";
import {boardRoot} from "../../../../../core/endpoints";
import {goTo} from "../../../../../helpers";
interface Props {
    //LEGACY
    diceClickRnd?: number;
    setDiceClickRnd?: any;
    rndPrefix?: number;
    itemHeight?: number | null;
    itemWidth?: number | null;
    tabletRatio: boolean;
}

const MobileTabletLayout = (props: Props) => {
    const { gameStore, authStore } = useStores();
    const [chatTogglerState, runChatToggler] = useState<
        GameChatToggleStatus.open | GameChatToggleStatus.hide | boolean
    >(false);

    const history = useHistory();
    const currentTabMain =
        authStore.currentUser?.settings?.dicechessBoardRightBlockMode ||
        EGameChatTab.chat;
    const [currentTab, setCurrentTab] = useState<any>(currentTabMain);
    const mobileTabletLayoutContainer = useRef<HTMLDivElement>(null);

    const { isMultiTable } = useChessBoardProps();
    const isViewMode = gameStore.isViewMode();

    const chatMobileType = GameChatType.MOBILE;
    const style = useStyles();

    const onChatToggler = useCallback(() => {
        switch (chatTogglerState) {
            case false:
                runChatToggler(GameChatToggleStatus.open);

                break;
            case GameChatToggleStatus.open:
                runChatToggler(GameChatToggleStatus.hide);

                break;
            case GameChatToggleStatus.hide:
                runChatToggler(GameChatToggleStatus.open);
                break;
            default:
                runChatToggler(false);
        }
    }, [chatTogglerState]);

    const onChatTabsToggler = (value: keyof typeof EGameChatTab) => {
        setCurrentTab(value);
    };

    const viewMode = gameStore.isViewMode();

    useEffect(() => {
        if (!viewMode) return;
        if (gameStore.nextRound) {
            // history.push(`/game/${gameStore.nextRound.gameId}`);
            // document.location.href = `/game/${gameStore.nextRound.gameId}`;


            (goTo(boardRoot + `/game/${gameStore.nextRound.gameId}?userKey=${localStorage.getItem("t")}&currentUserId=${authStore.currentUser?._id}`)); return;
        }
    }, [viewMode, gameStore.nextRound]);

    const mainProps = {
        ...props,
        mobileTabletLayoutContainer,
    };

    return (
        <>
            <div
                ref={mobileTabletLayoutContainer}
                className={cn(style.mobileTabletLayoutContainer, {})}
            >
                <LeftLayoutPropsContext.Provider
                    value={{
                        onChatToggler,
                        chatMobileType,
                        chatTogglerState,
                        onChatTabsToggler,
                        currentTab,
                    }}
                >
                    <ChessTableMobile {...mainProps} />
                </LeftLayoutPropsContext.Provider>
            </div>
            <div
                className={cn(
                    style.mobileTabletLeftBox,
                    GameChatType.MOBILE,
                    chatTogglerState,
                    {
                        isViewMode: viewMode,
                    }
                )}
            >
                <LeftLayoutPropsContext.Provider
                    value={{
                        onChatToggler,
                        chatMobileType,
                        chatTogglerState,
                        onChatTabsToggler,
                        currentTab,
                    }}
                >
                    <LeftLayout />
                </LeftLayoutPropsContext.Provider>
            </div>
        </>
    );
};

export default observer(MobileTabletLayout);
