import rabbitIcon from "src/assets/icons/rabbit.svg";

export const RapidIcon = (): JSX.Element => {
    return (
        <>
            <img src={rabbitIcon} alt="rapid" />
        </>
    );
};

// Save this for example svg logical, dont remove pls
// export const RapidIcon = () => {
//   return (
//       <>
//           {iOSDetect() ? (
//               <img src={rapidPng} alt='rapid' />
//           ) : (
//             <img src={rapidPng} alt='rapid' />
//               <SvgIcon component={rapid} />
//           )}
//       </>
//   );
// };
