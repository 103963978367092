import React, {useEffect} from "react";
import { Modal, useMediaQuery } from "@mui/material";
import { useStyles } from './styles';
import loadable from "@loadable/component";
import closeIcon from "src/assets/icons/x.svg"
import { useLocale } from "../../../providers/LocaleProvider";
import useStores from "../../../hooks/useStores";
import moment from "moment/moment";

const JackPotComponent = loadable(() => import("src/components/JackPot"), {
    fallback: <div>Loading...</div>
});

interface JackpotModalProps {
    open: boolean;
    onClose: () => void;
}

const JackpotModal: React.FC<JackpotModalProps> = ({ open, onClose }) => {
    const isDesktop = useMediaQuery('(min-width:1025px)');
    const { generalStore } = useStores();
    const tournament = generalStore.lastJackpot?.tournament;
    const styles = useStyles({ isDesktop });
    const {
        lobby: {
            jackpotModal: {
                title,
                timeTitle,
                dateTitle,
                description,
            },
        },
    } = useLocale();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={styles.modal}
            BackdropProps={{
                style: {
                    backgroundColor: isDesktop ? 'transparent' : 'rgba(0, 0, 0, 0.6)',
                    backdropFilter: isDesktop ? 'none' : 'blur(3px)',
                    WebkitBackdropFilter: isDesktop ? 'none' : 'blur(3px)',
                }
            }}
        >
            <div className={styles.modalContent}>
                <div
                    onClick={onClose}
                    className={styles.closeButton}
                >
                    <img src={closeIcon} />
                </div>
                {isDesktop ? (
                    <div className={styles.jackpotBox}>
                        <span className={styles.jackpotTitle}>JACKPOT</span>
                    </div>
                ) : (
                    <div className={styles.jackpotBox}>
                        <JackPotComponent isFullAnimation={false} isModal={true} />
                    </div>
                )}
                <div className={styles.content}>
                    <div className={styles.block}>
                        <span className={styles.jackpotBoxTitle}>
                            {title('Jackpot Freeroll Tournament')}
                        </span>
                    </div>
                    <div className={styles.block}>
                        <span className={styles.description}>
                            {description('For satellites winners, top 32 rated players and wildcard guests')}
                        </span>
                    </div>
                    {tournament && (
                      <div className={styles.blockDate}>
                          <div>
                              {dateTitle('Date')}:
                              <span className={styles.date}>
                                {moment(tournament?.startAt).format("DD/MM/YYYY")}
                            </span>
                          </div>
                          <div>
                              {timeTitle('Time')}:
                              <span className={styles.date}>
                               {moment(tournament?.startAt).format('HH:mm')}
                            </span>
                          </div>
                      </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default JackpotModal;