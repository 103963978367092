import {
    action,
    computed,
    makeAutoObservable,
    observable,
    runInAction,
} from "mobx";
import { Socket } from "socket.io-client";

import {
    ColorVariant,
    GameStatus,
    IGame,
    IGameUpdate,
    UserBalanceType,
    ILobbyInvites,
    ILobbyInvitesAccepts,
    ILobbyGame,
    ILobbyGameAgregator,
} from "src/store/models";
import { errorService } from "src/service/services";
import {
    ICreateGameRequest,
    ICreateGameRequestNew,
    GameCreateFen,
    GameModeCreate,
} from "src/service/api/lobby/requestResponses";

import {
    createGame,
    getGameById,
    getUserList,
    removeGame,
    createGameNew,
    connectNormalGame,
    connectFreeGame,
    declineNormalGame,
    declineFreeGame,
    cancelNormalInvite,
} from "../service/api/lobby/index";

import {
    getTimeAndIncrement,
    getTimeAndIncrementFilter,
    getTypeGameFilter,
} from "./libs";
import { bets, matches } from "./gameStore/constants/bets";

import { RootStore } from "./index";
import { ETypeFilter } from "src/pages/lobby/components/MainLobbyFilter/mainLobbyFilter";

export enum SortOrder {
    desc = 0,
    asc = 1,
}

interface ISortOrderFilters {
    typeGameSortOrder: SortOrder;
    betGameSortOrder: SortOrder;
    timeGameSortOrder: SortOrder;
}

export enum SortType {
    type = "type",
    bet = "bet",
    time = "time",
}

export enum GameTime {
    "2m" = "2 + 2",
    "3m" = "3 + 3",
    "5m" = "5 + 5",
    "10m" = "10 + 0",
    "20m" = "20 + 0",
}

//====
export enum GameTimeValues {
    "1m" = 60000,
    "2m" = 120000,
    "3m" = 180000,
    "5m" = 300000,
    "10m" = 600000,
    "20m" = 1200000,
}

export enum GameType {
    classic = "classic",
    doubling = "doubling",
}

export enum GameTypeNew {
    classic = "Classic",
    nineHundredSixty = "960",
}

export enum GameStatusLobbyFilter {
    match = "Match",
    proLimit = "ProLimit",
}


interface IGameFilters {
    time: { [s: string]: boolean };
    type: { [s: string]: boolean };
    bet: { [s: number]: boolean };
    status: { [s: string]: boolean };
    matches: { [s: number]: boolean };
}

interface IfilterToggler {
    status: boolean;
    filterType: ETypeFilter;
}

export enum FilterForms {
    type = "type",
    bet = "bet",
    time = "time",
}

export interface ILobbyStats {
    online: number;
    playing: number;
    watching: number;
}

const isMyGame = (game: IGame, userId: string) => {
    return (
        (Array.isArray(game.players) &&
            game.players.length > 0 &&
            game?.players[0]?.id &&
            game?.players[0]?.id === userId) ||
        (Array.isArray(game.players) &&
            game.players.length > 1 &&
            game?.players[1]?.id &&
            game?.players[1]?.id === userId)
    );
};

const sortGameComparator = (
    a,
    b,
    sortOrderType,
    betGameSortOrder: SortOrder,
    timeGameSortOrder: SortOrder
) => {
    if (a.participating) return -1;
    if (b.participating) return 1;

    if (a.status === GameStatus.AWAITING && b.status !== GameStatus.AWAITING) {
        return -1;
    } else if (
        b.status === GameStatus.AWAITING &&
        a.status !== GameStatus.AWAITING
    ) {
        return 1;
    }
    switch (sortOrderType) {
        case SortType.bet:
            if (a.bet === b.bet) {
                return Date.parse(b.created) - Date.parse(a.created);
            }
            return betGameSortOrder === SortOrder.desc
                ? b.bet - a.bet
                : a.bet - b.bet;

        case SortType.time:
            if (a.timeControl.time === b.timeControl.time) {
                return Date.parse(b.created) - Date.parse(a.created);
            }
            return timeGameSortOrder === SortOrder.desc
                ? b.timeControl.time - a.timeControl.time
                : a.timeControl.time - b.timeControl.time;
    }
    return 0;
};

export class LobbyStore {
    rootStore: RootStore;
    // creating new game
    _betConfig: {
        values: Partial<Record<UserBalanceType, number>>;
        isOpen: boolean;
    } = {
        values: { [UserBalanceType.play]: 1, [UserBalanceType.coins]: 1 },
        isOpen: false,
    };
    timeConfig: {
        // value: GameTime;
        value: GameTimeValues;
        isOpen: boolean;
    } = {
        //value: GameTimeValues["3m"],
        value: GameTimeValues["5m"],
        isOpen: false,
    };
    matchConfig: {
        isMatchMode: GameModeCreate;
        value: number;
        isOpen: boolean;
    } = {
        isMatchMode: 1,
        value: 1,
        isOpen: false,
    };
    gameModeConfig: {
        value: GameCreateFen;
        isOpen: boolean;
    } = {
        value: GameCreateFen.classic,
        isOpen: false,
    };
    lobbyMode: UserBalanceType = UserBalanceType.play;
    lobbySocket: Socket | null = null;
    isGamesLoading: boolean = true;
    selectedGame: IGame | null = null;
    isRematchGame: boolean = false;
    sortOrderFilters: ISortOrderFilters = {
        timeGameSortOrder: SortOrder.desc,
        typeGameSortOrder: SortOrder.desc,
        betGameSortOrder: SortOrder.desc,
    };
    sortOrderType: SortType = SortType.bet;
    joinedGame: IGame | null = null;
    acceptedGame: IGame | null = null;

    awaitingGames: IGame[] = [];

    cancelJoinGame: IGame | null = null;
    usersList: any[];
    isGetUserError: string | boolean = false;
    gameIndex: { [s: string]: IGame } = {};
    stats: ILobbyStats = {
        online: 0,
        playing: 0,
        watching: 0,
    };
    isPurchaseDiceCoins: boolean = false;
    connectionGameId: string | null = null;
    filterToggler: IfilterToggler = {
        status: false,
        filterType: ETypeFilter.filter,
    };
    filters: IGameFilters = {
        time: {
            [GameTime["2m"]]: true,
            [GameTime["3m"]]: true,
            [GameTime["5m"]]: true,
            [GameTime["10m"]]: true,
            [GameTime["20m"]]: true,
        },
        // type: {
        //     [GameType.doubling]: true,
        //     [GameType.classic]: true,
        // },
        type: {
            [GameTypeNew.classic]: true,
            [GameTypeNew.nineHundredSixty]: true,
        },
        bet: bets.reduce((acc, el) => {
            acc[el] = true;
            return acc;
        }, {}),
        status: {
            [GameStatusLobbyFilter.proLimit]: true,
            [GameStatusLobbyFilter.match]: true,
        },
        matches: matches.reduce((acc, el) => {
            acc[el] = true;
            return acc;
        }, {}),
    };

    games: IGame[] = [];

    forms: { [s: string]: boolean } = {
        [FilterForms.bet]: false,
        [FilterForms.time]: false,
        [FilterForms.type]: false,
    };

    @observable
    mobileFriendHandler: boolean = false;
    mobileNewGameHandler: boolean = false;
    showLobbyLoader: boolean = true;

    myInProgressGames: IGame[] = [];
    allMyGames: IGame[] = [];

    //=========;
    gamesAgregator: ILobbyGameAgregator[] = [];
    allLobbyInviteGames: ILobbyInvites[] = [];
    allInvitesAccepts: ILobbyInvitesAccepts[] = [];
    allLobbyGames: any[] = [];
    allLobbyParticipatingGames: ILobbyGame[] = [];

    @observable invitesAcceptsNormal: ILobbyInvitesAccepts[] = [];


    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }


    //-----------
    @action
    handleSocketTournamentState(stateData: any) {
        if (stateData?.tournaments) {
            this.rootStore.tournamentsStore.handleTournamentsFromLobby(stateData.tournaments);
        }
    }
    //-----------


    // TODO: fix naming, use camel case
    @action
    openmobileFriends(status: boolean) {
        this.mobileFriendHandler = status;
    }

    // TODO: fix naming, use camel case
    @action
    showOnlyCreateGameBlock(status: boolean) {
        this.mobileNewGameHandler = status;
    }

    // TODO: fix naming, use camel case
    @action
    showmobileGameOrFriends() {
        this.mobileNewGameHandler = false;
        this.mobileFriendHandler = false;
    }

    @action
    setShowLobbyLoader(showLoader: boolean) {
        this.showLobbyLoader = showLoader;
    }

    @action
    setLobbyMode(mode: UserBalanceType) {
        if (this.lobbyMode === mode) return;
        this.lobbySocket?.emit("lobby:leave", this.lobbyMode);
        this.lobbyMode = mode;
        this.resetLobby();
        this.lobbySocket?.emit("lobby:join", this.lobbyMode);
    }

    @action
    setSelectedGame(game: IGame | null) {
        this.selectedGame = game ? game : null;
    }

    @action
    updateSelectedGame(game: IGameUpdate) {
        if (!this.selectedGame) return;
        this.selectedGame.status = game.status;
        this.selectedGame.players[0] = game.players[0];
        this.selectedGame.players[1] = game.players[1];
    }

    @action
    toggleGameTimeSortOrder() {
        this.sortOrderType = SortType.time;
        this.sortOrderFilters.timeGameSortOrder =
            this.sortOrderFilters.timeGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
        this.sortGames();
    }

    @action
    toggleGameTypeSortOrder() {
        this.sortOrderType = SortType.type;
        this.sortOrderFilters.typeGameSortOrder =
            this.sortOrderFilters.typeGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
        this.sortGames();
    }

    @action
    toggleGameBetSortOrder() {
        this.sortOrderType = SortType.bet;
        this.sortOrderFilters.betGameSortOrder =
            this.sortOrderFilters.betGameSortOrder === SortOrder.desc
                ? SortOrder.asc
                : SortOrder.desc;
        this.sortGames();
    }

    @action
    setIsPurchaseDiceCoins(purchaseStatus: boolean) {
        this.isPurchaseDiceCoins = purchaseStatus;
    }

    @action
    setSortOrderType(type: SortType) {
        this.sortOrderType = type;
        this.sortGames();
    }

    @action
    toggleFilter(
        status: boolean,
        filterType: ETypeFilter,
        opponentId?: string
    ) {
        this.filterToggler = {
            status: status,
            filterType: filterType,
        };
    }

    @action
    resetAllFiltres() {
        this.filters.bet = bets.reduce((acc, el) => {
            acc[el] = true;
            return acc;
        }, {});
        this.filters.status = {
            [GameStatusLobbyFilter.proLimit]: true,
            [GameStatusLobbyFilter.match]: true,
        };
        this.filters.time = {
            [GameTime["2m"]]: true,
            [GameTime["3m"]]: true,
            [GameTime["5m"]]: true,
            [GameTime["10m"]]: true,
            [GameTime["20m"]]: true,
        };
        this.filters.type = {
            [GameTypeNew.classic]: true,
            [GameTypeNew.nineHundredSixty]: true,
        };
        this.filters.matches = matches.reduce((acc, el) => {
            acc[el] = true;
            return acc;
        }, {});

        // Reset both games and invites to their original state
        this.gamesAgregator = [...this.allLobbyGames];
        this.allLobbyInviteGames = [...this.allLobbyInviteGames];
    }

    @action
    setGameFilterTime(value: { [s: string]: boolean }) {
        const data = { ...this.filters.time, ...value };
        this.filters.time = {
            ...this.filters.time,
            ...value,
        };
    }

    @action
    clearAllGames() {
        // Очищаем все игры
        runInAction(() => {
            this.allLobbyGames = [];
            this.allLobbyInviteGames = [];
            this.gamesAgregator = [];
        });
    }

    @action
    clearRegularGames() {
        // Очищаем только обычные игры
        runInAction(() => {
            this.allLobbyGames = [];
            this.gamesAgregator = [];
        });
    }

    @action
    clearInviteGames() {
        // Очищаем только invite игры
        runInAction(() => {
            this.allLobbyInviteGames = [];
        });
    }

    @action
    setGameFilterType(value: { [s: string]: boolean }) {
        this.filters.type = {
            ...this.filters.type,
            ...value,
        };
    }

    @action
    setGameFilterStatus(value: { [s: string]: boolean }) {
        this.filters.status = {
            ...this.filters.status,
            ...value,
        };
    }

    @action
    setGameFilterBet(value: { [s: number]: boolean }) {
        this.filters.bet = {
            ...this.filters.bet,
            ...value,
        };
    }

    setGameFilterMatches(value: { [s: number]: boolean }) {
        this.filters.matches = {
            ...this.filters.matches,
            ...value,
        };
    }

    @action
    toggleBetFilterForm(value?: boolean) {
        this.forms[FilterForms.bet] =
            value !== undefined ? value : !this.forms[FilterForms.bet];
    }

    @action
    sortGames() {
        this.games = [...this.games].sort((a, b) => {
            return sortGameComparator(
                a,
                b,
                this.sortOrderType,
                this.sortOrderFilters.betGameSortOrder,
                this.sortOrderFilters.timeGameSortOrder
            );
        });
        this.allMyGames = [...this.allMyGames].sort((a, b) => {
            return sortGameComparator(
                a,
                b,
                this.sortOrderType,
                this.sortOrderFilters.betGameSortOrder,
                this.sortOrderFilters.timeGameSortOrder
            );
        });
    }

    @action
    filterGames() {
        this.games = [...this.games].filter((game) => {
            return (
                this.filters.bet[game.bet] &&
                this.filters.time[game.timeControl.time]
            );
        });
        this.allMyGames = [...this.allMyGames].filter((game) => {
            return (
                this.filters.bet[game.bet] &&
                this.filters.time[game.settings.time]
            );
        });
        //Dont Delete! there is logical for feature
        // const result = [...this.games].map((game) => {
        //     const gameTimeControl = `${game.timeControl.time} + ${game.timeControl.increment}`;
        //     return {
        //         ...game,
        //         hideInFilter:
        //             this.filters.bet[game.bet] &&
        //             this.filters.time[gameTimeControl],
        //     };
        // });
        // this.games === result;
    }


    @action
    filterGamesNew() {
        // Filter regular games
        this.gamesAgregator = [...this.allLobbyGames].filter((game) => {
            return (
                this.filters.bet[game.bet] &&
                this.filters.time[getTimeAndIncrementFilter(game.time)] &&
                this.filters.type[getTypeGameFilter(game.fenType)] &&
                //Временное решение
                game.type !== 8 
            );
        });

        // Filter invites using the same criteria
        const filteredInvites = [...this.allLobbyInviteGames].filter((invite) => {
            return (
                this.filters.bet[invite.details.bank] &&
                this.filters.time[getTimeAndIncrementFilter(invite.time)] &&
                this.filters.type[getTypeGameFilter(invite.details.fenType)] &&
                //Временное решение
                invite.details.type !== 8 
            );
        });

        // Update both filtered games and invites
        this.allLobbyInviteGames = filteredInvites;
    }

    @action
    toggleTimeFilterForm(value?: boolean) {
        this.forms[FilterForms.time] =
            value !== undefined ? value : !this.forms[FilterForms.time];
    }

    @action
    toggleTypeFilterForm(value?: boolean) {
        this.forms[FilterForms.type] =
            value !== undefined ? value : !this.forms[FilterForms.type];
    }

    async removeGame(game: IGame) {
        try {
            const response = await removeGame({ gameId: game.id });
            if (response.data.error) {
                this.refreshLobby();
            } else {
                this.rootStore.authStore.updateBalance(response.data.balance);
                this.setSelectedGame(null);
                this.setAwaitingGame(game.id);
            }
        } catch (e) {
            this.refreshLobby();
        }
    }

    async getUserList() {
        try {
            this.isGetUserError = true;
            const res = await getUserList();
            const { data } = res;
            data &&
                runInAction(() => {
                    this.usersList = data.users;
                    this.isGetUserError = false;
                });
        } catch (error) {
            runInAction(() => {
                this.isGetUserError = true;
            });
            return error;
        }
    }

    @computed
    getActiveGames() {
        return this.allMyGames.filter((game) => {
            return game.status === GameStatus.IN_PROGRESS;
        });
    }

    @computed
    getActiveGamesDot() {
        const me = this.rootStore.authStore.currentUser?._id;
        let myActiveGames = this.allLobbyGames.filter((game) => {
            return (
                (game.players[0]._id === me || (game.players[1] && game.players[1]._id === me)) &&
                //Временное решение
                game.type !== 8 
            );
        });
        return myActiveGames;
    }

    //   export enum UserBalanceType {
    //     coins = 'coins',
    //     play = 'play',
    //     referralBonus = 'referralBonus',
    // }

    @computed
    getAllMyParticipatingGames(userId?: string) {
        if (!userId) return [];
        if (Array.isArray(this.games) && this.games.length > 0) {
            return this.games.filter((game) => {
                return isMyGame(game, userId);
            });
        }
        return [];
    }

    @computed
    getParticipatingGames() {
        return this.allMyGames.filter((game) => {
            return game.status === GameStatus.IN_PROGRESS;
        });
    }

    @computed
    getMyNotReadyGame() {
        return this.allMyGames.filter((game) => {
            return game.status === GameStatus.NOT_READY;
        });
    }

    @computed
    getNotReadyGames() {
        return this.games.filter((game) => {
            return game.status === GameStatus.NOT_READY;
        });
    }

    @computed
    getChallengedGames(userId?: string) {
        return this.allMyGames.filter((game) => {
            return (
                game.status === GameStatus.AWAITING &&
                game.challenged?.id === userId
            );
        });
    }

    @action
    initLobbySocket(socket: Socket) {
        this.lobbySocket = socket;

        // Listen directly for friend invites
        socket.on('friend:invite', (data) => {
            runInAction(() => {
                this.invitesAcceptsNormal = [...this.invitesAcceptsNormal, data];
            });
        });

        socket.on('state', (data) => {
            runInAction(() => {
                this.invitesAcceptsNormal = data.invitesAcceptsNormal || [];
                this.allInvitesAccepts = data.invitesAccepts || [];
                this.allLobbyInviteGames = data.invites || [];
                // console.log(data);

                this.rootStore.authStore.updateBalance(data.balance);
                this.handleSocketTournamentState(data);
            });
        });
    }

    @computed
    allAwaitingGames(userId: string | undefined) {
        return [...this.allMyGames, ...this.games].filter((game) => {
            return (
                game.status === GameStatus.AWAITING &&
                game.challenged?.id !== userId
            );
        });
    }

    @computed
    get myInProgressGameCount() {
        return this.myInProgressGames.length;
    }
    @computed
    get allMyGameCount() {
        return this.allMyGames.length;
    }

    @action
    resetLobby() {
        this.games = [];
        this.gameIndex = {};
        this.selectedGame = null;
        this.awaitingGames = [];
        this.joinedGame = null;
        this.cancelJoinGame = null;
        this.acceptedGame = null;
        this.allMyGames = [];
        this.myInProgressGames = [];
    }

    refreshLobby() {
        this.resetLobby();
        if (!this.lobbySocket) return;
        this.lobbySocket.emit("lobby:join", this.lobbyMode);
        this.lobbySocket.emit("lobby:myGames");
    }

    @action
    refreshAllMyGames() {
        if (!this.lobbySocket) return;
        this.lobbySocket.emit("lobby:myGames");
    }

    @action
    setBetConfigValue(value: number) {
        this._betConfig.values = {
            ...this._betConfig.values,
            [this.lobbyMode]: value,
        };
    }

    @action
    setBetConfigIsOpen(isOpen: boolean) {
        this._betConfig.isOpen = isOpen;
    }

    @action
    setTimeConfigValue(value: GameTimeValues) {
        this.timeConfig.value = value;
    }

    @action
    setTimeConfigIsOpen(isOpen: boolean) {
        this.timeConfig.isOpen = isOpen;
    }

    @action
    setMatchConfigIsOpen(isOpen: boolean) {
        this.matchConfig.isOpen = isOpen;
    }

    @action
    setMatchConfigValue(matches: GameModeCreate) {
        this.matchConfig = {
            ...this.matchConfig,
            value: matches,
        };
    }
    @action
    setMatchMode(mode: number) {
        this.matchConfig = {
            ...this.matchConfig,
            isMatchMode: mode,
        };
    }

    @action
    setGameModeConfigIsOpen(isOpen: boolean) {
        this.gameModeConfig.isOpen = isOpen;
    }

    @action
    setGameModeConfigValue(value: GameCreateFen) {
        this.gameModeConfig.value = value;
    }

    @action
    setIsRematchGame(isRematchGame: boolean) {
        this.isRematchGame = isRematchGame;
    }

    async loadRematchGame(gameId: string) {
        const res = await getGameById({ gameId });
        if (res.status === 200 && !res.data.error) {
            this.setSelectedGame(res.data);
            this.isRematchGame = true;
        }
    }

    async loadGame(gameId: string) {
        const res = await getGameById({ gameId });
        if (res.status === 200 && !res.data.error) {
            this.setSelectedGame(res.data);
        }
    }

    async createGameNew(params: ICreateGameRequestNew) {
        try {
            const res = await createGameNew(params);

            if (params.sendTo && res.data && !("error" in res.data)) {
                this.lobbySocket?.emit("friend:challenged", {
                    gameId: res.data.gameId,
                    to: params.sendTo,
                });
            }

            return res;
        } catch (e) {
            console.error("Ошибка при создании игры:", e);
            throw e;
        }
    }

    @action
    async createFriendGame(params: ICreateGameRequestNew) {
        try {
            if (!this.selectedFriend) {
                throw new Error("No friend selected");
            }

            const res = await createGameNew(params);
            console.log("Friend game response:", res.data);

            if (res.data && !("error" in res.data)) {
                this.lobbySocket?.emit("friend:challenged", {
                    inviteId: res.data.inviteId,
                    to: this.selectedFriend,
                });

                this.connectionGameId = res.data.gameId;
            }

            return res;
        } catch (e) {
            console.error("Error creating friend game:", e);
            throw e;
        } finally {
            this.selectedFriend = null;
        }
    }

    async createGame(params?: Pick<ICreateGameRequest, "rounds">) {
        const time = getTimeAndIncrement(this.timeConfig.value);
        const request: ICreateGameRequest = {
            color: [ColorVariant.white, ColorVariant.black][
                Math.floor(Math.random() * 2)
            ],
            undo: true,
            position: false,
            rounds: params?.rounds ?? 1,
            private: false,
            time: time.time,
            increment: time.increment,
            fen: "",
            bet: this.betConfig.value,
            doubling: true,
            invite: "",
            balanceType: this.lobbyMode,
        };
        const response = await createGame(request);
        this.rootStore.authStore.updateBalance(response.data.balance);
        //this.refreshLobby();
    }

    async createGameBetweenFriends(
        friendId: string,
        params?: Pick<ICreateGameRequest, "rounds">
    ) {
        const time = getTimeAndIncrement(this.timeConfig.value);
        const request: ICreateGameRequest = {
            color: [ColorVariant.white, ColorVariant.black][
                Math.floor(Math.random() * 2)
            ],
            undo: true,
            position: false,
            rounds: params?.rounds ?? 1,
            private: false,
            time: time.time,
            increment: time.increment,
            fen: "",
            bet: this.betConfig.value,
            doubling: true,
            invite: "",
            challenged: friendId,
            balanceType: this.lobbyMode,
        };
        const response = await createGame(request);
        this.rootStore.authStore.updateBalance(response.data.balance);
        return response;
    }

    @action
    setAwaitingGame(game: IGame | string) {
        if (typeof game === "string") {
            this.awaitingGames = [...this.awaitingGames].filter(
                (t) => t.id != game
            );
        } else {
            this.awaitingGames.push(game);
        }
    }

    @action
    setJoinedGame(game: IGame | null) {
        this.joinedGame = game;
    }

    @action
    setAcceptedGame(game: IGame | null) {
        if (game?.previousGameId) {
            return;
        }
        this.acceptedGame = game;
    }

    @action
    setCancelledJoinGame(game: IGame | null) {
        this.cancelJoinGame = game;
    }

    @action
    refreshGames() {
        this.games = this.games.map((t) => {
            return this.gameIndex[t.id];
        });
    }

    @action
    addGamesToList(games: IGame[]) {
        games = games.filter((game) => {
            return (
                game.balanceType === this.lobbyMode &&
                game.status !== GameStatus.NOT_READY
            );
        });
        // Если игры нет в массиве gameIndex и она не является рематчем, то значит она новая
        const gamesToAdd = games.filter((item) => {
            return !this.gameIndex[item.id];
        });

        const additionalGamesToRemove = {};
        const gamesToUpdate = games.filter((t) => {
            if (!this.gameIndex[t.id]) return false;
            additionalGamesToRemove[t.id] = t;
            this.gameIndex[t.id] = t;

            return true;
        });
        this.allMyGames = this.allMyGames.filter(
            (g) => !additionalGamesToRemove[g.id]
        );

        const gamesToRemove: { [s: string]: boolean } = {};

        // Забираем массив всех новых игр
        [...gamesToAdd, ...gamesToUpdate].forEach((item) => {
            if (
                item.previousGameId &&
                this.gameIndex[item.previousGameId] &&
                item.status === GameStatus.IN_PROGRESS
            ) {
                delete this.gameIndex[item.previousGameId];
                gamesToRemove[item.previousGameId] = true;
            }
            this.gameIndex[item.id] = item;
        });

        let t = {};

        this.games = [...gamesToAdd, ...gamesToUpdate, ...this.games]
            .filter((game) => {
                if (t[game.id]) {
                    return false;
                }
                if (gamesToRemove[game.id]) {
                    return false;
                }
                t[game.id] = true;
                return true;
            })
            .sort((a, b) => {
                return sortGameComparator(
                    a,
                    b,
                    this.sortOrderType,
                    this.sortOrderFilters.betGameSortOrder,
                    this.sortOrderFilters.timeGameSortOrder
                );
            });
    }

    @action
    updateGamesFromList(games: IGameUpdate[]) {
        // Если игры ЕСТЬ в массиве gameIndex, то значит Это существующая игра
        const gamesToUpdate = games.filter((item) => {
            return !!this.gameIndex[item.id];
        });
        const gamesToRemove: { [s: string]: boolean } = {};

        gamesToUpdate.forEach((item) => {
            if (
                item.previousGameId &&
                this.gameIndex[item.previousGameId] &&
                item.status === GameStatus.IN_PROGRESS
            ) {
                delete this.gameIndex[item.previousGameId];
                gamesToRemove[item.previousGameId] = true;
            }
            if (
                item.previousGameId &&
                this.gameIndex[item.previousGameId] &&
                this.selectedGame?.id === item.previousGameId &&
                item.status === GameStatus.IN_PROGRESS
            ) {
                this.updateSelectedGame(item);
            }
            if (item.id === this.selectedGame?.id) {
                this.updateSelectedGame(item);
            }
            if (this.gameIndex[item.id]) {
                this.gameIndex[item.id].status = item.status;
                this.gameIndex[item.id].players[0] = item.players[0];
                this.gameIndex[item.id].players[1] = item.players[1];
            }
        });
        this.refreshGames();
        this.allMyGames = this.allMyGames.map((t) => {
            return this.gameIndex[t.id];
        });
        this.myInProgressGames = this.allMyGames.filter(
            (t) => t.status === GameStatus.IN_PROGRESS
        );
    }

    @computed
    getGameByIndex(index: number) {
        if (index < this.myGamesByLobbyModeCount) {
            return this.myGamesByLobbyMode[index];
        } else {
            index = index - this.myGamesByLobbyModeCount;
            return this.games[index];
        }
    }

    get myGamesByLobbyModeCount() {
        return this.allMyGames.filter((t) => t.balanceType === this.lobbyMode)
            .length;
    }

    get myGamesByLobbyMode() {
        return this.allMyGames.filter((t) => t.balanceType === this.lobbyMode);
    }

    getGamesListSize() {
        return this.myGamesByLobbyModeCount + this.games.length;
    }

    @action
    addMyGamesToList(games: IGame[]) {
        const gamesToAdd = games.filter((t) => !this.gameIndex[t.id]);

        const gamesToRemove = {};
        const gamesToUpdate = games.filter((t) => {
            if (!this.gameIndex[t.id]) return false;
            gamesToRemove[t.id] = t;
            this.gameIndex[t.id] = t;

            return true;
        });

        this.games = this.games.filter((g) => !gamesToRemove[g.id]);

        this.allMyGames = this.allMyGames.map((t) => {
            if (gamesToRemove[t.id]) {
                delete gamesToRemove[t.id];
            }
            return this.gameIndex[t.id];
        });

        const additionalGamesToAdd = gamesToUpdate.filter((t) => {
            if (gamesToRemove[t.id]) return true;
            return false;
        });

        this.allMyGames = [
            ...this.allMyGames,
            ...gamesToAdd,
            ...additionalGamesToAdd,
        ];
        this.myInProgressGames = this.allMyGames.filter(
            (t) => t.status === GameStatus.IN_PROGRESS
        );

        gamesToAdd.forEach((item) => {
            if (item.participating && item.status === GameStatus.AWAITING) {
                this.setAwaitingGame(item);
            } else if (
                item.participating &&
                item.status === GameStatus.NOT_READY &&
                item.players.length === 1 &&
                !item.previousGameId
            ) {
                this.setAwaitingGame(item);
                this.setJoinedGame(item);
            }
            this.gameIndex[item.id] = item;
        });
    }

    @action
    updateMyGamesToList(games: IGameUpdate[]) {
        games.forEach((item) => {
            if (item.id === this.selectedGame?.id) {
                this.updateSelectedGame(item);
            }
            if (this.gameIndex[item.id]) {
                this.gameIndex[item.id].status = item.status;
                this.gameIndex[item.id].players[0] = item.players[0];
                this.gameIndex[item.id].players[1] = item.players[1];
            }
        });
        this.allMyGames = this.allMyGames.map((t) => {
            return this.gameIndex[t.id];
        });
    }

    @action
    removeGamesFromList(games: string[]) {
        let indexToRemove = games.reduce((memo, gameId) => {
            if (!this.gameIndex[gameId]) {
                return memo;
            }
            const hasAcceptedRematch =
                this.gameIndex[gameId]?.rematchInfo[ColorVariant.black] &&
                this.gameIndex[gameId]?.rematchInfo[ColorVariant.white];
            if (hasAcceptedRematch) {
                return memo;
            }
            memo[gameId] = true;
            delete this.gameIndex[gameId];
            if (gameId === this.selectedGame?.id) {
                this.selectedGame = null;
            }
            if (this.awaitingGames.find((g) => g.id === gameId)) {
                this.awaitingGames = this.awaitingGames.filter(
                    (g) => g.id !== gameId
                );
            }
            return memo;
        }, {} as { [s: string]: boolean });
        if (Object.values(indexToRemove).length > 0) {
            this.games = this.games.filter((item) => !indexToRemove[item.id]);
            this.allMyGames = this.allMyGames.filter(
                (item) => !indexToRemove[item.id]
            );
            this.myInProgressGames = this.myInProgressGames.filter(
                (item) => !indexToRemove[item.id]
            );
        }
    }

    @computed
    get filteredGames() {
        return this.games;
    }

    get betConfig() {
        const valueByLobbyMode = this._betConfig.values[this.lobbyMode] ?? 1;
        return { value: valueByLobbyMode, isOpen: this._betConfig.isOpen };
    }

    cancelGame() {
        this.lobbySocket?.emit("game:canceled", this.acceptedGame?.id);
    }

    cancelFriendJoin(gameId: string) {
        this.lobbySocket?.emit("friend:challenge:refused", gameId);
    }

    @action
    setStats(stats: ILobbyStats) {
        this.stats = stats;
    }

    //==========
    @action
    setAllLobbyInviteGames(games: ILobbyInvites[]) {
        this.allLobbyInviteGames = games;
    }

    @action
    setAllLobbyParticipatingGames(games: ILobbyGame[]) {
        const myGames = games.filter((game) => {
            return game.players.some(
                (player) =>
                    player?._id === this.rootStore.authStore.currentUser?._id &&
                    game.state === GameStatus.IN_PROGRESS
            );
        });
        if (myGames.length) {
            this.allLobbyParticipatingGames = myGames;
            this.connectionGameId = myGames[myGames.length - 1]._id;
        } else {
            this.allLobbyParticipatingGames = [];
            this.connectionGameId = null;
        }
    }

    @action
    setAllLobbyGames(games: ILobbyGame[]) {
        this.allLobbyGames = games;
    }

    get lobbyInviteGames() {
        return this.allLobbyInviteGames;
    }

    get lobbyInviteGamesLength() {
        return this.allLobbyInviteGames.length;
    }

    @action getLobbyInviteGamesLength() {
        return this.allLobbyInviteGames.length;
    }

    @computed
    getLobbyInvitesByIndex(index: number) {
        if (index < this.lobbyInviteGamesLength) {
            return this.lobbyInviteGames[index];
        } else {
            index = index - this.lobbyInviteGamesLength;
            return this.lobbyInviteGames[index];
        }
    }

    @action
    setAllInvitesAccepts(games: ILobbyInvites[], normalInvites: ILobbyInvitesAccepts[]) {
        this.allLobbyInviteGames = games;
        this.invitesAcceptsNormal = normalInvites; // для новых инвайтов от друга к другу
    }

    //======= Lobby Invites Accepts

    @computed
    get hasNormalInvites() {
        return this.invitesAcceptsNormal.length > 0;
    }

    @action
    setAllLobbyInvitesAccepts(acceptsInvites: ILobbyInvitesAccepts[]) {
        this.allInvitesAccepts = acceptsInvites;
    }

    get lobbyInvitesAccepts() {
        return this.allInvitesAccepts;
    }

    get lobbyInvitesAcceptsLength() {
        return this.lobbyInvitesAcceptsLength.length;
    }

    @action
    inviteToMyGame() {
        const currentUserId = this.rootStore.authStore.currentUser?._id;

        // Для обычных инвайтов - я отправитель
        const regularInvite = this.allInvitesAccepts.find(
            accepts => accepts.from._id === currentUserId
        );
        if (regularInvite) return { ...regularInvite, isNormalInvite: true };

        // Для дружеских инвайтов - я получатель
        const friendInvite = this.invitesAcceptsNormal.find(
            accepts => accepts.to._id === currentUserId
        );
        return friendInvite ? { ...friendInvite, isNormalInvite: false } : null;
    }

    @action
    inviteToUsersGame() {
        const currentUserId = this.rootStore.authStore.currentUser?._id;

        // Для обычных инвайтов - я получатель
        const regularInvite = this.allInvitesAccepts.find(
            accepts => accepts.to._id === currentUserId
        );
        if (regularInvite) return { ...regularInvite, isNormalInvite: true };

        // Для дружеских инвайтов - я отправитель
        const friendInvite = this.invitesAcceptsNormal.find(
            accepts => accepts.from._id === currentUserId
        );
        return friendInvite ? { ...friendInvite, isNormalInvite: false } : null;
    }

    @action
    async connectToFreeGame(invitedId: string) {
        try {
            const res = await connectFreeGame(invitedId);
            console.log("connectToFreeGame RES", res);
        } catch (e) {
            return errorService.sendError(
                e.response?.data?.message || "Decline error"
            );
        }
    }

    @action
    async deleteFreeGame(invitedId: string) {
        try {
            console.log("Delete free game RES");
        } catch (e) {
            return errorService.sendError(
                e.response?.data?.message || "Decline error"
            );
        }
    }

    @action
    async connectToNormalGame(invitedId: string) {
        try {
            const { data } = await connectNormalGame(invitedId);
            if ("gameId" in data) {
                this.connectionGameId = data.gameId;
            }
        } catch (e) {
            return errorService.sendError(
                e.response?.data?.message || "Move Connect To Normal Game"
            );
        }
    }

    @action
    async declineFreeGame(invitedId: string) {
        try {
            const res = await declineFreeGame(invitedId);

            this.allInvitesAccepts = this.allInvitesAccepts.filter(
                invite => invite._id !== invitedId
            );

            this.invitesAcceptsNormal = this.invitesAcceptsNormal.filter(
                invite => invite._id !== invitedId
            );

            this.allLobbyInviteGames = this.allLobbyInviteGames.filter(
                invite => invite._id !== invitedId
            );

            return res;
        } catch (e) {
            return errorService.sendError(
                e.response?.data?.message || "Decline Free Game error"
            );
        }
    }

    @action
    async declineNormalGame(invitedId: string) {
        try {
            const res = await declineNormalGame(invitedId);

            this.allInvitesAccepts = this.allInvitesAccepts.filter(
                invite => invite._id !== invitedId
            );

            this.invitesAcceptsNormal = this.invitesAcceptsNormal.filter(
                invite => invite._id !== invitedId
            );

            this.allLobbyInviteGames = this.allLobbyInviteGames.filter(
                invite => invite._id !== invitedId
            );

            return res;
        } catch (e) {
            return errorService.sendError(
                e.response?.data?.message || "Decline Normal Game error"
            );
        }
    }

    @action
    async cancelNormalInvite(invitedId: string) {
        if (!invitedId) return;

        try {
            const res = await cancelNormalInvite(invitedId);

            this.allInvitesAccepts = this.allInvitesAccepts.filter(
                invite => invite._id !== invitedId
            );

            this.invitesAcceptsNormal = this.invitesAcceptsNormal.filter(
                invite => invite._id !== invitedId
            );

            this.allLobbyInviteGames = this.allLobbyInviteGames.filter(
                invite => invite._id !== invitedId
            );

            return res;
        } catch (e) {
            return errorService.sendError(
                e.response?.data?.message || "Cancel Invite Error"
            );
        }
    }
    @computed
    get totalActiveGamesCount() {
        const currentUserId = this.rootStore.authStore.currentUser?._id;

        const activeOrAwaitingGames = this.allMyGames.filter(
            (game) =>
                (game.status === GameStatus.IN_PROGRESS ||
                    game.status === GameStatus.AWAITING) &&
                game.players.some((player) => player.id === currentUserId)
        ).length;

        const sentInvitesCount = this.allLobbyInviteGames.filter(
            (invite) => invite.from._id === currentUserId
        ).length;

        return activeOrAwaitingGames + sentInvitesCount;
    }

    // для кнопки плей в друзьях
    @observable selectedFriend: string | null = null;

    @action
    setSelectedFriend(friendId: string | null) {
        this.selectedFriend = friendId;
    }
}

export default LobbyStore;
