import React, { useEffect, useState, useRef } from "react";
import { Modal } from "@mui/material";
import { useStyles } from "./styles";
import close from "src/assets/icons/close.svg";

interface RebuyModalProps {
    open: boolean;
    onClose: () => void;
    onRebuy: () => void;
}

const MAX_SECONDS = 30;

const RebuyModal: React.FC<RebuyModalProps> = ({ open, onClose, onRebuy }) => {
    const classes = useStyles();
    const [secondsLeft, setSecondsLeft] = useState(MAX_SECONDS);
    const timerRef = useRef<HTMLSpanElement>(null);
    const circleRef = useRef<SVGCircleElement>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const updateCircle = (seconds: number) => {
        if (!circleRef.current) return;
        const radius = 27;
        const circumference = 2 * Math.PI * radius;
        circleRef.current.style.strokeDasharray = `${circumference}`;
        circleRef.current.style.strokeDashoffset = `${-circumference * (1 - seconds / MAX_SECONDS)}`;
    };

    useEffect(() => {
        if (!open) return;

        let seconds = MAX_SECONDS;
        setSecondsLeft(seconds);         
        updateCircle(seconds);           

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            seconds -= 1;

            setSecondsLeft(seconds);     
            updateCircle(seconds);       

            if (seconds <= 0) {
                clearInterval(intervalRef.current!);
                intervalRef.current = null;
                setTimeout(onClose, 100);
            }
        }, 1000);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [open]);



    return (
        <Modal
            open={open}
            onClose={onClose}
            disableAutoFocus
        >
            <div className={classes.modalContent}>
                <div className={classes.header}>
                    <div className={classes.title}>Last call!</div>
                    <div className={classes.closeButton} onClick={onClose}>
                        <img className={classes.closeIcon} src={close}></img>
                    </div>
                </div>

                <div className={classes.description}>
                    You can still jump into the tournament or make a rebuy—once the timer runs out,
                    the real knockout action begins!
                </div>

                <div className={classes.timerWrapper}>
                    <svg width="64" height="64" viewBox="0 0 60 60">
                        <circle
                            ref={circleRef}
                            cx="30"
                            cy="30"
                            r="27"
                            fill="none"
                            stroke="#00FF7F"
                            strokeWidth="4"
                            strokeLinecap="round"
                            style={{
                                transform: 'rotate(-90deg)',
                                transformOrigin: 'center',
                                transition: 'stroke-dashoffset 0.9s linear',
                            }}
                        />
                    </svg>
                    <span ref={timerRef} className={classes.timerNumber}>{secondsLeft}</span>
                </div>

                <button className={classes.rebuyButton} onClick={onRebuy}>
                    Re-buy
                </button>
            </div>
        </Modal>
    );
};

export default RebuyModal;