import React, { useEffect, useState, useRef } from "react";
import starIcon from 'src/assets/icons/Star.svg';

interface UserStarsBorderProps {
    starsCount: number;
    starSize?: number;
    className?: string;
}

export const UserStarsBorder: React.FC<UserStarsBorderProps> = ({
    starsCount = 0,
    starSize: initialStarSize,
    className = "",
}) => {
    const [isReady, setIsReady] = useState(false);
    const [containerSize, setContainerSize] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const maxStars = 20;
    const actualStarsCount = Math.min(starsCount, maxStars);

    const starSize = initialStarSize || Math.max(containerSize * 0.15, 12);

    useEffect(() => {
        if (containerRef.current) {
            const updateSize = () => {
                if (containerRef.current) {
                    const size = Math.min(
                        containerRef.current.offsetWidth,
                        containerRef.current.offsetHeight
                    );
                    setContainerSize(size);
                }
            };

            updateSize();

            const resizeObserver = new ResizeObserver(updateSize);
            resizeObserver.observe(containerRef.current);

            setIsReady(true);

            return () => {
                if (containerRef.current) {
                    resizeObserver.unobserve(containerRef.current);
                }
            };
        }
    }, []);

    return (
        <div
            ref={containerRef}
            className={`user-stars-border ${className}`}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 2,
                pointerEvents: 'none'
            }}
        >
            {containerSize > 0 && Array.from({ length: maxStars }).map((_, index) => {
                const baseAngle = Math.PI / 2; 
                const stepAngle = (1 / maxStars) * (Math.PI * 2);
                const angle = index % 2 === 0
                    ? baseAngle - stepAngle * Math.ceil(index / 2) 
                    : baseAngle + stepAngle * Math.ceil(index / 2); 

                const radius = containerSize / 2;
                const centerX = radius * Math.cos(angle);
                const centerY = radius * Math.sin(angle);
                const left = centerX + radius - starSize / 2;
                const top = centerY + radius - starSize / 2;

                const isVisible = index < actualStarsCount;
                const delay = isVisible ? index * 0.05 : 0; 

                return (
                    <img
                        key={index}
                        src={starIcon}
                        alt="Star"
                        style={{
                            position: 'absolute',
                            left: `${left}px`,
                            top: `${top}px`,
                            width: `${starSize}px`,
                            height: `${starSize}px`,
                            opacity: isReady && isVisible ? 1 : 0,
                            transform: isReady && isVisible ? 'scale(1)' : 'scale(0.5)',
                            transition: `opacity 0.3s ease-in-out ${delay}s, transform 0.3s ease-in-out ${delay}s`,
                        }}
                    />
                );
            })}
        </div>
    );
};