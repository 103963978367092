import { makeStyles } from '@mui/styles';

import { media } from 'src/utils/media';
import theme from 'src/theme';
const USERNAME_BOX_BORDER = '2px solid #0E101A';

export const useStyles = makeStyles({
    userNameBox: {
        color: '#F5F5F5',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 300, 
        //fontSize: '1.1vw',
        lineHeight: '24px', 
        letterSpacing: '0.02em',
        textAlign: 'center', 
        marginLeft: 10,
        maxWidth: 150,
        overflow: 'hidden',
        '& .marquee-content': {
            display: "inline-block",
            animation: "$marquee 7s linear infinite",
        },       
        '& .side': {
            borderTop: 'none', 
            borderBottom: 'none',
            background: 'transparent', 
        },
        '& .left-side': {
            borderLeft: 'none', 
        },
        '& .right-side': {
            borderRight: 'none', 
        },
        '& .title': {
            fontSize: 18,
            fontWeight: 700,
            padding: 0, 
            color: '#fff', 
        },
        [theme.breakpoints.down(700)]: {
            display: 'none',
        },
    },

    avatarNicknameWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '5vw', 
        paddingLeft: '1vw',
        '@media (max-width: 1024px)': {
            marginRight: 0,
        },
        '@media (max-width: 800px)': {
            marginRight: 0,
        },
        '@media (max-width: 500px)': {
            marginRight: 0, 
        },
        '@media (max-width: 360px)': {
            marginRight: 0,
        },

    },
    avatarWrapper: {
        //marginRight: 10, 
        zIndex: 'unset', 
    },

    avatar: {
        width: media(40, 50, 1366),
        height: media(40, 50, 1366),
        padding: 1,
        '& .nickname-box': {
            fontSize: 12,
        },
        [theme.breakpoints.down('lg')]: {
            transform: 'translateY(-2px)',
            padding: 1,
            width: 40,
            height: 40,
        },
        [theme.breakpoints.down('md')]: {
            width: 36,
            height: 36,
            transform: 'translateY(0px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 32,
            height: 32,
            transform: 'translateY(0px)',
        },
        '@media (max-width: 350px)': {
            width: 32,
            height: 32,
            transform: 'translateY(0px)',
            marginRight: '2vw',
        },
    },

    badgeNotifications: {
        '&.MuiBadge-root > span': {
            [theme.breakpoints.up('xs')]: {
                transform: 'translate(50%, 3%)',
                zIndex: '2',
                background:
                    'linear-gradient(19.92deg, rgb(34, 13, 85) 13.3%, rgb(108, 80, 200) 86.7%)',
            },
        },
        '&.emptyNote': {
            '&.MuiBadge-root > span': {
                display: 'none',
            },
        },
    },

    arrowIcon: {
        color: '#fff',
        marginLeft: '0.2vw',
        [theme.breakpoints.down(700)]: {
            display: 'none',
        },
    },

    settingsIcon: {
        color: '#fff',
        marginLeft: 5,
        [theme.breakpoints.down(700)]: {
            display: 'none',
        },
    },

    //Никнейм бегущая строка
    '@keyframes marquee': {
        '0%': {
            transform: 'translateX(100%)',
        },
        '10%': {
            transform: 'translateX(0%)',
        },
        '90%': {
            transform: 'translateX(-100%)',
        },
        '100%': {
            transform: 'translateX(-100%)',
        },
    },

    avatarContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

    liveIconWrapper: {
        display: 'flex',
        width: 45,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: "-18px",
        zIndex: 2,
        [theme.breakpoints.down('lg')]: {
            marginTop: "-20px",
        },
        [theme.breakpoints.down('md')]: {
            width: 35,
            marginTop: "-15px",
        },
        [theme.breakpoints.down('sm')]: {
            width: 32,
            marginTop: "-15px",
        },
    },

    liveIcon: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
    },
});
