import React from "react";
import { Round } from "../../types";
import TournamentLines from "../tournamentLines";
import BracketColumn from "../bracketColumn";
import MatchPair from "../matchPair";


interface SimpleBracketProps {
    bracket: Round[];
    classes: { [key: string]: string };
}

export const SimpleBracket: React.FC<SimpleBracketProps> = ({
    bracket,
    classes,
}) => {
    return (
        <div className={`${classes.bracket} ${classes.bracketContent}`}>
            <TournamentLines bracket={bracket} />
            {bracket.map((round, roundIndex) => (
                <BracketColumn
                    key={round.title}
                    title={round.title}
                    columnIndex={roundIndex}
                    totalRounds={bracket.length}
                    matchesInRound={round.matches.length}
                    hideHeader={true}
                >
                    {round.matches.map((match, matchIndex) => {
                        // Если это финальный раунд и второй матч - считаем его матчем за 3-е место
                        const isFinalRound = roundIndex === bracket.length - 1;
                        const isThirdPlaceMatch = isFinalRound && matchIndex === 1;
                        return (
                            <div
                                key={matchIndex}
                                className={classes.singleMatch}
                                data-column={roundIndex}
                                data-match={matchIndex}
                            >
                                <MatchPair
                                    {...match}
                                    firstWins={match.firstWins ?? 0}
                                    secondWins={match.secondWins ?? 0}
                                    columnIndex={roundIndex}
                                    matchIndex={matchIndex}
                                    totalColumns={bracket.length}
                                    isUpperBracket={true}
                                    isThirdPlace={isThirdPlaceMatch || match.isThirdPlace}
                                />
                            </div>
                        );
                    })}
                </BracketColumn>
            ))}
        </div>
    );
};