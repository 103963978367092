import { makeStyles } from "@mui/styles";
import theme from "src/theme";

export const useStyles = makeStyles({
    topPlayersWrapper: {
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        position: "relative",
        overflow: "hidden",
        "&.isMobile": {
            gridGap: "20px",
            [theme.breakpoints.down("lg")]: {
                position: "absolute",
                width: "100%",
                height: "100%",
                transform: "translate(100%, 0%)",
                transition: "transform 0.2s ease-in",
                zIndex: 312,
                background: "rgba(18, 18, 18, 0.9)",
                backdropFilter: "none",
                "&.openTopPlayers": {
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    transform: "translate(0%, 0%)",
                    transition: "transform 0.3s ease-out",
                    zIndex: 312,
                    background: "rgba(18, 18, 18, 0.9)",
                    backdropFilter: "blur(3px)",
                },
            },
        },
    },

    topWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 24,
        [theme.breakpoints.down("lg")]: {
            marginBottom: 0,
            marginTop: 75,
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: 15,
            marginTop: 50,
        },
    },

    avatarContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    starsOverlay: {
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "100%", 
        height: "100%",
        pointerEvents: "none",
        zIndex: 10,
    },

    customScrollbar: {
        overflowX: "hidden",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: "0px !important",
            height: "0px !important",
        },
        "& .ScrollbarsCustom-Track": {
            display: "none",
        },
        "&. ScrollbarsCustom-Wrapper": {
            padding: "0 !important",
            margin: "0 !important",
            border: "none !important",
            overflow: "hidden !important",
            right: "0px !important",
            position: "static !important",
        },
        "& .ScrollbarsCustom-Scroller": {
            padding: "0 !important",
            margin: "0 !important",
        },
        "-ms-overflow-style": "none !important", // IE и Edge
        "scrollbar-width": "none !important",
    },

    currentRankWrapper: {
        width: 40,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    rankIcon: {
        width: 24,
        height: 24,
        objectFit: "contain",
        [theme.breakpoints.down("lg")]: {
            width: 22,
            height: 22,
        },
    },
    currentRank: {
        fontSize: 16,
        fontWeight: 700,
        color: "#fff",
        marginTop: -2,
        [theme.breakpoints.down("lg")]: {
            fontSize: 11,
            fontWeight: 500,
            color: "#fff",
            lineHeight: "11px",
            marginTop: -1,
        },
    },
    headerTitle: {
        fontSize: 32,
        fontWeight: 600,
        lineHeight: "39px",
        color: "#F5F5F5",
        margin: "0 24px 0 12px",
        [theme.breakpoints.down("lg")]: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "29px",
            margin: "0px",
        },
    },

    refreshIconButton: {
        width: 40,
        height: 40,
        borderRadius: "50%",
        display: "grid",
        placeItems: "center",
        background: "#F5F5F51A",

        cursor: "pointer",
    },
    refreshIcon: {
        width: 24,
        height: 24,
        objectFit: "contain",
    },

    listWrapper: {
        width: "100%",
        display: "grid",
        gridRowGap: 20,
        padding: "0px 8px",
        "&::-webkit-scrollbar": {
            display: "none !important",
        },
        "scrollbar-width": "none !important",
        [theme.breakpoints.down("lg")]: {
            gridRowGap: 8,
        },
    },

    itemWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#F5F5F50D",
        borderRadius: 44,
        padding: 13,
        width: '100%',
        "&.place-1": {
            background:
                "linear-gradient(332.6deg, rgba(245, 185, 64, 0.2) 0%, rgba(245, 186, 64, 0.2) 30.21%, rgba(245, 187, 65, 0.2) 54.48%, rgba(245, 188, 65, 0.2) 100%)",
            border: "2px solid #EFD57A",
        },
        "&.place-2": {
            background:
                "linear-gradient(137.95deg, rgba(122, 150, 172, 0.2) 2.28%, rgba(194, 212, 225, 0.2) 32.94%, rgba(212, 222, 229, 0.2) 62.15%, rgba(171, 189, 200, 0.2) 78.69%, rgba(188, 202, 215, 0.2) 95.24%)",
            border: "2px solid #E9EEF2",
        },
        "&.place-3": {
            background:
                "linear-gradient(137.95deg, rgba(163, 101, 42, 0.2) 2.28%, rgba(231, 123, 51, 0.2) 69.93%, rgba(220, 112, 42, 0.2) 84.05%, rgba(115, 61, 25, 0.2) 99.62%)",
            border: "2px solid #ECAD6B",
        },
        "&.myPosition": {
            "&:before": {
                content: "''",
                "-webkit-box-shadow": "0px 0px 5px 3px #58FFB96B",
                "-moz-box-shadow": "0px 0px 5px 3px #58FFB96B",
                boxShadow: "0px 0px 5px 3px #58FFB96B",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                borderRadius: "44px",
            },
        },
        [theme.breakpoints.down("lg")]: {
            padding: 7,
        },
        [theme.breakpoints.down("md")]: {
            position: 'relative',
        },
        [theme.breakpoints.down("sm")]: {
            position: 'relative',
        },
    },
    itemInfoWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    itemAvatar: {
        height: "49px",
        aspectRatio: "1 / 1",
        objectFit: "cover",
        marginRight: 16,
        borderRadius: "50%", 
        [theme.breakpoints.down("md")]: {
            marginRight: 8,
            height: "40px",
            aspectRatio: "1 / 1",
        },
        "&.isLoaded": {
            filter: "blur(3px)",
        },
    },
    itemNameAndRating: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    itemName: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "19.2px",
        color: "#F5F5F5CC",
        [theme.breakpoints.down("md")]: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: "16.8px",
        },
    },
    itemRating: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "25.6px",
        color: "#F5F5F5E5",
        "& span": {
            fontWeight: 700,
            lineHeight: "19.2px",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: "14.63px",
            color: "gray",
            "& span": {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: "14.63px",
                color: "#F5F5F5E5",
            },
        },
    },
    itemPlace: {
        width: 48,
        height: 48,
        borderRadius: "50%",
        display: "grid",
        placeItems: "center",
        fontSize: 24,
        fontWeight: 600,
        lineHeight: "39.01px",
        border: "1px solid #F5F5F599",
        color: "#F5F5F599",
        "&.place-1": {
            background:
                "linear-gradient(332.6deg, #F5B940 0%, #FFF1D5 16.15%, #F5BA40 30.21%, #F5BB41 54.48%, #FFE7B3 76.83%, #F5BC41 100%)",
            boxShadow: "0px 4px 6px 0px #FFFFFF99 inset",
            color: "#121212",
            border: "none",
        },
        "&.place-2": {
            background:
                "linear-gradient(137.95deg, #7A96AC 2.28%, #EAEFF3 19.8%, #C2D4E1 32.94%, #FFFFFF 50.16%, #D4DEE5 62.15%, #ABBDC8 78.69%, #BCCAD7 95.24%)",
            boxShadow: "0px 4px 6px 0px #FFFFFF99 inset",
            color: "#121212",
            border: "none",
        },
        "&.place-3": {
            background:
                "linear-gradient(137.95deg, #A3652A 2.28%, #EFB06E 22.72%, #FEFBF2 39.27%, #F9C176 53.87%, #E77B33 69.93%, #DC702A 84.05%, #733D19 99.62%)",
            boxShadow: "0px 4px 6px 0px #FFFFFF99 inset",
            color: "#121212",
            border: "none",
        },
        [theme.breakpoints.down("md")]: {
            height: "40px",
            width: "auto",
            aspectRatio: "1 / 1",
            fontSize: 20,
            fontWeight: 600,
            lineHeight: "38px",
        },
    },

    crossWrapper: {
        display: "none",
        [theme.breakpoints.down("lg")]: {
            display: "block",
            position: "absolute",
            top: "30px",
            right: "18px",
        },
    },

    cross: {
        width: 25,
        height: 25,
        position: "relative",
        "&::before": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(45deg)",
            transform: "translate(-50%,-50%) rotate(45deg)",
            background: "linear-gradient(180deg, white 0%, white 100%)",
        },
        "&::after": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(-45deg)",
            transform: "translate(-50%,-50%) rotate(-45deg)",
            background: "linear-gradient(180deg, white 0%, white 100%)",
        },
        [theme.breakpoints.down("sm")]: {
            width: 20,
            height: 20,
        },
    },

    //Modal for mobile
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: 0, 
        top: '7%', 
        backgroundColor: 'rgba(18, 18, 18, 0.9)',
        zIndex: 1000,
        height: 'calc(100vh - 50px)',
        width: '100%',
        maxWidth: '600px', 
        borderTop: '1px solid #FFFFFF26',
        borderRadius: '20px 20px 0 0',
        overflow: 'hidden', 
        outline: 'none',
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        },
        '& .MuiModal-backdrop': {
            outline: 'none'
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            inset: -1,
            padding: '1px',
            background: 'linear-gradient(180deg, rgba(245, 245, 245, 0.3) 0%, rgba(143, 143, 143, 0.05) 100%)',
            borderRadius: '8px',
            zIndex: -1,
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            '-webkit-mask-composite': 'destination-out',
        },
        '@media (min-width: 768px)': {
            top: '10%', 
            height: 'calc(100vh - 70px)',
        },
    },

    modalContent: {
        backgroundColor: 'rgba(18, 18, 18, 0.9)',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden', 
        borderRadius: '20px 20px 0 0',
    },

    closeButton: {
        position: 'absolute',
        right: '16px',
        top: '16px',
        background: 'none',
        border: 'none',
        color: '#FFFFFF',
        fontSize: '24px',
        cursor: 'pointer',
        padding: '5px',
        zIndex: 2000,
        '&:hover': {
            opacity: 0.7,
        },
    },

    content: {
        flex: 1,
        overflowY: 'auto',
        borderTop: '1px solid #FFFFFF26',
    },

});
