import React, {
    ReactNode,
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    useRef,
} from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import { useStyles } from "./styles";
import pawn from "src/pages/game-new2/alerts/pieces/white-01.svg";

interface IBeatPiecesCounter {
    piecesBeatCounter?: number;
    className?: string;
    isViewMode?: boolean;
    isLeague?: boolean;
}

const BeatPiecesCounter = observer((props: IBeatPiecesCounter) => {
    const classes = useStyles();
    const borderCounter = 0;
    const { piecesBeatCounter, className, isViewMode, isLeague } = props;

    return (
        <Box
            component="div"
            className={cn(
                classes.beatPiecesCounter,
                className,
                {
                    [classes.beatPiecesCounterLeague]: isLeague,
                }
            )}
        >
            {piecesBeatCounter && piecesBeatCounter > borderCounter ? (
                <>
                    <img
                        className={cn(classes.beatPiecesParagraphImg, {
                            isViewMode: isViewMode,
                        })}
                        src={pawn}
                        alt="pawn"
                    />
                    <p
                        className={cn(classes.beatPiecesParagraph, className, {
                            isViewMode: isViewMode,
                        })}
                    >
                        + {piecesBeatCounter}
                    </p>
                </>
            ) : null}
        </Box>
    );
});

export default BeatPiecesCounter;
