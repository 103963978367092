import { useEffect, useRef, useState } from "react";
import rightArrowIcon from 'src/assets/icons/ArrowLeft.svg';
import { observer } from "mobx-react";
import TournamentLinesDouble from "../tournamentLinesDouble";
import BracketColumn from "../bracketColumn";
import MatchPair from "../matchPair";
import { Round, SubBracketMatch } from "../../types";
import { useLocale } from "../../../../../../providers/LocaleProvider";

const DoubleBracketCarousel: React.FC<{ bracket: Round[]; classes: any }> = ({ bracket, classes }) => {
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [lastBracketLength, setBracketLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const {
        lobby: {
            tournaments: {
                ubTitle,
                cbTitle,
                crTitle,
                rTitle,
            },
        },
    } = useLocale();

    const mainBracket = bracket.map((round) => ({
        ...round,
        matches: round.matches.filter((match) => !match.sub),
    }));

    const subBracketMatches = bracket
        .flatMap((round) =>
            round.matches.filter(
                (match): match is SubBracketMatch =>
                    match.sub === true && "step" in match
            )
        );

    const subBracketByStep = subBracketMatches.reduce<Record<number, SubBracketMatch[]>>((acc, match) => {
        if (!acc[match.step]) {
            acc[match.step] = [];
        }
        acc[match.step].push(match);
        return acc;
    }, {});

    const subBracket = mainBracket
        .slice(1)
        .map((mainRound, index) => {
            const step = index + 1;
            const matches = subBracketByStep[step] || [];
            return {
                title: mainRound.title,
                matches,
                step,
            };
        })
        .filter((round) => round.matches.length > 0);


    const scrollLeft = () => {
        if (carouselRef.current) {
            const columnWidth = carouselRef.current.scrollWidth / filteredMainBracket.length;
            carouselRef.current.scrollBy({ left: -columnWidth, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            const columnWidth = carouselRef.current.scrollWidth / filteredMainBracket.length;
            carouselRef.current.scrollBy({ left: columnWidth, behavior: "smooth" });
        }
    };


    // Также обновим updateArrowVisibility
    const updateArrowVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            const maxScrollLeft = scrollWidth - clientWidth;

            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(Math.ceil(scrollLeft) < maxScrollLeft - 1);

            const currentPage = Math.round(scrollLeft / clientWidth);
            setCurrentPage(currentPage);
        }
    };

    useEffect(() => {
        updateArrowVisibility();
        const currentRef = carouselRef.current;

        if (currentRef) {
            currentRef.addEventListener("scroll", updateArrowVisibility);
        }

        let currentPage = bracket.length - 1;
        while (
            bracket[currentPage] &&
            bracket[currentPage].matches &&
            bracket[currentPage].matches.length > 0 &&
            bracket[currentPage].matches[0].player1?.isExpected
        ) {            currentPage--;
        }

        if (lastBracketLength !== currentPage && currentRef) {
            const { scrollLeft: scrollLeftRef, scrollWidth, clientWidth } = currentRef;
            const pageWidth = (scrollWidth - clientWidth) / (bracket.length - 1);
            const realPage = Math.round(scrollLeftRef / pageWidth);

            if (currentPage !== realPage) {
                currentRef?.scrollTo({ left: currentPage * pageWidth, behavior: "smooth" });
            }
            setBracketLength(currentPage);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener("scroll", updateArrowVisibility);
            }
        };
    }, [bracket]);

    const upperBracketRef = useRef<HTMLDivElement | null>(null);
    const lowerBracketRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleScroll = (e: Event) => {
            const target = e.target as HTMLDivElement;
            if (target === upperBracketRef.current) {
                lowerBracketRef.current?.scrollTo({
                    top: target.scrollTop,
                    behavior: "auto"
                });
            } else if (target === lowerBracketRef.current) {
                upperBracketRef.current?.scrollTo({
                    top: target.scrollTop,
                    behavior: "auto"
                });
            }
        };

        upperBracketRef.current?.addEventListener('scroll', handleScroll);
        lowerBracketRef.current?.addEventListener('scroll', handleScroll);

        return () => {
            upperBracketRef.current?.removeEventListener('scroll', handleScroll);
            lowerBracketRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const filteredMainBracket = mainBracket.map(round => ({
        ...round,
        isEmpty: round.isHidden
    }));


    return (
        <div className={classes.carouselContainerDouble}>
            {canScrollLeft && (
                <button className={classes.carouselButtonLeft} onClick={scrollLeft}>
                    <img src={rightArrowIcon} alt="Scroll Left" />
                </button>
            )}

            <div className={classes.bracketCarouselDouble} ref={carouselRef}>
                <div className={classes.bracketWrapperDouble}>
                    {filteredMainBracket.map((mainRound, roundIndex) => (
                        <div key={mainRound.title} className={classes.roundColumnDouble}>
                            <div className={classes.upperBracketSectionDouble}>
                                {(!mainRound.isHidden || roundIndex === 0) && (
                                    <div className={classes.titleBracketDouble}>
                                        {ubTitle('Upper bracket')}
                                    </div>
                                )}
                                <BracketColumn
                                    key={mainRound.title}
                                    title={mainRound.matches.length > 0 ? `${rTitle('Round')} ${roundIndex}` : ""}
                                    columnIndex={roundIndex}
                                    totalRounds={filteredMainBracket.length}
                                    matchesInRound={mainRound.matches.length}
                                    hideHeader={mainRound.matches.length === 0}
                                    isUpperBracket={true}
                                    isDoubleBracket={true}
                                    previousRoundMatches={roundIndex > 0 ? filteredMainBracket[roundIndex - 1].matches.length : 0}
                                >
                                    {mainRound.matches.map((match, matchIndex) => (
                                        <div key={matchIndex} className={classes.singleMatch}>
                                            <MatchPair
                                                {...match}
                                                firstWins={match.firstWins ?? 0}
                                                secondWins={match.secondWins ?? 0}
                                                columnIndex={roundIndex}
                                                matchIndex={matchIndex}
                                                totalColumns={filteredMainBracket.length}
                                                isUpperBracket={true}
                                                isSubBracket={false}
                                            />
                                        </div>
                                    ))}
                                </BracketColumn>
                            </div>

                            <div className={classes.lowerBracketSectionDouble}>
                                {roundIndex >= 1 && (
                                    <>
                                        {subBracket[roundIndex - 1]?.matches?.length > 0 && (
                                            <>
                                                <div className={classes.subBracketSeparatorDouble} />
                                                <div className={classes.titleBracketDouble}>
                                                    {cbTitle('Cellar bracket')}
                                                </div>
                                            </>
                                        )}
                                        <BracketColumn
                                            key={mainRound.title}
                                            title={`${crTitle('C-Round')} ${roundIndex}`}
                                            columnIndex={roundIndex - 1}
                                            totalRounds={subBracket.length}
                                            matchesInRound={subBracket[roundIndex - 1]?.matches?.length || 0}
                                            hideHeader={!subBracket[roundIndex - 1]?.matches?.length}
                                            isSubBracket={true}
                                            isDoubleBracket={true}
                                            previousRoundMatches={roundIndex > 1 ? subBracket[roundIndex - 2]?.matches?.length : 0}
                                        >
                                            {subBracket[roundIndex - 1]?.matches?.map((match, matchIndex) => (
                                                <div key={matchIndex} className={classes.singleMatch}>
                                                    <MatchPair
                                                        {...match}
                                                        firstWins={match.firstWins ?? 0}
                                                        secondWins={match.secondWins ?? 0}
                                                        columnIndex={roundIndex - 1}
                                                        matchIndex={matchIndex}
                                                        totalColumns={filteredMainBracket.length - 1}
                                                        isUpperBracket={false}
                                                        isSubBracket={true}
                                                    />
                                                </div>
                                            ))}
                                        </BracketColumn>
                                    </>
                                )}
                            </div>                           
                        </div>
                    ))}
                </div>
            </div>

            {canScrollRight && (
                <button className={classes.carouselButtonRight} onClick={scrollRight}>
                    <img style={{ transform: "rotate(180deg)" }} src={rightArrowIcon} alt="Scroll Right" />
                </button>
            )}
        </div>
    );
};

export default observer(DoubleBracketCarousel);