import { EColor } from "src/components/types";
import { makeStyles } from "@mui/styles";
import theme from "src/theme";
import { MIN_XL_TABLET } from "src/pages/game-new/constants";
import { appColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    text: (props: any) => ({
        opacity: props.active && !props.gameEnd ? 1 : 0.5,
        transition: "0.2s",
        textShadow: "0px 2px 14px rgb(0 0 0 / 10%)",
        // color:
        //     props.active && props.appearanceTheme === EColor.darkBlue
        //         ? props.warning
        //             ? "#F2432E"
        //             : "#36A79A"
        //         : props.active && props.appearanceTheme === EColor.darkGreen
        //         ? props.warning
        //             ? "#F2432E"
        //             : "rgba(38, 215, 195, 0.5)"
        //         : "rgb(196, 196, 196)",

        fontStyle: "normal",

        color:
            props.active && !props.gameEnd
                ? props.warning
                    ? "#F2432E"
                    : appColors.juiceGreen
                : "rgb(196, 196, 196)",
        fontFamily: "Montserrat",
        fontWeight: 700,
        "&.deepBlack": {
            color:
                props.active && !props.gameEnd
                    ? props.warning
                        ? "#F2432E"
                        : appColors.juiceGreen
                    : "rgb(196, 196, 196)",
            fontFamily: "Montserrat",
            fontWeight: 700,
        },
        "&.isMultiTable": {
            fontSize: 20,
            [theme.breakpoints.down(1500)]: {
                fontSize: 17,
            },
            "@media screen and (max-height: 900px)": {
                fontSize: 15,
            },
        },
        "&.isTwoGameTable": {
            fontSize: 22,
        },
    }),
    "&.redesign-clock-text": {
        [theme.breakpoints.down("md")]: {
            justifyContent: "flex-start",
        },
    },
    "&.isDesktopSize": {
        textAlign: "center",
    },

    slicedTimerBox: {
        display: "grid",
        width: "100%",
        justifyContent: "center",
        [theme.breakpoints.up("xs")]: {
            "@media (orientation: portrait)": {
                gridTemplateColumns: "auto auto 1fr",
            },
        },
        [theme.breakpoints.up(MIN_XL_TABLET)]: {
            "@media (orientation: portrait)": {
                gridTemplateColumns: "auto auto 1fr",
            },
            "@media (orientation: landscape)": {
                gridTemplateColumns: "auto auto 1fr",
            },
        },
    },

    slicedTimerPart: {
        [theme.breakpoints.up("lg")]: {
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "20px",
            "&.isViewMode": {
                fontWeight: 600,
            },
        },
        [theme.breakpoints.up("xl")]: {
            fontWeight: 600,
            fontSize: "35px",
            lineHeight: "28px",
        },
        [theme.breakpoints.up(1600)]: {
            fontSize: "34px",
            lineHeight: "34px",
        },
        [theme.breakpoints.up(1800)]: {
            fontSize: "44px",
            lineHeight: "44px",
            "&.isViewMode": {
                fontSize: "32px",
                lineHeight: "32px",
            },
        },
        "&.minutes": {
            justifySelf: "end",
            "@container (max-width: 450px)": {
                fontSize: "30px",
                lineHeight: "32px",
            },
            "@container (max-width: 400px)": {
                fontSize: "26px",
                lineHeight: "30px",
            },
        },
        "&.colon": {
            justifySelf: "center",
            "@container (max-width: 400px)": {
                fontSize: "30px",
                lineHeight: "32px",
            },
            "@container (max-width: 450px)": {
                fontSize: "26px",
                lineHeight: "30px",
            },
        },
        "&.seconds": {
            justifySelf: "start",
            "@container (max-width: 450px)": {
                fontSize: "30px",
                lineHeight: "32px",
            },
            "@container (max-width: 400px)": {
                fontSize: "26px",
                lineHeight: "30px",
            },
        },
    },
});
