import { useCallback, useMemo, memo, useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useLocale } from "src/providers/LocaleProvider";
import { Button } from "src/shared/ui/Button";
import paths from "src/consts/paths";
import useWindowSize from "src/hooks/useWindowSize";
import FadeInOutVertical from "src/components/Animated";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new2/constants";
import useStores from "src/hooks/useStores";
import useStyles from "./style";

export interface IToBracketFromGame {
    randomValue: number;
    winner: boolean | null;
}

const ToBracketFromGameAlert = observer(({ randomValue, winner }: IToBracketFromGame) => {
    const { gameStore } = useStores();
    const { width } = useWindowSize();
    const history = useHistory();
    const styles = useStyles();

    const MAX_XL_TABLET_SIZE = window.innerWidth <= MAX_CHANGE_SCREEN_WIDTH && window.innerWidth >= MAX_L_TABLET;
    const isMobileSize = width < MAX_XL_TABLET;

    const {
        lobby: {
            tournaments: { bracketTitle },
        },
        tournaments: {
            alerts,
            roundEndAlerts: { lossAlerts, winAlerts },
        },
    } = useLocale();

    // Get current game data
    const currentGame = gameStore?.currentGameNew;
    const matchId = currentGame?.matchId;
    const matchId2 = currentGame?.matchId2;
    const matchCount = currentGame?.matchCount;


    const isRoundComplete = useMemo(() => {
        if (!matchId || !matchId2 || !matchCount) return false;

        return matchCount === matchId - 1 || matchCount === matchId2 - 1;
    }, [matchId, matchId2, matchCount]);

    const toBracket = useCallback(() => {
        const tournamentId = gameStore?.currentGameNew?.tournamentId;
        history.push(`${paths.tournaments}/${tournamentId}`);
    }, [gameStore?.currentGameNew?.tournamentId]);

    const lossTitle = lossAlerts[1]("You lost");
    const winTitle = "You won";

    const calcTitle = useMemo(() => {
        if (winner === null) return;
        return winner ? winTitle : lossTitle;
    }, [winner, randomValue]);

    //if (winner === null || !isRoundComplete) return null;

    useEffect(() => {
        return () => {
            console.log('ToBracketFromGameAlert unmount with winner:', winner);
        };
    }, [winner, randomValue, isRoundComplete]);

    return (
        isRoundComplete && winner !== null ? (
            <FadeInOutVertical stylesProp={{ alignItems: "center" }}>
                <div className={cn(styles.alertBox, "toBracketAlert")}>
                    <Button
                        text={bracketTitle("Bracket")}
                        color="blue"
                        variant="contained"
                        onClick={toBracket}
                        className={cn(styles.buttonRequest, "toBracketAlert")}
                    />
                    <p className={cn(styles.title, "toBracketAlert", { win: winner, los: !winner })}>
                        {calcTitle}
                    </p>
                </div>
            </FadeInOutVertical>
        ) : null
    );
});

export default ToBracketFromGameAlert;

