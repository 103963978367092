import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    leagueContainer: {
        width: '100%',
        height: '100%',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        borderRadius: 12,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden', 
    },
    imageContainer: {
        width: "100%",
        height: "150px", 
        textAlign: "center",
        flexShrink: 0,
        paddingBottom: 5,
    },
    tournamentImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover", 
    },

    leftPanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "8px",
        width: "100%",
        flex: 1, 
        overflow: 'hidden', 
    },

    scrollableList: {
        width: "100%",
        height: "360px",
        overflowY: "auto",
        scrollbarWidth: "none", 
        "-ms-overflow-style": "none", 
        "&::-webkit-scrollbar": {
            display: "none", 
        },
        '@media (max-height: 640px)': {
            height: "250px",
        },
        '@media (max-height: 568px)': {
            height: "200px",
        },
        '@media (min-height: 768px) and (max-height: 1024px)': {
            height: "400px", 
        },
    },

    playerItem: {
        height: 60,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
        width: "100%",
        gap: 20,
        paddingLeft: 15,
        "&:hover": {
            background: "rgba(0, 186, 108, 0.1)",
        },
        "&:nth-child(even)": {
            backgroundColor: "rgba(245, 245, 245, 0.05)", 
        },
        "&:nth-child(odd)": {
            backgroundColor: "transparent", 
        },
    },

    rank: {
        fontWeight: 600,
        fontSize: 26,
        color: "rgba(255, 255, 255, 1)",
        fontFamily: 'Montserrat',
    },

    playerInfo: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: 'start',
    },

    playerName: {
        display: "block",
        fontWeight: "bold",
        color: "#fff",
        fontSize: "16px",
    },

    playerRating: {
        fontSize: "12px",
        color: "#aaa",
    },

    scoreContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

    },
    scoreCirclesRow: {
        display: "flex",
        gap: 4,
        marginBottom: "3px",
    },
    scoreNumbersRow: {
        display: "flex",
        gap: 7,
    },
    scoreCircle: {
        width: 15,
        height: 15,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 186, 108, 1)",
        color: "rgba(18, 18, 18, 1)",
        fontWeight: 700,
        fontSize: 12,
    },
    emptyCircle: {
        width: 15,
        height: 15,
    },
    scoreNumber: {
        textAlign: "center",
        fontWeight: 400,
        fontSize: 20,
    },
    whiteNumber: {
        color: "rgba(245, 245, 245, 1)",
    },
    redNumber: {
        color: "rgba(255, 28, 41, 1)",
    },
    scoreWin: {
        backgroundColor: "#00c853",
    },
    scoreLoss: {
        backgroundColor: "#00c853",
    },
    scoreDraw: {
        backgroundColor: "#00c853",
    },
    balanceBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "60px",
        height: "100%",
        minWidth: "60px",
    },

    balance: {
        color: "rgba(245, 245, 245, 1)",
        fontWeight: 600,
        fontSize: 20,
        textAlign: "center",
        width: "100%",
        borderLeft: '1px solid rgba(245, 245, 245, 0.3)',
    },
});