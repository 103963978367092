import React, {
    ReactNode,
    useEffect,
    useMemo,
    useState,
    useLayoutEffect,
    useCallback,
    memo,
    useRef,
} from 'react';
import { Box, useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import calcNameLength from 'src/utils/calcNameLength';
import paths from 'src/consts/paths';
import defaultPlaceholder from 'src/assets/images/defaultPlaceholder.png';
import useWindowSize from 'src/hooks/useWindowSize';
import useChangeTheme from 'src/hooks/useChangeTheme';
import { useWinOrLostStatus } from 'src/pages/game-new2/hooks/useWinOrLostStatus';
import { useRematchDialogs } from 'src/pages/game-new2/hooks/useRematchDialogs';
import useInitRematchTimer from 'src/hooks/useInitRematchTimer';
import lastMoveIsUnAvailable from 'src/utils/lastMoveIsUnAvailable';
import FenParser from 'src/store/gameStore/utils/parser';
import FenHelper from 'src/store/gameStore/utils/helper';
import useStores from 'src/hooks/useStores';
import useDoublingStageStatus from 'src/pages/game-new/hooks/useDoublingStageStatus';
import shortColor from 'src/utils/shortColor';
import { ArrayHelper } from 'src/shared/helpers/ArrayHelper';
import getOppositeColor from 'src/utils/oppositeColor';
import NickPlayerComponent from './components/NickComponent';
import GameChips from 'src/pages/game-new2/components/x2ButtonAndRollDice/x2ChipsButton';
import { CountdownCircular } from 'src/components/countDownCircular';
import { MAX_XL_TABLET } from 'src/pages/game-new2/constants';
import { miniPiecesColors, miniPiecesStroke } from 'src/consts/app-config';
import X2AndDiceRedesign from 'src/pages/game-new2/components/x2ButtonAndRollDice';
import X2ButtonAndRollDice from 'src/pages/game-new2/components/x2ButtonAndRollDice';
import {
    ColorVariant,
    IPlayer,
    UserBalanceType,
    PlayerInfoExistPosition,
    EGameRematchStatus,
    GameModeTypeNew,
} from 'src/store/models';

import UserAvatar from '../UserAvatar';

import { endpointsRoot } from '../../core/endpoints';

import { RedesignClock } from './components/RedesignClock';
import { useStyles } from './styles';
import miniPieces from './images/mini-pieces';
import chips from 'src/assets/icons/Chips.svg';
import BeatPiecesCounter from './components/BeatPicesCounter';
import {RedesignClockv2} from "./components/RedesignClockv2";
import { AvatarStarsBorder } from '../controls/app-bar/components/animateStar/JackpotStarAnimation';
import ReEntryModal from './components/modal';

interface IKilledPiece {
    name: string;
    component: any;
    killed: boolean;
}
const allPieces = {
    N: 2,
    B: 2,
    R: 2,
    Q: 1,
    P: 8,
};

interface IPlayerInfo {
    name: string;

    avatar: string;
    noTimerSound?: boolean;
    active: boolean;
    color: ColorVariant;
    elementPlace: keyof typeof PlayerInfoExistPosition;
    initTime: number;
    piecesBeatCounter?: number;
    isSystemAvatar?: boolean | undefined;
    activeClock?: {
        color: ColorVariant;
    };
    styles?: any;
    killedPieces: {};
    reverse?: boolean;
    className?: string;
    userCashBox?: string | number | any;
    id?: string;
    gameId?: string;
    winner?: string;
    resign?: string;
    showX2Bet?: boolean;
    balanceType?: UserBalanceType;
    isTournamentGame?: boolean | undefined;
    isXlTablet?: boolean | undefined;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    opponent?: IPlayer;
    isOpponent?: boolean;
    me?: boolean;
    stars?: number;
    opponentStars?: number;
    userChipsBox?: number;
}

const PlayerInfoRedesign = observer((props: IPlayerInfo) => {
    const classes = useStyles();

    const {
        name,
        avatar,
        noTimerSound = false,
        isSystemAvatar,
        isTournamentGame,
        isMultiTable,
        killedPieces,
        isOpponent,
        opponent,
        elementPlace,
        piecesBeatCounter,
        me,
        active,
        activeClock,
        initTime,
        showX2Bet,
        isTwoGameTable,
        color,
        reverse,
        className,
        userCashBox,
        winner,
        resign,
        id,
        gameId,
    } = props;

    const { gameStore, achievementsStatStore, authStore } = useStores();
    const { showAcceptOrRejectDialog } = useDoublingStageStatus();
    const { iAmInitAcceptOrRejectDialog, initForMeAcceptOrRejectDialog } =
        useRematchDialogs();
    const { isWon } = useWinOrLostStatus();
    const location = useLocation();
    const appearanceTheme = useChangeTheme();
    const { width: widthFromHook, height: heightFromHook } = useWindowSize();
    const isNotRoll =
        !!gameStore.gameState.legalMoves.length &&
        !!gameStore.gameState.legalPieces.length;
    const myMove = gameStore.isMyMove;
    const curMove = gameStore.currentGameNew?.curMove;
    const curChessMove = gameStore.currentGameNew?.curChessMove;
    const currentPlayerCanX2 = gameStore.currentGameNew?.currentPlayerCanX2;
    const gameEnd = !!gameStore.currentGameNew?.winner;
    const rematchEnd = gameStore.dialogs.rematchData.rematchEnd;
    const isViewMode = gameStore.isViewMode();
    const matchId = gameStore.currentGameNew?.matchId;
    const matchId2 = gameStore.currentGameNew?.matchId2;
    const isRollAwaiting = gameStore.rollAwaiting;
    const gameType = gameStore.currentGameNew?.type;

    const extra = gameStore?.currentGameNew?.extra;
    const waitingBetApply = extra
        ? extra['waitingBetApply'] &&
          gameStore?.currentGameNew?.curMove !== authStore.currentUser?._id
        : false;

    const isMobileTabletResign =
        widthFromHook < MAX_XL_TABLET ||
        elementPlace === PlayerInfoExistPosition.inLobbyPreview ||
        elementPlace === PlayerInfoExistPosition.inBracketPreview
            ? true
            : false;


    const isSmallScreen = useMediaQuery("(max-width: 480px)");
    const isMobile = useMediaQuery("(min-width: 481px) and (max-width: 768px)");
    const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");
    const isLaptop = useMediaQuery("(min-width: 1025px) and (max-width: 1440px)");
    const isMultiTableScreen = useMediaQuery("(max-width: 1700px)");
    const isSmallHeight = useMediaQuery("(max-height: 900px)");
    const isLargeScreen = useMediaQuery("(min-width: 1980px)"); 

    const calculateAvatarSize = () => {
        let size = 80;

        if (elementPlace === PlayerInfoExistPosition.inLobbyPreview ||
            elementPlace === PlayerInfoExistPosition.inBracketPreview) {
            if (window.innerWidth > 2000) {
                return 100;
            } else if (window.innerWidth > 1700) {
                return 70;
            } else {
                return 60;
            }
        }


        if (isMultiTable) {
            size = 52;

            if (isMultiTableScreen) {
                size = 48;
            }

            if (isSmallHeight) {
                size = 45;
            }
        } else if (isTwoGameTable) {
            size = 75;
        }

        if (isLargeScreen) {
            size = 123;
        } else if (isSmallScreen) {
            size = 60;
        } else if (isMobile) {
            size = 60;
        } else if (isTablet) {
            size = 70;
        } else if (isLaptop) {
            size = 81;
        }

        return size;
    };

    const avatarSize = calculateAvatarSize();

    const myColor = gameStore?.gameState?.getMyColor?.();
    const isLastMoveAreUnAvailable = useMemo(() => {
        return lastMoveIsUnAvailable(
            !gameStore?.gameState?.legalPieces?.length,
            gameStore?.gameState?.history ?? [],
            gameStore?.gameState?.getMyColor?.() ?? ColorVariant.white
        );
    }, [
        gameStore?.gameState?.legalPieces?.length,
        gameStore?.gameState?.history,
    ]);
    const moveFinished = !gameStore?.gameState?.legalPieces?.length;
    const history = gameStore?.gameState?.history;
    const leftColor =
        gameStore?.gameState?.getMyColor?.() === ColorVariant.white
            ? ColorVariant.black
            : ColorVariant.white;

    const parse = new FenParser(FenHelper);
    const lastRolledPlayer = parse.getWhoLastRollsColor(
        gameStore?.gameState?.history
    );
    const [pawns, setPawns] = useState<IKilledPiece[]>([]);
    const [pieces, setPieces] = useState<IKilledPiece[]>([]);
    const [killedPiecesLocal, setKilledPieces] = useState<any>();
    const [isChipsOpen, setIsChipsOpen] = useState(false);
    const [isTopUpModalOpen, setTopUpModalOpen] = useState(false);

    const moves = useMemo(() => {
        return parse.diceMessagesFromHistory(
            history as any,
            leftColor,
            [],
            moveFinished
        );
    }, [history?.length, leftColor, moveFinished]);

    useEffect(() => {
        // Logical For Show Hide VersusBox Preview
        if (moves?.[0] || gameStore?.currentGame?.history?.[0]) {
            return gameStore?.initGameBegin?.(true);
        }
        return gameStore?.initGameBegin?.(false);
    }, [moves, gameStore?.currentGame?.history]);

    useEffect(() => {
        //Turned off  for desktop
        if (!isMobileTabletResign) return;
        if (!killedPiecesLocal && killedPieces) {
            setKilledPieces(killedPieces);
        }
        const collectedPawns: IKilledPiece[] = [];
        const collectedPieces: IKilledPiece[] = [];

        Object.keys(allPieces).forEach((key) => {
            const total =
                allPieces[key] > killedPieces[key]
                    ? allPieces[key]
                    : killedPieces[key];
            const c = shortColor(getOppositeColor(color));
            const piece = c + key;
            let killed = killedPieces[key];

            for (let i = 0; i < total; i++) {
                if (key !== 'P') {
                    if (i <= allPieces[key] - 1) {
                        collectedPieces.push({
                            name: piece + i,
                            component: miniPieces[piece],
                            killed: killed > 0,
                        });
                        killed--;
                    } else {
                        collectedPawns.push({
                            name: `${c}P${collectedPawns.length}`,
                            component: miniPieces[`${c}P`],
                            killed: true,
                        });
                    }
                } else {
                    collectedPawns.push({
                        name: piece + i,
                        component: miniPieces[piece],
                        killed: killed > 0,
                    });
                    killed--;
                }
            }
        });

        setPieces(collectedPieces);
        setPawns(collectedPawns);
    }, [killedPieces, location.pathname]);

    const lastMyMoveIsUnAvailable =
        lastRolledPlayer === myColor && isLastMoveAreUnAvailable;

    const currentOpponent = opponent?.id
        ? achievementsStatStore.getMyCurrentOpponent(opponent?.id)
        : null;

    const actualTimer = useInitRematchTimer(rematchEnd, 15000);
    const playerAvatarRef = useRef(null);

    const showDiceButton =
        myMove &&
        !isNotRoll &&
        !waitingBetApply &&
        !showX2Bet &&
        !showAcceptOrRejectDialog &&
        !isWon &&
        curMove === curChessMove &&
        me;

    useLayoutEffect(() => {
        (curMove && curChessMove && curMove !== curChessMove) || winner
            ? gameStore.resetRollAwaiting()
            : null;
    }, [curMove, curChessMove, winner]);

    const [opponentState, setOpponentState] = useState<IPlayer | null>(null);

    useEffect(() => {
        if (opponent) {
            setOpponentState(opponent);
        }
    }, [opponent]);

    const UserAvatarMemo = useMemo(() => {
        const playerStars = me ? props.stars : (authStore.currentUser?.stars ?? 0);
        const opponentStars = opponent?.stars ?? opponentState?.stars ?? props.stars ?? 0;

        // console.log("=== DEBUG: Player & Opponent Stars ===");
        // console.log("Current User Stars (authStore):", playerStars);
        // console.log("Opponent Stars (before render):", opponentStars);

        return me &&
            isMobileTabletResign &&
            (iAmInitAcceptOrRejectDialog || initForMeAcceptOrRejectDialog) ? (
            <>
                {actualTimer && (
                    <CountdownCircular
                        seconds={actualTimer}
                        size={50}
                        reset={0}
                        pause={false}
                        onEnd={() => {
                            history.push(paths.diceChessLobby);
                            gameStore.rematchDecline(
                                gameStore?.currentGameNew?._id
                            );
                        }}
                        className={cn(
                            classes.circularRequest,
                            'waitForOpponentToDecideAlert'
                        )}
                        modalType={EGameRematchStatus.WAITING_FOR_ANSWER}
                    />
                )}
            </>
        ) : (
            <>
                {me ? (
                    <div className="player-avatar" data-avatar="player" ref={playerAvatarRef}
                        style={{ position: 'relative', width: avatarSize, height: avatarSize }}>
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <UserAvatar
                                winner={winner}
                                resign={resign}
                                color={color}
                                nickname={name}
                                avatarUrl={
                                    (avatar && `${endpointsRoot}/avatars/${avatar}`) ||
                                    defaultPlaceholder
                                }
                                className={cn(classes.userAvatar, {
                                    isMultiTable,
                                    isTwoGameTable,
                                    isMobileTabletResign,
                                })}
                                isSystemAvatar={isSystemAvatar}
                                isChessBoard
                                opponent={opponent}
                            />
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none',
                                zIndex: 2
                            }}>
                                <AvatarStarsBorder size={avatarSize} starsCount={playerStars} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="opponent-avatar" data-avatar="opponent"
                        style={{ position: 'relative', width: avatarSize, height: avatarSize }}>
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <UserAvatar
                                winner={winner}
                                resign={resign}
                                color={color}
                                nickname={name}
                                avatarUrl={
                                    (avatar && `${endpointsRoot}/avatars/${avatar}`) ||
                                    defaultPlaceholder
                                }
                                className={cn(classes.userAvatar, {
                                    isMultiTable,
                                    isTwoGameTable,
                                    isMobileTabletResign,
                                })}
                                isSystemAvatar={isSystemAvatar}
                                isChessBoard
                                opponent={opponent}
                            />
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none',
                                zIndex: 2
                            }}>
                                <AvatarStarsBorder size={avatarSize} starsCount={opponentStars} />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }, [
        me,
        winner,
        currentOpponent,
        iAmInitAcceptOrRejectDialog,
        initForMeAcceptOrRejectDialog,
        isMobileTabletResign,
        actualTimer,
        matchId,
        matchId2,
        avatarSize,
        props.stars, 
        props.opponentStars, 
        opponent?.stars
    ]);


    if (!isMobileTabletResign) {
        return (
            <>
                <Box
                    component='div'
                    className={cn(
                        classes.playerInfoRedesign,
                        className,
                        elementPlace,
                        appearanceTheme,
                        {
                            isMultiTable,
                            isTwoGameTable,
                            // active,
                        }
                    )}
                >
                    <div className={classes.avatarAndButtonWrapperRedesign}>
                        <span
                            className={cn(
                                classes.userAvatarSpan,
                                elementPlace,
                                appearanceTheme
                            )}
                            id={`${color}-${gameStore?.currentGameNew?._id}`}
                            onClick={() => {
                                if (opponent) {
                                    achievementsStatStore.getPlayerSettingsModal(
                                        opponent
                                    );
                                }
                            }}
                        >
                            {UserAvatarMemo}
                        </span>
                    </div>
                    <Box
                        className={cn(
                            classes.leftPlayerWrapperRedesign,
                            appearanceTheme,
                            {}
                        )}
                    >
                        <div
                            className={cn(
                                classes.clockWrapperRedesign,
                                'clock-wrapper',
                                appearanceTheme,
                                {
                                    isOpponent: opponent,
                                }
                            )}
                        >
                            <Box
                                component='div'
                                className={cn(
                                    classes.nikAndclockWrapperBoxRedesign,
                                    elementPlace,
                                    appearanceTheme,
                                    {
                                        isMultiTable,
                                        isTwoGameTable,
                                    }
                                )}
                            >
                                <Box
                                    component='div'
                                    className={cn(
                                        classes.redesignClockWrapperRedesign,
                                        appearanceTheme,
                                        elementPlace,
                                        {
                                            isMultiTable,
                                        }
                                    )}
                                >
                                    <div
                                        className={cn(
                                            classes.redesignClockBoxRedesign,
                                            appearanceTheme,
                                            {
                                                isMultiTable,
                                            }
                                        )}
                                    >
                                        <RedesignClockv2
                                            warningLimit={30}
                                            active={active}
                                            id={id}
                                            initTime={initTime}
                                            noTimerSound={noTimerSound}
                                            activeClock={activeClock}
                                            color={color}
                                            isMultiTable={isMultiTable}
                                            isTwoGameTable={isTwoGameTable}
                                            gameEnd={gameEnd}
                                            gameId={gameId}
                                        />
                                    </div>
                                </Box>
                                <NickPlayerComponent
                                    name={name}
                                    appearanceTheme={appearanceTheme}
                                    isMultiTable={isMultiTable}
                                    isTwoGameTable={isTwoGameTable}
                                    opponent={opponent}
                                />
                            </Box>
                        </div>
                    </Box>

                    <BeatPiecesCounter
                        piecesBeatCounter={piecesBeatCounter}
                        isViewMode={isViewMode}
                        isLeague={gameType === GameModeTypeNew.TOURNAMENT_LEAGUE}
                    />


                    {gameType === GameModeTypeNew.TOURNAMENT_LEAGUE && (
                        <div className={classes.coinsWrapper}>
                            {me ? (
                                <>
                                    {Number(props.userChipsBox) === 0 && (
                                        <button className={classes.coinsButton} onClick={() => setTopUpModalOpen(true)}>
                                            Top up
                                        </button>
                                    )}
                                    <div className={classes.coinsInfo}>
                                        <img src={chips} alt="chips" className={classes.chipsIcon} />
                                        <span
                                            className={cn(classes.coinValue, {
                                                [classes.coinValueEmpty]: Number(props.userChipsBox) === 0,
                                            })}
                                        >
                                            {props.userChipsBox ?? 0}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div className={classes.coinsInfo}>
                                    <img src={chips} alt="chips" className={classes.chipsIcon} />
                                    <span className={classes.coinValue}>{props.userChipsBox ?? 0}</span>
                                </div>
                            )}
                        </div>
                    )}
                </Box>

                <ReEntryModal
                    open={isTopUpModalOpen}
                    onClose={() => setTopUpModalOpen(false)}
                    onRebuy={() => {
                        console.log('User clicked Re-buy');
                        setTopUpModalOpen(false);
                    }}
                />
            </>
        );

    }

    return (
        <Box
            component='div'
            className={cn(
                classes.playerInfoRedesign,
                className,
                elementPlace,
                appearanceTheme,
                {
                    isMultiTable,
                    isTwoGameTable,
                }
            )}
        >
            <Box
                component='div'
                className={cn(
                    classes.leftPlayerInfoBox,
                    className,
                    elementPlace,
                    appearanceTheme,
                    {
                        isMultiTable,
                        isTwoGameTable,
                    }
                )}
            >
                <BeatPiecesCounter
                    piecesBeatCounter={piecesBeatCounter}
                    isViewMode={isViewMode}
                />

                {elementPlace === PlayerInfoExistPosition.inGame &&
                    me &&
                    myMove &&
                    currentPlayerCanX2 &&
                    !isWon &&
                    !isTournamentGame && <GameChips onChipTogglerChange={setIsChipsOpen} />}
            </Box>

            <div
                className={cn(
                    classes.avatarAndButtonWrapperRedesign,
                    elementPlace,
                    appearanceTheme
                )}
                id={`${color}-${gameStore?.currentGameNew?._id}`}
            >
                {/* {toRoll &&
                !lastMyMoveIsUnAvailable &&
                !showX2Bet &&
                !showAcceptOrRejectDialog &&
                (isTournamentGame || userCashBox) ? ( */}
                {myMove &&
                !isNotRoll &&
                !waitingBetApply &&
                !showX2Bet &&
                !showAcceptOrRejectDialog &&
                !isWon &&
                curMove === curChessMove &&
                me &&
                // gameType !== GameModeTypeNew.MATCH &&
                gameType !== GameModeTypeNew.TOURNAMENT &&
                gameType !== GameModeTypeNew.MATCH &&
                !isRollAwaiting ? (
                    <span
                        className={cn(
                            classes.userAvatarSpan,
                            'isMobileTabletResign',
                            'isDiceButton',
                            elementPlace,
                            appearanceTheme
                        )}
                    >
                        <X2AndDiceRedesign
                            isPlayerInfo={true}
                            diceButtonForTabletandMobile
                        />
                    </span>
                ) : (
                    <span
                        id={`${color}-${gameStore?.currentGameNew?._id}`}
                        className={cn(
                            classes.userAvatarSpan,
                            'isMobileTabletResign',
                            elementPlace,
                            appearanceTheme
                        )}
                        onClick={() => {
                            if (opponent) {
                                achievementsStatStore.getPlayerSettingsModal(
                                    opponent
                                );
                            }
                        }}
                    >
                        {UserAvatarMemo}
                    </span>
                )}
            </div>

            <Box
                className={cn(
                    classes.leftPlayerWrapperRedesign,
                    appearanceTheme,
                    elementPlace,
                    {}
                )}
            >
                <div
                    className={cn(
                        classes.clockWrapperRedesign,
                        'clock-wrapper',
                        elementPlace,
                        {
                            isOpponent: opponent,
                        }
                    )}
                >
                    <Box
                        component='div'
                        className={cn(
                            classes.nikAndclockWrapperBoxRedesign,
                            appearanceTheme,
                            elementPlace,
                            {
                                isMultiTable,
                                isTwoGameTable,
                            }
                        )}
                    >
                        <Box
                            component='div'
                            className={cn(
                                classes.redesignClockWrapperRedesign,
                                appearanceTheme,
                                elementPlace,
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <div
                                className={cn(
                                    classes.redesignClockBoxRedesign,
                                    appearanceTheme,
                                    elementPlace,
                                    {
                                        isMultiTable,
                                    }
                                )}
                            >
                                <RedesignClockv2
                                    warningLimit={30}
                                    active={active}
                                    id={id}
                                    initTime={initTime}
                                    noTimerSound={noTimerSound}
                                    activeClock={activeClock}
                                    color={color}
                                    isMultiTable={isMultiTable}
                                    isTwoGameTable={isTwoGameTable}
                                    gameEnd={gameEnd}
                                    isViewMode={isViewMode}
                                    gameId={gameId}
                                />
                            </div>
                        </Box>
                        <NickPlayerComponent
                            name={name}
                            appearanceTheme={appearanceTheme}
                            isMultiTable={isMultiTable}
                            isTwoGameTable={isTwoGameTable}
                            opponent={opponent}
                        />
                    </Box>
                </div>

                
                {/* {elementPlace === PlayerInfoExistPosition.inGame &&
                    me &&
                    !!userCashBox &&
                    curMove === curChessMove &&
                    !isTournamentGame && (
                        <X2AndDiceRedesign
                            isTournamentGame={isTournamentGame}
                            isOpponent={!!opponent?.id}
                            x2ForTabletandMobile={true}
                        />
                    )} */}
            </Box>
        </Box>
    );
});

export default PlayerInfoRedesign;
