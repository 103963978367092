import { useEffect, useRef } from "react";
import { Round, TournamentLinesProps } from "../types";
import { observer } from "mobx-react";

// Добавляем функцию debounce для оптимизации отрисовки
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

const TournamentLines: React.FC<TournamentLinesProps> = ({
    bracket,
    isSubBracket,
    startFromSecondRound = false
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isDrawing = useRef(false);

    const drawLines = () => {
        // Предотвращаем параллельные вызовы отрисовки
        if (isDrawing.current) return;
        isDrawing.current = true;

        const canvas = canvasRef.current;
        if (!canvas) {
            isDrawing.current = false;
            return;
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) {
            isDrawing.current = false;
            return;
        }

        const container = canvas.parentElement;
        if (!container) {
            isDrawing.current = false;
            return;
        }

        // Проверяем, виден ли контейнер
        const containerRect = container.getBoundingClientRect();
        const isVisible =
            containerRect.top < window.innerHeight &&
            containerRect.bottom > 0 &&
            containerRect.left < window.innerWidth &&
            containerRect.right > 0;

        if (!isVisible) {
            isDrawing.current = false;
            return;
        }

        const pixelRatio = window.devicePixelRatio || 1;

        // Настраиваем размеры канваса на основе полного размера контента
        canvas.width = container.scrollWidth * pixelRatio;
        canvas.height = container.scrollHeight * pixelRatio;

        ctx.scale(pixelRatio, pixelRatio);

        // Устанавливаем стиль для канваса
        canvas.style.width = `${container.scrollWidth}px`;
        canvas.style.height = `${container.scrollHeight}px`;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = "#2F3136";
        ctx.lineWidth = 1;

        const matchCoords: { [key: string]: { x: number; y: number; width: number; height: number } } = {};

        // Собираем координаты матчей
        bracket.forEach((round, roundIndex) => {
            const matches = container.querySelectorAll(`[data-column="${roundIndex}"][data-match]`);
            matches.forEach((match) => {
                const matchIndex = parseInt(match.getAttribute("data-match") || "0");
                const rect = match.getBoundingClientRect();

                matchCoords[`${roundIndex}-${matchIndex}`] = {
                    x: rect.left - containerRect.left + container.scrollLeft,
                    y: rect.top - containerRect.top + container.scrollTop,
                    width: rect.width,
                    height: rect.height,
                };
            });
        });

        const drawLineWithRoundedCorners = (
            startX: number,
            startY: number,
            endX: number,
            endY: number,
            radius: number = 4
        ) => {
            ctx.beginPath();

            if (startY === endY) {
                ctx.moveTo(startX, startY);
                ctx.lineTo(endX, endY);
            } else {
                const cornerX = startX;
                const cornerY = endY;

                ctx.moveTo(startX, startY);
                if (Math.abs(startY - cornerY) > radius) {
                    ctx.lineTo(cornerX, startY + (startY < cornerY ? -radius : radius));
                    ctx.arcTo(cornerX, cornerY, cornerX + (startX < endX ? radius : -radius), cornerY, radius);
                }
                ctx.lineTo(endX, endY);
            }

            ctx.stroke();
        };

        // Начинаем с нужного раунда
        const startRound = startFromSecondRound ? 1 : 0;

        for (let roundIndex = startRound; roundIndex < bracket.length - 1; roundIndex++) {
            const round = bracket[roundIndex];
            const lineOffset = 40;

            for (let matchIndex = 0; matchIndex < round.matches.length; matchIndex += 2) {
                const match1 = matchCoords[`${roundIndex}-${matchIndex}`];
                const match2 = matchCoords[`${roundIndex}-${matchIndex + 1}`];
                const nextMatch = matchCoords[`${roundIndex + 1}-${Math.floor(matchIndex / 2)}`];

                if (!match1 || !nextMatch) continue;

                const verticalLineX = match1.x + match1.width + lineOffset;
                const match1Y = match1.y + match1.height / 2;
                const nextMatchY = nextMatch.y + nextMatch.height / 2;

                // Линия от первого матча
                ctx.beginPath();
                ctx.moveTo(match1.x + match1.width, match1Y);
                ctx.lineTo(verticalLineX, match1Y);
                ctx.stroke();

                if (match2) {
                    const match2Y = match2.y + match2.height / 2;

                    // Линия от второго матча
                    ctx.beginPath();
                    ctx.moveTo(match2.x + match2.width, match2Y);
                    ctx.lineTo(verticalLineX, match2Y);
                    ctx.stroke();

                    // Вертикальная соединяющая линия
                    ctx.beginPath();
                    ctx.moveTo(verticalLineX, match1Y);
                    ctx.lineTo(verticalLineX, match2Y);
                    ctx.stroke();

                    // Линия к следующему матчу
                    const midY = (match1Y + match2Y) / 2;
                    drawLineWithRoundedCorners(verticalLineX, midY, nextMatch.x, nextMatchY);
                } else {
                    // Прямая линия к следующему матчу если нет второго матча
                    drawLineWithRoundedCorners(verticalLineX, match1Y, nextMatch.x, nextMatchY);
                }
            }
        }

        isDrawing.current = false;
    };

    // Используем debounce для отрисовки при скролле
    const drawLinesDebounced = debounce(drawLines, 50);

    // Отложенная отрисовка с гарантированным таймером
    const drawLinesDelayed = () => {
        setTimeout(drawLines, 10);
        // Дополнительно запускаем через 100ms для большей надежности
        setTimeout(drawLines, 100);
        // И еще через 500ms, если были какие-то долгие анимации/перестройки DOM
        setTimeout(drawLines, 500);
    };

    useEffect(() => {
        // Первоначальная отрисовка с несколькими попытками
        drawLinesDelayed();

        const container = canvasRef.current?.parentElement;
        if (container) {
            // Обработчик скролла
            container.addEventListener("scroll", drawLinesDebounced);

            // Обработчик изменения размеров
            const resizeObserver = new ResizeObserver(() => {
                drawLinesDelayed();
            });
            resizeObserver.observe(container);

            // Обработчик изменения размера окна
            window.addEventListener("resize", drawLinesDelayed);

            // Настройка таймеров для гарантированной отрисовки
            const initialTimer = setTimeout(drawLinesDelayed, 100);
            const secondTimer = setTimeout(drawLinesDelayed, 500);
            const finalTimer = setTimeout(drawLinesDelayed, 1000);

            // Очистка обработчиков при размонтировании
            return () => {
                clearTimeout(initialTimer);
                clearTimeout(secondTimer);
                clearTimeout(finalTimer);

                container.removeEventListener("scroll", drawLinesDebounced);
                resizeObserver.disconnect();
                window.removeEventListener("resize", drawLinesDelayed);
            };
        }
    }, [bracket, startFromSecondRound]);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
                pointerEvents: 'none',
                transition: 'opacity 0.2s ease', 
            }}
        />
    );
};

export default observer(TournamentLines);