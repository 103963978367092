import { makeStyles } from "@mui/styles";
import theme from "../../../../../../../theme";

export const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        width: "100%",
        height: "70vh",
        overflow: "hidden",
        gap: 40,
        padding: "0 10px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: 10,
        },
    },
    leftPanel: {
        flex: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        borderRadius: 12,
    },
    scrollableList: {
        flexGrow: 1,
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbarWidth': 'none',
        width: '100%',
        position: "relative", 
    },
    playerItem: {
        height: 60,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        width: "100%",
        gap: 20,
        paddingLeft: 15,
        "&:hover": {
            background: "rgba(0, 186, 108, 0.1)",
        },
        transform: 'translateY(0)',
        transition: 'transform 0ms',
        willChange: 'transform', 
        zIndex: 1, 
    },
    rank: {
        width: 25,
        fontWeight: 600,
        fontSize: 26,
        color: "rgba(255, 255, 255, 1)",
        fontFamily: 'Montserrat',
        [theme.breakpoints.down("sm")]: {
           width: 20,
        },
    },
    playerInfo: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
    playerName: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 15,
        color: 'rgba(245, 245, 245, 0.9)',
        maxWidth: 100,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("sm")]: {
            maxWidth: 200,
        },
    },
    playerRating: {
        fontSize: "12px",
        color: "#aaa",
    },
    scoreRow: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },

    scoreContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    scoreCirclesRow: {
        display: "flex",
        gap: 4,
        marginBottom: "3px",
    },

    scoreNumbersRow: {
        display: "flex",
        gap: 7,
    },

    scoreCircle: {
        width: 15,
        height: 15,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 186, 108, 1)",
        color: "rgba(18, 18, 18, 1)",
        fontWeight: 700,
        fontSize: 12,
    },

    emptyCircle: {
        width: 15,
        height: 15,
    },

    scoreNumber: {
        textAlign: "center",
        fontWeight: 400,
        fontSize: 18,
    },

    whiteNumber: {
        color: "rgba(245, 245, 245, 1)",
    },

    redNumber: {
        color: "rgba(255, 28, 41, 1)",
    },
    balanceBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "60px",
        height: "100%",
        minWidth: "60px",
    },

    balance: {
        color: "rgba(245, 245, 245, 1)",
        fontWeight: 600,
        fontSize: 20,
        textAlign: "center",
        width: "100%",
        borderLeft: '1px solid rgba(245, 245, 245, 0.3)',
    },
    balanceValue: {
        fontWeight: 700,
        fontSize: 15,
        fontFamily: 'Montserrat',
        color: 'rgba(245, 245, 245, 0.8)',
    },
    centerPanel: {
        flex: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        borderRadius: 12,
    },
    matchListWrapper: {
        flexGrow: 1,
        overflowY: "auto",
        width: "100%",
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbarWidth': 'none',
        position: "relative",
    },
    matchList: {
        width: "100%",
    },
    matchItemEven: {
        background: "rgba(245, 245, 245, 0.05)",
    },
    matchItemOdd: {
        background: "transparent",
    },
    matchItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            borderBottom: 'none',
            position: "relative",
            padding: 0,
            "&::after": { 
                content: '""',
                width: '100%',
                position: "absolute",
                height: 1, 
                background: "rgba(255, 255, 255, 0.2)", 
                zIndex: 1, 
            },
        },
    },
    matchItemSelected: {
        background: "rgba(0, 186, 108, 0.1) !important",
        borderBottom: '1px solid rgba(0, 186, 108, 1) !important',
        [theme.breakpoints.down("sm")]: {
            borderBottom: 'none !important',
        },
    },
    player: {
        display: "flex",
        alignItems: "center",
    },
    playerAvatar: {
        borderRadius: "50%",
        marginRight: "10px",
        width: "40px",
        height: "40px",
    },

    avatarContainer: {
        position: 'relative',
        width: 40,
        height: 40,
    },

    starsOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
    },

    playerBalanceSmall: {
        fontSize: "12px",
        color: "rgba(245, 245, 245, 0.8)",
        fontWeight: 400,
        marginTop: "2px",
    },
    cameraIconContainer: {
        width: 35,
        height: 35,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "10px",
        zIndex: 2,
        transition: "background-color 0.3s, border 0.3s",
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 10,
        },
    },

    playContainer: {
        backgroundColor: "rgb(35, 57, 42)", 
        border: "1px solid #4CAF50",
    },

    viewContainer: {
        backgroundColor: "rgba(131, 181, 255, 0.1)",
        border: "1px solid rgba(131, 181, 255, 1)",

        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'rgb(41, 46, 52)',
        },
    },

    cameraIcon: {
        width: 16,
        height: 16,
    },

    nicknameBox: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 15,
        color: 'rgba(245, 245, 245, 0.9)',
        maxWidth: 100, 
        whiteSpace: "nowrap", 
        overflow: "hidden", 
        textOverflow: "ellipsis", 
        [theme.breakpoints.down("sm")]: {
            maxWidth: 200, 
        },
    },
    vs: {
        fontWeight: 700,
        fontSize: 15,
        color: "rgba(245, 245, 245, 0.8)",
    },
    rightPanel: {
        flex: 1,
        display: "flex",
    },
    playerWithBalance: {
        width: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
            gap: 5,
            width: 200,
        },
    },

    //Mobile styles
    tabButtons: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5,
        gap: 5,
        border: '1px solid rgba(255, 255, 255, 0.3)',
        borderRadius: 12,
    },
    tabButton: {
        flex: 1,
        height: 40,
        background: 'transparent',
        border: 'none',
        borderRadius: 8,
    },
    activeTab: {
        background: 'rgba(0, 186, 108, 0.3)',
    },
    iconTab: {
        width: 22,
    },
    players: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '90%',
        padding: '10px 0 10px 10px',
    },
    layerWithBalance: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },

    balanceText: {
        fontSize: "14px",
        color: "rgba(200, 200, 200, 0.8)",
    },
    searchingOpponent: {
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: 14,
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
            color: "rgba(255, 255, 255, 0.6)",
            fontSize: 14,
            marginLeft: 0,
            display: "flex", 
            alignItems: "center", 
            height: 40, 
            width: "100%", 
        },
    },

    pauseBox: {
        padding: "10px",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "14px",
        fontWeight: 500,
        textAlign: "center",
    },

    centerPanelHidden: {
        opacity: 0,
        pointerEvents: "none",
        transition: "opacity 0.3s ease",
    },

}));