import cn from "classnames";
import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MatchPairProps } from '../types';
import { useStyles } from "../styles";
import tbdIcon from 'src/assets/icons/UserCircle.svg';
import { observer } from "mobx-react";
import useStores from "../../../../../hooks/useStores";
import videoCameraIcon from "src/assets/icons/VideoCamera.svg";
import playIcon from "src/assets/icons/Play.svg";
import { Avatar } from "../../../../../shared/ui/Avatar";
import endpoints, {boardRoot} from "../../../../../core/endpoints";
import defaultAvatar from "src/assets/images/defaultPlaceholder.png";
import { MAX_XL_TABLET } from "src/pages/game-new2/constants";
import paths from "src/consts/paths";
import LiveIcon from "src/assets/icons/LIVE.svg";
import { UserStarsBorder } from "../../../../../components/controls/app-bar/components/animateStar/UserStarsBorder";
import {goTo} from "../../../../../helpers";

const MatchPair: React.FC<MatchPairProps> = ({
    player1,
    player2,
    gameId,
    columnIndex,
    matchIndex,
    totalColumns,
    firstWins,
    secondWins,
    isFinished,
    isSubBracket = false,
    isUpperBracket = false,
    isThirdPlace = false
}) => {

    const isFinalColumn = columnIndex === totalColumns - 1;
    const classes = useStyles({
        matchIndex,
        marginTop: 0,
        columnIndex,
        isFinalColumn,
    });

    const { authStore, viewHistoryGameStore } = useStores();
    const history = useHistory();
    const currentUserId = authStore.currentUser?._id;

    const isTBDPlayer = (player: any): boolean =>
        player && player._id.startsWith("TBD_");

    const isByePlayer = (player: any): boolean =>
        player && player._id.startsWith("BYE_");

    const isCurrentUser = (playerId: string): boolean => {
        return playerId === currentUserId;
    };

    const isCurrentUserMatch =
        (player1?._id === currentUserId || player2?._id === currentUserId) &&
        !isTBDPlayer(player1) &&
        !isTBDPlayer(player2);

    const getAvatarURL = (avatarPath: string | null): string => {
        return avatarPath
            ? `${endpoints.avatarPath}/${avatarPath}`
            : defaultAvatar;
    };

    const isFinalMatch = (isUpperBracket || (!isUpperBracket && !isSubBracket)) &&
        columnIndex === totalColumns - 1 &&
        matchIndex === 0;

    const renderPlayer = (
        player: { name: string; avatar?: string; _id: string, stars?: number } | null,
        position: "top" | "bottom"
    ) => {
        const isCurrentUserPlayer = player && isCurrentUser(player._id);

        if (isTBDPlayer(player) || isByePlayer(player)) {
            return (
                <div
                    className={cn(
                        classes.player,
                        position === "top" ? classes.playerTop : classes.playerBottom,
                        { [classes.currentUser]: isCurrentUserPlayer }
                    )}
                >
                    <div className={classes.tbdInfo}>
                        <img src={tbdIcon} className={classes.avatar} alt="TBD" />
                        <span>{player?.name || "TBD"}</span>
                    </div>
                </div>
            );
        }

        const playerData = player as {
            name: string;
            avatar?: string;
            _id: string;
            stars?: number;
        };

        const playerWins = position === "top" ? firstWins : secondWins;
        const opponentWins = position === "top" ? secondWins : firstWins;
        const isWinner = (isFinished && playerWins > opponentWins) ||
            (position === "top" && isByePlayer(player2)) ||
            (position === "bottom" && isByePlayer(player1));

        const starsCount = playerData.stars || 0; 

        return (
            <div
                className={cn(
                    classes.player,
                    position === "top" ? classes.playerTop : classes.playerBottom,
                    {
                        [classes.winner]: isWinner,
                        [classes.currentUser]: isCurrentUserPlayer
                    }
                )}
                data-player={position === "top" ? "1" : "2"}
            >
                <div className={cn(
                    classes.playerInfo,
                    { [classes.currentUserInfo]: isCurrentUserPlayer }
                )}>
                    <div className={classes.avatarContainer}>
                        <div className={classes.avatarWrapper}>
                            <Avatar
                                src={getAvatarURL(playerData.avatar || null)}
                                wrapperClassName={cn(classes.avatar, { [classes.currentUserAvatar]: isCurrentUserPlayer })}
                            />
                        </div>
                    </div>
                    <span
                        className={cn({
                            [classes.winnerName]: isWinner,
                            [classes.loserName]: !isWinner,
                            [classes.currentUserName]: isCurrentUserPlayer
                        })}
                    >
                        {playerData.name}
                    </span>
                </div>
                <span
                    className={cn(classes.score, {
                        [classes.winnerScore]: isWinner,
                        [classes.loserScore]: !isWinner,
                        [classes.currentUserScore]: isCurrentUserPlayer
                    })}
                >
                    {playerWins}
                </span>
            </div>
        );
    };

    const handleMatchAction = () => {
        if (isCurrentUserMatch) {
            if (gameId) {

                goTo(boardRoot + `/game/${gameId}?userKey=${localStorage.getItem("t")}&currentUserId=${authStore.currentUser?._id}`); return;
            } else {
                console.error("Game ID is missing for your match.");
            }
        } else if (gameId) {
            if (window.innerWidth < MAX_XL_TABLET) {

                goTo(boardRoot + `/game/${gameId}?userKey=${localStorage.getItem("t")}&currentUserId=${authStore.currentUser?._id}`); return;
            } else {
                viewHistoryGameStore.reset();
                let it = setTimeout(() => {
                    viewHistoryGameStore.setGameIdNewView(gameId);
                    clearTimeout(it);
                }, 200);
            }
        }
    };

    const shouldDisplayActionButtons = !!gameId && !isFinished;

    return (
        <div
            className={cn(classes.matchPair)}
            data-column={columnIndex}
            data-match={matchIndex}
            data-third-place={isThirdPlace}
        >
            {isFinalMatch && (
                <div className={classes.matchLabel}>
                    1st Place Decider
                </div>
            )}

            {isThirdPlace && (
                <div className={classes.matchLabel}>
                    3rd Place Decider
                </div>
            )}

            <div className={classes.matchPairContent}>
                {renderPlayer(player1, "top")}
                {renderPlayer(player2, "bottom")}

                {shouldDisplayActionButtons && (
                    <button
                        className={cn(classes.matchButton, {
                            [classes.playButton]: isCurrentUserMatch,
                            [classes.viewButton]: !isCurrentUserMatch,
                        })}
                        onClick={handleMatchAction}
                    >
                        {isCurrentUserMatch ? (
                            <img src={playIcon} alt="Play match" className={classes.viewIcon} />
                        ) : (
                            <img
                                src={videoCameraIcon}
                                alt="View match"
                                className={classes.viewIcon}
                            />
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export default observer(MatchPair);