import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    modal: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        },
        '& .MuiModal-backdrop': {
            outline: 'none'
        }
    },
    modalContent: {
        position: 'relative',
        width: 350,
        height: 'auto',
        padding: "20px",
        margin: "70px 0",
        left: '50%',
        transform: 'translateX(calc(-50% - 60px))',
        textAlign: "center",
        backgroundColor: "rgba(245, 245, 245, 0.1)",
        backdropFilter: "blur(32px)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        color: '#fff',
        borderRadius: "8px",
        outline: 'none',
        border: '1px solid transparent', 
        backgroundClip: 'padding-box', 
        '&::before': {
            content: '""',
            position: 'absolute',
            inset: -1, 
            padding: '1px', 
            background: 'linear-gradient(180deg, rgba(245, 245, 245, 0.3) 0%, rgba(143, 143, 143, 0.05) 100%)',
            borderRadius: '8px',
            zIndex: -1,
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', 
            maskComposite: 'exclude',
            '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            '-webkit-mask-composite': 'destination-out',
        },
        [theme.breakpoints.down('lg')]: {
            transform: 'translateX(-50%)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translateX(-50%)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translateX(-50%)',
            margin: "50px 0",
        },
    },
    jackpotBox: {
        width: '100%',
        height: 'auto',
        marginBottom: '2vw',
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        padding: '4px',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '& img': {
            width: 15,
            height: 15,
            objectFit: 'contain',
            filter: 'brightness(0) invert(1)',
            opacity: 0.8,
            transition: 'opacity 0.2s ease',
            '&:hover': {
                opacity: 1
            }
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    block: {
        display: 'flex',
        textAlign: 'start',
    },
    jackpotBoxTitle: {
        display: 'flex',
        fontSize: 16,
        fontWeight: 700,
    },
    blockDate: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    date: {
        marginLeft: 5,
        fontWeight: 700,
    },
    description: {
        display: 'flex',
        fontSize: 16,
        fontWeight: 400,
    },
    jackpotTitle: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '2.5vw',
        textAlign: 'center',
        background: 'linear-gradient(to top, #FF9604, #FFE522, #FFFFC1, #FFBB1B, #E28800, #E9A600)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        filter: 'drop-shadow(0.2vw 0.2vw 0.2vw rgba(0, 0, 0, 0.6))',
    },
});