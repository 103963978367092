import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100vh',
        position: 'relative',
        backgroundColor: '#050505',
        color: '#F5F5F5',
        overflow: 'hidden',
        [theme.breakpoints.down("sm")]: {
            display: 'flex', 
            flexDirection: 'column',
        },
    },
    imageContainer: {
        position: 'fixed',
        height: '100vh',
        width: '100%',
        top: '10%', 
        left: 0,
        overflow: 'hidden',
    },

    backgroundImage: {
        backgroundSize: 'cover',
        backgroundPosition: 'center center', 
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
    },
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `
        linear-gradient(90deg, rgba(5, 5, 5, 1) 0%, rgba(5, 5, 5, 0.2) 25%, rgba(5, 5, 5, 0.2) 75%, rgba(5, 5, 5, 1) 100%),
        linear-gradient(180deg, 
            rgba(5, 5, 5, 0) 0%, 
            rgba(5, 5, 5, 0.1) 15%,
            rgba(5, 5, 5, 0.4) 30%,
            rgba(5, 5, 5, 0.7) 45%,
            rgba(5, 5, 5, 0.85) 60%,
            rgba(5, 5, 5, 0.95) 75%,
            #050505 85%
        ),
        linear-gradient(180deg,
            rgba(5, 5, 5, 0.5) 0%, 
            rgba(5, 5, 5, 0.55) 20%,
            rgba(5, 5, 5, 0.65) 50%,
            rgba(5, 5, 5, 0.7) 70%,
            #050505 90%
        ),
        linear-gradient(-115deg, rgba(5, 5, 5, 0.8) 0%, rgba(5, 5, 5, 0) 25%)
    `,
        zIndex: 1
    },
    content: {
        position: 'relative',
        height: '100vh',
        maxWidth: 1200,
        margin: '0 auto',
        padding: '0',
        overflowY: 'auto',
        overflowX: 'hidden', 
        zIndex: 2,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        [theme.breakpoints.down("lg")]: {
            height: '88vh',
            padding: '0 20px 40px',
        },
        [theme.breakpoints.down("md")]: {
            height: '88vh',
            padding: '0 15px 40px',
        },
        [theme.breakpoints.down("sm")]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            height: '100vh', 
            padding: '0 10px 40px', 
        },
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        margin: '2vw 0',
        color: '#F5F5F5',
        fontFamily: 'Montserrat',
    },
    date: {
        fontSize: 14,
        color: 'rgb(156, 156, 156)',
        marginBottom: 16,
        fontFamily: 'Montserrat',
    },
    text: {
        fontSize: '16px !important',
        lineHeight: '1.6 !important',
        color: '#F5F5F5 !important',
        fontFamily: 'Montserrat !important',
        marginBottom: '32px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important',
            marginBottom: '32px !important', 
        },
        '& blockquote': {
            margin: '16px 0 !important',
            padding: '10px 20px !important',
            borderLeft: '4px solid rgba(255, 255, 255, 0.3) !important',
            backgroundColor: '#1A1A1A !important',
            color: '#F5F5F5  !important',
            fontStyle: 'Montserrat !important',
        },
        '& p': {
            margin: '12px 0 !important',
        },
        '& a': {
            color: '#F5F5F5  !important',
            textDecoration: 'none !important',
            '&:hover': {
                color: 'rgba(255, 255, 255, 0.3) !important',
            },
        },
    },

    backButton: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
        color: '#F5F5F5',
        padding: '12px 0',
        transition: 'opacity 0.2s ease',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10vw',
        },
    },
    arrowIcon: {
        width: 24,
        height: 24,
        rotate: '90deg',
        '&:hover': {
            opacity: 0.6
        }
    },
    backText: {
        fontSize: 16,
        fontWeight: 500,
        color: '#F5F5F5',
        fontFamily: 'Montserrat',
    }
}));