import React, {
    useState,
    useMemo,
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
} from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import TabContext from "@mui/lab/TabContext";

import StickerContainer from "src/pages/game-new2/components/game-chat-history/stickerProvider";
import useStores from "src/hooks/useStores";
import {
    GameMessageType,
    ISendMessageInGame,
} from "src/service/api/gameChat/requestResponses";
import { useLocale } from "src/providers/LocaleProvider";
import { useFriends } from "src/pages/profile/hooks/useFriends";
import {
    EGameChatTab,
    IStickerPackList,
    GameChatToggleStatus,
    GameModeType,
    GameModeTypeNew,
} from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";
import FenHelper from "src/store/gameStore/utils/helper";
import FenParser from "src/store/gameStore/utils/parser";
import {
    IGameMessage,
    ICommunicationHistoryNotification,
} from "src/store/gameStore/types";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";

import ChatTabs from "./chatTabs";
import BadgeMobile from "./badgeContainers/badgeMobile";
import BadgeDesktop from "./badgeContainers/badgeDesktop";
import GameChatPanel from "./tabPanels/chatPanel";
import HistoryChatPanel from "./tabPanels/historyPanel";
import WatchersChatPanel from "./tabPanels/watchersPanel";
import FastGamePanel from "./tabPanels/fastGamePanel";

import { useStyles } from "./styles";
import { GameChatHistoryTextArea } from "./text-area";
import { IGameChatHistory, StickerStatusType } from "./types/IChat";
import { MAX_XL_TABLET } from "src/pages/game-new/constants";
import LeagueChatPanel from "./tabPanels/leaguePanel/leagueChatPanel";

const GameChatHistoryNew = observer(
    ({
        chat,
        history,
        notifications,
        onMessage,
        readonly,
        maxMessageSize,
        onChangeTab,
        currentChatTab,
    }: IGameChatHistory) => {
        const appearanceTheme = useChangeTheme();
        const {
            gameStore,
            authStore,
            achievementsStatStore,
            tournamentsStore,
            lobbyStore,
        } = useStores();
        const [lastUnreadMessage, setLastUnreadMessage] = useState<string | null>(null);

        // const { handleAddFriendNew, handleRemoveFriendNew } = useFriends();
        const isTabletAndMobile = window.innerWidth <= MAX_XL_TABLET;

        const getTextParser = {
            isLabel: "label",
            isNumber: "number",
            isObject: "object",
            isString: "string",
            isEmptyMessage: "",
        };

        const {
            onChatToggler,
            chatMobileType,
            chatDesktopType,
            chatTogglerState,
            onChatTabsToggler,
            currentTab: currentTabProvider,
        } = useLeftLayoutContext();
        const [message, setMessage] = useState("");
        const [showHideOpponent, setOpponent] = useState<boolean>(false);
        const [stickerTogglerState, runStickerToggler] = useState<
            boolean | string
        >(false);
        const [insertStickerFunction, setInsertStickerFunction] = useState<
            ((stickerUrl: string) => void) | null
        >(null);

        const currentTab = currentTabProvider
            ? currentTabProvider
            : authStore.currentUser?.settings?.dicechessBoardRightBlockMode ||
              EGameChatTab.chat;

        const me = authStore.currentUser?._id;

        const tournamentId = gameStore.currentGameNew?.tournamentId;
        const isLeagueTournament = useMemo(() => {
            if (!tournamentId) return false;
            const tournament = tournamentsStore.tournaments.find(t => t._id === tournamentId);
            return tournament?.type === 3;
        }, [tournamentId, tournamentsStore.tournaments]);

        const opponentInformation = gameStore.currentGame?.players.filter(
            (player) => player?.id !== me
        )[0];

        const opponentInformationData =
            typeof opponentInformation?.id === "string"
                ? opponentInformation.id
                : undefined;
        const currentOpponent = achievementsStatStore.getMeSettingsCollections(
            opponentInformationData
        );
        const { isChatBlocked, isGamePlayBlocked } = currentOpponent;

        const viewMode = gameStore.isViewMode();

        const showTextDataArea =
            currentTab === EGameChatTab.chat && !readonly && !isChatBlocked;
        const allAwaitingGames = lobbyStore.allAwaitingGames(me);
        const shouldHide = tournamentsStore.isHideChat;
        const chessboardSize = gameStore?.chessboardSize;
        const chatMessagesCount = gameStore?.newChatMessage?.length;
        const gameSeries =
            tournamentsStore?.currentTournamentGame?.tournament?.gameSeries;

        const opponentInformationNew =
            gameStore?.currentGameNew?.players.filter(
                (player) => player?._id !== me
            )[0];

        const FIRST_GAME_IN_MATCH =
            (tournamentsStore.currentTournamentGame?.players?.[0]?.score ?? 0) +
                (tournamentsStore.currentTournamentGame?.players?.[1]?.score ??
                    0) ===
            0;
        const stickers = authStore.stickers;
        const isLeftChatBoxHeight =
            chatDesktopType &&
            typeof chessboardSize === "number" &&
            chessboardSize > 380;
        const parse = new FenParser(FenHelper);

        // const gameID = gameStore?.currentGameNew?._id;
        const gameID = gameStore?.getGameID;

        // const moveMessages: ICommunicationHistoryNotification[] = [
        //     history,
        //     notifications,
        // ]
        //     .filter((pseudo) => Array.isArray(pseudo))
        //     .reduce(
        //         (all, current) =>
        //             parse.mergeChatHistoryMessagesNew(all, current),
        //         []
        //     );
        //====
        const moveMessages = history;

        const classes = useStyles({
            shouldHide,
            showHideOpponent,
            appearanceTheme,
            chessboardSize,
            stickers,
        });

        useEffect(() => {
            const tabToSet = isLeagueTournament ? EGameChatTab.league : EGameChatTab.chat;

            if (currentTab !== tabToSet) {
                onChangeTab(tabToSet);
                if (onChatTabsToggler) {
                    onChatTabsToggler(tabToSet);
                }
            }
        }, [isLeagueTournament]);

        const handleTabChange = (
            _: React.SyntheticEvent,
            newValue: keyof typeof EGameChatTab
        ) => {
            gameStore.setNewChatMessage(null);
            onChangeTab(newValue);
            onChatTabsToggler && onChatTabsToggler(newValue);
            runStickerToggler(false);
        };

        //const onChangeInput = useCallback(
        //    (data: string = getTextParser.isEmptyMessage) => {
        //        const message = `${data}`.slice(0, maxMessageSize);
        //        message
        //            ? setMessage(message)
        //            : setMessage(getTextParser.isEmptyMessage);
        //    },
        //    []
        //);

        // const onEnterMessage = useCallback(
        //     (message: string = getTextParser.isEmptyMessage) => {
        //         if (message.trim()) {
        //             setMessage(getTextParser.isEmptyMessage);
        //             onMessage(message);
        //         }
        //     },
        //     []
        // );
        // =====
        const onEnterMessage = useCallback(
            (messageData: ISendMessageInGame) => {
                const messageText = messageData.message;

                if (messageText.trim()) {
                    setMessage(getTextParser.isEmptyMessage);
                    onMessage(messageData);
                }
            },
            []
        );

        const onChangeInput = useCallback(
            (data: string = getTextParser.isEmptyMessage) => {
                const message = `${data}`.slice(0, maxMessageSize);
                setMessage(message);
            },
            [maxMessageSize]
        );

        const onStickerToggler = useCallback(() => {
            switch (stickerTogglerState) {
                case false:
                    runStickerToggler(StickerStatusType.open);
                    break;
                case StickerStatusType.open:
                    runStickerToggler(StickerStatusType.hide);
                    break;
                case StickerStatusType.hide:
                    runStickerToggler(StickerStatusType.open);
                    break;
                default:
                    runStickerToggler(false);
            }
        }, [stickerTogglerState, runStickerToggler]);

        const onSendSticker = useCallback(
            (gifUrl: string, stickerId: string) => {
                if (gameID) {
                    onEnterMessage({
                        gameId: gameID,
                        message: `[img sticker="${stickerId}"]`,
                        type: GameMessageType.one,
                        stickerId: stickerId,
                    });
                }
            },
            [gameID, onEnterMessage]
        );


        useEffect(() => {
            if (FIRST_GAME_IN_MATCH) {
                tournamentsStore.regularGameChatToggler(false);
            }
            if (
                gameStore?.getGameType === GameModeTypeNew.REGULAR ||
                !tournamentsStore.isHideChat ||
                FIRST_GAME_IN_MATCH
            )
                return;
            tournamentsStore.hideTournamnetMatchChat();
        }, [gameSeries]);

        //Logical for hide/show eye component
        useEffect(() => {
            if (
                chatMessagesCount > 0 &&
                (!shouldHide ||
                    chatTogglerState === GameChatToggleStatus.open) &&
                currentTab === EGameChatTab.chat
            ) {
                gameStore.setNewChatMessage(null);
            }
        }, [shouldHide, chatTogglerState]);

        useLayoutEffect(() => {
            if (isTabletAndMobile && currentTab === EGameChatTab.fastGame) {
                gameStore.setNewChatMessage(null);
                onChangeTab(EGameChatTab.chat);
                onChatTabsToggler && onChatTabsToggler(EGameChatTab.chat);
                runStickerToggler(false);
            }
        }, [isTabletAndMobile, currentTab]);

        if (!gameStore?.getGameID) return;

        const handleInputClick = useCallback(() => {
            setLastUnreadMessage(null);
            gameStore.setNewChatMessage(null);
        }, [gameStore]);

        //temporary return this for test
        return (
            <div
                className={cn(
                    classes.chatBoxMain,
                    appearanceTheme,
                    chatMobileType,
                    {
                        shouldHide: shouldHide,
                    }
                )}
            >
                {/*<BadgeMobile*/}
                {/*    currentTab={currentTab}*/}
                {/*    showHideOpponent={showHideOpponent}*/}
                {/*    isChatBlocked={isChatBlocked}*/}
                {/*/>*/}
                {/*{chatDesktopType && (*/}
                {/*    <BadgeDesktop*/}
                {/*        currentTab={currentTab}*/}
                {/*        showHideOpponent={showHideOpponent}*/}
                {/*        isChatBlocked={isChatBlocked}*/}
                {/*    />*/}
                {/*)}*/}
                <TabContext value={currentTab}>
                    <ChatTabs
                        currentTab={currentTab}
                        handleTabChange={handleTabChange}
                        showHideOpponent={showHideOpponent}
                        isChatBlocked={isChatBlocked}
                        isLeagueTournament={isLeagueTournament}
                    />
                    {/* 
                  //Dont Delete! there are logical for fast game. Temporary trned off
                  {!isTabletAndMobile && (
                      <FastGamePanel
                          currentTab={currentTab}
                          showHideOpponent={showHideOpponent}
                      />
                  )} */}

                    <LeagueChatPanel
                        currentTab={currentTab}
                        showHideOpponent={showHideOpponent}
                    />
                    <GameChatPanel
                        currentTab={currentTab && currentTab}
                        showHideOpponent={showHideOpponent}
                        chat={chat}
                        isChatBlocked={isChatBlocked}
                    />
                    <WatchersChatPanel
                        currentTab={currentTab && currentTab}
                        showHideOpponent={showHideOpponent}
                    />
                    <HistoryChatPanel
                        currentTab={currentTab && currentTab}
                        showHideOpponent={showHideOpponent}
                        moveMessages={moveMessages}
                    />
                </TabContext>

                {currentTab === EGameChatTab.chat && stickerTogglerState && (
                    <div
                        className={cn(classes.stickersListWrapper, {
                            open:
                                stickerTogglerState === StickerStatusType.open,
                            hide:
                                stickerTogglerState === StickerStatusType.hide,
                        })}
                    >
                        {stickers.length > 0 ? (
                            stickers.map((stickerPack: IStickerPackList, i) => {
                                console.log(
                                    "Passing onSendSticker to StickerContainer"
                                );
                                return (
                                    <StickerContainer
                                        key={i}
                                        onSendSticker={onSendSticker}
                                    />
                                );
                            })
                        ) : (
                            <StickerContainer
                                onSendSticker={(stickerSrc) => {
                                    if (gameID) {
                                        onEnterMessage({
                                            gameId: gameID,
                                            message: `[img sticker="${stickerSrc}"]`,
                                            type: GameMessageType.one,
                                            stickerId: stickerSrc,
                                        });
                                    }
                                }}
                            />
                        )}
                    </div>
                )}
                {showTextDataArea && gameID && (
                    <div className={classes.textAreaBox}>
                        <GameChatHistoryTextArea
                            gameID={gameID}
                            shouldHide={shouldHide}
                            text={message}
                            onChange={onChangeInput}
                            onEnter={onEnterMessage}
                            onStickerToggler={onStickerToggler}
                            setInsertStickerFunction={setInsertStickerFunction}
                            onInputClick={handleInputClick}
                            setLastUnreadMessage={() => setLastUnreadMessage(null)}
                        />
                    </div>
                )}
                {/* {opponentInformationNew && (
                    <button
                        style={{ background: "white", color: "brown" }}
                        onClick={() => {
                            handleAddFriendNew({
                                playerTo: opponentInformationNew._id,
                            });
                        }}
                    >
                        {`Add ${opponentInformationNew.nickname} to Friend`}
                    </button>
                )} */}
            </div>
        );

        if (viewMode) {
            return (
                <>
                    <div
                        className={cn(
                            classes.chatBoxMain,
                            appearanceTheme,
                            chatMobileType
                        )}
                    >
                        <TabContext value={EGameChatTab.watchers}>
                            <WatchersChatPanel
                                currentTab={EGameChatTab.watchers}
                                showHideOpponent={showHideOpponent}
                            />
                        </TabContext>
                    </div>
                </>
            );
        }

        return (
            <div
                className={cn(
                    classes.chatBoxMain,
                    appearanceTheme,
                    chatMobileType
                )}
            >
                <BadgeMobile
                    currentTab={currentTab}
                    showHideOpponent={showHideOpponent}
                    isChatBlocked={isChatBlocked}
                />
                {chatDesktopType && (
                    <BadgeDesktop
                        currentTab={currentTab}
                        showHideOpponent={showHideOpponent}
                        isChatBlocked={isChatBlocked}
                    />
                )}
                <TabContext value={currentTab}>
                    <ChatTabs
                        currentTab={currentTab}
                        handleTabChange={handleTabChange}
                        showHideOpponent={showHideOpponent}
                        isChatBlocked={isChatBlocked}
                    />
                    <GameChatPanel
                        currentTab={currentTab && currentTab}
                        showHideOpponent={showHideOpponent}
                        chat={chat}
                        isChatBlocked={isChatBlocked}
                    />
                    {/* 
                    //Dont Delete! there are logical for fast game. Temporary trned off
                    {!isTabletAndMobile && (
                        <FastGamePanel
                            currentTab={currentTab}
                            showHideOpponent={showHideOpponent}
                        />
                    )} */}
                    <WatchersChatPanel
                        currentTab={currentTab && currentTab}
                        showHideOpponent={showHideOpponent}
                    />
                    <HistoryChatPanel
                        currentTab={currentTab && currentTab}
                        showHideOpponent={showHideOpponent}
                        moveMessages={moveMessages}
                    />
                </TabContext>

                {currentTab === EGameChatTab.chat && stickerTogglerState && (
                    <div
                        className={cn(classes.stickersListWrapper, {
                            open:
                                stickerTogglerState === StickerStatusType.open,
                            hide:
                                stickerTogglerState === StickerStatusType.hide,
                        })}
                    >
                        {stickers.length > 0 ? (
                            stickers.map((stickerPack: IStickerPackList, i) => {
                                console.log(
                                    "Passing onSendSticker to StickerContainer"
                                );
                                return (
                                    <StickerContainer
                                        onSendSticker={onSendSticker}
                                    />
                                );
                            })
                        ) : (
                            <StickerContainer />
                        )}
                    </div>
                )}
                {showTextDataArea && gameID && (
                    <div className={classes.textAreaBox}>
                        <GameChatHistoryTextArea
                            gameID={gameID || "123"}
                            shouldHide={shouldHide}
                            text={message}
                            onChange={onChangeInput}
                            onEnter={onEnterMessage}
                            onStickerToggler={onStickerToggler}
                            onInputClick={handleInputClick}
                        />
                    </div>
                )}
            </div>
        );
    }
);

export default memo(GameChatHistoryNew);
