import React from "react";

import BOneIcon from "./images/numbers/b-1";
import BTwoIcon from "./images/numbers/b-2";
import BThreeIcon from "./images/numbers/b-3";
import BFourIcon from "./images/numbers/b-4";
import BFiveIcon from "./images/numbers/b-5";
import BSixIcon from "./images/numbers/b-6";
import WOneIcon from "./images/numbers/w-1";
import WTwoIcon from "./images/numbers/w-2";
import WThreeIcon from "./images/numbers/w-3";
import WFourIcon from "./images/numbers/w-4";
import WFiveIcon from "./images/numbers/w-5";
import WSixIcon from "./images/numbers/w-6";
//import {
//    BBIcon, BKIcon, BNIcon, BPIcon, BQIcon, BRIcon,
//    WBIcon, WKIcon, WNIcon, WPIcon, WQIcon, WRIcon
//} from 'src/components/fastChessboard/piece-memos-deepBlack';

// Импорт изображений для черных фигур
import BlackPawn from "src/assets/icons/diceChess/PawnBlack.svg";
import BlackKnight from "src/assets/icons/diceChess/KnightBlack.svg";
import BlackBishop from "src/assets/icons/diceChess/BishopBlack.svg";
import BlackRook from "src/assets/icons/diceChess/RookBlack.svg";
import BlackQueen from "src/assets/icons/diceChess/QueenBlack.svg";
import BlackKing from "src/assets/icons/diceChess/KingBlack.svg";

// Импорт изображений для белых фигур
import WhitePawn from "src/assets/icons/diceChess/PawnWhite.svg";
import WhiteKnight from "src/assets/icons/diceChess/KnightWhite.svg";
import WhiteBishop from "src/assets/icons/diceChess/BishopWhite.png";
import WhiteRook from "src/assets/icons/diceChess/RookWhite.svg";
import WhiteQueen from "src/assets/icons/diceChess/QueenWhite.svg";
import WhiteKing from "src/assets/icons/diceChess/KingWhite.svg";


export const getBlackFiguresIcons = (size) => {
    return [
        <img src={BlackPawn} key={1} width={size} height={size} alt="Black Pawn" />,
        <img src={BlackKnight} key={2} width={size} height={size} alt="Black Knight" />,
        <img src={BlackBishop} key={3} width={size} height={size} alt="Black Bishop" />,
        <img src={BlackRook} key={4} width={size} height={size} alt="Black Rook" />,
        <img src={BlackQueen} key={5} width={size} height={size} alt="Black Queen" />,
        <img src={BlackKing} key={6} width={size} height={size} alt="Black King" />,
    ];
};

export const getWhiteFiguresIcons = (size) => {
    return [
        // Пешка
        <img src={WhitePawn} key={1} width={size} height={size} alt="White Pawn" />,
        // Конь
        <img src={WhiteKnight} key={2} width={size} height={size} alt="White Knight" />,
        // Офицер
        <img src={WhiteBishop} key={3} width={size} height={size} alt="White Bishop" />,
        // Тура
        <img src={WhiteRook} key={4} width={size} height={size} alt="White Rook" />,
        // Королева
        <img src={WhiteQueen} key={5} width={size} height={size} alt="White Queen" />,
        // Король
        <img src={WhiteKing} key={6} width={size} height={size} alt="White King" />,
    ];
};

export const whiteFiguresIconsPaths = [
    WhitePawn,
    WhiteKnight,
    WhiteBishop,
    WhiteRook,
    WhiteQueen,
    WhiteKing,
];

export const blackFiguresIconsPaths = [
    BlackPawn,
    BlackKnight,
    BlackBishop,
    BlackRook,
    BlackQueen,
    BlackKing,
];

export const getWhiteDigitsIcons = (size) => {
    return [
        <WOneIcon key={1} width={size} height={size} />,
        <WTwoIcon key={2} width={size} height={size} />,
        <WThreeIcon key={3} width={size} height={size} />,
        <WFourIcon key={4} width={size} height={size} />,
        <WFiveIcon key={5} width={size} height={size} />,
        <WSixIcon key={6} width={size} height={size} />,
    ];
};

export const getBlackDigitsIcons = (size) => {
    return [
        <BOneIcon key={1} width={size} height={size} />,
        <BTwoIcon key={2} width={size} height={size} />,
        <BThreeIcon key={3} width={size} height={size} />,
        <BFourIcon key={4} width={size} height={size} />,
        <BFiveIcon key={5} width={size} height={size} />,
        <BSixIcon key={6} width={size} height={size} />,
    ];
};
