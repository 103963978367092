import React, { memo, useCallback } from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { Box } from "@mui/material";
import Badge from "@mui/material/Badge";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { EGameChatTab } from "src/store/models";

import { useLocale } from "src/providers/LocaleProvider";
import { appColors } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import useChangeTheme from "src/hooks/useChangeTheme";
import WatchingPlayers from "src/components/WatchingPlayers";
import WatchingToggler from "src/components/WatchingPlayers/components/WatchingToggler";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import {
    IWatchingList,
    WatchersStatusType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import paths from "src/consts/paths";
import ReturnToPrevious from "src/pages/lobby/components/GamePreview/components/returnToFriends";
import {
    GameModeTypeNew,
    UserBalanceType,
    PlayerInfoExistPosition,
} from "src/store/models";

import MultiGameTabs from "../multiGameTabs";
import LineOfActiveIcons from "src/pages/game-new2/components/actionAndReturn/lineOfActiveIcons";

import { EColor } from "src/components/types";

import { useStyles } from "./styles";

const ActionAndReturn = observer(
    ({
        setOpen,
        isOpen,
        watchingArray,
        isMobileGamePage,
        solidMultiGames,
        isMobileView,
        elementPlace,
    }: IWatchingList) => {
        const styles = useStyles();
        const {
            header: { lobby, tournament },
        } = useLocale();
        const { gameStore, authStore } = useStores();
        const { onChatToggler, onChatTabsToggler, currentTab } =
            useLeftLayoutContext();

        const history = useHistory();
        const appearanceTheme = useChangeTheme();
        // const gameType = gameStore.currentGame?.type;
        const gameType = gameStore.currentGameNew?.type;
        const tournamentId = gameStore.currentGameNew?.tournamentId;
        const balanceType = gameStore.currentGame?.balanceType;
        const winner = gameStore?.gameState?.gameResultData?.winner;
        const chatMessagesCount = gameStore?.newChatMessage?.length;

        const currentTabMain =
            authStore.currentUser?.settings?.dicechessBoardRightBlockMode ||
            EGameChatTab.chat;

        const onChangeGameTab = useCallback(
            (tab: keyof typeof EGameChatTab) => {
                authStore.setGameChatTab(tab);
                onChatTabsToggler && onChatTabsToggler(tab);
            },
            []
        );

        const checkGameTypeUseCallBack = useCallback(() => {
            if (
                gameType === GameModeTypeNew.TOURNAMENT ||
                gameType === GameModeTypeNew.TOURNAMENT_LEAGUE
            ) {
                if (tournamentId) {
                    return `/tournaments/${tournamentId}`;
                }
                return paths.tournaments;
            }
            if (gameType && balanceType) {
                if (
                    gameType === GameModeTypeNew.REGULAR &&
                    balanceType === UserBalanceType.play
                )
                    return paths.diceChessLobby;
                if (
                    gameType === GameModeTypeNew.REGULAR &&
                    balanceType === UserBalanceType.coins
                )
                    return paths.lobbyFreePlay;
            }
            return paths.diceChessLobby;
        }, [gameType, balanceType, tournamentId]);

        const checkTitleTypeUseCallBack = useCallback(() => {
            if (gameStore.currentGame?.balanceType) {
                if (gameType === GameModeTypeNew.TOURNAMENT ||
                    gameType === GameModeTypeNew.TOURNAMENT_LEAGUE)
                    return tournament("Tournaments");
                if (
                    balanceType === UserBalanceType.play &&
                    gameType === GameModeTypeNew.REGULAR
                )
                    return lobby("Lobby");
                if (
                    balanceType === UserBalanceType.coins &&
                    gameType === GameModeTypeNew.REGULAR
                )
                    return lobby("Lobby");
            }
            return lobby("Lobby");
        }, [gameType, gameStore.currentGame?.balanceType]);

        const pushTitleTypeUseCallBack = useCallback(() => {
            history.push(checkGameTypeUseCallBack());
            winner && gameStore.dialogs.rejectRematch();
        }, []);

        const style = useSpring({
            from: {
                opacity: 0,
                display: "none",
                transform: "translate3d(0,20px,0) scale(0.9)",
                "-webkit-transform": "translate3d(0,20px,0) scale(0.9)",
                minHeight: "inherit",
            },
            to: {
                opacity: 1,
                display: "block",
                transform: `translate3d(0, 0, 0) scale(1)`,
                "-webkit-transform": `translate3d(0, 0, 0) scale(1)`,
                minHeight: "inherit",
            },
            delay: 100,
        });

        const arrowStyle = {
            color: appColors.white,
            "&:hover": {
                color: appColors.txt300,
                transition: "all .4s",
            },
        };

        if (
            elementPlace === PlayerInfoExistPosition.inProgressPreview &&
            isMobileView
        ) {
            return (
                <>
                    <animated.div
                        style={{
                            ...style,
                        }}
                    >
                        <div
                            className={cn(
                                styles.wrapperReturnFromGame,
                                EColor.deepBlack,
                                {
                                    isMobileGamePage: isMobileGamePage,
                                    isMultitableMobile:
                                        solidMultiGames &&
                                        solidMultiGames.length > 0,
                                }
                            )}
                        >
                            <>
                                <div
                                    className={cn(
                                        styles.actionButtonWrapper,
                                        appearanceTheme,
                                        "return",
                                        {}
                                    )}
                                    onClick={pushTitleTypeUseCallBack}
                                >
                                    <ArrowBackIosNewOutlinedIcon
                                        sx={arrowStyle}
                                    />
                                </div>

                                <Box
                                    component="div"
                                    className={cn(
                                        styles.actionIconsBox,
                                        appearanceTheme,
                                        {
                                            isMobileGamePage: isMobileGamePage,
                                            isMultitableMobile:
                                                solidMultiGames &&
                                                solidMultiGames.length > 0,
                                        }
                                    )}
                                >
                                    <LineOfActiveIcons
                                        isMobileGamePage={isMobileGamePage}
                                        solidMultiGames={solidMultiGames}
                                        elementPlace={elementPlace}
                                        watchersCounter={
                                            watchingArray.length || 1
                                        }
                                    />
                                </Box>
                            </>
                        </div>
                    </animated.div>
                </>
            );
        }

        if (
            appearanceTheme === EColor.deepBlack ||
            appearanceTheme === EColor.darkBlue
        ) {
            return (
                <>
                    <animated.div
                        style={{
                            ...style,
                        }}
                    >
                        <div
                            className={cn(
                                styles.wrapperReturnFromGame,
                                EColor.deepBlack,
                                {
                                    isMobileGamePage: isMobileGamePage,
                                    isMultitableMobile:
                                        solidMultiGames &&
                                        solidMultiGames.length > 0,
                                }
                            )}
                        >
                            <>
                                <div
                                    className={cn(
                                        styles.actionButtonWrapper,
                                        EColor.deepBlack,
                                        "return",
                                        {}
                                    )}
                                    onClick={pushTitleTypeUseCallBack}
                                >
                                    <ArrowBackIosNewOutlinedIcon
                                        sx={arrowStyle}
                                    />
                                </div>

                                <Box
                                    component="div"
                                    className={cn(
                                        styles.actionIconsBox,
                                        EColor.deepBlack,
                                        {
                                            isMobileGamePage: isMobileGamePage,
                                            isMultitableMobile:
                                                solidMultiGames &&
                                                solidMultiGames.length > 0,
                                        }
                                    )}
                                >
                                    <LineOfActiveIcons
                                        isMobileGamePage={isMobileGamePage}
                                        solidMultiGames={solidMultiGames}
                                        elementPlace={elementPlace}
                                    />
                                    {solidMultiGames &&
                                        solidMultiGames?.length > 0 && (
                                            <MultiGameTabs
                                                solidMultiGames={
                                                    solidMultiGames
                                                }
                                            />
                                        )}
                                </Box>
                            </>
                        </div>
                    </animated.div>
                </>
            );
        }

        return (
            <>
                <animated.div
                    style={{
                        ...style,
                    }}
                >
                    <div
                        className={cn(styles.wrapperReturnFromGame, {
                            isMobileGamePage: isMobileGamePage,
                            isMultitableMobile:
                                solidMultiGames && solidMultiGames.length > 0,
                        })}
                    >
                        {isMobileGamePage ? (
                            <>
                                <ReturnToPrevious
                                    title={checkTitleTypeUseCallBack}
                                    onClick={pushTitleTypeUseCallBack}
                                />
                                {solidMultiGames && !solidMultiGames?.length ? (
                                    <WatchingToggler
                                        setOpen={setOpen}
                                        openStatus={WatchersStatusType.show}
                                        isOpen={isOpen}
                                        watchingArray={watchingArray}
                                        isMobileGamePage={isMobileGamePage}
                                    />
                                ) : (
                                    <MultiGameTabs
                                        solidMultiGames={solidMultiGames}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <ReturnToPrevious
                                    title={checkTitleTypeUseCallBack}
                                    onClick={pushTitleTypeUseCallBack}
                                />
                                <Box className={styles.watchingViewMobWrapper}>
                                    <WatchingPlayers
                                        setOpen={setOpen}
                                        openStatus={WatchersStatusType.show}
                                        isOpen={isOpen}
                                        watchingArray={watchingArray}
                                        isMobileView={isMobileView}
                                    />
                                </Box>
                            </>
                        )}
                    </div>
                </animated.div>
            </>
        );
    }
);

export default memo(ActionAndReturn);
