import React, {
    memo,
    useEffect,
    useState,
    useMemo,
    useLayoutEffect,
} from "react";
import moment from "moment";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { textAlign } from "@mui/system";

import { ColorVariant } from "src/store/models";
import Countdown, { zeroPad } from "react-countdown";
import { usePlayer } from "src/sounds/use-player";
import shortColor from "src/utils/shortColor";
import useChangeTheme from "src/hooks/useChangeTheme";
import { usePreciesTimer } from "src/hooks/usePreciseTimer";

import { useStyles } from "./styles";
import useStores from "../../../../hooks/useStores";

type RedesignClockProps = {
    warningLimit: number;
    active: boolean;
    activeClock?: { color: ColorVariant };
    initTime: number;
    noTimerSound: boolean;
    color: ColorVariant;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    gameEnd?: boolean;
    isViewMode?: boolean;
    id?: string;
    gameId?: string;
};

export const RedesignClockv2 = memo(
    ({
        warningLimit,
        initTime,
        activeClock,
        noTimerSound,
        color,
        isMultiTable,
        isTwoGameTable,
        gameEnd,
        isViewMode,
        id,
        ...props
    }: RedesignClockProps) => {


        const [seconds, setSeconds] = useState(-1);
        const currentWidth = window.innerWidth;
        const appearanceTheme = useChangeTheme();

        const { gameStore } = useStores();

        useEffect(() => {
            // console.log('gameId', props.gameId);
            localStorage.setItem('last-view', props.gameId || '');
        }, [props.gameId]);

        useEffect(() => {
            let it = setInterval(
                () => {

                    const timersStr = localStorage.getItem('game-timers');
                    const playersStr = localStorage.getItem('game-players');

                    if (timersStr && playersStr) {


                        const timers = JSON.parse(timersStr);
                        const players = JSON.parse(playersStr);

                        if (localStorage.getItem('game-id') === props.gameId) {

                            let nwSeconds = players[0]._id === id ? timers[0] / 1000 : timers[1] / 1000;

                            if (gameStore.currentGameNew?.curMove === id && gameStore.currentGameNew?.state !== 4) {
                                const outStr = localStorage.getItem('game-out');
                                const outs = JSON.parse(outStr || '');


                                const dieTime = players[0]._id === id ? outs[0] : outs[1];

                                nwSeconds = Math.max(Math.round((+new Date(dieTime) - Date.now()) / 1000), 0);
                            }

                            if (nwSeconds !== seconds) {
                                setSeconds(nwSeconds);
                            }
                        }

                    }

                },
                500
            );

            return () => {
                clearInterval(it)
            };
        }, [id])


        const classes = useStyles({
            warning:
                warningLimit >= seconds,
            appearanceTheme,
            gameEnd,
            ...props,
        });
        const desktopStyleProps = {
            // TODO: maybe we should set this values in useStyles?
            paddingLeft: !isMultiTable
                ? currentWidth > 1250
                    ? "0%"
                    : "0px"
                : undefined,
        };

        const timeText = seconds >= 0 ? moment()
            .startOf("day")
            .seconds(seconds)
            .format("mm:ss") : '     ';

        return (
            <div
                style={{ ...desktopStyleProps }}
                className={cn(
                    classes.text,
                    "redesign-clock-text",
                    appearanceTheme,
                    {
                        isMultiTable,
                        isTwoGameTable,
                    }
                )}
            >
                <div
                    className={cn(classes.slicedTimerBox, appearanceTheme, {
                        isMultiTable,
                    })}
                >
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            "minutes",
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(0, 2)}
                    </span>
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            "colon",
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(2, 3)}
                    </span>
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            "seconds",
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(3)}
                    </span>
                </div>
            </div>
        );
    }
);
