import { makeStyles } from "@mui/styles";
import { keyframes, letterSpacing } from "@mui/system";

import { EColor } from "src/components/types";
import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";

interface IInput {
    clicker: any;
    myColor: any;
    oponentColor: any;
}

export const useStyles = makeStyles({
    "@global": {
        "@keyframes gameScoreTitlesFirst": {
            "0%": {
                transform: "scale(1)",
            },
            "25%": {
                fontWeight: 600,
                transform: "scale(2)",
            },
            "35%": {
                transform: "scale(2)",
                filter: "drop-shadow(0px 0px 0px black)",
            },
            "50%": {
                transform: "scale(2)",
                fontWeight: 700,
                filter: "drop-shadow(0px 0px 4px #F8BF44)",
            },
            "65%": {
                transform: "scale(2)",
                filter: "drop-shadow(0px 0px 0px black)",
            },
            "75%": {
                transform: "scale(2)",
                fontWeight: 600,
            },
            "100%": {
                transform: "scale(1)",
            },
        },
        "@keyframes changeNumber": {
            "0%": { fontSize: 12 },
            "70%": { fontSize: 32 },
            "100%": { fontSize: 24 },
        },

        "@keyframes marquee": {
            "0%": { transform: "translateX(0%)" },
            "100%": { transform: "translateX(-100%)" },
        },
    },

    match: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        // width: 40,
        width: "fit-content",
        height: 32,
        "&.isTournamentPreview": {

        }
    },
    matchAnimate: {
        animation: "changeNumber 1s linear 1",
    },

    multiRoundGameWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        justifyContent: "center",
        alignItems: "center",
        "@media screen and (min-height: 880px) and (max-width: 1251px)": {
            padding: "0px",
        },
        [theme.breakpoints.down("lg")]: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: '0 5px',
            justifyContent: "space-between",
            "&.isRegualarOrMatch": {},
            "&.isTournamentGame": {},
            "&.isTournamentPreview": {
                display: "flex",
                flexDirection: "row", 
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            },
        },
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: '0 5px',
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
        },
        [theme.breakpoints.down(350)]: {
            flexDirection: "row",
            "&.isRegualarOrMatch": {},
            "&.isTournamentGame": {},
        },
        "&.inProgressPreview": {
            display: "flex",
            flexDirection: "row",
            width: "55%",
            justifyContent: "space-between",
        },
        "&.inBracketPreview": {
            display: "flex",
            flexDirection: "row",
            width: "55%",
            justifyContent: "space-between",
        },
        "&.isTournamentPreview": {
            [theme.breakpoints.up("lg")]: {
                display: "flex",
                flexDirection: "column", 
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
            },
        },
        "&.forceRow": {
            [theme.breakpoints.up("lg")]: {
                flexDirection: "row !important",
                gap: 15,
            }
        },
        "&.forceColumn": {
            [theme.breakpoints.up("lg")]: {
                flexDirection: "column !important", 
            }
        }
    },

    matchGameScoreTitleBox: {
        [theme.breakpoints.up(1250)]: {
            width: "100%",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        width: "100%",
        height: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&.isTournamentPreview": {
            width: "auto",
            maxWidth: 'none',
            flex: 1,
        },
        [theme.breakpoints.down("lg")]: {
        //    flex: 1,
        },
    },

    tournamentTitleMarquee: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "relative",
        width: "100%",

        "& p": {
            margin: 0,
            position: "relative",
            width: "fit-content",
            display: "inline-block",
            animation: "$marqueeAnimation 10s linear infinite",
        },
    },

    "@keyframes marqueeAnimation": {
        "0%": { transform: "translateX(50%)" }, 
        "100%": { transform: "translateX(-100%)" }, 
    },

    tournamentTitleBox: {
        [theme.breakpoints.down("lg")]: {
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: 215,
        },
        [theme.breakpoints.down("sm")]: {
            width: "30%", 
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },

    tournamentTitleCentered: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    centeredText: {
        margin: 0,
        position: "relative",
        width: "fit-content",
        textAlign: "center",
        maxWidth: "100%",
    },

    tournamentScoreBox: {
        [theme.breakpoints.down("lg")]: {
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%", 
            display: "flex",
            justifyContent: "center", 
            alignItems: "center",
        },
    },

    tournamentRoundContainer: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        [theme.breakpoints.down("lg")]: {
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },

    tournamentRoundBox: {
        [theme.breakpoints.down("lg")]: {
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%", 
            textAlign: "right", 
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },

    tournamentTitleText: {
        [theme.breakpoints.down("lg")]: {
            fontSize: "18px !important",
            color: 'rgba(255,255,255, 0.8)',
            fontWeight: 600,
            textAlign: "center",
        },
    },

    tournamentScoreText: {
        [theme.breakpoints.down("lg")]: {
            fontSize: "22px !important",
            fontWeight: 700,
            color: "#F8BF44",
            background: "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
        },
    },

    tournamentRoundText: {
        [theme.breakpoints.down("lg")]: {
            fontSize: "16px !important",
            color: 'rgba(255,255,255, 0.7)',
            fontWeight: 500,
            textAlign: "center",
        },
    },

    tournamentTitleBoxDesktop: {
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            height: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        [theme.breakpoints.down("sm")]: {
            flex: "1 1 auto", 
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "40%", 
        },
    },

    tournamentScoreBoxDesktop: {
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            height: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }
    },

    tournamentRoundBoxDesktop: {
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            height: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        [theme.breakpoints.down("sm")]: {
            flex: "1 1 auto", 
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "40%", 
        },
    },

    tournamentTitleTextDesktop: {
        [theme.breakpoints.up("lg")]: {
            fontSize: "28px !important",
            color: 'rgba(255,255,255, 0.9)',
            fontWeight: 600,
            textAlign: "center",
        }
    },

    tournamentScoreTextDesktop: {
        [theme.breakpoints.up("lg")]: {
            fontSize: "32px !important",
            fontWeight: 700,
            color: "#F8BF44",
            background: "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
        }
    },

    tournamentRoundTextDesktop: {
        [theme.breakpoints.up("lg")]: {
            fontSize: "24px !important",
            color: 'rgba(255,255,255, 0.8)',
            fontWeight: 500,
            textAlign: "center",
        }
    },

    matchRoundBox: {
        width: "100%",
        height: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexShrink: 2,
        gridGap: "7px",
    },

    gameScoreTitles: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: "linear-gradient(180deg, #DDDDDD 0%, #FFFFFF 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        margin: 0,
        fontWeight: 400,
        "&.tournamentDetails": {
            minWidth: "fit-content !important",
            width: "fit-content",
            color: 'rgba(255,255,255, 0.65)',
            fontSize: "20px !important",
            overflow: "hidden",
            whiteSpace: "nowrap",
            position: "relative",
            [theme.breakpoints.up("lg")]: {
                fontSize: "28px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                // maxWidth: '310px',
                minWidth: "310px",
                overflow: "hidden",
            },
            "@media screen and (max-height: 750px) and (max-width: 450px)": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontSize: "16px",
            },
            "&.tournamentDetailsTitle": {
                position: "relative",
            },
            "&.tournamentDetailsRound": {
                filter: "drop-shadow(1px 2px 1px black)",
                [theme.breakpoints.up("lg")]: {
                    fontSize: "24px",
                },
            },
        },

        "&.score": {
            fontWeight: 600,
            fontSize: "18px",
            filter: "drop-shadow(1px 2px 1.5px black)",
            [theme.breakpoints.up("lg")]: {
                fontSize: "28px",
            },
            "@media screen and (max-height: 750px) and (max-width: 450px)": {
                fontSize: "18px",
            },
        },
        "&.upTo": {
            fontWeight: 500,
            fontSize: "14px",
            filter: "drop-shadow(1px 2px 1px black)",
            [theme.breakpoints.up("lg")]: {
                fontSize: "20px",
            },
            "@media screen and (max-height: 750px) and (max-width: 450px)": {
                fontSize: "14px",
            },
        },

        "&.colon": {
            fontWeight: 600,
            fontSize: "18px",
            [theme.breakpoints.up("lg")]: {
                fontSize: "28px",
            },
            "@media screen and (max-height: 750px) and (max-width: 450px)": {
                fontSize: "18px",
            },
        },

        "&.inProgressPreview": {
            width: "fit-content",
            fontSize: "16px !important",
            minWidth: "60px !important",
            letterSpacing: "-0.5px",
            [theme.breakpoints.up(1800)]: {
                fontSize: "20px !important",
                minWidth: "80px !important",
                letterSpacing: "-0.3x",
            },
        },
        "&.isTournamentPreview": {
            minWidth: "fit-content !important",
            width: "fit-content",
            color: "#FFFFFF !important",  

            background: "none !important",
            "-webkit-background-clip": "unset !important",
            "-webkit-text-fill-color": "unset !important",
            backgroundClip: "unset !important",
            textFillColor: "unset !important",
        },

    },

    tournamentHeaderRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '4px',
    },

    tournamentTitle: {
        margin: 0,
        flex: '1 1 auto',
        textAlign: 'left',
    },

    scoreDisplay: {
        margin: 0,
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
    },

    roundLabel: {
        margin: 0,
        width: '100%',
        textAlign: 'left',
        fontSize: '0.9em',
        opacity: 0.9,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
    },

    marqueeText: {
        display: 'inline-block',
        width: 'auto',
        animation: '$marquee 15s linear infinite',
        '&:hover': {
            animationPlayState: 'paused',
        },
    },

    '@keyframes marquee': {
        '0%': {
            transform: 'translateX(100%)',
        },
        '100%': {
            transform: 'translateX(-100%)',
        },
    },

    matchGameScoreTitleBoxShine: {
        width: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 0,
        //background:
        //    "radial-gradient(50% 4622.78% at 50% 50%, #DDDDDD 0%, rgba(255, 255, 255, 0) 100%)",
        opacity: 0.35,
        filter: "blur(10px)",

        [theme.breakpoints.up("xs")]: { height: "25px" },
        [theme.breakpoints.up("lg")]: { height: "40px" },
    },

    gameScoreTitlesFirst: ({ clicker, myColor }: IInput) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        margin: 0,
        fontWeight: 500,
        fontSize: "18px",
        maxWidth: "130px",
        gridGap: "3px",

        width: "14px",
        [theme.breakpoints.up("xs")]: {
            filter: "drop-shadow(0.5px 0.5px 1px black)",
        },
        [theme.breakpoints.up(1250)]: {
            width: "fit-content",
            filter: "drop-shadow(1px 1px 1px black)",
        },
        "@media screen and (max-height: 750px) and (max-width: 450px)": {
            fontSize: "18px",
        },
        animationName: "empty",
        "&.me": {
            animationName: "gameScoreTitlesFirst",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "2300ms",
            animationDelay: "0s",
        },
    }),

    gameScoreTitlesSecond: ({ clicker, oponentColor }: IInput) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        margin: 0,
        fontWeight: 500,
        fontSize: "18px",
        maxWidth: "130px",
        gridGap: "3px",
        // animationName:
        //     clicker === oponentColor ? 'gameScoreTitlesFirst' : 'empty',
        animationDelay: "0s",
        animationIterationCount: "1",
        animationFillMode: "forwards",
        animationDuration: "2300ms",
        width: "14px",
        filter: "drop-shadow(1px 2px 1px black)",
        // [theme.breakpoints.up('lg')]: {
        //     fontSize: '26px',
        // },
        [theme.breakpoints.up("xs")]: {
            filter: "drop-shadow(0.5px 0.5px 1px black)",
        },
        [theme.breakpoints.up(1250)]: {
            // width: '18px',
            width: "fit-content",
            justifyContent: "flex-start",
        },
        "@media screen and (max-height: 750px) and (max-width: 450px)": {
            fontSize: "18px",
        },
        animationName: "empty",
        "&.oponent": {
            animationName: "gameScoreTitlesFirst",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "2300ms",
            animationDelay: "0s",
        },
    }),

    gameScoreTitlesName: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "start",
        background: "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        fontWeight: 500,
        fontSize: "18px",
        overflow: "hidden",
        margin: 0,
        [theme.breakpoints.up("lg")]: {
            fontSize: "26px",
        },
        "@media screen and (max-height: 750px) and (max-width: 450px)": {
            fontSize: "18px",
        },
        "&.nickname": {
            width: "fit-content",
            maxWidth: "100px",
            whiteSpace: "pre",
            fontSize: "20px",
            maskImage: "linear-gradient(to right,black 95%,transparent 100%)",
            [theme.breakpoints.up(1250)]: {
                fontSize: "26px",
            },
        },
        "&.nicknameSecond": {
            // marginLeft: '5px',
        },
        "&.colon": {
            // marginLeft: '5px',
            // marginRight: '5px',
        },
        "&.score": {
            minWidth: "fit-content",
            justifyContent: "center",
        },
    },
});
