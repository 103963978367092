import { makeStyles } from '@mui/styles';

import theme from 'src/theme';
import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';

export const useStyles = makeStyles({
    '@global': {
        '@keyframes skeletonGradient': {
            '0%': {
                backgroundPosition: '0% 50%',
            },
            '100%': {
                backgroundPosition: '100% 50%',
            },
        },
    },

    stickersListBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        minHeight: '100px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0',
    },
    stickersList: {
        display: 'flex',
        flexWrap: 'wrap', 
        justifyContent: 'center',
        gap: '5px', 
        width: '100%',
        height: '100%',
        overflow: 'auto',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '& > *': {
            flex: '1 0 calc(25% - 5px)', 
            maxWidth: 'calc(25% - 5px)',  
        },
        '@media (max-width: 530px)': {
            gap: '5px',
            '& > *': {
                flex: '1 0 calc(25% - 5px)',
                maxWidth: 'calc(25% - 5px)',
            },
        },
    },

    stickersTitle: {
        margin: 0,
        padding: 0,
        color: 'white',
        textAlign: 'center',
        fontSize: 12,
        '&.pulseLoader': {
            display: 'block',
            height: '12px',
            width: '100%',
        },
    },

    stickersImg: {
        opacity: 0,
        width: '100%',
        height: 'auto',
        maxHeight: '75px',
        transition: 'ease-in-out opacity 300ms',
        '&.isLoaded': {
            opacity: 1,
            cursor: 'pointer',
            transition: 'transform 0.3s ease-in-out',
            '@media (hover: hover)': {
                '&:hover': {
                    transform: 'scale(1.1)',
                },
            },
        },
        '@media (max-width: 500px)': {
            maxWidth: '60%',
            maxHeight: '40%',
        },
    },

    stickersSkeleton: {
        opacity: 0,
        display: 'none',
        '&.loadingSkeleton': {
            opacity: 1,
            width: '45px',
            height: '45px',
            transform: 'scale(1)',
            border: 'white 1px solid',
            display: 'block',
            borderRadius: '50%',
            backgroundSize: '1000% 100%',
            background:
                'linear-gradient(60deg, rgba(160,160,160,1) 20%, rgba(255,255,255,1) 25%, rgba(160,160,160,1) 30%, rgba(160,160,160,1) 70%, rgba(255,255,255,1) 75%, rgba(160,160,160,1) 80%)',
            animationName: 'skeletonGradient',
            animationDelay: '0s',
            animationIterationCount: 'infinite',
            animationFillMode: 'forwards',
            animationTimingFunction: 'linear',
            animationDuration: '1500ms',
            [theme.breakpoints.between('lg', MAX_CHANGE_SCREEN_WIDTH)]: {
                width: '40px',
                height: '40px',
            },
        },
    },
});
