import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { useLocale } from "src/providers/LocaleProvider";
import { Button } from "src/shared/ui/Button";
import paths from "src/consts/paths";
import useInitRematchTimer from "src/hooks/useInitRematchTimer";
import useWindowSize from "src/hooks/useWindowSize";
import { usePlayer } from "src/sounds/use-player";
import UserAvatar from "src/components/UserAvatar";
import FadeInOutVertical from "src/components/Animated";
import useChangeTheme from "src/hooks/useChangeTheme";
import { ColorVariant, UserBalanceType } from "src/store/models";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new2/constants";
import { updateSearchParams } from "src/utils/update-search-params";
import { useChessBoardProps } from "src/pages/game-new/hooks/useChessBoardProps";

import useStores from "../../../../../../hooks/useStores";
import useStyles from "../../../common/rematchGeneralDialog/style";
import {boardRoot, endpointsRoot} from "../../../../../../core/endpoints";
import { CountdownCircular } from "../../../../../../components/countDownCircular";
import {
    ButtonType,
    GreenButton,
} from "../../../../../../components/buttons/greenButton";
import { EGameRematchStatus } from "src/store/models";
import {goTo} from "../../../../../../helpers";

const AcceptOrRejectAlert = ({ isXlTablet, isMultiTable = false }) => {
    const { gameStore, gamesStore, authStore } = useStores();
    const { width, height } = useWindowSize();
    const history = useHistory();
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const soundPlayer = usePlayer();
    const { onLobbyClose } = useChessBoardProps();
    const MAX_XL_TABLET_SIZE =
        window.innerWidth <= MAX_CHANGE_SCREEN_WIDTH &&
        window.innerWidth >= MAX_L_TABLET;
    const isMobileSize = width < MAX_XL_TABLET;


    useEffect(() => {
        const isRematchAccepted = gameStore.gameState.isRematchAccepted();
        if (isRematchAccepted && !isMultiTable) {
            goTo(boardRoot + `/game/${gameStore.rematch.gameId}?userKey=${localStorage.getItem("t")}&currentUserId=${authStore.currentUser?._id}`); return;
        } else if (isRematchAccepted && isMultiTable) {
            updateSearchParams({ id: undefined });
            const newGameTables = gamesStore.gameTables.map((table) => {
                if (table && table?.id === gameStore.currentGame?.id) {
                    return { ...table, id: gameStore.rematch.gameId as string };
                }
                return table;
            });
            gamesStore.setGameTables(newGameTables);
        }
    }, [
        gameStore?.rematch?.gameId,
        gameStore?.rematch[ColorVariant.black],
        gameStore?.rematch[ColorVariant.white],
    ]);

    const {
        game: {
            rematch: {
                acceptOrReject: { title, accept, reject, rematch },
            },
        },
    } = useLocale();

    const opponentColor = gameStore.gameState.getOpponentColor();
    const rematchEnd = gameStore.dialogs.rematchData.rematchEnd;

    // const actualTimer = useInitRematchTimer(
    //     gameStore?.rematch?.gameCreatedTime,
    //     10000
    // );

    const actualTimer = useInitRematchTimer(rematchEnd, 15000);

    // if (!opponentColor) return null;

    const player = gameStore.gameState.getPlayerByColor(opponentColor);
    // if (!player) return null;

    const onCancel = useCallback(() => {
        gameStore.rematchDecline(gameStore?.currentGameNew?._id);
        if (isMultiTable) {
            onLobbyClose?.();
            return;
        }
        history.push(paths.diceChessLobby);
        gameStore.currentGameNew?.free
            ? history.push(paths.lobbyFreePlay)
            : history.push(paths.diceChessLobby);
    }, [gameStore?.currentGameNew?._id]);

    if (isMobileSize) {
        return (
            <>
                {gameStore.currentGameNew?.type !== 8 && (
                    <FadeInOutVertical stylesProp={{ alignItems: "center" }}>
                        <Box
                            component="div"
                            className={cn(
                                styles.rematchAlertContainer,
                                "acceptOrReject"
                            )}
                        >
                            <Button
                                text={reject("Reject")}
                                variant="outlined"
                                onClick={() => {
                                    gameStore.rematchDecline(
                                        gameStore?.currentGameNew?._id
                                    );
                                    history.push(paths.diceChessLobby);
                                }}
                                className={cn(
                                    styles.buttonReject,
                                    "acceptOrReject",
                                    "reject"
                                )}
                            />
                            <Button
                                text={accept("Rematch")}
                                variant="contained"
                                onClick={() =>
                                    gameStore.rematchAccept(
                                        gameStore?.currentGameNew?._id
                                    )
                                }
                                className={cn(
                                    styles.buttonRematch,
                                    "acceptOrReject",
                                    "rematch"
                                )}
                            />

                        </Box>
                    </FadeInOutVertical>
                )}
            </>
        );
    }

    return (
        <>
            {gameStore.currentGameNew?.type !== 8 && (
                <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
                    <Box
                        component="div"
                        className={cn(styles.rematchAlertContainer, "acceptOrReject")}
                    >
                        {actualTimer && (
                            <CountdownCircular
                                seconds={actualTimer}
                                size={50}
                                reset={0}
                                pause={false}
                                onEnd={() => {
                                    onCancel();
                                }}
                                modalType={EGameRematchStatus.WAITING_FOR_ANSWER}
                                className={cn(styles.circularRequest, "acceptOrReject")}
                            />
                        )}
                        <div className={cn(styles.btnContainer, "acceptOrReject")}>
                            <Button
                                text={reject("Reject")}
                                variant="outlined"
                                onClick={() => {
                                    gameStore.rematchDecline(
                                        gameStore?.currentGameNew?._id
                                    );
                                    history.push(paths.diceChessLobby);
                                }}
                                className={cn(
                                    styles.buttonReject,
                                    "acceptOrReject",
                                    "reject"
                                )}
                            />
                            <Button
                                text={accept("Rematch")}
                                variant="contained"
                                onClick={() =>
                                    gameStore.rematchAccept(gameStore?.currentGameNew?._id)
                                }
                                className={cn(
                                    styles.buttonRematch,
                                    "acceptOrReject",
                                    "rematch"
                                )}
                            />
                        </div>

                    </Box>
                </FadeInOutVertical>
            )}
        </>
    );
};

export default observer(AcceptOrRejectAlert);
