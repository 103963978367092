import { useEffect, useRef } from "react";
import { Round, TournamentLinesProps } from "../types";
import { observer } from "mobx-react";

const TournamentLinesDouble: React.FC<TournamentLinesProps> = ({
    bracket,
    isSubBracket,
    startFromSecondRound = false
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const drawLinesDelayed = () => {
        setTimeout(() => {
            const canvas = canvasRef.current;
            if (!canvas) return;

            const ctx = canvas.getContext("2d");
            if (!ctx) return;

            const container = canvas.parentElement;
            if (!container) return;

            const pixelRatio = window.devicePixelRatio || 1;
            const containerRect = container.getBoundingClientRect();

            // Собираем координаты матчей и находим максимальные размеры
            const matchCoords: { [key: string]: { x: number; y: number; width: number; height: number } } = {};
            let maxY = 0;
            let maxX = 0;

            bracket.forEach((round, roundIndex) => {
                if (round.isHidden) return;  // ✅ Пропускаем скрытые колонки

                const matches = container.querySelectorAll(`[data-column="${roundIndex}"][data-match]`);
                matches.forEach((match) => {
                    const matchIndex = parseInt(match.getAttribute("data-match") || "0");
                    const rect = match.getBoundingClientRect();
                    const matchX = rect.left - containerRect.left + container.scrollLeft;
                    const matchY = rect.top - containerRect.top + container.scrollTop;

                    matchCoords[`${roundIndex}-${matchIndex}`] = {
                        x: matchX,
                        y: matchY,
                        width: rect.width,
                        height: rect.height,
                    };

                    // Обновляем максимальные координаты
                    maxY = Math.max(maxY, matchY + rect.height);
                    maxX = Math.max(maxX, matchX + rect.width);
                });
            });


            // Добавляем отступы для линий
            const padding = {
                bottom: 50,
                right: 60  // Отступ для линий справа
            };
            maxY += padding.bottom;
            maxX += padding.right;

            // Устанавливаем размеры canvas
            canvas.width = maxX * pixelRatio;
            canvas.height = maxY * pixelRatio;

            ctx.scale(pixelRatio, pixelRatio);

            canvas.style.width = `${maxX}px`;
            canvas.style.height = `${maxY}px`;

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#2F3136";
            ctx.lineWidth = 1;

            const drawLineWithRoundedCorners = (
                startX: number,
                startY: number,
                endX: number,
                endY: number,
                radius: number = 4
            ) => {
                ctx.beginPath();

                if (startY === endY) {
                    ctx.moveTo(startX, startY);
                    ctx.lineTo(endX, endY);
                } else {
                    const cornerX = startX;
                    const cornerY = endY;

                    ctx.moveTo(startX, startY);
                    if (Math.abs(startY - cornerY) > radius) {
                        ctx.lineTo(cornerX, startY + (startY < cornerY ? -radius : radius));
                        ctx.arcTo(cornerX, cornerY, cornerX + (startX < endX ? radius : -radius), cornerY, radius);
                    }
                    ctx.lineTo(endX, endY);
                }

                ctx.stroke();
            };

            // Начинаем с нужного раунда
            const startRound = startFromSecondRound ? 1 : 0;

            const findNextVisibleRoundIndex = (currentIndex: number) => {
                for (let i = currentIndex + 1; i < bracket.length; i++) {
                    if (!bracket[i].isHidden) return i; // ✅ Возвращаем индекс следующей видимой колонки
                }
                return -1; // ❌ Если больше нет колонок
            };


            for (let roundIndex = startRound; roundIndex < bracket.length - 1; roundIndex++) {
                const round = bracket[roundIndex];
                const lineOffset = 40;

                for (let matchIndex = 0; matchIndex < round.matches.length; matchIndex += 2) {
                    const match1 = matchCoords[`${roundIndex}-${matchIndex}`];
                    const match2 = matchCoords[`${roundIndex}-${matchIndex + 1}`];
                    const nextVisibleRoundIndex = findNextVisibleRoundIndex(roundIndex);
                    if (nextVisibleRoundIndex === -1) continue;  // ✅ Если дальше колонок нет, не рисуем линию

                    const nextMatch = matchCoords[`${nextVisibleRoundIndex}-${Math.floor(matchIndex / 2)}`];
                    if (!nextMatch) continue;

                    if (!match1 || !nextMatch) continue;

                    const verticalLineX = match1.x + match1.width + lineOffset;
                    const match1Y = match1.y + match1.height / 2;
                    const nextMatchY = nextMatch.y + nextMatch.height / 2;

                    // Линия от первого матча
                    ctx.beginPath();
                    ctx.moveTo(match1.x + match1.width, match1Y);
                    ctx.lineTo(verticalLineX, match1Y);
                    ctx.stroke();

                    if (match2) {
                        const match2Y = match2.y + match2.height / 2;

                        // Линия от второго матча
                        ctx.beginPath();
                        ctx.moveTo(match2.x + match2.width, match2Y);
                        ctx.lineTo(verticalLineX, match2Y);
                        ctx.stroke();

                        // Вертикальная соединяющая линия
                        ctx.beginPath();
                        ctx.moveTo(verticalLineX, match1Y);
                        ctx.lineTo(verticalLineX, match2Y);
                        ctx.stroke();

                        // Линия к следующему матчу
                        const midY = (match1Y + match2Y) / 2;
                        drawLineWithRoundedCorners(verticalLineX, midY, nextMatch.x, nextMatchY);
                    } else {
                        // Прямая линия к следующему матчу если нет второго матча
                        drawLineWithRoundedCorners(verticalLineX, match1Y, nextMatch.x, nextMatchY);
                    }
                }
            }
        }, 0);
    };

    useEffect(() => {
        drawLinesDelayed();

        const container = canvasRef.current?.parentElement;
        if (container) {
            container.addEventListener("scroll", drawLinesDelayed);

            const resizeObserver = new ResizeObserver(drawLinesDelayed);
            resizeObserver.observe(container);

            window.addEventListener("resize", drawLinesDelayed);

            return () => {
                container.removeEventListener("scroll", drawLinesDelayed);
                resizeObserver.disconnect();
                window.removeEventListener("resize", drawLinesDelayed);
            };
        }
    }, [bracket, startFromSecondRound]);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: 'absolute',
                inset: 0,
                zIndex: -1,
                pointerEvents: 'none',
            }}
        />
    );
};

export default observer(TournamentLinesDouble);