import axios from 'axios';
import endpoints from 'src/core/endpoints';
import { IGetJackpotResponse, IGetLastJackpotResponse } from './requestResponses';

export const getJackpot = async () => {
    return await axios.get<IGetJackpotResponse>(endpoints.getJackpot);
};

export const getLastJackpot = async () => {
    return await axios.get<IGetLastJackpotResponse>(endpoints.getLastJackpot);
};
