import { Box } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import cn from "classnames";

import AvatarBorder from "src/assets/images/avatar-border.svg";
import { NICKNAME_REPLACEMENT } from "src/consts";
import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import useStores from "src/hooks/useStores";
import { usePlayerColor } from "src/hooks/usePlayerColorMarker";
import { IPlayer } from "src/store/models";

import AvatarAnimation from "./AvatarAnimation/avatarAnimation";
import { useStyles } from "./styles";


type UserAvatarProps = {
    isSystemAvatar?: boolean;
    avatarUrl?: string;
    nickname?: string;
    bg?: string;
    className?: string; 
    id?: string;
    resign?: string;
    color?: string;
    winner?: string;
    isWon?: boolean;
    lobbyPlayers?: Array<string> | [];
    isLobbyAvatar?: boolean | undefined;
    isChessBoard?: boolean;
    pathCompressed?: string;
    isRivalAvatar?: boolean;
    opponent?: IPlayer;
};

const Avatar = ({
    avatarUrl,
    nickname,
    bg = "rgb(26, 23, 27)",
    className,
    resign,
    color,
    winner,
    isWon,
    isLobbyAvatar,
    isSystemAvatar,
    isChessBoard,
    pathCompressed,
    isRivalAvatar,
    id,
    opponent,
}: UserAvatarProps) => {
    const styles = useStyles({ isLobbyAvatar });
    const { gameStore } = useStores();

    const [imageLoadError, setImageError] = useState(false);
    const handleImageLoadError = () => {
        setImageError(true);
    };
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const viewMode = gameStore?.isViewMode();
    const isFinalGameForPlayer = !viewMode && resign && winner === color;
    const isFinalGameForWatcher = viewMode && resign && winner === color;

    useEffect(() => {
        setImageError(false);
    }, [avatarUrl]);

    const isOpponent = opponent?.id ? opponent?.id : "";
    const boxShadowColor = usePlayerColor(isOpponent);

    return (
        <Box className={cn(styles.avatarBlock, className)} id={id ?? color}>
            <Box
                className={cn(styles.avatarImageBlock, className)}
                style={{ boxShadow: `0px 0px 7px 4px ${boxShadowColor}` }}
            >
                {!isSystemAvatar &&
                    !isFinalGameForPlayer &&
                    !isFinalGameForWatcher &&
                    !isWon && (
                        <img
                            src={AvatarBorder}
                            className={cn(
                                styles.avatarBorderImage,
                                "avatar-border"
                            )}
                            alt="Avatar Border"
                        />
                    )}
                {isFinalGameForPlayer || isWon ? (
                    <AvatarAnimation
                        winner={winner}
                        userColor={color}
                        isWon={isWon}
                        isChessBoard={isChessBoard}
                        isSystemAvatar={!isSystemAvatar}
                    />
                ) : isFinalGameForWatcher ? (
                    <AvatarAnimation
                        winner={winner}
                        isWon={isWon}
                        viewMode={viewMode}
                        isChessBoard={isChessBoard}
                        isSystemAvatar={!isSystemAvatar}
                    />
                ) : null}

                {avatarUrl !== "" && !imageLoadError ? (
                    <LazyImages
                        className={cn(styles.avatarImage, {
                            isSystemAvatar: isSystemAvatar,
                            isLoaded: true, //!isLoaded && !isRivalAvatar,
                        })}
                        wrapperClassName={styles.lazyImageWrapper}
                        placeholderImg={
                            pathCompressed && !isRivalAvatar
                                ? `${pathCompressed}`
                                : !isRivalAvatar
                                ? avatarPlaceholder
                                : null
                        }
                        src={`${avatarUrl}`}
                        alt={nickname ? nickname : NICKNAME_REPLACEMENT}
                        errorImg={avatarPlaceholder}
                        setLoaded={(status: boolean) => setLoaded(status)}
                        onError={handleImageLoadError}
                        style={{
                            background: bg,
                        }}
                    />
                ) : (
                    <Box
                        style={{ background: bg }}
                        className={cn(styles.noImageBlock, "nickname-box")}
                    >
                        {nickname
                            ? nickname[0].toUpperCase()
                            : NICKNAME_REPLACEMENT}
                    </Box>
                )}
            </Box>           
        </Box>
    );
};

export default Avatar;
