import {observer} from 'mobx-react';
import {createContext, FC, useContext, useEffect, useMemo} from 'react';
import {usePageVisibility} from 'react-page-visibility';

import GameStore from 'src/store/gameStore';

import useStores from './useStores';

interface IGameStoreContext {
    gameStore: GameStore;
}

const GameStoreContext = createContext<IGameStoreContext>(
    {} as IGameStoreContext
);

export const useGameStore = () => {
    const context = useContext(GameStoreContext);
    return context;
};

export interface IGameStoreProviderProps {
    id: string;
    children: any;
}

export const GameStoreProvider: FC<IGameStoreProviderProps> = observer(
    ({id, children}) => {
        const {gamesStore, authStore, lobbyStore} = useStores();
        const isVisible = authStore.isVisibleWebApp;

        const gameStore = useMemo(() => {
            const gameStoreById = gamesStore.getGameStoreById(id);
            // If we wanna add forced updated nonVisible - Visible action add
            // if (gameStoreById && isVisible) {
            if (gameStoreById) {
                // gameStoreById.setGameId(id);
                //========
                gameStoreById.resetGameId();
                gameStoreById.setGameIdNew(id);
                return gameStoreById;
            }
            // gameStoreById.setGameId(id);
            //=========
            const newGameStore = gamesStore.createGameStore();
            //in this line we have problem with socket connection& Socket had been created but dont work (connection false status) on setUpEventListenerNew action
            newGameStore.setGameIdNew(id);
            return newGameStore;
        }, [id, isVisible]);

        useEffect(() => {
            //Check thit moment - if we have id, will create socket again
            if (gameStore.gameId) {
                // gameStore.setUpEventListenerNew();
            }
        }, [gameStore.gameId]);

        if (gameStore) {
            return (
                <GameStoreContext.Provider value={{gameStore}}>
                    <>{children}</>
                </GameStoreContext.Provider>
            );
        }
    }
);
