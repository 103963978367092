import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import useStores from "src/hooks/useStores";
import { useStyles } from "./styles";
import { ITournamentPlayerNew } from "../../../../../../store/models";
import { toJS } from "mobx";
import { fileHelper } from "../../../../../../shared/helpers/FileHelper";

interface LeagueChatPanelProps {
    currentTab: string;
    showHideOpponent?: boolean;
}

const LeagueChatPanel: React.FC<LeagueChatPanelProps> = observer(({ currentTab, showHideOpponent }) => {
    const { gameStore, tournamentsStore } = useStores();
    const classes = useStyles();
    const [players, setPlayers] = useState<ITournamentPlayerNew[]>([]);
    const [balances, setBalances] = useState<Map<string, number>>(new Map());

    const playerPositions = useRef(new Map());
    const playerRefs = useRef(new Map());

    const tournamentId = gameStore?.currentGameNew?.tournamentId ?? null;

    const leagueTournament = useMemo(() => {
        const tournament = tournamentsStore.tournaments.find(t => t._id === tournamentId) || null;
        return tournament ? toJS(tournament) : null;
    }, [tournamentsStore.tournaments, tournamentId]);

    useEffect(() => {
        if (!leagueTournament || !leagueTournament.players) return;

        setPlayers(leagueTournament.players);

        const balanceMap = new Map<string, number>();
        leagueTournament.table?.forEach(entry => {
            if (entry.player?._id) {
                balanceMap.set(entry.player._id, entry.chips ?? 0);
            }
        });

        setBalances(balanceMap);
    }, [leagueTournament]);

    const savePlayerPositions = () => {
        playerRefs.current.forEach((el, playerId) => {
            if (el) {
                const rect = el.getBoundingClientRect();
                playerPositions.current.set(playerId, rect);
            }
        });
    };

    const applyPlayerAnimation = () => {
        playerRefs.current.forEach((el, playerId) => {
            if (el) {
                const prevRect = playerPositions.current.get(playerId);
                if (prevRect) {
                    const currentRect = el.getBoundingClientRect();
                    const deltaY = prevRect.top - currentRect.top;

                    if (Math.abs(deltaY) > 1) {
                        el.style.transform = `translateY(${deltaY}px)`;
                        el.style.transition = 'transform 0s';

                        requestAnimationFrame(() => {
                            el.style.transform = 'translateY(0)';
                            el.style.transition = 'transform 500ms ease';

                            setTimeout(() => {
                                el.style.transform = '';
                                el.style.transition = '';
                            }, 500);
                        });
                    }
                }
            }
        });
    };

    const steakBonus = leagueTournament?.tournamentSettings?.steakBonus ?? 0;
    const steakBonusStart = leagueTournament?.tournamentSettings?.steakBonusStart ?? 0;

    const sortedPlayers = useMemo(() => {
        savePlayerPositions();

        const sorted = [...players].sort((a, b) => {
            const balanceA = balances.get(a._id) ?? 0;
            const balanceB = balances.get(b._id) ?? 0;
            return balanceB - balanceA;
        });

        setTimeout(() => {
            applyPlayerAnimation();
        }, 0);

        return sorted;
    }, [players, balances]);

    return currentTab !== "league" ? null : (
        <div className={classes.leagueContainer}>
            <div className={classes.imageContainer}>
                <img
                    src={fileHelper.getMediaFileURL(leagueTournament?.imageURL || "")}
                    alt="Tournament Banner"
                    className={classes.tournamentImage}
                />
            </div>

            <div className={classes.leftPanel}>
                <div className={classes.scrollableList}>
                    {sortedPlayers.map((player, index) => {
                        const tableEntry = leagueTournament?.table.find(entry => entry.player._id === player._id);
                        const winLoseHistory = tableEntry?.winLoseHistory || [];
                        const balance = balances.get(player._id) ?? 0;

                        return (
                            <div
                                key={player._id}
                                className={classes.playerItem}
                                ref={el => playerRefs.current.set(player._id, el)}
                            >
                                <span className={classes.rank}>{index + 1}</span>
                                <div className={classes.playerInfo}>
                                    <span className={classes.playerName}>{player.nickname}</span>
                                    <span className={classes.playerRating}>
                                        Rating: {Math.round(player.rating ?? 1200)}
                                    </span>
                                </div>

                                <div className={classes.scoreContainer}>
                                    <div className={classes.scoreCirclesRow}>
                                        {winLoseHistory.map((num, i) => {
                                            const isWhite = Number(num) < 6;
                                            const isEligible = isWhite && Number(num) >= steakBonusStart;
                                            return (
                                                <span key={i} className={isEligible ? classes.scoreCircle : classes.emptyCircle}>
                                                    {isEligible ? steakBonus : ""}
                                                </span>
                                            );
                                        })}
                                    </div>

                                    <div className={classes.scoreNumbersRow}>
                                        {winLoseHistory
                                            .map((num, i) => {
                                                const isWhite = Number(num) < 6;
                                                return (
                                                    <span
                                                        key={i}
                                                        className={`${classes.scoreNumber} ${isWhite ? classes.whiteNumber : classes.redNumber}`}
                                                    >
                                                        {num}
                                                    </span>
                                                );
                                            })}
                                    </div>
                                </div>

                                <div className={classes.balanceBox}>
                                    <span className={classes.balance}>{balance}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});

export default memo(LeagueChatPanel);
