import { useState, useEffect } from "react";
import { io, Socket } from "socket.io-client";

import { Nullable } from "src/shared/types";

export const useSocketOpen = (params?: { token?: string; id?: string, lobby?: boolean }) => {
    const [host, setHost] = useState<Nullable<string>>();
    const [socket, setSocket] = useState<Socket | null>(null);
    const token = localStorage.getItem("t");

    useEffect(() => {
        let gameId: string | undefined = undefined;
        if (!params?.lobby) {
            if (location.href.includes("/game/")) {
                const gameForSearch = location.href.split("/").slice(-1)[0];
                gameId = gameForSearch;
            }
            if (params?.id) {
                gameId = params?.id;
            }
        }

        if (!host) return;

        const createQuery = token
            ? {
                  userKey: token,
                  place: 2,
                  gameId,
              }
            : {
                  place: 2,
                  gameId,
              };

        // console.log(createQuery, gameId);

        const t = io(host, {
            // forceNew: true,
            //withCredentials: true,
            // query: {
            //     Authorization: `Bearer ${params?.token}`,
            // },
            query: createQuery,
        });
        setSocket(t);
        return () => {
            if (socket) {
                socket.removeAllListeners();
                socket.disconnect();
                setSocket(null);
            }
        };
    }, [host, params?.id, token]);

    const initSocket = (host: Nullable<string>) => {
        setHost(host);
    };
    const disconnectSocket = () => {
        setHost(null);
        if (socket) {
            socket.removeAllListeners();
            socket.disconnect();
            setSocket(null);
        }
    };
    return [initSocket, socket, disconnectSocket] as [
        (host: Nullable<string>) => void,
        Socket,
        () => void
    ];
};
