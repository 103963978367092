import { makeStyles } from "@mui/styles";
import { appColors } from "src/consts/app-config";
import theme from "src/theme";

export const useStyles = makeStyles({
    mainLobbyFilterContainer: {
        background: "rgba(0, 0, 0, 0.6)",
        "& .MuiDialog-root": {
            top: "50px !important",
        },
        "& .MuiDialog-paper": {
            padding: 0,
            margin: 0,
            width: "100%",
            height: "auto",
            background: "rgba(0, 0, 0, 1)",
            overflow: "auto", 
            scrollbarWidth: "none", 
            msOverflowStyle: "none", 
            "&::-webkit-scrollbar": {
                width: "0px", 
                height: "0px",
                display: "none", 
            },
        },
        "& .MuiPaper-root": {
            background: "rgba(245, 245, 245, 0.05)",
            backdropFilter: "blur(5px)",
            // aspectRatio: "13 / 9",
        },
        "&.creator": {
            top: "50px !important",
            "& .MuiBackdrop-root": {
                top: "50px !important",
            },
        },
        "&.filter": {
            "& .MuiDialog-paper": {
                padding: 0,
                margin: 0,
                width: "100%",
                height: "fit-content",
                background: "rgba(0, 0, 0, 1)",
                [theme.breakpoints.up("lg")]: {
                    maxHeight: "800px",
                    height: "60dvh",
                    display: "flex",
                    minHeight: "470px",
                    width: "100%",
                    maxWidth: "600px",
                },
                [theme.breakpoints.down("lg")]: {
                    height: "100%",
                },
            },
            "& .MuiPaper-root": {
                background: "rgba(245, 245, 245, 0.05)",
                backdropFilter: "blur(5px)",
                // aspectRatio: "13 / 9",
                overflow: "auto",
            },
        },
        "& > div": {
            height: "100%",
            width: "100%",
        },
    },

    dialogExpanded: {
        "& .MuiDialog-paper": {
            [theme.breakpoints.up("lg")]: {
                height: "68dvh !important",
                minHeight: "575px !important",
            },
        },
    },

    dialogCollapsed: {
        "& .MuiDialog-paper": {
            [theme.breakpoints.up("lg")]: {
                height: "71dvh !important",
                minHeight: "470px !important",
            },
        },
    },

    dialogTransition: {
        "& .MuiDialog-paper": {
            transition: "height 0.25s ease-in-out, min-height 0.25s ease-in-out",
        },
    },


    dialogPaper: {
        border: "1px solid white",
        width: "100%",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 0,
        overflowY: "unset",
        maxWidth: "40% !important",
        padding: "24px !important",
        position: "fixed",
        // top: "25.5%",
        top: "15%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        "@media (max-height: 860px)": {
            top: "5%",
        },
        "@media (max-height: 740px)": {
            top: "5%",
        },
        [theme.breakpoints.down(1600)]: {
            maxWidth: "60% !important",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "800px !important",
            padding: "12px !important",
            border: "1px solid rgba(255, 255, 255, 0.15)",
            display: "grid",
            alignContent: "flex-start",
            alignItems: "flex-start",
            gridGap: 5,
        },
        [theme.breakpoints.down("lg")]: {
            maxWidth: "90% !important",
            padding: "12px !important",
            border: "1px solid rgba(255, 255, 255, 0.15)",
            display: "grid",
            alignContent: "flex-start",
            alignItems: "flex-start",
            gridGap: 5,
        },
        [theme.breakpoints.down("md")]: {
            maxWidth: "100% !important",
            padding: "8px !important",
            gridTemplateRows: "40px 1fr 48px",
        },
        "@media screen and (max-width: 1024px) and (orientation: portrait)": {
            top: "50px",
        },
    },

    boxGridWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gridGap: "8px",
        margin: 0,
        width: "100%",
        height: "auto", 
        padding: "8px",
        borderRadius: "8px",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        "&.filter": {
            height: "auto",
            [theme.breakpoints.down("lg")]: {
                height: "100%",
            },
        },
        "&.isFreePlay": {
            maxHeight: 'none',
            justifyContent: "flex-start",
        },
        [theme.breakpoints.down("lg")]: {
            maxHeight: 600,
        },
    },

    boxGridWrapperExpanded: {
        [theme.breakpoints.down("sm")]: {
            maxHeight: 640,
        },
    },

    topTitleBox: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "20px",
        color: "rgba(245, 245, 245, 0.9)",
        [theme.breakpoints.down("lg")]: {
            padding: "0px",
        },
        [theme.breakpoints.up(2400)]: {
            fontSize: "24px",
        },
        [theme.breakpoints.up(3000)]: {
            fontSize: "30px",
        },
        padding: "10px",
    },

    filterBox: {
        display: "flex",
        gridGap: "6px",
        fontSize: "20px",
        alignItems: "center",
    },

    cross: {
        width: 18,
        height: 18,
        position: "relative",
        cursor: "pointer",
        "&::before": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(45deg)",
            transform: "translate(-50%,-50%) rotate(45deg)",
            background: `${appColors.white}`,
        },
        "&::after": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(-45deg)",
            transform: "translate(-50%,-50%) rotate(-45deg)",
            background: `${appColors.white}`,
        },
        [theme.breakpoints.up(2400)]: {
            width: 21,
            height: 21,
        },
        [theme.breakpoints.up(3000)]: {
            width: 30,
            height: 30,
        },
        "&.plus": {
            "&::before": {
                content: '""',
                display: "block",
                width: "110%",
                height: "2px",
                position: "absolute",
                top: "50%",
                left: "50%",
                "-webkit-transform": "translate(-50%,-50%) rotate(90deg)",
                transform: "translate(-50%,-50%) rotate(90deg)",
                background: `${appColors.white}`,
            },
            "&::after": {
                content: '""',
                display: "block",
                width: "110%",
                height: "2px",
                position: "absolute",
                top: "50%",
                left: "50%",
                "-webkit-transform": "translate(-50%,-50%) rotate(0deg)",
                transform: "translate(-50%,-50%) rotate(0deg)",
                background: `${appColors.white}`,
            },
            [theme.breakpoints.up(2400)]: {
                width: 21,
                height: 21,
            },
            [theme.breakpoints.up(3000)]: {
                width: 30,
                height: 30,
            },
        },
    },

    dividerSpan: {
        width: "98%",
        dispaly: "inline-block",
        margin: "0px 5px",
        height: "1px",
        background: "rgba(255, 255, 255, 0.3)",
    },

    gridContainer: {
        width: "100%",
        height: "auto",
        "&.filter": {
            "&.buttons": {
                height: "fit-content",
                padding: "4px 0",
                gridGap: "8px",
                [theme.breakpoints.down("xl")]: {
                    height: "50px",
                },
                [theme.breakpoints.down("md")]: {
                    height: "40px",
                },
            },
        },
        "@media (max-height: 799px)": {
            "& .MuiGrid-item": {
                gap: "8px !important",
            }
        },
    },

    filterCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: '8px 0',
    margin: 0,
    borderRadius: "4px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    height: "42px",
    minHeight: "42px", 
    maxHeight: "42px", 
    fontSize: "18px",
    background: "rgba(0, 186, 108, 0.3)",
    color: "rgba(245, 245, 245, 0.9)",

    [theme.breakpoints.down("lg")]: {
        height: "42px",
        minHeight: "42px",
        maxHeight: "42px",
    },
    [theme.breakpoints.up("lg")]: {
        height: "42px",
        minHeight: "42px",
        maxHeight: "42px",
    },
    [theme.breakpoints.up(2400)]: {
        fontSize: "24px",
        height: "54px",
        minHeight: "54px",
        maxHeight: "54px",
    },
    [theme.breakpoints.up(3000)]: {
        fontSize: "30px",
        height: "64px",
        minHeight: "64px",
        maxHeight: "64px",
    },
    "&.amount": {
        gridGap: "6px",
        justifyContent: "flex-start",
        paddingLeft: "15px",
        [theme.breakpoints.down("lg")]: {
            paddingLeft: "5px",
        },
    },
    "&.duration": {
        gridGap: "6px",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
            paddingLeft: "5px",
        },
    },
    "&.match": {
        gridGap: "6px",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
            paddingLeft: "5px",
        },
    },
    "&.outlined": {
        outline: "1px solid rgba(255, 255, 255, 0.15)",
        background: "none",
        [theme.breakpoints.down("lg")]: {
            paddingLeft: "5px",
        },
    },
},

    lastItemCenteredCell: {
        justifyContent: 'center !important', 
    },

    coinIcon: {
        width: 16,
        aspectRatio: "1 / 1",
        [theme.breakpoints.up(2400)]: {
            width: 19,
        },
        [theme.breakpoints.up(3000)]: {
            width: 25,
        },
        "&.duration": {
            width: 24,
            aspectRatio: "1 / 1",
            [theme.breakpoints.up(2400)]: {
                width: 29,
            },
            [theme.breakpoints.up(3000)]: {
                width: 37,
            },
        },
    },

    textArea: {
        fontSize: "20px",
        [theme.breakpoints.up(2400)]: {
            fontSize: "24px",
        },
        [theme.breakpoints.up(3000)]: {
            fontSize: "30px",
        },
        "&.amount": {
            "&.contented": {
                textShadow: "0px 1.13216px 3.39647px rgba(0, 0, 0, 0.25)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                background:
                    "linear-gradient(180deg, #F8BF44 50%, #A16529 100%)",
                display: "inline !important",
                [theme.breakpoints.down("lg")]: {
                    fontSize: "14px",
                },
            },
            "&.outlined": {
                background: "none",
                color: "rgba(255, 255, 255, 0.3)",
                [theme.breakpoints.down("lg")]: {
                    fontSize: "14px",
                },
            },
        },
        "&.duration": {
            "&.contented": {
                color: "rgba(255, 255, 255, 0.9)",
            },
            "&.outlined": {
                color: "rgba(255, 255, 255, 0.3)",
            },
        },
        "&.match": {
            "&.contented": {
                color: "rgba(255, 255, 255, 0.9)",
                [theme.breakpoints.down("lg")]: {
                    fontSize: "14px",
                },
            },
            "&.outlined": {
                color: "rgba(255, 255, 255, 0.3)",
                [theme.breakpoints.down("lg")]: {
                    fontSize: "14px",
                },
            },
        },
    },

    createGameButtonBox: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
    },

    buttonStyles: {
        borderRadius: "8px",
        textAlign: "center",
        "&.resetButton": {
            width: "50%",
            background: "none",
            height: "100%",
            [theme.breakpoints.down("md")]: {
                padding: 0,
                fontSize: "16px",
            },
        },
        "&.applyButton": {
            width: "50%",
            height: "100%",
            [theme.breakpoints.down("md")]: {
                padding: 0,
                fontSize: "16px",
            },
        },
        "&.createButton": {
            width: "100%",
            height: "48px",
        },
    },
});
