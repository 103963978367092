import React, { useState, useEffect } from "react";
import starIcon from 'src/assets/icons/Star.svg';
import { useStyles } from "./styles";
import { useMediaQuery } from "@mui/material";
import useStores from "../../../../../hooks/useStores";

interface AvatarStarsBorderProps {
    size?: number;
    starsCount?: number; 
}

let globalStarsCount = 0;
const maxStars = 20;

export const addStarGlobal = (authStore) => {
    if (!authStore || !authStore.currentUser) return;

    const currentStars = authStore.currentUser.stars ?? 0;
    const newStarsCount = Math.min(currentStars + 1, maxStars); 

    authStore.currentUser.stars = newStarsCount; 

    window.dispatchEvent(new CustomEvent("star-added", { detail: { starsCount: newStarsCount } }));
};



export const AvatarStarsBorder: React.FC<AvatarStarsBorderProps> = ({
    size = 100,
    starsCount: externalStarsCount
}) => {
    const [localStarsCount, setLocalStarsCount] = useState(globalStarsCount);
    const isSmallScreen = useMediaQuery("(max-width: 1024px)");
    const starSize = isSmallScreen ? 15 : 14;
    const styles = useStyles();
    const numericSize = typeof size === 'number' ? size : 100;

    const { authStore } = useStores(); 

    useEffect(() => {
        setLocalStarsCount(externalStarsCount ?? 0);
    }, [externalStarsCount]);

    useEffect(() => {
        const handleStarAdded = (e: CustomEvent) => {
            setLocalStarsCount(e.detail.starsCount);

            const index = e.detail.starsCount - 1;
            const baseAngle = Math.PI / 2; // 90° → вниз
            const stepAngle = (1 / maxStars) * (Math.PI * 2); // Шаг распределения
            const angle = index % 2 === 0
                ? baseAngle - stepAngle * Math.ceil(index / 2) // Четные вправо
                : baseAngle + stepAngle * Math.ceil(index / 2); // Нечетные влево

            const radius = size / 2;
            const starX = radius * Math.cos(angle) + radius;
            const starY = radius * Math.sin(angle) + radius;

            const container = document.querySelector(".avatar-stars-border");
            if (container) {
                const rect = container.getBoundingClientRect();
                const finalX = rect.left + starX;
                const finalY = rect.top + starY;

                const starPositionEvent = new CustomEvent("new-star-position", {
                    detail: { x: finalX, y: finalY }
                });
                window.dispatchEvent(starPositionEvent);
            }
        };

        window.addEventListener("star-added", handleStarAdded as EventListener);
        return () => {
            window.removeEventListener("star-added", handleStarAdded as EventListener);
        };
    }, [size]);

    return (
        <div className={`${styles.avatarStarsBorder} avatar-stars-border`} style={{
            width: size,
            height: size,
        }}>
            {Array.from({ length: maxStars }).map((_, index) => {
                const baseAngle = Math.PI / 2; // 90° (начинаем снизу)
                const stepAngle = (1 / maxStars) * (Math.PI * 2);
                const angle = index % 2 === 0
                    ? baseAngle - stepAngle * Math.ceil(index / 2) // Четные вправо
                    : baseAngle + stepAngle * Math.ceil(index / 2); // Нечетные влево

                const radius = size / 2;
                const centerX = radius * Math.cos(angle);
                const centerY = radius * Math.sin(angle);
                const left = centerX + radius - starSize / 2;
                const top = centerY + radius - starSize / 2;

                const isVisible = index < localStarsCount;

                return (
                    <img
                        key={index}
                        src={starIcon}
                        alt="Border Star"
                        style={{
                            position: 'absolute',
                            left: `${left}px`,
                            top: `${top}px`,
                            width: `${starSize}px`,
                            height: `${starSize}px`,
                            opacity: isVisible ? 1 : 0,
                            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                            transform: isVisible ? 'scale(1)' : 'scale(0.8)',
                        }}
                    />
                );
            })}
        </div>
    );
};

interface FlyingStarProps {
    start: { x: number; y: number };
    end: { x: number; y: number };
    onComplete?: () => void;
}

const FlyingStar: React.FC<FlyingStarProps> = ({ start, end, onComplete }) => {
    const [visible, setVisible] = useState(true);
    const [animationState, setAnimationState] = useState({
        position: { x: start.x, y: start.y },
        opacity: 0,
        particleIntensity: 0,
        trailWidth: 6,
        trailLength: 60,
        blur: 5
    });
    const { authStore } = useStores(); 

    const offsetX = -150;

    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);
    
    const particles = [
        { size: 2, offset: 10, delay: 0.1, opacity: 0.9 },
        { size: 3, offset: 20, delay: 0.15, opacity: 0.8 },
        { size: 2, offset: 30, delay: 0.2, opacity: 0.7 },
        { size: 1, offset: 40, delay: 0.25, opacity: 0.6 },
        { size: 2, offset: 50, delay: 0.3, opacity: 0.5 },
    ];

    useEffect(() => {
        const appearStart = setTimeout(() => {
            setAnimationState(prev => ({
                ...prev,
                opacity: 1,
                particleIntensity: 1,
                trailLength: 70
            }));
        }, 30);

        const animationStart = setTimeout(() => {
            setAnimationState(prev => ({
                ...prev,
                position: { 
                    x: end.x, 
                    y: end.y 
                },
                particleIntensity: 1.2,
                trailWidth: 8,
                trailLength: 80
            }));
        }, 100);

        const animationComplete = setTimeout(() => {
            
            setVisible(false);
            
            try {
                addStarGlobal(authStore); 
            } catch (error) {
                console.error("Error adding star:", error);
            }

            if (onComplete) {
                setTimeout(() => onComplete(), 50);
            }
            
        }, 400); 

        return () => {
            clearTimeout(appearStart);
            clearTimeout(animationStart);
            clearTimeout(animationComplete);
        };
    }, [end, onComplete]);

    if (!visible) return null;

    return (
        <div style={{
            position: "fixed",
            left: `${start.x}px`,
            top: `${start.y}px`,
            pointerEvents: "none",
            zIndex: 9999,
            opacity: animationState.opacity,
            transform: `translate(
                ${animationState.position.x - start.x}px, 
                ${animationState.position.y - start.y}px
            ) rotate(${angle}deg)`,
            transformOrigin: "center center",
            transition: "transform 0.6s ease-out, opacity 0.2s ease-in-out"
        }}>
            {/* Основной след (градиент) */}
            <div style={{
                position: "absolute",
                width: `${animationState.trailLength}px`,
                height: `${animationState.trailWidth}px`,
                background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,215,0,0.4) 40%, rgba(255,255,255,0.9) 100%)",
                borderRadius: "50px",
                filter: `blur(${animationState.blur}px)`,
                right: "0px",
                top: `-${animationState.trailWidth/2}px`,
                transformOrigin: "right center",
                zIndex: 9999,
                transition: "width 0.6s ease-out, height 0.6s ease-out, opacity 0.2s ease-in-out, filter 0.3s ease-out"
            }} />
            
            {/* Блестящие частицы вдоль следа */}
            {particles.map((particle, index) => (
                <div 
                    key={index}
                    style={{
                        position: "absolute",
                        width: `${particle.size * animationState.particleIntensity}px`,
                        height: `${particle.size * animationState.particleIntensity}px`,
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        boxShadow: "0 0 3px 1px rgba(255,215,0,0.7)",
                        right: `${particle.offset}px`,
                        top: `${Math.sin(index * 2) * (animationState.trailWidth/3)}px`,
                        opacity: particle.opacity * animationState.particleIntensity,
                        transition: `all 0.6s ease-out ${particle.delay}s`,
                        zIndex: 10000
                    }}
                />
            ))}
            
            {/* Яркая точка в конце следа (лидирующая частица) */}
            <div style={{
                position: "absolute",
                width: `${4 * animationState.particleIntensity}px`,
                height: `${4 * animationState.particleIntensity}px`,
                borderRadius: "50%",
                background: "rgba(255, 255, 255, 1)",
                boxShadow: "0 0 5px 3px rgba(255,215,0,0.8)",
                right: "0px",
                top: "0px",
                zIndex: 10001,
                transition: "width 0.6s ease-out, height 0.6s ease-out, box-shadow 0.3s ease-out"
            }} />
        </div>
    );
};

export default FlyingStar;