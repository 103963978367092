import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import useCalcTableLabel from "src/hooks/useCalcTableLabel";
import {
    ETournamentStatus,
    GameModeType,
    GameStatus,
    GameType,
    UserBalanceType,
    ITournament
} from "src/store/models";
import blurOnHover from "src/pages/lobbyNew/assets/backgrounds/backgroundBlurWild.svg";
import gameStore from "../../../../../store/gameStore";
import trophyIcon from "src/assets/icons/trophyGreen.svg";
import blueTrophyIcon from "src/assets/icons/blueTrophy.svg";
import generateTournamentBracket from "../../../../../pages/tournaments/components/bracketContainerNew/generateTournamentBracket";
import {boardRoot} from "../../../../../core/endpoints";

const DesktopNav = ({ styles, isLanding }) => {
    const {
        header: { cashBattle, lobbyFreePlay, tournaments, allSlots, allDealers, Soon },
        tournaments: {
          bracket: {
            stagesTitles: { final, semiFinal, thrdPlaceMatch, rTitle, crTitle, gfTitle, cfTitle }
        },
      },
    } = useLocale();
    const { generalStore, tournamentsStore, lobbyStore, gamesStore, gameStore, authStore } = useStores();
    const {
        diceChessLobby,
        lobbyFreePlay: lobbyFreePlayPath,
        tournaments: tournamentPath,
        algaltente,
        liveDealer: liveDealerPath,
    } = paths;
    const currentLocation = useLocation();
    const activeLobbyGames = lobbyStore.getActiveGamesDot();

    const showReturnToTournament = tournamentsStore.hasActiveGames;


    // const isCashBattle = useMemo(() => {
    //     if (activeLobbyGames) {
    //         return activeLobbyGames.filter(
    //             (game) => game.balanceType === UserBalanceType.play
    //         );
    //     }
    // }, [activeLobbyGames]);

    // const isFreePlay = useMemo(() => {
    //     if (activeLobbyGames) {
    //         return activeLobbyGames.filter(
    //             (game) => game.balanceType === UserBalanceType.coins
    //         );
    //     }
    // }, [activeLobbyGames]);

    // const isCashBattleDot = useMemo(() => {
    //     if (!isCashBattle) return false;
    //     return (
    //         activeLobbyGames &&
    //         activeLobbyGames?.length > 0 &&
    //         !!isCashBattle.length &&
    //         !currentLocation.pathname.includes(isCashBattle[0].id) &&
    //         !currentLocation.pathname.includes(paths.diceChessLobby)
    //     );
    // }, [activeLobbyGames, isCashBattle]);

    const isCashBattle = useMemo(() => {
        return !!activeLobbyGames.length;
    }, [activeLobbyGames]);

    const isCashBattleDot = useMemo(() => {
        if (!isCashBattle) return false;
        return (
            isCashBattle &&
            activeLobbyGames &&
            activeLobbyGames[0]?._id &&
            !currentLocation.pathname.includes(activeLobbyGames[0]?._id) &&
            !currentLocation.pathname.includes(paths.diceChessLobby)
        );
    }, [activeLobbyGames, isCashBattle]);

    // const isFreePlayDot = useMemo(() => {
    //     if (!isFreePlay) return false;
    //     return (
    //         activeLobbyGames &&
    //         activeLobbyGames?.length > 0 &&
    //         !!isFreePlay.length &&
    //         !currentLocation.pathname.includes(isFreePlay[0].id) &&
    //         !currentLocation.pathname.includes(paths.lobbyFreePlay)
    //     );
    // }, [activeLobbyGames, isFreePlay]);

    const isFreePlayDot = useMemo(() => {
        return false;
    }, [activeLobbyGames]);

    const isTournamentDot = useMemo(() => {
        const isNotInGamePage = tournamentsStore.myGamesList.every(
            (g) => !currentLocation.pathname.includes(g?.id)
        );
        return (
            showReturnToTournament &&
            !isNotInGamePage &&
            !currentLocation.pathname.includes(paths.tournaments)
        );
    }, [showReturnToTournament, tournamentsStore.myGamesList]);

    const links: React.ReactNode[] = [];
    const isHalyk = generalStore.isHalyk;

    // для поиска текущей игры пользователя
    const findCurrentUserGame = (tournamentsStore, gamesStore, authStore) => {
        const userId = authStore.currentUser?._id;
        if (!userId) return null;

        // Найти активный турнир, в котором участвует пользователь
        const activeTournament = tournamentsStore.tournaments?.find(
            (tournament) =>
                tournament.status === ETournamentStatus.RUNNING &&
                tournament.players.some((player) => player._id === userId)
        );

        if (!activeTournament) {
            return null;
        }


        // Проверить, есть ли пользователь в незавершённых парах
        const activePair = activeTournament.pairs?.find(
            (pair) =>
                !pair.isFinished &&
                (pair.player1?._id === userId || pair.player2?._id === userId)
        );

        if (!activePair) {
            return null;
        }

        // Проверить статус игры
        const game = gamesStore.getGameStoreById(activePair.gameId);
        if (!game) {
            return null;
        }


        if (game.status === GameStatus.FINISHED) {
            return null;
        }

        return activePair.gameId;
    };


    const currentUserGameId = useMemo(
        () => findCurrentUserGame(tournamentsStore, gamesStore, authStore),
        [tournamentsStore.tournaments, authStore.currentUser]
    );

    const history = useHistory();
    const [realPath, setRealPath] = useState(window.location.pathname);
    const [isOnSlotGamePage, setIsOnSlotGamePage] = useState(realPath.startsWith("/slots/") && realPath.includes("/play"));

   const location = useLocation();  // Уже есть в вашем коде как currentLocation

    // Добавьте эту строку для отслеживания пути через useLocation
    useEffect(() => {
        const isSlotGame = location.pathname.startsWith("/slots/") && location.pathname.includes("/play");
        setIsOnSlotGamePage(isSlotGame);

        // Если мы попали на страницу игры, можно выполнить дополнительные действия
        if (isSlotGame) {
            // Возможно, обновить какие-то данные в сторе или выполнить другие действия
            const slotId = location.pathname.split('/')[2]; // Получаем ID игры из пути

            // Если нужно обновить что-то в store
            // generalStore.setCurrentSlotId(slotId);
        }
    }, [location.pathname]); //


    if (generalStore.gameType === GameType.dicechess && !isLanding) {
        if (!isHalyk) {
            links.push(
                <Box
                    key="tournaments"
                    component="div"
                    className={cn(styles.navMenuItemBox)}
                >
                    <NavLink
                        to={tournamentPath}
                        className={cn(
                            styles.navMenuItem,
                            currentLocation.pathname === tournamentPath && styles.navMenuActive
                        )}
                    >
                        {tournaments("Tournaments")}
                    </NavLink>
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    {isTournamentDot && <div className={cn(styles.gameInfoDot)}></div>}
                </Box>
            );

            links.push(
                <Box
                    key="cashBattle"
                    component="div"
                    className={cn(styles.navMenuItemBox, {
                        gameInfoDot: isCashBattleDot,
                    })}
                >
                    <NavLink
                        to={diceChessLobby}
                        className={cn(styles.navMenuItem)}
                        activeClassName={cn(styles.navMenuActive)}
                    >
                        {cashBattle("Cash Battle")}
                    </NavLink>
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    {isCashBattleDot && <div className={cn(styles.gameInfoDot)}></div>}
                </Box>
            );            


            const pathParts = location.pathname.split('/');
            const isGamePage = pathParts.length > 2 && pathParts[1] === 'game';
            const gameId = isGamePage ? pathParts[2] : null;

            const gameStore = gameId ? gamesStore?.getGameStoreById(gameId) : null;
            const tournamentData = gameStore?.currentGameNew;
            const userTournament = tournamentsStore?.tournaments?.find(t => t.players.some(p => p._id === authStore.currentUser?._id));

            const getSingleBracketRound = (tournament, userId) => {
                if (!tournament || !tournament.pairs || tournament.pairs.length === 0) {
                    return '';
                }

                const maxStep = Math.max(...tournament.pairs.map(p => p.step));
                const matchesInRound = tournament.pairs.filter(p => p.step === maxStep);
                const playersInRound = matchesInRound.length;
                const previousRoundMatches = tournament.pairs.filter(p => p.step === maxStep - 1).length;


                if (playersInRound === 2 && previousRoundMatches === 2) {
                    const [match1, match2] = matchesInRound;


                    const isUserInMatch1 = match1 && (match1.player1?._id === userId || match1.player2?._id === userId);
                    const isUserInMatch2 = match2 && (match2.player1?._id === userId || match2.player2?._id === userId);

                    // Если игрок участвует в первом матче → считаем его финалом
                    if (isUserInMatch1) {
                        return final("Final");
                    }

                    // Если игрок участвует во втором матче → это игра за 3-е место
                    if (isUserInMatch2) {
                        return thrdPlaceMatch("3rd place");
                    }

                    return '';
                }

                // Обычный финал (без матча за 3-е место)
                if (playersInRound === 1 && previousRoundMatches === 2) {
                    return final("Final");
                }

                const titleStage = new Map([
                    [1, final("Final")],
                    [2, semiFinal("Semi-final")]
                ]);

                if (titleStage.has(playersInRound)) {
                    return titleStage.get(playersInRound);
                }

                return `1/${playersInRound}`;
            };



            const getDoubleBracketRound = (tournament: ITournament, userId: string): string => {
                // Проверяем базовые условия
                if (!tournament?.pairs?.length || !userId) return '';

                // Находим текущую активную пару игрока
                const currentPair = tournament.pairs.find(pair =>
                    !pair.isFinished &&
                    (pair.player1?._id === userId || pair.player2?._id === userId)
                );

                if (!currentPair) return '';

                // 1. Расчет структуры сетки
                // Находим все пары первого раунда верхней сетки
                const firstRoundPairs = tournament.pairs.filter(item =>
                    item.step === 1 && !item.sub
                ) || [];
                // Получаем максимальное количество игроков
                const maxPlayers = firstRoundPairs.length * 2;

                // 2. Создаем карту структуры турнира
                const bracketStructure = new Map<number, { main: number; lower: number }>();

                // 3. Расчет структуры основной (верхней) сетки
                let mainBracketPlayers = maxPlayers;
                let round = 1;

                // Заполняем количество пар в каждом раунде верхней сетки
                while (mainBracketPlayers >= 1) {
                    bracketStructure.set(round, {
                        main: mainBracketPlayers / 2,  // количество пар в верхней сетке
                        lower: 0                       // пока что нижняя сетка пустая
                    });
                    mainBracketPlayers = Math.floor(mainBracketPlayers / 2);
                    round++;
                }

                // 4. Расчет структуры нижней сетки
                Array.from(bracketStructure.entries()).forEach(([step, counts]) => {
                    if (step > 1) {
                        const prevCounts = bracketStructure.get(step - 1);
                        if (prevCounts) {
                            // Проигравшие из верхней сетки
                            const losersFromUpper = Math.floor(prevCounts.main / 2);
                            // Выжившие из нижней сетки
                            const survivorsFromLower = Math.floor(prevCounts.lower / 2);
                            // Общее количество пар в нижней сетке на этом шаге
                            counts.lower = losersFromUpper + survivorsFromLower;
                            bracketStructure.set(step, counts);
                        }
                    }
                });

                // 5. Определение текущего раунда

                // Разделяем пары на верхнюю и нижнюю сетки
                const upperBracketPairs = tournament.pairs?.filter(p => !p.sub);
                const lowerBracketPairs = tournament.pairs?.filter(p => p.sub);
                const maxLowerStep = Math.max(...lowerBracketPairs.map(p => p.step));
                const lowerFinal = lowerBracketPairs.find(p => p.step === maxLowerStep);
                const unfinishedLowerPairs = lowerBracketPairs.filter(p => !p.isFinished);

                // Гранд-финал это следующий матч после финала нижней сетки
                const isGrandFinal =
                    // Текущий матч не в нижней сетке
                    !currentPair.sub &&
                    // Финал нижней сетки существует и завершен
                    lowerFinal?.isFinished &&
                    // Текущий матч начался после финала нижней сетки
                    currentPair.step > lowerFinal.step;

                if (isGrandFinal) {
                    return gfTitle('Grand Final');
                }
                
                // Если игрок в нижней сетке (sub === true)
                if (currentPair.sub) {
                    // Группируем пары по шагам, чтобы определить количество пар в каждом раунде
                    const pairsByStep = new Map<number, number>();
                    lowerBracketPairs.forEach(pair => {
                        const count = pairsByStep.get(pair.step) || 0;
                        pairsByStep.set(pair.step, count + 1);
                    });

                    // Считаем текущий раунд по количеству пар
                    const currentRoundPairsCount = pairsByStep.get(currentPair.step) || 0;

                    // Определяем номер раунда по позиции в последовательности уникальных шагов
                    const uniqueSteps = Array.from(pairsByStep.keys()).sort((a, b) => a - b);
                    const roundIndex = uniqueSteps.indexOf(currentPair.step);

                    // Проверяем, что это не первый раунд нижней сетки
                    const isLastSinglePairRound = roundIndex > 0 && currentRoundPairsCount === 1 &&
                        Array.from(pairsByStep.entries())
                            .filter(([step, count]) => step > currentPair.step)
                            .every(([_, count]) => count >= currentRoundPairsCount);

                    if (isLastSinglePairRound) {
                        return cfTitle('Cellar final');
                    }


                    // Определяем номер раунда по позиции в последовательности уникальных шагов
                    return `${crTitle('C-Round')} ${roundIndex + 1}`;
                }

                // Для верхней сетки
                return `${rTitle('Round')} ${currentPair.step}`;
            };

            const getCurrentRound = (tournament, userId) => {
                if (!tournament?.pairs?.length || !userId) return '';

                return tournament.type === 2
                    ? getDoubleBracketRound(tournament, userId)
                    : getSingleBracketRound(tournament, userId);
            };

            const roundTitle = getCurrentRound(userTournament, authStore.currentUser?._id);

            links.push(
                <Box
                    component="div"
                    className={cn(styles.navMenuItemBox)}
                >
                    
                    {/*{isGamePage && tournamentData?.tournament?.title ? (*/}
                    {/*    <NavLink*/}
                    {/*        to={`/tournaments/${tournamentData.tournament._id}`}*/}
                    {/*        className={cn(*/}
                    {/*            styles.navMenuItem,*/}
                    {/*            (location.pathname.includes(`/tournaments/${tournamentData.tournament._id}`) ||*/}
                    {/*                location.pathname.includes(`/game/`)) && styles.navMenuActive*/}
                    {/*        )}*/}
                    {/*    >*/}
                    {/*        {tournamentData.tournament.title}*/}
                    {/*    </NavLink>*/}
                    {/*) : currentUserGameId ? (*/}
                    {/*    <NavLink*/}
                    {/*        to={`/game/${currentUserGameId}`}*/}
                    {/*        className={cn(*/}
                    {/*            styles.navMenuItem,*/}
                    {/*            (location.pathname.includes(`/game/${currentUserGameId}`) ||*/}
                    {/*                location.pathname.includes(`/tournaments/`)) && styles.navMenuActive*/}
                    {/*        )}*/}
                    {/*    >*/}
                    {/*        <img src={trophyIcon} alt="Trophy" className={styles.iconActive} />*/}
                    {/*        <span>{getCurrentRound(userTournament)}</span>*/}
                    {/*    </NavLink>*/}
                    {/*) : null}*/}
                    

                    {/*Дубликат блока без активного/неактивного состояний*/}
                    {isGamePage && tournamentData?.tournament?.title ? (
                        <NavLink
                            to={`/tournaments/${tournamentData.tournament._id}`}
                            className={styles.navMenuItem}
                            style={{ color: '#83B5FF' }} 
                        >
                            {tournamentData.tournament.title}
                        </NavLink>
                    ) : currentUserGameId ? (
                        <a
                            // to={`/game/${currentUserGameId}`}
                            href={boardRoot + `/game/${currentUserGameId}?userKey=${localStorage.getItem("t")}&currentUserId=${authStore.currentUser?._id}`}
                            className={styles.navMenuItem}
                            style={roundTitle.length > 6 ? 
                              { color: '#83B5FF', fontSize: '14px', letterSpacing: '-0.5px'} : 
                              { color: '#83B5FF'}
                            }  
                        >
                            <img src={blueTrophyIcon} alt="Trophy" className={styles.iconActive} />
                            <span>{roundTitle}</span>
                        </a>
                    ) : null}
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    {isTournamentDot && <div className={cn(styles.gameInfoDot)}></div>}
                </Box>
            );
        }
    }

    if (isOnSlotGamePage) {
        if (generalStore.gameType === GameType.slots) {
            links.push(
                <Box
                    component="div"
                    className={cn(styles.navMenuItemBox, {})}
                >
                    <NavLink
                        to={algaltente}
                        className={cn(styles.navMenuItem, {})}
                        activeClassName={cn(styles.navMenuActive)}
                    >
                        {allSlots("All slots")}
                        <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    </NavLink>
                </Box>
            );
        }
    }


    if (generalStore.gameType === GameType.liveDealer) {
        links.push(
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={liveDealerPath}
                    className={cn(styles.navMenuItem, {})}
                    activeClassName={cn(styles.navMenuActive)}
                >
                    {allDealers("All dealers")}
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                </NavLink>
            </Box>
        );
    }

    return <div className={styles.navMenu}>{links}</div>;
};

export default observer(DesktopNav);