import { observer } from 'mobx-react';
import cn from 'classnames';
import { Box } from '@mui/material';

import theme from 'src/theme';
import ModalStatusWrapper from 'src/assets/icons/ModalStatusWrapper';
import ModalStatusWrapperMobile from 'src/assets/icons/ModalStatusWrapperMobile';
import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

type IModalUserStatusBox = {
    status?: string; 
    label?: string;
    placePosition?: string;
};

const ModalUserStatusBox = ({ status, label, placePosition }: IModalUserStatusBox) => {
    console.log('ModalUserStatusBox props:', { status, label, placePosition });


    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const isTablet = theme.breakpoints.values.md;
    const width = window.innerWidth;

    if (!status) {
        console.log('No status provided to ModalUserStatusBox');
        return null;
    }

    const statusText = placePosition === 'fastGame' ? status : `Статус: ${status}`;


    return (
        <Box
            className={cn(
                styles.modalStatusBox,
                appearanceTheme,
                label,
                placePosition
            )}
        >
            {width > isTablet ? (
                <ModalStatusWrapper />
            ) : (
                <ModalStatusWrapperMobile />
            )}
            <p
                className={cn(
                    styles.statusParagraphText,
                    appearanceTheme,
                    label,
                    placePosition
                )}
            >
                {statusText}
            </p>
        </Box>
    );
};

export default observer(ModalUserStatusBox);
