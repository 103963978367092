import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    list: {
        // listStylePosition: 'inside',
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 10,
        margin: 0,
        padding: '0 0 0 7px',
        gap: 8,
    },
});
