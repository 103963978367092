import { makeStyles } from "@mui/styles";
import { appColors } from "src/consts/app-config";

import theme from "src/theme";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes runningLine": {
            "0%": {
                transform: "translateX(0px)",
            },
            "90%": {
                transform: "translateX(var(--animation-bias))",
            },
            "100%": {
                transform: "translateX(0px)",
            },
        },
    },

    nicknameWrapper: {
        textAlign: "left",
        padding: 0,
        fontSize: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
        [theme.breakpoints.up(1023)]: {
            textAlign: "center",
            "@media (orientation: landscape)": {
                textAlign: "left",
                "&.isLargeNick": {
                    textAlign: "left",
                },
            },
            "@media (orientation: portrait)": {
                textAlign: "left",
                "&.isLargeNick": {
                    textAlign: "left",
                },
            },
        },
        [theme.breakpoints.down("lg")]: {
            justifyContent: "flex-start",
        },
        "&.isMultiTable": {
            textAlign: "center!important",
        },
    },

    playerName: {
        width: "100%",
        "&.isMultiTable": {
            display: "flex",
            paddingRight: 5,
        },
        [theme.breakpoints.down("lg")]: {
            overflow: "hidden",
            width: "90%",
            maxWidth: "280px",
        },
    },

    playerNameParagraph: (props: { bias: number | null }) => ({
        fontSize: 22,
        display: "inline-block",
        // width: "100%",
        opacity: 0.9,
        margin: "0 auto",
        fontFamily: "Montserrat",
        fontWeight: 400,
        color: appColors.semiwhite,
        whiteSpace: "nowrap",
        wordBreak: "initial",
        "&.deepBlack": {
            fontFamily: "Montserrat",
            fontWeight: 400,
            color: appColors.semiwhite,
            wordBreak: "initial",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 18,
            lineHeight: "18px",
        },
        [theme.breakpoints.down("sm")]: {
            // width: "100%",
            width: "fit-content",
        },
        [theme.breakpoints.down(380)]: {
            fontSize: "4vw",
            lineHeight: "4vw",
        },
        "&.isMultiTable": {
            fontSize: 18,
            lineHeight: "18px",
            textAlign: "start",
            [theme.breakpoints.down(1700)]: {
                fontSize: 16,
                lineHeight: "16px",
            },
            [theme.breakpoints.down(1500)]: {
                fontSize: 14,
                lineHeight: "14px",
            },
            "@media screen and (max-height: 900px)": {
                fontSize: 13,
                lineHeight: "13px",
            },
        },
        "&.isLargeNick": {
            // fontWeight: 800,
            [theme.breakpoints.between(1023, MAX_CHANGE_SCREEN_WIDTH)]: {
                fontSize: "14px",
                // maxWidth: "160px",
            },
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                fontSize: "14px",
                // maxWidth: "100%",
            },
            [theme.breakpoints.up("xl")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up(1800)]: {
                fontSize: "24px",
            },
        },
        // "&.isOpponent": {
        //     [theme.breakpoints.between("xs", "md")]: {
        //         "@media (orientation: portrait)": {
        //             maxWidth: "100%",
        //             width: "100%",
        //         },
        //     },
        //     [theme.breakpoints.up("md")]: {
        //         "@media (orientation: portrait)": {
        //             width: "100%",
        //         },
        //     },
        // },
        "&.runAnimation": {
            "--animation-bias": `${props.bias}px`,
            width: "fit-content",
            transform: "translateX(0px)",
            animationName: "runningLine",
            animationDelay: "0.2s",
            animationIterationCount: "infinite",
            animationDuration: "14000ms",
            animationTimingFunction: "ease-in-out",
            "&:hover": {
                animation: "none",
            },
        },
    }),
});
