import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "../../../../theme";

export const useStyles = makeStyles({
    wrapperRanks: {
        //background: 'rgba(255, 255, 255, 0.05)',
        border: '1px solid #FFFFFF4D',
        borderRadius: 16,
        overflow: 'hidden',
        padding: '0 0.4vw',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        //[theme.breakpoints.down('xl')]: {
        //    height: '90vw',
        //    margin: '2vw 0',
        //},
        //[theme.breakpoints.between(1200, 1600)]: {
        //    height: '33vw',
        //    margin: '0.5vw 0',
        //},
        [theme.breakpoints.down('sm')]: {
            height: '100vw',
            margin: '2vw',
            marginBottom: '18vw',
        },
    },
    tableContainer: {
        overflowY: 'scroll',
        flex: 1,
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    rankTable: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 0.7vw',
    },
    headerTable: {
        borderSpacing: '0 0.4vw',
    },
    tableHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        '& th': {
            padding: '0.6vw 1.6vw 0 1.6vw',
            fontFamily: 'Montserrat',
            fontSize: 15,
            fontWeight: 700,
            color: 'rgba(245, 245, 245, 0.9)',
            borderBottom: 'none',
            textAlign: 'center',
            width: '25%',
            '&:first-child': {
                textAlign: 'left',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 11,
            },

        },
    },
    tableBody: {
        '& td': {
            padding: '0.6vw 1.6vw',
            fontFamily: 'Montserrat',
            fontSize: 15,
            fontWeight: 700,
            color: 'rgba(245, 245, 245, 0.8)',
            border: 'none',
            textAlign: 'center',
            width: '25%',
            '&:first-child': {
                textAlign: 'left',
            },
        },
    },
    firstColumnContent: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    rankRow: {
        background: 'rgba(30, 30, 30, 0.6)',
        borderRadius: '8px',
        '& td': {
            padding: '0.6vw 0 0.6vw 1vw',
            fontFamily: 'Montserrat',
            fontSize: 15,
            fontWeight: 700,
            color: 'rgba(245, 245, 245, 0.8)',
            border: 'none',
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
            },
        },
        '& td:first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '& td:last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        },
        '&:hover': {
            '& td': {
                color: '#00BA6C',
            },
            '& td:last-child': {
                color: 'rgba(245, 245, 245, 0.8)',
            },
        },
    },
    coinAmount: {
        background: 'linear-gradient(180deg, #FF9604 -0.31%, #FFE522 11.9%, #FFFFC1 36.23%, #FFBB1B 65.11%, #E28800 65.12%, #E9A600 88.87%)',
        '-webkit-background-clip': 'text',
        'background-clip': 'text',
        color: 'transparent',
        display: 'inline-block',
        fontWeight: 'bold',
    },
    rankIcon: {
        marginRight: '0.5vw',
        verticalAlign: 'middle',
    },
    bonusIcon: {
        marginRight: '0.2vw',
        verticalAlign: 'middle',
        width: '1vw',
        [theme.breakpoints.down('sm')]: {
            width: '4vw',
        },
    },
    icon: {
        width: 25,
        marginRight: '18px',
    },
    rankTitle: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    highlightedRow: {
        background: 'rgba(0, 186, 108, 0.1)',
        border: '2px solid rgba(0, 186, 108, 1) !important',
        '& td': {
            color: '#00BA6C',
        },
        '& td:last-child': {
            color: 'rgba(245, 245, 245, 0.8)',
        },
    },
});
