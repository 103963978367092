import { rankIconsList } from "src/components/Chessmen/SvgIcons";
import { appColors } from "src/consts/app-config";
import { useRanksTranslations } from "src/hooks/useRankData";
import { IRank } from "src/store/models";

export const useRankSettings = (currentIndex: number) => {
    const rankSystemTranslations = useRanksTranslations();

    const getRankSettings = (rank: IRank, index: number) => {
        const lowerCaseTitle = rank.title.toLowerCase();
        const isCurrentRank = index === currentIndex;
        const isReached = index < currentIndex;
        const iconInfo = rankIconsList[lowerCaseTitle];
        const icon = iconInfo?.contained;
        const label = rankSystemTranslations[lowerCaseTitle]?.toUpperCase();
        const textColor = isCurrentRank ? appColors.white : iconInfo.color;
        const iconColor = iconInfo.color;
        const threshold = rank.threshold ? `${rank.threshold}` : "-";
        const rakeBack = rank.rakeBack + "%";

        return {
            icon,
            isReached,
            isCurrentRank,
            label,
            textColor,
            iconColor,
            threshold,
            rakeBack,
        };
    };

    return { getRankSettings };
};
