import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    title: {
        fontSize: 20,
        fontWeight: 600,
        color: 'transparent', 
        background: 'linear-gradient(180deg, #FF9604 -0.31%, #FFE522 11.9%, #FFFFC1 36.23%, #FFBB1B 65.11%, #E28800 65.12%, #E9A600 88.87%)',
        backgroundClip: 'text', 
        WebkitBackgroundClip: 'text', 
        WebkitTextFillColor: 'transparent', 
        display: 'inline-block', 
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
});
