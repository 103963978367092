import { EColor } from "src/components/types";

export const getButtonColor = (appearanceTheme, buttonType) => {
    if (appearanceTheme === EColor.darkBlue) return buttonType.primary;
    if (appearanceTheme === EColor.darkGreen) return buttonType.purple;
    return buttonType.primary;
};


export const replaceDomain = (list: string[]) => {
    return list.map(str => str.replace(/beturanga\.com/g, document.location.host))
}

const oldHref = document.location.href;
const oldHrefTime = Date.now();
let redirected = 0;
export const goTo = (url: string) => {

    if (Date.now() - redirected < 5000 || document.location.href === url || oldHref === url && Date.now() - oldHrefTime < 3000) return;

    redirected = Date.now();

    document.location.href = url;
}