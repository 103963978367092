import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import useCalcTableLabel from "src/hooks/useCalcTableLabel";
import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import {
    ColorVariant,
    IPlayer,
    GameModeType,
    GameModeTypeNew,
    TournamentPair,
    ETournamentType,
    ITournamentsNew,
} from "src/store/models";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new2/constants";
import { ChatExistPosition } from "src/store/models";
import { appColors } from "src/consts/app-config";
import { useStyles } from "./styles";
import { BPIcon, WPIcon } from "../fastChessboard/piece-memos-deepBlack";

interface IGameScore {
    stage?: number;
    title?: string;
    winner?: string;
    isView?: boolean;
    elementPosition?: ChatExistPosition;
    className?: string; 
    forceDirection?: 'row' | 'column';

}


interface RoundInfo {
    title: string;
    bracketType: 'upper' | 'lower' | 'grand' | 'single';
}

const GameScoreRedesign = ({
    stage,
    title,
    winner,
    elementPosition,
    isView = false,
    forceDirection
}: IGameScore) => {
    const {
        tournaments: {
            titles: {
                detailsTab: { upTo },
            },
        },
        game: { pot, match },
    } = useLocale();
    const history = useHistory();
    const { gameStore, tournamentsStore } = useStores();
    const currentTournament = gameStore.currentGameNew?.tournament;
    const {
        tournaments: {
            bracket: {
                stagesTitles: { final, semiFinal, thrdPlaceMatch, rTitle, crTitle, cfTitle, gfTitle }
            },
            ubTitle,
            cbTitle,
        },
    } = useLocale();
    const viewMode = gameStore?.isViewMode();
    const location = useLocation();
    const isTournamentGame =
        gameStore?.currentGameNew?.type === GameModeTypeNew.TOURNAMENT;

    const isTournamentLeagueGame =
        gameStore?.currentGameNew?.type === GameModeTypeNew.TOURNAMENT_LEAGUE
        ;

    const myColor = viewMode
        ? ColorVariant.white
        : gameStore?.gameState.getMyColor() ?? ColorVariant.white;

    const firstPlayer = viewMode
        ? gameStore?.gameState.getPlayerByColor(ColorVariant.white)
        : gameStore?.gameState.getPlayerByColor(myColor);

    const oponentColor = viewMode
        ? ColorVariant.black
        : gameStore?.gameState.getOpponentColor() ?? ColorVariant.black;
    const secondPlayer = viewMode
        ? gameStore?.gameState.getPlayerByColor(ColorVariant.black)
        : gameStore?.gameState.getPlayerByColor(oponentColor);

    const rounds = gameStore?.currentGameNew?.matchCount;
    const amount = gameStore?.currentGameNew?.bank;

    const [match1, setMatch1] = useState(0);
    const [match2, setMatch2] = useState(0);
    const [oldMatch1, setOldMatch1] = useState(0);
    const [oldMatch2, setOldMatch2] = useState(0);

    useEffect(() => {
        let _match1 = (gameStore?.currentGameNew?.matchId || 0) - 1;
        let _match2 = (gameStore?.currentGameNew?.matchId2 || 0) - 1;

        const meIsBlack =
            !isView &&
            gameStore?.currentGameNew?.players[0]._id !==
                gameStore.rootStore.authStore.currentUser?._id;

        if (meIsBlack) {
            const tmp = _match1;
            _match1 = _match2;
            _match2 = tmp;
        }

        setMatch1(_match1);
        setMatch2(_match2);

        const tm = setTimeout(() => {
            clearTimeout(tm);
            setOldMatch1(_match1);
            setOldMatch2(_match2);
        }, 10000);
    }, [
        gameStore?.currentGameNew?.matchId,
        gameStore?.currentGameNew?.matchId2,
    ]);

    const [firstPlayerState, setFirstScore] = useState<IPlayer | any>(null);
    const [secondPlayerState, setSecondScore] = useState<IPlayer | any>(null);
    const [clicker, setClick] = useState<boolean | string>(false);
    const delayForAnumationStart = 1000;
    const [needsMarquee, setNeedsMarquee] = useState(false);
    const [needsRoundMarquee, setNeedsRoundMarquee] = useState(false);
    const titleTextRef = useRef(null);
    const titleContainerRef = useRef(null);
    const roundTextRef = useRef(null);
    const roundContainerRef = useRef(null);

    const width = window.innerWidth;
    const styles = useStyles({
        clicker,
        myColor,
        oponentColor,
    });


    const calcStage = (array: TournamentPair[], obj: TournamentPair, tournament: ITournamentsNew): RoundInfo => {
        if (tournament.type === ETournamentType.PLAY_OFF_LONG) {
            return getDoubleBracketRound(array, obj);
        } else {
            return getSingleBracketRound(array, obj);
        }
    };

    const getSingleBracketRound = (array: TournamentPair[], obj: TournamentPair): RoundInfo => {

        const maxStep = Math.max(...array.map(p => p.step));

        const matchesInRound = array.filter(p => p.step === maxStep);

        const playersInRound = matchesInRound.length;

        const previousRoundMatches = array.filter(p => p.step === maxStep - 1).length;

        if (obj.isFinished) {
            return {
                title: obj.forThirdPlace ? thrdPlaceMatch("3rd place") : `1/${playersInRound}`,
                bracketType: 'single',
            };
        }

        if (playersInRound === 2 && previousRoundMatches === 2) {
            const [match1, match2] = matchesInRound;

            if (match1 === obj) {
                return { title: final("Final"), bracketType: 'single' };
            }

            if (match2 === obj) {
                return { title: thrdPlaceMatch("3rd place"), bracketType: 'single' };
            }
        }

        if (obj.step === maxStep - 1 && previousRoundMatches > 1) {
            return { title: semiFinal("Semi-final"), bracketType: 'single' };
        }

        const titleStage = new Map([
            [1, final("Final")],
            [2, semiFinal("Semi-final")]
        ]);

        const roundTitle = titleStage.has(playersInRound) ? titleStage.get(playersInRound)! : `1/${playersInRound}`;

        return { title: roundTitle, bracketType: 'single' };
    };


    const getDoubleBracketRound = (
        array: TournamentPair[],
        obj: TournamentPair,
        bracketStructure?: Map<number, { main: number; lower: number }>
    ): RoundInfo => {
        const upperBracketPairs = array.filter(p => !p.sub);
        const lowerBracketPairs = array.filter(p => p.sub);
        const isMobile = window.innerWidth <= MAX_CHANGE_SCREEN_WIDTH;

        const maxUpperStep = Math.max(...upperBracketPairs.map(p => p.step), 0);
        const maxLowerStep = Math.max(...lowerBracketPairs.map(p => p.step), 0);

        // финальный матч нижней сетки (Cellar Final)
        const lowerFinal = lowerBracketPairs.sort((a, b) => b.step - a.step)[0];

        function getLowerBracketRoundTitle(step: number): string {
            if (!bracketStructure) return `${crTitle('C-Round')} ${step}`;

            const steps = Array.from(bracketStructure.keys()).sort((a, b) => a - b);
            const totalLowerRounds = steps.length - 2;

            if (step === steps[steps.length - 2]) {
                return cfTitle('Cellar Final');
            }

            const roundIndex = steps.indexOf(step);
            return `${crTitle('C-Round')} ${roundIndex + 1}`;
        }

        //Проверяем Grand Final:
        const isGrandFinal = !obj.sub && lowerFinal && obj.step > lowerFinal.step;
        if (isGrandFinal) {
            return {
                title: gfTitle('Grand Final'),
                bracketType: 'grand'
            };
        }

        // Cellar Final
        const uniqueLowerSteps = Array.from(new Set(lowerBracketPairs.map(p => p.step)));
        const multipleLowerRounds = uniqueLowerSteps.length > 1;

        const lastLowerMatches = lowerBracketPairs.filter(p => p.step === maxLowerStep);
        const isCellarFinal = obj.sub &&
            multipleLowerRounds &&
            obj.step === maxLowerStep &&
            lastLowerMatches.length === 1 &&
            obj === lastLowerMatches[0];

        if (isCellarFinal) {
            return {
                title: cfTitle('Cellar final'),
                bracketType: 'lower'
            };
        }

        //Если матч в нижней сетке
        if (obj.sub) {
            const uniqueSteps = Array.from(new Set(lowerBracketPairs.map(p => p.step))).sort((a, b) => a - b);
            const roundIndex = uniqueSteps.indexOf(obj.step);

            return {
                title: bracketStructure
                    ? getLowerBracketRoundTitle(obj.step)
                    : isMobile
                        ? `R ${roundIndex + 1}` 
                        : `${crTitle('C-Round')} ${roundIndex + 1}`,  
                bracketType: 'lower'
            };
        }

        return {
            title: isMobile
                ? `R ${obj.step}` 
                : `${rTitle('Round')} ${obj.step}`, 
            bracketType: 'upper'
        };
    };


    if (
        stage &&
        stage === 1 &&
        gameStore.currentGameNew?.tournament &&
        gameStore.currentGameNew?.tournament.pairs.length > 1
    ) {
        const lastGame =
            gameStore.currentGameNew?.tournament.pairs[
                gameStore.currentGameNew?.tournament.pairs.length - 1
            ];
        const preLastGame =
            gameStore.currentGameNew?.tournament.pairs[
                gameStore.currentGameNew?.tournament.pairs.length - 2
            ];

        if (
            lastGame.gameId === gameStore.currentGameNew?._id &&
            preLastGame.step === lastGame.step
        ) {
            stage *= -1;
        }
    }

    const tableLabel = useCalcTableLabel(stage);
    const isLeagueType = currentTournament?.type === ETournamentType.LEAGUE;

    const stageLabel = useMemo(() => {
        if (isLeagueType) return "";

        if (currentTournament && currentTournament.pairs) {

            const currentPair = currentTournament.pairs.find(
                (pair) => pair.gameId === gameStore.currentGameNew?._id
            );

            if (currentPair) {
                const roundInfo = calcStage(currentTournament.pairs, currentPair, currentTournament as ITournamentsNew);

                let bracketText = '';
                const isMobile = window.innerWidth <= MAX_CHANGE_SCREEN_WIDTH;

                if (isMobile) {
                    let mobileTitle = roundInfo.title.replace(/Round (\d+)/, 'R $1');
                    mobileTitle = mobileTitle.replace(/C-Round (\d+)/, 'C-R $1');

                    let bracketText = '';
                    if (roundInfo.bracketType === 'upper') {
                        bracketText = 'Upper';
                    } else if (roundInfo.bracketType === 'lower') {
                        bracketText = 'Cellar';
                    }


                    return `${mobileTitle} ${bracketText ? `- ${bracketText}` : ''}`;
                } else {
                    let bracketText = '';
                    if (roundInfo.bracketType === 'upper') {
                        bracketText = ubTitle('Upper bracket');
                    } else if (roundInfo.bracketType === 'lower') {
                        bracketText = cbTitle('Cellar bracket');
                    }



                    return `${roundInfo.title} ${bracketText ? `- ${bracketText}` : ''}`;
                }
            }
        }

        return tableLabel;
    }, [currentTournament, gameStore.currentGameNew, tableLabel]);

    useEffect(() => {
        setClick(false);
        if (!winner || !firstPlayerState || !secondPlayerState) return;
        if (winner === myColor) {
            setClick("me");
            setTimeout(() => {
                setFirstScore({
                    ...firstPlayerState,
                    score: firstPlayerState.score + 1,
                });
            }, delayForAnumationStart);
            return;
        }
        if (winner === oponentColor) {
            setClick("oponent");
            setTimeout(() => {
                setSecondScore({
                    ...secondPlayerState,
                    score: secondPlayerState.score + 1,
                });
            }, delayForAnumationStart);
            return;
        }
    }, [winner]);

    useEffect(() => {
        if (firstPlayer && !firstPlayerState) {
            setFirstScore(firstPlayer);
        }
        if (secondPlayer && !secondPlayerState) {
            setSecondScore(secondPlayer);
        }
    }, [firstPlayer, secondPlayer]);

    useEffect(() => {
        if (location.pathname) {
            if (firstPlayer) {
                setFirstScore(firstPlayer);
            }
            if (secondPlayer) {
                setSecondScore(secondPlayer);
            }
        }
    }, [location.pathname]);

    const checkTextOverflow = (textElement, containerElement) => {
        if (!textElement || !containerElement) return false;
        return textElement.scrollWidth > containerElement.clientWidth;
    };

    useEffect(() => {
        const checkOverflow = () => {
            if (titleTextRef.current && titleContainerRef.current) {
                const needsScroll = checkTextOverflow(titleTextRef.current, titleContainerRef.current);
                setNeedsMarquee(needsScroll);
            }

            if (roundTextRef.current && roundContainerRef.current) {
                const needsRoundScroll = checkTextOverflow(roundTextRef.current, roundContainerRef.current);
                setNeedsRoundMarquee(needsRoundScroll);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, [title, stageLabel]);


    if (isTournamentGame) {
        return (
            <>
                <Box
                    component="div"
                    className={cn(styles.multiRoundGameWrapper, {
                        isTournamentGame: isTournamentGame && !isView,
                        inProgressPreview:
                            elementPosition ===
                            ChatExistPosition.inProgressPreview,
                        isTournamentPreview: isTournamentGame && isView,
                        forceRow: forceDirection === 'row',
                        forceColumn: forceDirection === 'column',
                    })}
                >
                    {width <= MAX_CHANGE_SCREEN_WIDTH ? (
                        <>
                            {/* Tournament title */}
                            <Box component="div" className={cn(styles.matchGameScoreTitleBox, styles.tournamentTitleBox)}>
                                <div
                                    className={cn(styles.tournamentTitleBox, {
                                        [styles.tournamentTitleMarquee]: needsMarquee,
                                        [styles.tournamentTitleCentered]: !needsMarquee
                                    })}
                                    ref={titleContainerRef}
                                >
                                    <p
                                        ref={titleTextRef}
                                        className={cn(
                                            styles.gameScoreTitles,
                                            styles.tournamentTitleText,
                                            "tournamentDetails",
                                            {
                                                inProgressPreview: elementPosition === ChatExistPosition.inProgressPreview,
                                                isTournamentPreview: isTournamentGame && isView,
                                                [styles.marqueeText]: needsMarquee,
                                                [styles.centeredText]: !needsMarquee
                                            }
                                        )}
                                    >
                                        {title}
                                    </p>
                                </div>
                                <span className={cn(styles.matchGameScoreTitleBoxShine)} />
                            </Box>

                            {/* Score display */}
                            <Box
                                component="div"
                                className={cn(styles.matchGameScoreTitleBox, styles.tournamentScoreBox, {
                                    isTournamentPreview: isView,
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitles,
                                        styles.tournamentScoreText,
                                        "tournamentDetails",
                                        {
                                            inProgressPreview:
                                                elementPosition ===
                                                ChatExistPosition.inProgressPreview,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        }
                                    )}
                                >
                                    <span
                                        className={cn(styles.match, {
                                            [styles.matchAnimate]: match1 !== oldMatch1,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        })}
                                    >
                                        {match1}
                                    </span>
                                    :
                                    <span
                                        className={cn(styles.match, {
                                            [styles.matchAnimate]: match2 !== oldMatch2,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        })}
                                    >
                                        {match2}
                                    </span>
                                    <br />
                                    <span
                                        className={cn(
                                            styles.matchGameScoreTitleBoxShine
                                        )}
                                    />
                                </p>
                            </Box>

                            {/* Round name display */}
                            <Box
                                component="div"
                                className={cn(styles.matchGameScoreTitleBox, styles.tournamentRoundBox, {
                                    isTournamentPreview: isView,
                                })}
                            >
                                <div
                                    className={cn(styles.tournamentRoundContainer, {
                                        [styles.tournamentTitleMarquee]: needsRoundMarquee,
                                        [styles.tournamentTitleCentered]: !needsRoundMarquee
                                    })}
                                    ref={roundContainerRef}
                                >
                                    <p
                                        ref={roundTextRef}
                                        className={cn(
                                            styles.gameScoreTitles,
                                            styles.tournamentRoundText,
                                            "tournamentDetails",
                                            {
                                                inProgressPreview:
                                                    elementPosition === ChatExistPosition.inProgressPreview,
                                                isTournamentPreview: isTournamentGame && isView,
                                                [styles.marqueeText]: needsRoundMarquee,
                                                [styles.centeredText]: !needsRoundMarquee
                                            }
                                        )}
                                    >
                                        {stageLabel}
                                    </p>
                                </div>
                                <span className={cn(styles.matchGameScoreTitleBoxShine)} />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                component="div"
                                className={cn(styles.matchGameScoreTitleBox, styles.tournamentTitleBoxDesktop)}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitles,
                                        styles.tournamentTitleTextDesktop,
                                        "tournamentDetails",
                                        {
                                            inProgressPreview:
                                                elementPosition ===
                                                ChatExistPosition.inProgressPreview,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        }
                                    )}
                                >
                                    {title}
                                    <span
                                        className={cn(
                                            styles.matchGameScoreTitleBoxShine
                                        )}
                                    />
                                </p>
                            </Box>

                            <Box
                                component="div"
                                className={cn(styles.matchGameScoreTitleBox, styles.tournamentScoreBoxDesktop, {
                                    isTournamentPreview: isView,
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitles,
                                        styles.tournamentScoreTextDesktop,
                                        "tournamentDetails",
                                        {
                                            inProgressPreview:
                                                elementPosition ===
                                                ChatExistPosition.inProgressPreview,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        }
                                    )}
                                >
                                    <span
                                        className={cn(styles.match, {
                                            [styles.matchAnimate]: match1 !== oldMatch1,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        })}
                                    >
                                        {match1}
                                    </span>
                                    :
                                    <span
                                        className={cn(styles.match, {
                                            [styles.matchAnimate]: match2 !== oldMatch2,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        })}
                                    >
                                        {match2}
                                    </span>
                                    <br />
                                    <span
                                        className={cn(
                                            styles.matchGameScoreTitleBoxShine
                                        )}
                                    />
                                </p>
                            </Box>

                            <Box
                                component="div"
                                className={cn(styles.matchGameScoreTitleBox, styles.tournamentRoundBoxDesktop)}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitles,
                                        styles.tournamentRoundTextDesktop,
                                        "tournamentDetails",
                                        "tournamentDetailsTitle",
                                        {
                                            inProgressPreview:
                                                elementPosition ===
                                                ChatExistPosition.inProgressPreview,
                                            isTournamentPreview:
                                                isTournamentGame && isView,
                                        }
                                    )}
                                >
                                    {stageLabel}
                                    <span
                                        className={cn(
                                            styles.matchGameScoreTitleBoxShine
                                        )}
                                    />
                                </p>
                            </Box>
                        </>
                    )}
                </Box>
            </>
        );
    }

    return (
        <>
            <Box
                component="div"
                className={cn(styles.multiRoundGameWrapper, {
                    isRegualarOrMatch:
                        gameStore?.currentGame?.type === GameModeType.REGULAR,
                    isTournamentGame:
                        gameStore?.currentGame?.type ===
                        GameModeType.TOURNAMENT,
                    inLobbyPreview:
                        elementPosition === ChatExistPosition.inProgressPreview,
                    inBracketPreview:
                        elementPosition === ChatExistPosition.inProgressPreview,
                })}
            >
                {/* TODO: replace !title with isMultiRoundGame props */}
                {!title && (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {match("Match")} {rounds} wins
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                )}{" "}
                {!title && (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            <span
                                className={cn(styles.match, {
                                    [styles.matchAnimate]: match1 !== oldMatch1,
                                })}
                            >
                                <div style={{ width: 20, height: 20 }}>
                                    {" "}
                                    {isView && (
                                        <WPIcon
                                            width={12}
                                            mobileHover={false}
                                            isMultiTable={false}
                                        />
                                    )}{" "}
                                </div>
                                {match1}
                            </span>
                            -
                            <span
                                className={cn(styles.match, {
                                    [styles.matchAnimate]: match2 !== oldMatch2,
                                })}
                            >
                                {match2}
                                <div style={{ width: 20, height: 20 }}>
                                    {isView && (
                                        <BPIcon
                                            width={12}
                                            mobileHover={false}
                                            isMultiTable={false}
                                        />
                                    )}
                                </div>
                            </span>
                            <br />
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                )}
                {width <= MAX_CHANGE_SCREEN_WIDTH ? (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {title
                                ? `${title}, ${stageLabel}`
                                : `${pot("POT")}: $${amount}`}
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                ) : (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                "tournamentDetailsTitle",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {title ?? `${pot("POT")}: $${amount}`}
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                "tournamentDetailsRound",
                                {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                }
                            )}
                        >
                            {stageLabel}
                        </p>
                    </Box>
                )}
                <Box component="div" className={styles.matchRoundBox}>
                    {firstPlayerState && secondPlayerState && (
                        <>
                            <span
                                className={cn(styles.gameScoreTitlesFirst, {
                                    me: clicker === "me",
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitlesName,
                                        "score"
                                    )}
                                >
                                    {firstPlayerState?.score}
                                </p>
                            </span>
                            <span
                                className={cn(styles.gameScoreTitles, "colon", {
                                    inProgressPreview:
                                        elementPosition ===
                                        ChatExistPosition.inProgressPreview,
                                })}
                            >
                                :
                            </span>
                            <span
                                className={cn(styles.gameScoreTitlesSecond, {
                                    oponent: clicker === "oponent",
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitlesName,
                                        "score"
                                    )}
                                >
                                    {secondPlayerState?.score}
                                </p>
                            </span>
                            {rounds && rounds > 1 && (
                                <span
                                    className={cn(
                                        styles.gameScoreTitles,
                                        "upTo",
                                        {
                                            inProgressPreview:
                                                elementPosition ===
                                                ChatExistPosition.inProgressPreview,
                                        }
                                    )}
                                >
                                    {`(${upTo(
                                        "Up to"
                                    ).toLowerCase()} ${rounds})`}
                                </span>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default observer(GameScoreRedesign);
