import { makeStyles } from "@mui/styles";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new/constants";
import { appColors, backgroundColors } from "src/consts/app-config";
import { DARKGREEN_ALERTS } from "src/pages/game-new/constants";
import theme from "src/theme";

const useStyles = makeStyles({
    rematchAlertContainer: {
        width: "100%",
        height: "48px",
        display: "flex",
        justifyContent: "center",
        gridGap: "10px",
        alignItems: "flex-end",
        marginBottom: "5px",
        [theme.breakpoints.up(2400)]: {
            height: "64px",
        },
        [theme.breakpoints.up(3200)]: {
            height: "72px",
        },
        [theme.breakpoints.down('sm')]: {
            height: '42px',
        },
    },
    buttonRematch: {
        height: "100%",
        width: "50%",
        padding: 0,
        background: '#00BA6C',
        color: '#11141C',
        [theme.breakpoints.down('sm')]: {
            width: '45%',
        },
    },
    buttonLobby: {
        height: "100%",
        width: "50%",
        padding: 0,
        background: 'none',
        color: '#EB5757',
        border: '2px #EB5757 solid',
        [theme.breakpoints.down('sm')]: {
            width: '45%',
        },
    },

    circularRequest: {
        width: "auto",
        height: "100%",
    },
});

export default useStyles;
