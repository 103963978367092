import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    modalContent: {
        width: 360,
        background: "rgba(18, 18, 18, 0.9)",
        borderRadius: 16,
        padding: 20,
        color: "#fff",
        textAlign: "center",
        position: "relative",
        margin: "100px auto",
        outline: "none",
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeIcon: {

    },
    closeButton: {
        cursor: 'pointer',
        "&:hover": {
            color: "#fff",
        },
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 12,
    },
    description: {
        fontSize: 13,
        lineHeight: 1.4,
        color: "#ccc",
        marginBottom: 20,
    },
    timerWrapper: {
        position: "relative",
        width: 64,
        height: 64,
        margin: "0 auto 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    timerNumber: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: 30,
        fontWeight: 700,
        color: "#00FF7F",
    },
    rebuyButton: {
        backgroundColor: "#00FF7F",
        border: "none",
        borderRadius: 6,
        padding: "10px 20px",
        color: "#000",
        fontWeight: 600,
        fontSize: 14,
        cursor: "pointer",
        width: "100%",
        transition: "background-color 0.3s ease",
        "&:hover": {
            backgroundColor: "#00FF7F",
        },
    },
});