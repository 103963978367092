import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";

import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import DesktopWatchers from "src/pages/game-new2/components/desktopWatchers";
import WatchingToggler from "src/components/WatchingPlayers/components/WatchingToggler";

import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import { ChatExistPosition } from "src/store/models";
import { Spinner } from "src/components/spinner";
import paths from "src/consts/paths";
import BoardV3 from "src/components/BoardV3";

import useChangeTheme from "src/hooks/useChangeTheme";
import { GameStoreProvider } from "src/hooks/useGameStore";
import useWindowSize from "src/hooks/useWindowSize";
import { getBeatCounter } from "src/utils/getBeatCounter";
import ViewModeModals from "src/pages/game-new2/components/modals/index";
import PlayerInfoRedesign from "src/components/PlayerInfoRedesign";
import RiseAndCoinWrapper from "src/components/controls/bank/coinAnimation/riseAndCoinAnimation";
import { useLocale } from "src/providers/LocaleProvider";
import GameScore from "src/components/gameScore";
import { appColors } from "src/consts/app-config";
import FullScreenSVG from "src/assets/icons/profileAndSettings/fullScreenSVG";
import GameScoreRedesign from "src/components/gameScoreRedesign";
import ViewDesktopChessboardWrapper from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/desktopChessboard-wrapper";
import {
    ChessboardType,
    ColorVariant,
    GameModeType,
    GameModeTypeNew,
    UserBalanceType,
    PlayerInfoExistPosition,
} from "src/store/models";
import crossIcon from "src/assets/icons/cross.svg";
import arrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import DicesWrapper from "src/pages/game-new2/components/dicesWrapper";

import LineOfActiveIcons from "src/pages/game-new2/components/actionAndReturn/lineOfActiveIcons";
import useStores from "../../../../../hooks/useStores";

import DesktopPotView from "./components/betAndTime/desktopPotView";
import ChessBoard from "./components/chessboard";
import { useStyles } from "./styles";
import {boardRoot} from "../../../../../core/endpoints";

const playerStyles = {
    avatarSize: 85,
    height: 52,
    fontSize: 15,
    clock: {
        height: 41,
        width: 71,
        fontSize: 16,
    },
};

interface IGamePreview {
    showFriends: (status: boolean) => void;
    showPreview: (status: boolean) => void;
    togglePreview: any;
    componentPlace?: PlayerInfoExistPosition;
}

const GameInProgressPreview = observer(
    ({
        showFriends,
        showPreview,
        togglePreview,
        componentPlace,
    }: IGamePreview) => {
        const { lobbyStore, gameStore, authStore, viewHistoryGameStore } =
            useStores();
        const windowSize = useWindowSize();
        const history = useHistory();
        const pairs = gameStore.currentGameNew?.tournament?.pairs;
        const resign = gameStore?.gameState?.gameResultData?.cause;
        const winner = gameStore?.currentGameNew?.winner;
        const extra = gameStore?.currentGameNew?.extra;
        const nextMatch = gameStore?.currentGameNew?.nextMatch;
        const isLeftBet = !!extra && !!extra.leftBet;
        const isRightBet = !!extra && !!extra.rightBet;
        const elementPosition =
            componentPlace ?? PlayerInfoExistPosition.inLobbyPreview;

        // const game = lobbyStore.selectedGame;
        const game = gameStore.currentGameNew;
        const watchers = gameStore.currentGameNew?.viewers || [];
        const ref = useRef<HTMLDivElement>(null);
        const playerInfoRef = useRef<HTMLDivElement>(null);
        const chessBoardRef = useRef<HTMLDivElement>(null);
        const loadRematchGameTimeoutRef = useRef<number>();
        const appearanceTheme = useChangeTheme();
        const gameType = gameStore?.currentGameNew?.type;
        const isMatchGame = gameStore.currentGame?.settings?.rounds! > 1;
        const isTournamentGame = gameType === GameModeTypeNew.TOURNAMENT;

        const [chessBoardWidth, setChessBoardWidth] = useState<number>(0);

        const [isOpenWatchers, setOpen] = useState<boolean | null>(null);
        const [ratio, setRatio] = useState<number | undefined>(1.75);
        const [isWatchersVisible, setIsWatchersVisible] = useState<boolean>(false);
        const [isBackButtonVisible, setIsBackButtonVisible] = useState<boolean>(false);

        const {
            backArrowButton: { back },
        } = useLocale();


        useEffect(() => {
            const ratio = window.innerWidth / window.innerHeight;
            setRatio(ratio);
        }, [window.innerWidth | window.innerHeight]);

        useEffect(() => {
            return () => {
                gameStore.reset();
            };
        }, []);

        const isLeague = game?.tournament?.type === 3;

        const table = isLeague ? gameStore.currentGameNew?.tournament?.table || [] : [];

        const whiteEntry = table.find(entry => entry.player?._id === pWhitePieces?._id);
        const blackEntry = table.find(entry => entry.player?._id === pBlackPieces?._id);

        const whiteChips = isLeague ? whiteEntry?.chips ?? 0 : undefined;
        const blackChips = isLeague ? blackEntry?.chips ?? 0 : undefined;

        const classes = useStyles({ ratio, isLeague });

        useEffect(() => {
            if (!game) return;
            gameStore?.setGameId(game._id);
        }, [game?._id]);

        useLayoutEffect(() => {
            if (chessBoardRef.current) {
                setChessBoardWidth(chessBoardRef.current.clientWidth);
            }
        }, [chessBoardRef.current]);

        useEffect(() => {
            const rematch = gameStore.rematch;
            if (game && rematch && rematch.w && rematch.b && rematch.gameId) {
                const gameId = rematch.gameId;
                loadRematchGameTimeoutRef.current = setTimeout(() => {
                    lobbyStore.loadRematchGame(gameId);
                }, 500) as any;
            }
        }, [gameStore.rematch]);

        useEffect(() => {
            if (gameStore.nextRound) {
                const gameId = gameStore.nextRound.gameId;
                lobbyStore.loadGame(gameId);
            }
        }, [gameStore.nextRound]);

        useEffect(() => {
            let resetTimeout;
            // Удаляем автоматический сброс
            /*
            const timeReset = 10000;
            if (gameStore.isLoadedPreview) {
                clearTimeout(resetTimeout);
                return;
            }
            setOpen(null);
            resetTimeout = setTimeout(() => {
                viewHistoryGameStore.reset();
            }, timeReset);
            */
            return () => clearTimeout(resetTimeout);
        }, [gameStore.isLoadedPreview]);

        useEffect(() => {
            return () => {
                clearTimeout(loadRematchGameTimeoutRef.current);
                lobbyStore.setIsRematchGame(false);
                gameStore.resetGame();
                showFriends(true);
            };
        }, []);

        useEffect(() => {
            if (nextMatch) {
                clearTimeout(loadRematchGameTimeoutRef.current);
                lobbyStore.setIsRematchGame(false);
                gameStore.resetGame();
                gameStore.clearSocket();
                showFriends(true);
                viewHistoryGameStore.setGameIdNewView(nextMatch);
            }
        }, [nextMatch]);

        useEffect(() => {
            let timeout;
            if (gameStore.gameState.isOver) {
                timeout = setTimeout(() => {
                    if (!game || gameStore.gameState.isOver) {
                        lobbyStore.setSelectedGame(null);
                    }
                }, 30000);
            }
            return () => {
                clearTimeout(timeout);
            };
        }, [gameStore.gameState.isOver, game]);

        const currentChessBorardHeight = chessBoardRef?.current?.clientHeight;

        useEffect(() => {
            if (!game) {
                showFriends(true);
            }
        }, [game]);

        const killedPieces = useMemo(() => {
            return gameStore.gameState.killedPiecesNew;
        }, [gameStore.gameState.killedPiecesNew]);


        const checkGameType = () => {
            if (gameStore.currentGame?.balanceType) {
                if (
                    gameStore.currentGame?.balanceType === UserBalanceType.coins
                )
                    return paths.lobbyFreePlay;
                if (gameStore.currentGame?.balanceType === UserBalanceType.play)
                    return paths.diceChessLobby;
            }
            return paths.diceChessLobby;
        };

        const piecesBeatCounter = useMemo(() => {
            const data = getBeatCounter(killedPieces);
            return data;
        }, [killedPieces]);

        const activeClock = gameStore.gameState.activeClockView;
        const pWhitePieces = gameStore.currentGameNew?.players?.[0] ?? null;
        const pBlackPieces = gameStore.currentGameNew?.players?.[1] ?? null;

        const initTimeWhitePieces = useMemo(() => {
            if (!gameStore?.rightBlockCollector.clockData.length) return 0;
            const getOpponentTime = 0;
            const timeLeftMemo =
                gameStore?.rightBlockCollector.clockData[getOpponentTime];
            return timeLeftMemo || 0;
        }, [
            gameStore?.rightBlockCollector.clockData,
            gameStore.gameId,
            activeClock,
        ]);
        const initTimeBlackPieces = useMemo(() => {
            if (!gameStore?.rightBlockCollector.clockData.length) return 0;
            const getOpponentTime = 1;
            const timeLeftMemo =
                gameStore?.rightBlockCollector.clockData[getOpponentTime];
            return timeLeftMemo || 0;
        }, [
            gameStore?.rightBlockCollector.clockData,
            gameStore.gameId,
            activeClock,
        ]);

        const stage = useMemo(() => {
            if (isTournamentGame && pairs) {
                const avangardData = pairs.reduce(
                    (c, pair) => c + (pair.step === 1 ? 1 : 0),
                    0
                );

                const arergardData =
                    1 <<
                    ((pairs.find(
                        (pair) => pair.gameId == gameStore.currentGameNew?._id
                    )?.step || 1) -
                        1);

                const stageCalc = avangardData / arergardData;
                return stageCalc;
            }
            return null;
        }, [gameStore.currentGameNew?._id, pairs, isTournamentGame]);


        const marqueeContainerRef = useRef<HTMLDivElement | null>(null);
        const marqueeTextRef = useRef<HTMLDivElement | null>(null);
        const [isTextOverflowing, setIsTextOverflowing] = useState(false);

        useEffect(() => {
            const checkOverflow = () => {
                if (marqueeContainerRef.current && marqueeTextRef.current) {
                    const containerWidth = marqueeContainerRef.current.clientWidth;
                    const textWidth = marqueeTextRef.current.scrollWidth;
                    setIsTextOverflowing(textWidth > containerWidth);
                }
            };

            checkOverflow();

            // Повторная проверка при изменении размера окна
            window.addEventListener('resize', checkOverflow);
            return () => window.removeEventListener('resize', checkOverflow);
        }, [gameStore?.currentGameNew?.tournament?.title, stage]);

        if (!gameStore.isLoadedPreview) {
            return (
                <div className={cn(classes.previewGame, appearanceTheme)}>
                    <div className={classes.gameNotSelect}>
                        <Spinner />
                    </div>
                </div>
            );
        }

        const arrowStyle = {
            color: appColors.white,
            transition: "all .4s",
            "&:hover": {
                color: appColors.txt300,
                transition: "all .4s",
            },
        };

        if (!game) {
            return null;
        }

        const isCurrentPlayer = (playerId) => {
    return authStore.currentUser?._id === playerId;
};


        if (!pWhitePieces || !pBlackPieces) return null; 
        return (
            <>
                {componentPlace === PlayerInfoExistPosition.inBracketPreview && !isLeague && (
                    <>
                        <div
                            className={cn(
                                classes.desktopWatchersBox,
                                PlayerInfoExistPosition.inBracketPreview,
                                {
                                    show: isOpenWatchers,
                                    hide: isOpenWatchers === false,
                                }
                            )}
                        >
                            <DesktopWatchers
                                componentPosition={
                                    ChatExistPosition.inBracketPreview
                                }
                            />
                        </div>
                        <div
                            className={cn(classes.solidLine, {
                                show: isOpenWatchers,
                                hide: isOpenWatchers === false,
                            })}
                        ></div>
                    </>
                )}
                <div
                    className={cn(classes.previewGame, "deepBlack", {
                        inBracketPreview:
                            componentPlace ===
                            PlayerInfoExistPosition.inBracketPreview,
                    })}
                    ref={ref}
                >
                    {/* <div className={classes.timeAndBet}>
                          <TimeAndBetPreview
                              timeControl={game.timeControl}
                              time={game.time}
                              bet={gameStore.gameState.bank}
                          />
                      </div>
                      // DONT DELETE. Temporary commented friends for stream

                      */}
                    {componentPlace !== PlayerInfoExistPosition.inBracketPreview && !isLeague && (
                        <div
                            className={cn(classes.desktopWatchersBox, {
                                show: isOpenWatchers,
                                hide: isOpenWatchers === false,
                            })}
                        >
                            <DesktopWatchers
                                componentPosition={
                                    ChatExistPosition.inProgressPreview
                                }
                            />
                        </div>
                    )}
                    <div
                        className={
                            resign
                                ? cn(classes.viewMobilePotWrapperAnimateDesk, {
                                      isTournamentGame:
                                          game.type ===
                                          GameModeTypeNew.TOURNAMENT,
                                  })
                                : cn(classes.viewMobilePotWrapperDesk, {
                                      isTournamentGame:
                                          game.type ===
                                          GameModeTypeNew.TOURNAMENT,
                                  })
                        }
                    >
                        {game.type === GameModeTypeNew.REGULAR && (
                            <div
                                className={cn(classes.bankContainer, {
                                    activeInit: isLeftBet,
                                    isWinnerWithReject:
                                        isLeftBet && !isRightBet && winner,
                                    acceptedAndFinished:
                                        isLeftBet && isRightBet && !winner,
                                    isWinnerAndHideBank: winner && isRightBet,
                                    solidWinner:
                                        winner && !isLeftBet && !isRightBet,
                                })}
                            >
                                <RiseAndCoinWrapper
                                    chessboardType={ChessboardType.desktopGame}
                                    view
                                />
                            </div>
                        )}
                        {game.type === GameModeTypeNew.MATCH && (
                            <GameScoreRedesign
                                elementPosition={
                                    ChatExistPosition.inProgressPreview
                                }
                                winner={winner}
                                stage={
                                    gameStore?.currentGame?.tournament?.stage
                                }
                                // @ts-ignore
                                title={
                                    gameStore?.currentGame?.tournament?.title
                                }
                            />
                        )}
                        {/*{isTournamentGame && (*/}
                        {/*    <GameScoreRedesign*/}
                        {/*        winner={winner}*/}
                        {/*        stage={stage !== null ? stage : 1}*/}
                        {/*        title={*/}
                        {/*            gameStore?.currentGameNew?.tournament.title*/}
                        {/*        }*/}
                        {/*        isView={true}*/}
                        {/*    />*/}
                        {/*)}*/}
                    </div>
                   <div className={cn(classes.returnAndWatchersBox)}>
                        {componentPlace === PlayerInfoExistPosition.inBracketPreview ? (
                            <>
                                <div className={cn(classes.controlsContainer)}>
                                    {/* Блок с кнопкой просмотра зрителей */}
                                    <div className={cn(classes.watchersButtonBlock)}
                                        onClick={() => {
                                            setOpen((prev) => !prev);
                                        }}
                                    >
                                        {isOpenWatchers ? (
                                            <div className={cn(classes.closeButton)}>
                                                <img src={arrowIcon} alt="Close" />
                                            </div>
                                        ) : (
                                            <LineOfActiveIcons
                                                isMobileGamePage={false}
                                                solidMultiGames={null}
                                                elementPlace={elementPosition}
                                                watchersCounter={watchers ? watchers.length : 1}
                                                isOpen={isOpenWatchers}
                                            />
                                        )}
                                    </div>

                                    {/* Блок с раундом и счетом (бегущая строка) */}
                                    <div className={cn(classes.stageInfoBlock)}>
                                        <div className={cn(classes.textContainer)} ref={marqueeContainerRef}>
                                            <div className={cn(classes.textContent, {
                                                [classes.marqueeAnimation]: isTextOverflowing
                                            })} ref={marqueeTextRef}>
                                                <GameScoreRedesign
                                                    winner={winner}
                                                    stage={stage !== null ? stage : 1}
                                                    title={gameStore?.currentGameNew?.tournament.title}
                                                    isView={true}
                                                    forceDirection="row"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Блок с кнопкой полноэкранного режима */}
                                    <div className={cn(classes.fullscreenButtonBlock)}>
                                        <div className={cn(classes.fullscreenIconWrapper)}>
                                            <FullScreenSVG
                                                onClick={() => {
                                                    if (viewHistoryGameStore.gameId) {
                                                        history.push(`/game/${viewHistoryGameStore.gameId}`);
                                                    }
                                                }}
                                                width={15}
                                                height={15}
                                                fillColor={appColors.semiwhite}
                                            />
                                        </div>
                                    </div>

                                    {/* Блок с крестиком */}
                                    <div className={cn(classes.closeButtonBlock)}>
                                        <div className={cn(classes.returnPreview, "inBracketPreview")}
                                            onClick={() => {
                                                viewHistoryGameStore.reset();
                                            }}
                                        >
                                            <img src={crossIcon} alt="Close" className={cn(classes.iconClose)} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {" "}
                                <div
                                    className={cn(
                                        classes.returnPreview,
                                        appearanceTheme,
                                        "return",
                                        {}
                                    )}
                                    onClick={() => {
                                        viewHistoryGameStore.reset();
                                    }}
                                >
                                    <ArrowBackIosNewOutlinedIcon
                                        sx={arrowStyle}
                                    />
                                </div>
                                <div
                                    className={cn(
                                        classes.watchersAndFullScreenBox,
                                        {}
                                    )}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "65px",
                                            height: "100%",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => {
                                            setOpen((prev) => !prev);
                                        }}
                                    >
                                        <LineOfActiveIcons
                                            isMobileGamePage={false}
                                            solidMultiGames={null}
                                            elementPlace={elementPosition}
                                            watchersCounter={
                                                watchers ? watchers.length : 1
                                            }
                                            isOpen={isOpenWatchers}
                                        />
                                    </div>
                                    <FullScreenSVG
                                        onClick={() => {
                                            if (viewHistoryGameStore.gameId) {
                                                window.location.href = `/game/${viewHistoryGameStore.gameId}`;
                                            }
                                        }}

                                        width={25}
                                        height={25}
                                        fillColor={appColors.white}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    {isLeague && (
                        <div
                            className={cn(classes.desktopWatchersBoxLeague, {
                                show: isOpenWatchers === true,
                                hide: isOpenWatchers === false
                            })}
                        >
                            <DesktopWatchers
                                componentPosition={
                                    ChatExistPosition.inProgressPreview
                                }
                            />
                        </div>
                    )}

                    <div
                        className={cn(classes.diceWrapper, {
                            isWinner: winner,
                            hide: isOpenWatchers || isLeague && isOpenWatchers, 
                        })}
                    >
                        <DicesWrapper
                            rndPrefix={""}
                            diceClickRnd={123}
                            myColor={ColorVariant.white}
                            isMultiTable={false}
                            isDesktop
                            viewMode={true}
                            opponentColor={ColorVariant.black}
                        />
                    </div>
                    <div
                        className={cn(classes.chessboardContainerPreview, {
                            hide: isOpenWatchers || isLeague && isOpenWatchers, 
                        })}
                    >
                        <Box component="div" className={cn(classes.topWrapper)}>
                            <div
                                ref={playerInfoRef}
                                className={cn(
                                    classes.playerInfoWrapperView,
                                    "topPlayer",
                                    "deepBlack"
                                )}
                            >
                                <div
                                    className={cn(
                                        classes.viewModeModalsWrapper,
                                        elementPosition,
                                        "top"
                                    )}
                                >
                                    <ViewModeModals player={pWhitePieces} />
                                </div>
                                <PlayerInfoRedesign
                                    resign={resign}
                                    winner={winner}
                                    noTimerSound
                                    styles={playerStyles}
                                    avatar={pBlackPieces.avatar}
                                    stars={pBlackPieces.stars ?? 0}
                                    opponentStars={pBlackPieces.stars ?? 0}


                                    name={pBlackPieces.nickname || "Unknown"}
                                    initTime={initTimeBlackPieces}
                                    activeClock={activeClock}
                                    active={gameStore.isActiveViewPlayer(
                                        pBlackPieces?._id
                                    )}
                                    id={pBlackPieces._id}
                                    killedPieces={
                                        killedPieces[ColorVariant.white]
                                    }
                                    color={ColorVariant.black}
                                    className={classes.playerInfo}
                                    isSystemAvatar={pBlackPieces.isSystemAvatar}
                                    piecesBeatCounter={
                                        piecesBeatCounter[ColorVariant.black]
                                    }
                                    elementPlace={elementPosition}
                                    gameId={gameStore?.currentGameNew?._id}
                                    userChipsBox={isLeague ? blackChips : undefined}
                                />
                            </div>
                        </Box>

                        <div
                            className={cn(classes.chessBoardWrapper, {
                                isWinner: winner,
                            })}
                        >
                            {/* {gameStore.dialogs.doublingDialog.isOpenWatchers &&
                                !gameStore.dialogs.giveUpDialog.isOpenWatchers && <X2Modal />}
                                {gameStore.dialogs.drawDialog.isOpenWatchers && <DrawModal />}
                                <RejectDrawModal />
                                <X2AcceptedModal /> */}
                            {/* <div className={classes.viewModeModalsWrapper}>
                                    <ViewModeModals />
                                </div> */}
                            <div
                                className={classes.chessBoardInnerWrapper}
                                ref={chessBoardRef}
                            >
                                {/* <ChessBoard width={chessBoardWidth} /> */}
                                <BoardV3 />
                            </div>
                        </div>

                        <Box
                            component="div"
                            className={cn(classes.bottomWrapper)}
                        >
                            <div
                                className={cn(
                                    classes.playerInfoWrapperView,
                                    "bottomPlayer",
                                    "deepBlack"
                                )}
                            >
                                <div
                                    className={cn(
                                        classes.viewModeModalsWrapper,
                                        elementPosition,
                                        "bottom"
                                    )}
                                >
                                    <ViewModeModals player={pBlackPieces} />
                                </div>
                                <PlayerInfoRedesign
                                    resign={resign}
                                    winner={winner}
                                    noTimerSound={false}
                                    styles={playerStyles}
                                    avatar={pWhitePieces.avatar}
                                    stars={pWhitePieces.stars ?? 0} 
                                    opponentStars={pWhitePieces.stars ?? 0} 

                                    name={pWhitePieces.nickname || "Unknown"}
                                    initTime={initTimeWhitePieces}
                                    activeClock={activeClock}
                                    active={gameStore.isActiveViewPlayer(
                                        pWhitePieces?._id
                                    )}
                                    id={pWhitePieces._id}
                                    killedPieces={
                                        killedPieces[ColorVariant.black]
                                    }
                                    color={ColorVariant.white}
                                    reverse
                                    className={classes.playerInfo}
                                    isSystemAvatar={pWhitePieces.isSystemAvatar}
                                    piecesBeatCounter={
                                        piecesBeatCounter[ColorVariant.white]
                                    }
                                    elementPlace={elementPosition}
                                    gameId={gameStore?.currentGameNew?._id}
                                    userChipsBox={isLeague ? whiteChips : undefined}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </>
        );
    }
);

export const GameInProgressPreviewContainer = (props) => {
    const { authStore, viewHistoryGameStore } = useStores();
    const isVisible = authStore.isVisibleWebApp;
    const gameId = viewHistoryGameStore.gameId;

    const MemoizedPreviewGameContainer = useMemo(() => {
        if (gameId) {
            return (
                <GameStoreProvider id={gameId}>
                    <GameInProgressPreview {...props} />
                </GameStoreProvider>
            );
        }
    }, [gameId, isVisible]);

    if (!gameId) return null;

    return <>{MemoizedPreviewGameContainer}</>;
};

export default observer(GameInProgressPreviewContainer);
