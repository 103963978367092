import { memo, useEffect, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import cx from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import { IRank, RankType } from "src/store/models";
import Junior1Icon from 'src/assets/icons/chessmen/profile/Junior_Level_01.svg';
import Junior2Icon from 'src/assets/icons/chessmen/profile/Junior_Level_02.svg';
import Junior3Icon from 'src/assets/icons/chessmen/profile/Junior_Level_03.svg';
import Pawn1Icon from 'src/assets/icons/chessmen/profile/Pawn_Level_01.svg';
import Pawn2Icon from 'src/assets/icons/chessmen/profile/Pawn_Level_02.svg';
import Pawn3Icon from 'src/assets/icons/chessmen/profile/Pawn_Level_03.svg';
import Bishop1Icon from 'src/assets/icons/chessmen/profile/Bishop_Level_01.svg';
import Bishop2Icon from 'src/assets/icons/chessmen/profile/Bishop_Level_02.svg';
import Bishop3Icon from 'src/assets/icons/chessmen/profile/Bishop_Level_03.svg';
import Knight1Icon from 'src/assets/icons/chessmen/profile/Knigh_Level_01.svg';
import Knight2Icon from 'src/assets/icons/chessmen/profile/Knigh_Level_02.svg';
import Knight3Icon from 'src/assets/icons/chessmen/profile/Knigh_Level_03.svg';
import Rook1Icon from 'src/assets/icons/chessmen/profile/Rook_Level_01.svg';
import Rook2Icon from 'src/assets/icons/chessmen/profile/Rook_Level_02.svg';
import Rook3Icon from 'src/assets/icons/chessmen/profile/Rook_Level_03.svg';
import Queen1Icon from 'src/assets/icons/chessmen/profile/Queen_Level_01.svg';
import Queen2Icon from 'src/assets/icons/chessmen/profile/Queen_Level_02.svg';
import Queen3Icon from 'src/assets/icons/chessmen/profile/Queen_Level_03.svg';
import King1Icon from 'src/assets/icons/chessmen/profile/King_Level_01.svg';
import King2Icon from 'src/assets/icons/chessmen/profile/King_Level_02.svg';
import King3Icon from 'src/assets/icons/chessmen/profile/King_Level_03.svg';
import Maverick1Icon from 'src/assets/icons/chessmen/profile/Maverick_Level_01.svg';
import Maverick2Icon from 'src/assets/icons/chessmen/profile/Maverick_Level_02.svg';
import Maverick3Icon from 'src/assets/icons/chessmen/profile/Maverick_Level_03.svg';
import Expert1Icon from 'src/assets/icons/chessmen/profile/Expert_Level_01.svg';
import Expert2Icon from 'src/assets/icons/chessmen/profile/Expert_Level_02.svg';
import Expert3Icon from 'src/assets/icons/chessmen/profile/Expert_Level_03.svg';
import Virtuoso1Icon from 'src/assets/icons/chessmen/profile/Virtuoso_Level_01.svg';
import Virtuoso2Icon from 'src/assets/icons/chessmen/profile/Virtuoso_Level_02.svg';
import Virtuoso3Icon from 'src/assets/icons/chessmen/profile/Virtuoso_Level_03.svg';
import Pro1Icon from 'src/assets/icons/chessmen/profile/Pro_Level_01.svg';
import Pro2Icon from 'src/assets/icons/chessmen/profile/Pro_Level_02.svg';
import Pro3Icon from 'src/assets/icons/chessmen/profile/Pro_Level_03.svg';
import Maestro1Icon from 'src/assets/icons/chessmen/profile/Maestro_Level_01.svg';
import Maestro2Icon from 'src/assets/icons/chessmen/profile/Maestro_Level_02.svg';
import Maestro3Icon from 'src/assets/icons/chessmen/profile/Maestro_Level_03.svg';
import GM1Icon from 'src/assets/icons/chessmen/profile/Grand Master_Level_01.svg';
import GM2Icon from 'src/assets/icons/chessmen/profile/Grand Master_Level_02.svg';
import GM3Icon from 'src/assets/icons/chessmen/profile/Grand Master_Level_03.svg';
import Atlant1Icon from 'src/assets/icons/chessmen/profile/Atlant_Level_01.svg';
import Atlant2Icon from 'src/assets/icons/chessmen/profile/Atlant_Level_02.svg';
import Atlant3Icon from 'src/assets/icons/chessmen/profile/Atlant_Level_03.svg';
import Reptilian1Icon from 'src/assets/icons/chessmen/profile/Reptilian_Level_01.svg';
import Reptilian2Icon from 'src/assets/icons/chessmen/profile/Reptilian_Level_02.svg';
import Reptilian3Icon from 'src/assets/icons/chessmen/profile/Reptilian_Level_03.svg';
import Kairos1Icon from 'src/assets/icons/chessmen/profile/Kairos_Level_01.svg';
import Kairos2Icon from 'src/assets/icons/chessmen/profile/Kairos_Level_02.svg';
import Kairos3Icon from 'src/assets/icons/chessmen/profile/Kairos_Level_03.svg';
import CaissasHand1Icon from 'src/assets/icons/chessmen/profile/Caissas_hand_01.svg';
import CaissasHand2Icon from 'src/assets/icons/chessmen/profile/Caissas_hand_02.svg';
import CaissasHand3Icon from 'src/assets/icons/chessmen/profile/Caissas_hand_03.svg';
import BonusCoin from 'src/assets/icons/bonusCoin.svg';

import { useRankSettings } from "../../hooks/useRankSettings";

import { useStyles } from "./styles";
import { getRanks } from "../../../../service/api/profile";
import useStores from "../../../../hooks/useStores";
import classNames from "classnames";

SwiperCore.use([Navigation]);

export const getIconByRank = (rankName: RankType) => {
    const sanitizedRankName = rankName.replace(/['\s]+/g, '').toLowerCase();
    const match = sanitizedRankName.match(/^(.*?)(\d+)?$/);
    const piece = match?.[1] || ''; 
    const level = match?.[2] || '1'; 

    const getIcon = (level1Icon, level2Icon, level3Icon) => {
        if (level === '1') return level1Icon;
        if (level === '2') return level2Icon;
        return level3Icon;
    };

    switch (piece) {
        case 'junior':
            return getIcon(Junior1Icon, Junior2Icon, Junior3Icon);
        case 'pawn':
            return getIcon(Pawn1Icon, Pawn2Icon, Pawn3Icon);
        case 'knight':
            return getIcon(Knight1Icon, Knight2Icon, Knight3Icon);
        case 'bishop':
            return getIcon(Bishop1Icon, Bishop2Icon, Bishop3Icon);
        case 'castle': 
            return getIcon(Rook1Icon, Rook2Icon, Rook3Icon);
        case 'queen':
            return getIcon(Queen1Icon, Queen2Icon, Queen3Icon);
        case 'king':
            return getIcon(King1Icon, King2Icon, King3Icon);
        case 'maverick':
            return getIcon(Maverick1Icon, Maverick2Icon, Maverick3Icon);
        case 'expert':
            return getIcon(Expert1Icon, Expert2Icon, Expert3Icon);
        case 'virtuoso':
            return getIcon(Virtuoso1Icon, Virtuoso2Icon, Virtuoso3Icon);
        case 'pro':
            return getIcon(Pro1Icon, Pro2Icon, Pro3Icon);
        case 'maestro':
            return getIcon(Maestro1Icon, Maestro2Icon, Maestro3Icon);
        case 'grandmaster': 
            return getIcon(GM1Icon, GM2Icon, GM3Icon);
        case 'atlant':
            return getIcon(Atlant1Icon, Atlant2Icon, Atlant3Icon);
        case 'reptilian':
            return getIcon(Reptilian1Icon, Reptilian2Icon, Reptilian3Icon);
        case 'kairos':
            return getIcon(Kairos1Icon, Kairos2Icon, Kairos3Icon);
        case 'caissashand': 
            return getIcon(CaissasHand1Icon, CaissasHand2Icon, CaissasHand3Icon);
        default:
            console.warn(`Unknown rank: ${rankName}`);
            return Junior1Icon; 
    }
};

type RankSystemProps = {
    ranks?: IRank[];
    currentRank: IRank;
};

const RankSystem = ({ currentRank }: RankSystemProps) => {
    const [ranks, setRanks] = useState<IRank[]>([]);
    const { getRankSettings } = useRankSettings(
        ranks.findIndex((el) => el._id === currentRank?._id)
    );
    const styles = useStyles();
    const {
        profile: {
            rankSystem: { allRanks, games, rakeBack, bonus },
        },
    } = useLocale();
    const {
        authStore: { currentUser },
    } = useStores();
    const appearanceTheme = useChangeTheme();

    // Тестовые данные
    const testRanks: IRank[] = [
        { _id: "1", title: RankType.junior1, threshold: 0, rakeBack: 0, bonus: 0, deleted: false },
        { _id: "2", title: RankType.junior2, threshold: 100, rakeBack: 1, bonus: 1, deleted: false },
        { _id: "3", title: RankType.junior3, threshold: 200, rakeBack: 2, bonus: 2, deleted: false },
        { _id: "4", title: RankType.pawn1, threshold: 300, rakeBack: 3, bonus: 3, deleted: false },
        { _id: "5", title: RankType.pawn2, threshold: 400, rakeBack: 4, bonus: 4, deleted: false },
        { _id: "6", title: RankType.pawn3, threshold: 500, rakeBack: 5, bonus: 5, deleted: false },
        { _id: "7", title: RankType.knight1, threshold: 600, rakeBack: 6, bonus: 6, deleted: false },
        { _id: "8", title: RankType.knight2, threshold: 700, rakeBack: 7, bonus: 7, deleted: false },
        { _id: "9", title: RankType.knight3, threshold: 800, rakeBack: 8, bonus: 8, deleted: false },
        { _id: "10", title: RankType.bishop1, threshold: 900, rakeBack: 9, bonus: 9, deleted: false },
        { _id: "11", title: RankType.bishop2, threshold: 1000, rakeBack: 10, bonus: 10, deleted: false },
        { _id: "12", title: RankType.bishop3, threshold: 1100, rakeBack: 11, bonus: 11, deleted: false },
        { _id: "13", title: RankType.rook1, threshold: 1300, rakeBack: 12, bonus: 12, deleted: false },
        { _id: "14", title: RankType.rook2, threshold: 1500, rakeBack: 13, bonus: 13, deleted: false },
        { _id: "15", title: RankType.rook3, threshold: 1700, rakeBack: 14, bonus: 14, deleted: false },
        { _id: "16", title: RankType.queen1, threshold: 1900, rakeBack: 15, bonus: 15, deleted: false },
        { _id: "17", title: RankType.queen2, threshold: 2100, rakeBack: 16, bonus: 16, deleted: false },
        { _id: "18", title: RankType.queen3, threshold: 2300, rakeBack: 17, bonus: 17, deleted: false },
        { _id: "19", title: RankType.king1, threshold: 2600, rakeBack: 18, bonus: 18, deleted: false },
        { _id: "20", title: RankType.king2, threshold: 2900, rakeBack: 19, bonus: 19, deleted: false },
        { _id: "21", title: RankType.king3, threshold: 3200, rakeBack: 20, bonus: 20, deleted: false },
        { _id: "22", title: RankType.maverick1, threshold: 3600, rakeBack: 21, bonus: 21, deleted: false },
        { _id: "23", title: RankType.maverick2, threshold: 4000, rakeBack: 22, bonus: 22, deleted: false },
        { _id: "24", title: RankType.maverick3, threshold: 4400, rakeBack: 23, bonus: 23, deleted: false },
        { _id: "25", title: RankType.expert1, threshold: 4800, rakeBack: 24, bonus: 24, deleted: false },
        { _id: "26", title: RankType.expert2, threshold: 5200, rakeBack: 25, bonus: 25, deleted: false },
        { _id: "27", title: RankType.expert3, threshold: 5600, rakeBack: 26, bonus: 26, deleted: false },
        { _id: "28", title: RankType.virtuoso1, threshold: 6000, rakeBack: 27, bonus: 27, deleted: false },
        { _id: "29", title: RankType.virtuoso2, threshold: 6400, rakeBack: 28, bonus: 28, deleted: false },
        { _id: "30", title: RankType.virtuoso3, threshold: 6800, rakeBack: 29, bonus: 29, deleted: false },
        { _id: "31", title: RankType.pro1, threshold: 7400, rakeBack: 30, bonus: 30, deleted: false },
        { _id: "32", title: RankType.pro2, threshold: 8000, rakeBack: 31, bonus: 31, deleted: false },
        { _id: "33", title: RankType.pro3, threshold: 8600, rakeBack: 32, bonus: 32, deleted: false },
        { _id: "34", title: RankType.maestro1, threshold: 9200, rakeBack: 33, bonus: 33, deleted: false },
        { _id: "35", title: RankType.maestro2, threshold: 9800, rakeBack: 34, bonus: 34, deleted: false },
        { _id: "36", title: RankType.maestro3, threshold: 10400, rakeBack: 35, bonus: 35, deleted: false },
        { _id: "37", title: RankType.gm1, threshold: 11000, rakeBack: 36, bonus: 36, deleted: false },
        { _id: "38", title: RankType.gm2, threshold: 12000, rakeBack: 37, bonus: 37, deleted: false },
        { _id: "39", title: RankType.gm3, threshold: 13000, rakeBack: 38, bonus: 38, deleted: false },
        { _id: "40", title: RankType.atlant1, threshold: 14000, rakeBack: 39, bonus: 39, deleted: false },
        { _id: "41", title: RankType.atlant2, threshold: 15000, rakeBack: 40, bonus: 40, deleted: false },
        { _id: "42", title: RankType.atlant3, threshold: 16000, rakeBack: 41, bonus: 41, deleted: false },
        { _id: "43", title: RankType.reptilian1, threshold: 17000, rakeBack: 42, bonus: 42, deleted: false },
        { _id: "44", title: RankType.reptilian2, threshold: 18000, rakeBack: 43, bonus: 43, deleted: false },
        { _id: "45", title: RankType.reptilian3, threshold: 19000, rakeBack: 44, bonus: 44, deleted: false },
        { _id: "46", title: RankType.kairos1, threshold: 20000, rakeBack: 45, bonus: 45, deleted: false },
        { _id: "47", title: RankType.kairos2, threshold: 22000, rakeBack: 46, bonus: 46, deleted: false },
        { _id: "48", title: RankType.kairos3, threshold: 24000, rakeBack: 47, bonus: 47, deleted: false },
        { _id: "49", title: RankType.caissashand1, threshold: 26000, rakeBack: 48, bonus: 48, deleted: false },
        { _id: "50", title: RankType.caissashand2, threshold: 28000, rakeBack: 49, bonus: 49, deleted: false },
        { _id: "51", title: RankType.caissashand3, threshold: 30000, rakeBack: 50, bonus: 50, deleted: false },
    ];

    //useEffect(() => {
    //    // Закомментировать этот эффект, если нужно использовать только тестовые данные
    //    const fetchRanks = async () => {
    //        try {
    //            const response = await getRanks();
    //            console.log('Полный ответ от сервера:', response);
    //            console.log('Полученные ранги:', response.data.ranks);
    //            setRanks(response.data.ranks);
    //        } catch (error) {
    //            console.error("Ошибка при получении рангов:", error);
    //        }
    //    };

    //    fetchRanks();
    //}, []);

    // Раскомментировать следующую строку, если нужно использовать только тестовые данные
    useEffect(() => { setRanks(testRanks); }, []);

    const userCurrentRankId = currentUser?.stats?.rate?.rank;

    return (
        <Box className={cx(styles.wrapperRanks, appearanceTheme)}>
            <Table className={cx(styles.rankTable, styles.headerTable)}>
                <TableHead>
                    <TableRow className={styles.tableHeader}>
                        <TableCell>{allRanks("All ranks")}</TableCell>
                        <TableCell>{games("Games")}</TableCell>
                        <TableCell>{rakeBack("Rakeback")}</TableCell>
                        <TableCell>{bonus("Bonus")}</TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <Box className={styles.tableContainer}>
                <Table className={cx(styles.rankTable, styles.tableBody)}>
                    <TableBody>
                        {ranks.length > 0 ? (
                            ranks.map((rank) => {
                                return (
                                    <TableRow
                                        key={rank._id}
                                        className={`${styles.rankRow} ${String(rank._id) === String(userCurrentRankId) ? styles.highlightedRow : ''}`}
                                    >
                                        <TableCell>
                                            <div className={styles.firstColumnContent}>
                                                <img
                                                    src={getIconByRank(rank.title)}
                                                    alt={rank.title}
                                                    className={styles.icon}
                                                />
                                                <span className={styles.rankTitle}>
                                                    {rank.title}
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell>{rank.threshold}</TableCell>
                                        <TableCell>{rank.rakeBack}%</TableCell>
                                        <TableCell>
                                            <img className={styles.bonusIcon} src={BonusCoin} alt="Bonus" />
                                            <span className={styles.coinAmount}>{rank.bonus}</span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <div>No ranks</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
};


export default memo(RankSystem);
