import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

export const useStyles = makeStyles({
    container: {
        minWidth: 200,
        width: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateY(20px)',
        transition: '0.4s ease',
        padding: 20,
        boxShadow: '12px -6px 32px 0px rgba(255, 255, 255, 0.05) inset, 0px 6px 24px 0px rgba(0, 0, 0, 0.2), 0px 10px 30px rgba(0, 0, 0, 0.3)',
        position: 'absolute',
        left: '-120px',
        top: 'calc(100% + 10px)',
        borderRadius: 16,
        background: 'linear-gradient(rgba(18, 18, 18, 0.9), rgba(18, 18, 18, 0.9)) padding-box, linear-gradient(180deg, rgba(245, 245, 245, 0.3) 0%, rgba(143, 143, 143, 0.05) 100%) border-box',
        border: '1px solid transparent',
        backdropFilter: 'blur(60px)',

        '&.open': {
            transition: '0.3s ease',
            opacity: 1,
            transform: 'translateY(0px)',
            visibility: 'visible',
        },
        [theme.breakpoints.down('md')]: {
            right: 0,
            left: 'unset',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '2px',
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        color: '#866838',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
    list: {
        // listStylePosition: 'inside',
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 0,
        padding: '0 0 0 7px',
    },
    listItem: {
        fontSize: 16,
        fontWeight: 400,
        color: '#866838',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
    listItemDot: {
        fontSize: 13,
        marginRight: 4,
    },
});
