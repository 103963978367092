import { useEffect, useRef, useState } from "react";
import { BracketColumn } from "../bracketColumn";
import rightArrowIcon from 'src/assets/icons/ArrowLeft.svg';
import { observer } from "mobx-react";
import MatchPair from "../matchPair";
import { Round } from "../../types";
import TournamentLines from "../tournamentLines";

const SimpleBracketCarousel: React.FC<{ bracket: Round[]; classes: any }> = ({ bracket, classes }) => {
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [lastBracketLength, setBracketLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const updateArrowVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            const maxScrollLeft = scrollWidth - clientWidth;
            const pageWidth = (scrollWidth - clientWidth) / (bracket.length - 1);
            const newPage = Math.round(scrollLeft / pageWidth);

            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(newPage < bracket.length - 1);
            setCurrentPage(newPage);
        }
    };

    const scrollLeft = () => {
        const currentRef = carouselRef.current;
        if (currentRef) {
            const { scrollLeft, scrollWidth, clientWidth } = currentRef;
            const pageWidth = (scrollWidth - clientWidth) / (bracket.length - 1);
            if (canScrollLeft && carouselRef.current) {
                carouselRef.current.scrollBy({ left: -pageWidth, behavior: "smooth" });
            }
        }
    };

    const scrollRight = () => {
        const currentRef = carouselRef.current;
        if (currentRef) {
            const { scrollLeft, scrollWidth, clientWidth } = currentRef;
            const pageWidth = (scrollWidth - clientWidth) / (bracket.length - 1);
            if (canScrollRight && carouselRef.current) {
                carouselRef.current.scrollBy({ left: pageWidth, behavior: "smooth" });
            }
        }
    };

    useEffect(() => {
        updateArrowVisibility();
        const currentRef = carouselRef.current;

        if (currentRef) {
            currentRef.addEventListener("scroll", updateArrowVisibility);
        }

        let currentPage = bracket.length - 1;
        while (bracket[currentPage].matches[0].player1?.isExpected) {
            currentPage--;
        }

        if (lastBracketLength !== currentPage && currentRef) {
            const { scrollLeft: scrollLeftRef, scrollWidth, clientWidth } = currentRef;
            const pageWidth = (scrollWidth - clientWidth) / (bracket.length - 1);
            const realPage = Math.round(scrollLeftRef / pageWidth);

            if (currentPage !== realPage) {
                currentRef?.scrollTo({ left: currentPage * pageWidth, behavior: "smooth" });
            }
            setBracketLength(currentPage);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener("scroll", updateArrowVisibility);
            }
        };
    }, [bracket]);

    return (
        <div className={classes.carouselContainer}>
            <button
                className={classes.carouselButton}
                onClick={scrollLeft}
                disabled={!canScrollLeft}
                style={!canScrollLeft ? { opacity: 0, cursor: 'not-allowed' } : {}}
            >
                <img src={rightArrowIcon} alt="Scroll Left" />
            </button>
            <div className={classes.bracketCarousel} ref={carouselRef}>
                {/*<TournamentLines bracket={bracket} />*/}
                {bracket.map((round, roundIndex) => (
                    <BracketColumn
                        key={round.title}
                        title={round.title}
                        columnIndex={roundIndex}
                        totalRounds={bracket.length}
                        matchesInRound={round.matches.length}
                    >
                        {round.matches.map((match, matchIndex) => (
                            <div key={matchIndex} className={classes.singleMatch}>
                                <MatchPair
                                    {...match}
                                    columnIndex={roundIndex}
                                    matchIndex={matchIndex}
                                    totalColumns={bracket.length}
                                />
                            </div>
                        ))}
                    </BracketColumn>
                ))}
            </div>
            {canScrollRight && (
                <button
                    className={classes.carouselButton}
                    onClick={scrollRight}
                    style={!canScrollRight ? { opacity: 0.15, cursor: "not-allowed" } : {}}
                >
                    <img style={{ transform: "rotate(180deg)" }} src={rightArrowIcon} alt="Scroll Right" />
                </button>
            )}
        </div>
    );
};

export default observer(SimpleBracketCarousel);