import { createContext, useContext } from 'react';

export type ModalTypes =
    | 'signUp'
    | 'signIn'
    | 'deposit'
    | 'withdraw'
    | 'auth'
    | 'idData'
    | 'idDataPhoto'
    | 'notifications'
    | 'register'
    | 'login'
    | 'phone-auth'
    | 'businessMsg'
    | 'recovery-password'
    | "jackpot"
    | "top";

interface IContext {
    openModal: (modal: ModalTypes) => void;
    closeModal: (modal: ModalTypes) => void;
}

export const ModalProvider = createContext<IContext>({} as IContext);

export const useModal = () => useContext(ModalProvider);
