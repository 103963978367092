import React from 'react';
import { BracketColumnProps, MatchPairProps } from '../types';
import { useStyles } from "../styles";
import { observer } from 'mobx-react';
import { useLocale } from '../../../../../providers/LocaleProvider';

export const BracketColumn: React.FC<BracketColumnProps> = ({
    title,
    children,
    columnIndex,
    totalRounds,
    hideHeader = false,
    isSubBracket = false,
    isUpperBracket = false,
    matchesInRound = 0,
    previousRoundMatches = 0,
    isDoubleBracket = false,
    hideColumn = false,
}) => {
    const classes = useStyles({ matchIndex: 0, marginTop: 0, columnIndex });
    const isMobile = window.innerWidth < 1000;
    const isFinal = columnIndex + 1 === totalRounds;

    const {
        lobby: {
            tournaments: {
                roundTitle,
                crTitle,
                cFinaltitle,
                finalTitle,
                sfTitle,
                gfTitle,
            },
        },
    } = useLocale();

    // Если флаг hideColumn установлен, не отображаем колонку
    if (hideColumn) {
        return null;
    }

    const upperBracketWidth = isMobile ? '200px' : `${200 + (columnIndex * 100)}px`;
    const spacerColumn = isSubBracket && columnIndex === 0 && (
        <div style={{ width: upperBracketWidth, marginRight: '120px' }} />
    );

    let baseGap;
    if (isSubBracket) {
        baseGap = 20;
    } else if (isMobile) {
        baseGap = 20;
    } else if (isFinal) {
        baseGap = 20;
    } else {
        baseGap = 20 + (1 << columnIndex) * 100 - 100;
    }

    let marginTop;

    if (isMobile) {
        // Для мобильных устройств
        marginTop = 20;
    } else {
        // Для десктопа 
        marginTop = isSubBracket
            ? 0
            : (columnIndex ? 50 + 100 * (columnIndex * (columnIndex - 1) / 2) : 0);

        // Логика выравнивания отступов: если в обоих раундах по одной паре
        if (columnIndex > 0 && matchesInRound === 1 && previousRoundMatches === 1) {
            // Устанавливаем одинаковый отступ для колонок с 1 матчем
            marginTop = 150;
        }
    }

    const columnWidth = isSubBracket ? '200px' : 'auto';

    //if (isFinal && !isSubBracket && !isMobile) {
    //    marginTop -= 320;
    //}

    const getTitle = () => {
        if (isSubBracket) {
            if (isFinal) {
                return cFinaltitle('Cellar final');
            }
            return `${crTitle('C-Round')} ${columnIndex + 1}`;
        }

        if (isUpperBracket) {
            if (isFinal) {
                return gfTitle('Grand Final');
            }

            // Если это двойная сетка, всегда показываем номер раунда
            if (isDoubleBracket) {
                return `${roundTitle('Round')} ${columnIndex + 1}`;
            }
        }

        // Для одинарной сетки оставляем старую логику
        if (!isDoubleBracket && (title === finalTitle('Final') || title === sfTitle('Semifinal'))) {
            return title;
        }

        return title;
    };

    return (
        <>
            {spacerColumn}
            <div className={classes.columnWrapper} style={{ width: columnWidth }}>
                {!hideHeader && (
                    <div className={classes.columnHeader}>
                        {getTitle()}
                    </div>
                )}
                <div
                    className={classes.column}
                    style={{
                        gap: `${baseGap}px`,
                        marginTop: `${marginTop}px`,
                    }}
                >
                    {React.Children.map(children, (child, matchIndex) => {
                        if (React.isValidElement<MatchPairProps>(child)) {
                            return React.cloneElement(child, {
                                columnIndex,
                                matchIndex,
                                isSubBracket,
                                isUpperBracket,
                            });
                        }
                        return child;
                    })}
                </div>
            </div>
        </>
    );
};

export default observer(BracketColumn);
;
