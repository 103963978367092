import { action, makeAutoObservable, runInAction } from "mobx";
import {
    approveFriend,
    addFriend,
    getAllUsers,
    getFriends,
    removeFriend,
    searchUsers,
} from "src/service/api/friends";
import {
    IAcceptFriendRequest,
    IFriendAdd,
    IFriendRemove,
    IFriendActionResponse,
} from "src/service/api/friends/requestResponses";

import { IFriend, ISearchedUser, IUser, RankType } from "./models";

interface IFriendUpdateParams {
    online: boolean;
    isPlaying: boolean;
    rematchAwait?: boolean;
    joinAwait?: boolean;
    game: {
        id: string;
    };
}

export class FriendsStore {
    friends: IFriend[] = [];
    sentRequests: string[] = [];
    isLoaded: boolean = false;
    errorOccured: boolean = false;
    selectedFriend: IFriend | null = null;
    allUsers: IUser[] = [];
    searchResults: ISearchedUser[] = []; 

    constructor() {
        makeAutoObservable(this);
    }

    @action
    addSentRequest(userId: string) {
        if (!this.sentRequests.includes(userId)) {
            this.sentRequests.push(userId);
        }
    }

    @action
    removeSentRequest(userId: string) {
        this.sentRequests = this.sentRequests.filter(id => id !== userId);
    }

    @action
    clearSentRequests() {
        this.sentRequests = [];
    }


    @action
    async getFriends() {
        try {
            const friends = await getFriends(); 

            if (!Array.isArray(friends)) {
                console.error("Error: getFriends returned invalid data", friends);
                return;
            }

            runInAction(() => {
                this.friends = friends;
            });

        } catch (error) {
            console.error("Error fetching friends:", error);
        }
    }



    @action
    async getAllUsers() {
        try {
            this.setLoadedState(false);
            const response = await getAllUsers();
            this.allUsers = response.data;
        } catch (error) {
            console.error("Error fetching all users:", error);
            this.allUsers = [];
        } finally {
            this.setLoadedState(true);
        }
    }

    @action
    async searchUsers(name: string): Promise<ISearchedUser[]> {
        try {
            const response = await searchUsers(name);

            if (response.data && Array.isArray(response.data.users)) {
                const lowerName = name.toLowerCase();

                const usersStartingWith = response.data.users.filter((user: IUser) =>
                    user.nickname.toLowerCase().startsWith(lowerName)
                );
                const usersContaining = response.data.users.filter((user: IUser) =>
                    !user.nickname.toLowerCase().startsWith(lowerName) && user.nickname.toLowerCase().includes(lowerName)
                );

                const sortedUsers: IUser[] = [...usersStartingWith, ...usersContaining].sort((a: IUser, b: IUser) => {
                    return a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase());
                });

                const searchedUsers: ISearchedUser[] = sortedUsers.map((user: IUser) => ({
                    id: user._id,
                    name: user.nickname,
                    avatar: user.avatar || "",
                    online: false,
                    isSystemAvatar: user.isSystemAvatar,
                    rating: user.rating,
                    rank: user.rankFull
                        ? {
                            title: user.rankFull.name as RankType, 
                            level: user.rankFull.rank,   
                            start: user.rankFull.start,  
                            end: user.rankFull.end,      
                            rakeBack: user.rankFull.rakeBack, 
                        }
                        : undefined,
                }));

                return searchedUsers;
            } else {
                console.error("Unexpected response format:", response.data);
                return [];
            }
        } catch (error) {
            console.error("Error in searchUsers:", error);
            return [];
        }
    }

    @action
    async addFriend(addFriendParams: IFriendAdd) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await addFriend(addFriendParams);
            const data = res.data;
            if ("success" in data) {
                this.addSentRequest(addFriendParams.playerTo);
                await this.getFriends();
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setIsErrorOccured(true);
            return e.response?.data || "Add friend error";
        } finally {
            this.setLoadedState(true);
        }
    }


    @action
    async removeFriend(removeFriendParams: IFriendRemove) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await removeFriend(removeFriendParams);
            const data = res.data;
            if ("success" in data) {
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setIsErrorOccured(true);
            return e.response?.data || "Remove friend error";
        } finally {
            this.setLoadedState(true);
        }
    }

    @action
    async approveFriendRequest(params: IAcceptFriendRequest): Promise<IFriendActionResponse | void> {
        try {
            this.setLoadedState(false);
            this.setIsErrorOccured(false);

            const approveFriendParams: IFriendAdd = {
                playerTo: params.id,
            };

            const response = await approveFriend(approveFriendParams);

            await this.refreshFriends();
            return response.data;
        } catch (e) {
            console.error('Error in approveFriendRequest:', e);
            this.setIsErrorOccured(true);
            if (e.response && e.response.status === 404) {
                return { success: false, message: "friend_not_found", error: "Friend not found", statusCode: 404 };
            }
            throw e;
        } finally {
            this.setLoadedState(true);
        }
    }

    @action
    async rejectFriendRequest(rejectFriendParams: IFriendRemove) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await removeFriend(rejectFriendParams);
            const data = res.data;
            if ("success" in data) {
                await this.getFriends();
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setIsErrorOccured(true);
            if (e.response && e.response.status === 401) {
                return {
                    success: false,
                    message: "They are not friends",
                    error: "friend_not_friends",
                    statusCode: 401,
                    playerTo: rejectFriendParams.playerTo
                };
            }
            return e.response?.data || "Reject friend request error";
        } finally {
            this.setLoadedState(true);
        }
    }

    @action
    async deleteFriend(deleteFriendParams: IFriendRemove) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await removeFriend(deleteFriendParams);
            const data = res.data;
            if (data.success) {
                this.removeSentRequest(deleteFriendParams.playerTo);
                this.friends = this.friends.filter(
                    friend => friend.user._id !== deleteFriendParams.playerTo
                );
                await this.getFriends();
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setIsErrorOccured(true);
            return e.response?.data || "Delete friend error";
        } finally {
            this.setLoadedState(true);
        }
    }


    @action
    private async refreshFriends(): Promise<void> {
        try {
            await this.getFriends();
        } catch (e) {
            console.error('Error refreshing friends:', e);
            this.setIsErrorOccured(true);
        }
    }

    @action
    updateFriendById(friendId: string, params: Partial<IFriendUpdateParams>) {
        this.friends = this.friends
            .map((friend) => {
                if (friend.id === friendId) {
                    const updatedGame = params.game
                        ? { ...friend.game, ...params.game }
                        : friend.game;

                    return { ...friend, ...params, game: updatedGame };
                }
                return friend;
            })
            .sort((a, b) => {
                return a.online === b.online ? 0 : a.online ? -1 : 1;
            });
    }

    @action
    updateAfterDisconnect(friendId: string) {
        this.friends = this.friends
            .map((friend) => {
                if (friend.id === friendId) {
                    return friend.isPlaying
                        ? { ...friend, online: false, isPlaying: true }
                        : { ...friend, online: false, isPlaying: false };
                }
                return friend;
            })
            .sort((a, b) => {
                return a.online === b.online ? 0 : a.online ? -1 : 1;
            });
    }

    @action
    setLoadedState(isLoaded: boolean) {
        this.isLoaded = isLoaded;
    }

    @action
    setIsErrorOccured(isErrorOccured: boolean) {
        this.errorOccured = isErrorOccured;
    }

    @action
    setFriends(friends: IFriend[]) {
        if (Array.isArray(friends)) {
            this.friends = friends.map((friend) => ({
                ...friend,
                online: friend.online || false,
                isPlaying: friend.isPlaying || false,
            }));
        } else {
            console.error("Invalid friends data in setFriends:", friends);
            this.friends = [];
        }
        this.isLoaded = true;
    }

    @action
    setSelectedFriend(friend: IFriend) {
        this.selectedFriend = friend;
    }

    private async updateFriendsList() {
        try {
            await this.getFriends();
        } catch (e) {
            console.error('Error updating friends list:', e);
        }
    }

    @action
    isFriend(userId: string): boolean {
        return this.friends.some(friend => friend.user._id === userId);
    }

    @action
    clearFriends() {
        this.friends = [];
        this.sentRequests = [];
    }
}

export default FriendsStore;
