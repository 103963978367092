import React from "react";
import { Modal, useMediaQuery } from "@mui/material";
import { useStyles } from '../styles';
import closeIcon from "src/assets/icons/x.svg";
import TopPlayers from "src/pages/lobby/components/TopPlayers/TopPlayers";

interface TopModalProps {
    open: boolean;
    onClose: () => void;
}

const TopModal: React.FC<TopModalProps> = ({ open, onClose }) => {
    const isDesktop = useMediaQuery('(min-width:1025px)');
    const styles = useStyles({ isDesktop });

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={styles.modal}
            BackdropProps={{
                style: {
                    backgroundColor: isDesktop ? 'transparent' : 'rgba(0, 0, 0, 0.2)'
                }
            }}
        >
            <div className={styles.modalContent}>
                <button onClick={onClose} className={styles.closeButton}>
                    <img src={closeIcon} alt="Close" />
                </button>
                <div className={styles.content}>
                    <TopPlayers componentPlace="modal" />
                </div>
            </div>
        </Modal>
    );
};

export default TopModal;
