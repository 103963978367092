import { GameTime, GameTimeValues, GameTypeNew } from "./lobbyStore";
import { GameCreateFen } from "./models";

export const getTimeAndIncrement = (t: GameTimeValues) => {
    if (t === GameTimeValues["2m"]) { 
        return {
            time: 2,
            increment: 2,
        };
    } else if (t === GameTimeValues["3m"]) {
        return {
            time: 3,
            increment: 3,
        };
    } else if (t === GameTimeValues["5m"]) {
        return {
            time: 5,
            increment: 5,
        };
    } else if (t === GameTimeValues["10m"]) {
        return {
            time: 10,
            increment: 0,
        };
    } else if (t === GameTimeValues["20m"]) {
        return {
            time: 20,
            increment: 0,
        };
    } else {
        return {
            time: 10,
            increment: 10,
        };
    }
};

export const getTimeAndIncrementFilter = (t: GameTimeValues) => {
    if (t === GameTimeValues["2m"]) { 
        return GameTime["2m"];
    } else if (t === GameTimeValues["3m"]) {
        return GameTime["3m"];
    } else if (t === GameTimeValues["5m"]) {
        return GameTime["5m"];
    } else if (t === GameTimeValues["10m"]) {
        return GameTime["10m"];
    } else if (t === GameTimeValues["20m"]) {
        return GameTime["20m"];
    } else {
        return GameTime["3m"];
    }
};

export const getTypeGameFilter = (t: GameCreateFen) => {
    if (t === GameCreateFen.classic) {
        return GameTypeNew.classic;
    } else if (t === GameCreateFen.nineHundredSixty) {
        return GameTypeNew.nineHundredSixty;
    } else {
        return GameTypeNew.classic;
    }
};
