import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        marginBottom: 16,
    },

    icon: {
        width: 30,
        height: 30,
    },

    listtitle: {
        fontSize: 18,
        fontWeight: 700,
        color: '#F5F5F5',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
});
