import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStyles } from "./styles";
import defaultAvatar from 'src/assets/images/defaultPlaceholder.png';
import videoCameraIcon from "src/assets/icons/VideoCamera.svg";
import useStores from "../../../../../../../hooks/useStores";
import { ITournamentPlayerNew, TournamentPair } from "../../../../../../../store/models";
import { useHistory, useParams } from "react-router-dom";
import inActiveLeagueTab from "src/assets/icons/inActiveLeague.svg";
import activeLeagueTab from "src/assets/icons/ListBullets.svg";
import inActiveVideoTab from "src/assets/icons/VideoIcons.svg";
import activeVideoTab from "src/assets/icons/activeVideo.svg";
import endpoints from "../../../../../../../core/endpoints";
import { Avatar } from "../../../../../../../shared/ui/Avatar";
import { UserStarsBorder } from "../../../../../../../components/controls/app-bar/components/animateStar/UserStarsBorder";
import { MAX_XL_TABLET } from "../../../../../../game-new2/constants";
import { goTo } from "../../../../../../../helpers";
import playIcon from "src/assets/icons/Play.svg";
import cn from "classnames";

const MobileLeaguePage = () => {
    const styles = useStyles();
    const { tournamentsStore, authStore, viewHistoryGameStore } = useStores();
    const { id } = useParams<{ id: string }>();
    const currentUserId = authStore.currentUser?._id;
    const history = useHistory();

    const [selectedMatchIndex, setSelectedMatchIndex] = useState<number | null>(null);
    const [players, setPlayers] = useState<ITournamentPlayerNew[]>([]);
    const [balances, setBalances] = useState<Map<string, number>>(new Map());
    const [matches, setMatches] = useState<{
        player1: any;
        player2: any;
        gameId: string | null;
        state: 'active' | 'upcoming' | 'searching';
    }[]>([]);
    const [activeTab, setActiveTab] = useState<"players" | "matches">("players"); 
    const [pauseTimeLeft, setPauseTimeLeft] = useState<string | null>(null);
    const [isRebuyOpen, setIsRebuyOpen] = useState(false);

    const playerPositions = useRef(new Map());
    const playerRefs = useRef(new Map());
    const matchPositions = useRef(new Map());
    const matchRefs = useRef(new Map());
    const prevBalanceRef = useRef<number | null>(null);

    const currentTournament = useMemo(() => {
        return tournamentsStore.tournaments.find(t => t._id === id);
    }, [id, tournamentsStore.tournaments]);

    useEffect(() => {
        if (!currentTournament) return;

        setPlayers(currentTournament.players || []);

        const balanceMap = new Map<string, number>();
        const matchesList: {
            player1: any;
            player2: any;
            gameId: string | null;
            state: 'active' | 'upcoming' | 'searching';
        }[] = [];

        const gameIdToEntries = new Map<string, any[]>();

        currentTournament.table?.forEach(entry => {
            if (entry.player?._id) {
                balanceMap.set(entry.player._id, entry.chips || 0);
            }

            if (entry.currentGameId) {
                if (!gameIdToEntries.has(entry.currentGameId)) {
                    gameIdToEntries.set(entry.currentGameId, []);
                }
                gameIdToEntries.get(entry.currentGameId)?.push(entry);
            }
        });

        // Активные матчи
        gameIdToEntries.forEach((entries, gameId) => {
            let player1 = entries[0]?.player ?? null;
            let player2 = entries[1]?.player ?? null;

            if (!player2 && entries[0]?.nextOpponent) {
                player2 = entries[0].nextOpponent;
            }

            matchesList.push({ player1, player2, gameId, state: 'active' });
        });

        // Те, у кого нет игры
        currentTournament.table?.forEach(entry => {
            if (!entry.currentGameId && entry.player?._id) {
                const alreadyInMatch = matchesList.some(m =>
                    m.player1?._id === entry.player._id || m.player2?._id === entry.player._id
                );
                if (alreadyInMatch) return;

                const player1 = entry.player;
                const player2 = entry.nextOpponent ?? null;
                const state = player2 ? 'upcoming' : 'searching';

                matchesList.push({ player1, player2, gameId: null, state });
            }
        });

        setBalances(balanceMap);
        setMatches(matchesList);
    }, [currentTournament]);

    const isPauseActive = useMemo(() => {
        const ts = currentTournament?.tournamentSettings;
        return !!(ts?.currentTournamentPause || ts?.currentTournamentWaitingGamesEnd);
    }, [currentTournament]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        const updatePauseTimer = () => {
            const pauseEnd = currentTournament?.tournamentSettings?.currentPauseEndsAt;

            if (!pauseEnd) {
                setPauseTimeLeft(null);
                return;
            }

            const diff = new Date(pauseEnd).getTime() - Date.now();
            if (diff <= 0) {
                setPauseTimeLeft("00:00");
                clearInterval(interval);
                return;
            }

            const minutes = Math.floor(diff / 1000 / 60);
            const seconds = Math.floor((diff / 1000) % 60);
            const pad = (n: number) => (n < 10 ? `0${n}` : n);
            setPauseTimeLeft(`${pad(minutes)}:${pad(seconds)}`);
        };

        if (currentTournament?.tournamentSettings?.currentTournamentPause) {
            updatePauseTimer();
            interval = setInterval(updatePauseTimer, 1000);
        }

        return () => clearInterval(interval);
    }, [currentTournament?.tournamentSettings?.currentPauseEndsAt, currentTournament?.tournamentSettings?.currentTournamentPause]);

    useEffect(() => {
        if (!currentTournament || !currentUserId) return;

        const chipsStartLevel = currentTournament.tournamentSettings?.chipsStartLevel ?? 100;
        const threshold = chipsStartLevel * 0.4;
        const currentBalance = balances.get(currentUserId) ?? 0;
        const prevBalance = prevBalanceRef.current;

        const isPauseActive = currentTournament?.tournamentSettings?.currentTournamentPause || currentTournament?.tournamentSettings?.currentTournamentWaitingGamesEnd;

        // Проверка на паузу и снижение баланса
        if (
            (prevBalance !== null &&
                prevBalance >= threshold &&
                currentBalance < threshold &&
                currentBalance > 0) ||
            (isPauseActive && currentBalance < chipsStartLevel) // Если на паузе и меньше 100% от chipsStartLevel
        ) {
            setIsRebuyOpen(true);
        }

        // Обновляем предыдущее значение
        prevBalanceRef.current = currentBalance;
    }, [balances, currentTournament, currentUserId]);


    const getAvatarURL = (avatarPath: string | null | undefined): string => {
        return avatarPath ? `${endpoints.avatarPath}/${avatarPath}` : defaultAvatar;
    };

    const savePlayerPositions = () => {
        playerRefs.current.forEach((el, playerId) => {
            if (el) {
                const rect = el.getBoundingClientRect();
                playerPositions.current.set(playerId, rect);
            }
        });
    };

    const applyPlayerAnimation = () => {
        playerRefs.current.forEach((el, playerId) => {
            if (el) {
                const prevRect = playerPositions.current.get(playerId);
                if (prevRect) {
                    const currentRect = el.getBoundingClientRect();
                    const deltaY = prevRect.top - currentRect.top;

                    if (Math.abs(deltaY) > 1) {
                        el.style.transform = `translateY(${deltaY}px)`;
                        el.style.transition = 'transform 0s';

                        requestAnimationFrame(() => {
                            el.style.transform = 'translateY(0)';
                            el.style.transition = 'transform 500ms ease';

                            setTimeout(() => {
                                el.style.transform = '';
                                el.style.transition = '';
                            }, 500);
                        });
                    }
                }
            }
        });
    };

    const saveMatchPositions = () => {
        matchRefs.current.forEach((el, matchId) => {
            if (el) {
                const rect = el.getBoundingClientRect();
                matchPositions.current.set(matchId, rect);
            }
        });
    };

    const applyMatchAnimation = () => {
        matchRefs.current.forEach((el, matchId) => {
            if (el) {
                const prevRect = matchPositions.current.get(matchId);
                if (prevRect) {
                    const currentRect = el.getBoundingClientRect();
                    const deltaY = prevRect.top - currentRect.top;

                    if (Math.abs(deltaY) > 1) {
                        el.style.transform = `translateY(${deltaY}px)`;
                        el.style.transition = 'transform 0s';

                        requestAnimationFrame(() => {
                            el.style.transform = 'translateY(0)';
                            el.style.transition = 'transform 500ms ease';

                            setTimeout(() => {
                                el.style.transform = '';
                                el.style.transition = '';
                            }, 500);
                        });
                    }
                }
            }
        });
    };

    const handleMatchClick = (index: number) => {
        setSelectedMatchIndex(index === selectedMatchIndex ? null : index);
    };

    const steakBonus = currentTournament?.tournamentSettings?.steakBonus ?? 0;
    const steakBonusStart = currentTournament?.tournamentSettings?.steakBonusStart ?? 0;

    const sortedPlayers = useMemo(() => {
        savePlayerPositions();

        const sorted = [...players].sort((a, b) => {
            const balanceA = balances.get(a._id) ?? 0;
            const balanceB = balances.get(b._id) ?? 0;
            return balanceB - balanceA;
        });

        setTimeout(() => {
            applyPlayerAnimation();
        }, 0);

        return sorted;
    }, [players, balances]);

    const sortedMatches = useMemo(() => {
        saveMatchPositions();

        const activeMatches = matches.filter(match => match.gameId);

        const sorted = [...activeMatches].sort((a, b) => {
            const aUserMatch = currentUserId && (a.player1?._id === currentUserId || a.player2?._id === currentUserId);
            const bUserMatch = currentUserId && (b.player1?._id === currentUserId || b.player2?._id === currentUserId);

            if (aUserMatch && !bUserMatch) return -1;
            if (!aUserMatch && bUserMatch) return 1;

            const balanceA = (balances.get(a.player1?._id || "") ?? 0) + (balances.get(a.player2?._id || "") ?? 0);
            const balanceB = (balances.get(b.player1?._id || "") ?? 0) + (balances.get(b.player2?._id || "") ?? 0);
            return balanceB - balanceA;
        });

        setTimeout(() => {
            applyMatchAnimation();
        }, 0);

        return sorted;
    }, [matches, balances, currentUserId]);

    const handleMatchAction = (gameId: string, player1Id: string, player2Id: string) => {
        const isCurrentUserMatch = currentUserId && (currentUserId === player1Id || currentUserId === player2Id);

        if (!gameId) return;

        if (isCurrentUserMatch) {
            goTo(`/game/${gameId}?userKey=${localStorage.getItem("t")}&currentUserId=${currentUserId}`);
        } else {
            if (window.innerWidth < MAX_XL_TABLET) {
                goTo(`/game/${gameId}?userKey=${localStorage.getItem("t")}&currentUserId=${currentUserId}`);
                return;
            } else {
                viewHistoryGameStore.reset();
                let it = setTimeout(() => {
                    viewHistoryGameStore.setGameIdNewView(gameId);
                    clearTimeout(it);
                }, 200);
            }
        }
    };

    return (
        <div className={styles.container}>
            {/* Панель переключения */}
            <div className={styles.tabButtons}>
                <button
                    className={`${styles.tabButton} ${activeTab === "players" ? styles.activeTab : ""}`}
                    onClick={() => setActiveTab("players")}
                >
                    <img
                        className={styles.iconTab}
                        src={activeTab === "players" ? activeLeagueTab : inActiveLeagueTab} 
                        alt="Players"
                    />
                </button>
                <button
                    className={`${styles.tabButton} ${activeTab === "matches" ? styles.activeTab : ""}`}
                    onClick={() => setActiveTab("matches")}
                >
                    <img
                        className={styles.iconTab}
                        src={activeTab === "matches" ? activeVideoTab : inActiveVideoTab} 
                        alt="Matches"
                    />
                </button>
            </div>

            {/* Содержимое вкладки Players */}
            {activeTab === "players" && (
                <div className={styles.leftPanel}>
                    <div className={styles.scrollableList}>
                        {sortedPlayers.map((player, index) => {
                            const tableEntry = currentTournament?.table.find(entry => entry.player._id === player._id);
                            const winLoseHistory = tableEntry?.winLoseHistory || [];
                            const balance = balances.get(player._id) ?? 0;

                            return (
                                <div
                                    key={player._id}
                                    className={styles.playerItem}
                                    ref={el => playerRefs.current.set(player._id, el)}
                                >
                                    <span className={styles.rank}>{index + 1}</span>
                                    <div className={styles.playerInfo}>
                                        <span className={styles.playerName}>{player.nickname}</span>
                                        <span className={styles.playerRating}>
                                            Rating: {Math.round(player.rating ?? 1200)}
                                        </span>
                                    </div>

                                    <div className={styles.scoreContainer}>
                                        <div className={styles.scoreCirclesRow}>
                                            {winLoseHistory.map((num, i) => {
                                                const isWhite = Number(num) < 6;
                                                const isEligible = isWhite && Number(num) >= steakBonusStart;
                                                return (
                                                    <span key={i} className={isEligible ? styles.scoreCircle : styles.emptyCircle}>
                                                        {isEligible ? steakBonus : ""}
                                                    </span>
                                                );
                                            })}
                                        </div>

                                        <div className={styles.scoreNumbersRow}>
                                            {winLoseHistory
                                                .map((num, i) => {
                                                    const isWhite = Number(num) < 6;
                                                    return (
                                                        <span
                                                            key={i}
                                                            className={`${styles.scoreNumber} ${isWhite ? styles.whiteNumber : styles.redNumber}`}
                                                        >
                                                            {num}
                                                        </span>
                                                    );
                                                })}
                                        </div>
                                    </div>

                                    <div className={styles.balanceBox}>
                                        <span className={styles.balance}>{balance}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {/* Содержимое вкладки Matches */}
            {activeTab === "matches" && (
                <div className={styles.centerPanel}>
                    {isPauseActive && (
                        <div className={styles.pauseBox}>
                            {currentTournament?.tournamentSettings?.currentTournamentPause && pauseTimeLeft ? (
                                <span>Tournament pause: {pauseTimeLeft}</span>
                            ) : (
                                <span>Waiting for all games to finish...</span>
                            )}
                        </div>
                    )}

                    <div className={styles.matchListWrapper}>
                        {!isPauseActive && (
                            <div className={styles.matchList}>
                                {sortedMatches.map((match, index) => {
                                    const player1 = match.player1;
                                    const player2 = match.player2;

                                    const balance1 = balances.get(player1?._id || "") ?? 0;
                                    const balance2 = balances.get(player2?._id || "") ?? 0;

                                    const isCurrentUserMatch = currentUserId && (player1?._id === currentUserId || player2?._id === currentUserId);

                                    return (
                                        <div
                                            key={match.gameId}
                                            className={`${styles.matchItem} ${index % 2 === 0 ? styles.matchItemEven : styles.matchItemOdd
                                                } ${selectedMatchIndex === index ? styles.matchItemSelected : ''}`}
                                            onClick={() => handleMatchClick(index)}
                                            ref={el => matchRefs.current.set(match.gameId, el)}
                                        >
                                            <div className={styles.players}>
                                                {/* Игрок 1 */}
                                                <div className={styles.player}>
                                                    <div className={styles.avatarContainer}>
                                                        <Avatar
                                                            src={getAvatarURL(player1?.avatar)}
                                                            wrapperClassName={styles.playerAvatar}
                                                        />
                                                        <UserStarsBorder
                                                            starsCount={player1?.stars || 0}
                                                            className={styles.starsOverlay}
                                                            starSize={10}
                                                        />
                                                    </div>
                                                    <div className={styles.playerWithBalance}>
                                                        <span className={styles.nicknameBox}>{player1?.nickname || "Unknown"}</span>
                                                        <span className={styles.playerBalanceSmall}>
                                                            Balance: <span className={styles.balanceValue}>{balance1}</span>
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Игрок 2 */}
                                                <div className={styles.player}>
                                                    {player2 ? (
                                                        <>
                                                            <div className={styles.avatarContainer}>
                                                                <Avatar src={getAvatarURL(player2.avatar)} wrapperClassName={styles.playerAvatar} />
                                                                <UserStarsBorder
                                                                    starsCount={player2.stars || 0}
                                                                    className={styles.starsOverlay}
                                                                    starSize={10}
                                                                />
                                                            </div>
                                                            <div className={styles.playerWithBalance}>
                                                                <span className={styles.nicknameBox}>{player2.nickname}</span>
                                                                <span className={styles.playerBalanceSmall}>
                                                                    Balance: <span className={styles.balanceValue}>{balances.get(player2._id) ?? '—'}</span>
                                                                </span>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className={styles.avatarContainer}></div>
                                                            <div className={styles.playerWithBalance}>
                                                                <span className={styles.searchingOpponent}>Search opponent...</span>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            
                                            <div
                                                className={cn(styles.cameraIconContainer, {
                                                    [styles.playContainer]: isCurrentUserMatch,
                                                    [styles.viewContainer]: !isCurrentUserMatch,
                                                })}
                                                onClick={() => match.gameId && handleMatchAction(match.gameId, player1?._id, player2?._id)}
                                            >
                                                <img
                                                    className={styles.cameraIcon}
                                                    src={isCurrentUserMatch ? playIcon : videoCameraIcon}
                                                    alt={isCurrentUserMatch ? "Play" : "View"}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileLeaguePage;
