import cn from 'classnames';
import {useLayoutEffect, useRef, useState, useEffect} from 'react';
import {animated, useSpring} from 'react-spring';
import {useHistory} from 'react-router-dom';
import {usePlayer} from 'src/sounds/use-player';

import {useLocale} from 'src/providers/LocaleProvider';
import useWindowSize from 'src/hooks/useWindowSize';
import paths from 'src/consts/paths';
import useStores from 'src/hooks/useStores';
import {
    GameChatToggleStatus,
    EGameChatTab,
    GameModeTypeNew,
} from 'src/store/models';

import {useChessBoardProps} from '../hooks/useChessBoardProps';
import {MAX_XL_TABLET} from '../constants';

import {useStyles} from './styles';

import DesktopLayout from './layoutTypes/desktop/desktopLayout';
import MobileTabletLayout from './layoutTypes/mobileTablet/mobileTabletLayout';
import {boardRoot} from "../../../core/endpoints";
import {goTo} from "../../../helpers";

interface Props {
    //LEGACY
    diceClickRnd?: number;
    setDiceClickRnd?: any;
    rndPrefix?: number;
}

const Layout = (props: Props) => {
    const {gameStore, gamesStore, authStore, tournamentsStore} = useStores();
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;
    const isMoveSound = gameStore.gameState.moveSound;
    const winner = gameStore.currentGameNew?.winner;
    const isTournamentGame =
        gameStore.currentGameNew?.type === GameModeTypeNew.TOURNAMENT ||
        gameStore.currentGameNew?.type === GameModeTypeNew.TOURNAMENT_LEAGUE;

    const isViewMode = gameStore.isViewMode();
    const tournamentId = gameStore.currentGameNew?.tournamentId ?? null;
    const moveHistory = gameStore.currentGameNew?.movesHistory;

    const me = authStore.currentUser?._id;
    const [tabletRatio, setRatioSize] = useState<boolean | undefined>(() => {
        // const orientation = currentWidth - currentHeight;
        if (currentWidth < MAX_XL_TABLET) true;
        else return false;
    });

    const [chatTogglerState, runChatToggler] = useState<
        GameChatToggleStatus.open | GameChatToggleStatus.hide | boolean
    >(false);
    const {width, height} = useWindowSize();
    const player = usePlayer();

    const gameContainerRef = useRef<any>(null);
    const history = useHistory();
    const {
        game: {
            multiTable: {toSoloGame},
        },
    } = useLocale();

    const {isMultiTable, isTwoGameTable, currentGame} = useChessBoardProps();

    const style = useStyles();
    const isOneActiveGame = gamesStore.gameTables.filter(
        (table) => table.state === 'game'
    )?.length;

    const styleSpring = useSpring({
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
        delay: 1000,
    });

    useLayoutEffect(() => {
        if (currentWidth < 1024) setRatioSize(true);
        else setRatioSize(false);
    }, [width, height, currentWidth, currentHeight]);

    const viewMode = gameStore.isViewMode();
    const moveHistoryData = moveHistory
        ? moveHistory[moveHistory.length - 1]
        : null;

    const oppoSound =
        moveHistoryData &&
        moveHistoryData.user !== me &&
        !!moveHistoryData.move;

    useEffect(() => {
        if (!viewMode) return;
        if (gameStore.nextRound) {
            // history.push(`/game/${gameStore.nextRound.gameId}`);

            // document.location.href = `/game/${gameStore.nextRound.gameId}`;

            (goTo(boardRoot + `/game/${gameStore.nextRound.gameId}?userKey=${localStorage.getItem("t")}&currentUserId=${authStore.currentUser?._id}`)); return;

        }
    }, [viewMode, gameStore.nextRound]);

    // useEffect(() => {
    //     if (oppoSound && !winner) {
    //         return player("pieceSoundPlay");
    //     }
    // }, [isMoveSound, oppoSound, moveHistoryData]);

    // useEffect(() => {
    //     const redirectTime = 3000;
    //     let redirectTimeout;
    //     if (isTournamentGame && !isViewMode && !!winner && !!tournamentId) {
    //         redirectTimeout = setTimeout(() => {
    //             history.push(`${paths.tournaments}/${tournamentId}`);
    //         }, redirectTime);
    //     }
    //     if (redirectTimeout) {
    //         return () => {
    //             clearTimeout(redirectTimeout);
    //         };
    //     }
    // }, [isViewMode, isTournamentGame, winner, tournamentId]);

        useEffect(() => {
        if (isTournamentGame && !isViewMode && !!winner && !!tournamentId) {
            tournamentsStore.setFinishedGameForAlert(tournamentId, 5000);
        }
    }, [isViewMode, isTournamentGame, winner, tournamentId]);

    if (!width || !height) return null;

    return (
        <animated.div
            className={cn(style.gameContainerAppearAnimate, {})}
            style={{
                ...styleSpring,
            }}
        >
            <div
                ref={gameContainerRef}
                className={cn(style.gameContainer, {
                    isMultiTable,
                    isTwoGameTable,
                })}
            >
                {tabletRatio ? (
                    <MobileTabletLayout tabletRatio={tabletRatio} />
                ) : (
                    <DesktopLayout
                        tabletRatio={tabletRatio}
                        gameContainerRef={gameContainerRef?.current}
                    />
                )}
            </div>
        </animated.div>
    );
};

export default Layout;
